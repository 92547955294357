import React from "react";
import PropTypes from "prop-types";

interface RightChevronProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
}

export class RightChevron extends React.Component<RightChevronProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          transform={this.props.transform}
          d="M8,29.8l-1.3-1.3c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0L18.9,15L6.7,2.7
	c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0L8,0.2c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0l14.2,14.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
	L9.2,29.8C8.8,30.1,8.3,30.1,8,29.8z"
        />
        {/* <path d="M0 0h24v24H0z" fill="none" /> */}
      </svg>
    );
  }
}

interface PlusCircleProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class PlusCircle extends React.Component<PlusCircleProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    filter: PropTypes.string
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        // viewBox={this.props.viewBox}
        viewBox={"0 0 20 20"}
        xmlns="http://www.w3.org/2000/svg"
        filter={this.props.filter}>
        <path
          fill={"white"}
          d="m15.81,11.13v-2.26c0-.27-.22-.48-.48-.48h-3.71v-3.71c0-.27-.22-.48-.48-.48h-2.26c-.27,0-.48.22-.48.48v3.71h-3.71c-.27,0-.48.22-.48.48v2.26c0,.27.22.48.48.48h3.71v3.71c0,.27.22.48.48.48h2.26c.27,0,.48-.22.48-.48v-3.71h3.71c.27,0,.48-.22.48-.48Z"
        />
        <path d="m10,0C4.48,0,0,4.48,0,10s4.48,10,10,10,10-4.48,10-10S15.52,0,10,0Zm5.81,11.13c0,.27-.22.48-.48.48h-3.71v3.71c0,.27-.22.48-.48.48h-2.26c-.27,0-.48-.22-.48-.48v-3.71h-3.71c-.27,0-.48-.22-.48-.48v-2.26c0-.27.22-.48.48-.48h3.71v-3.71c0-.27.22-.48.48-.48h2.26c.27,0,.48.22.48.48v3.71h3.71c.27,0,.48.22.48.48v2.26Z" />
      </svg>
    );
  }
}

interface SearchIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class SearchIcon extends React.Component<SearchIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 20 20"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m19.73,17.29l-3.9-3.9c-.18-.18-.42-.27-.66-.27h-.64c2.76-3.54,2.13-8.64-1.41-11.4C9.59-1.04,4.48-.41,1.72,3.13-1.04,6.66-.41,11.77,3.13,14.53c2.94,2.29,7.06,2.29,10,0v.64c0,.25.1.49.27.66l3.9,3.9c.36.37.96.37,1.32,0,0,0,0,0,0,0l1.11-1.11c.37-.37.37-.96,0-1.33Zm-11.61-4.17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5h0c0,2.76-2.24,5-5,5Z" />
      </svg>
    );
  }
}

interface ChevronCircleIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ChevronCircleIcon extends React.Component<ChevronCircleIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 21 21"}
        xmlns="http://www.w3.org/2000/svg">
        <circle fill={"white"} stroke={this.props.color} cx="10.5" cy="10.5" r="10" />
        <path d="m13.3,16.41l.54-.54c.13-.13.13-.33,0-.46,0,0,0,0,0,0l-4.9-4.91,4.9-4.91c.13-.13.13-.33,0-.46,0,0,0,0,0,0l-.54-.54c-.13-.13-.33-.13-.46,0,0,0,0,0,0,0l-5.67,5.68c-.13.13-.13.33,0,.46,0,0,0,0,0,0l5.68,5.67c.13.13.33.13.46,0Z" />
      </svg>
    );
  }
}

interface InfoIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class InfoIcon extends React.Component<InfoIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m15,0C6.72,0,0,6.72,0,15s6.72,15,15,15,15-6.72,15-15S23.28,0,15,0Zm0,27.1c-6.68,0-12.1-5.42-12.1-12.1S8.32,2.9,15,2.9s12.1,5.42,12.1,12.1h0c0,6.68-5.41,12.09-12.08,12.1,0,0,0,0-.01,0Zm0-20.44c1.4,0,2.54,1.14,2.54,2.54s-1.14,2.54-2.54,2.54-2.54-1.14-2.54-2.54,1.14-2.54,2.54-2.54h0Zm3.39,15.36c0,.4-.32.72-.72.72h-5.33c-.4,0-.72-.32-.73-.72v-1.45c0-.4.32-.73.72-.73,0,0,0,0,0,0h.73v-3.87h-.73c-.4,0-.72-.32-.73-.72v-1.45c0-.4.32-.72.73-.73h3.87c.4,0,.72.32.72.73v6.04h.74c.4,0,.72.32.72.72v1.46Z" />
      </svg>
    );
  }
}

interface DeleteIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class DeleteIcon extends React.Component<DeleteIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 18 20"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0,3.3V2.2c0-0.5,0.4-0.9,0.9-0.9h4.4l0.4-0.7C5.8,0.2,6.2,0,6.5,0H11c0.4,0,0.7,0.2,0.8,0.5l0.4,0.7h4.4
	c0.5,0,0.9,0.4,0.9,0.9v1.1c0,0.3-0.2,0.5-0.5,0.5H0.5C0.2,3.8,0,3.5,0,3.3z M16.3,5.5v12.7c0,1-0.8,1.9-1.9,1.9H3.1
	c-1,0-1.9-0.8-1.9-1.9V5.5C1.3,5.2,1.5,5,1.7,5h14.1C16,5,16.3,5.2,16.3,5.5z M5.6,8.1c0-0.3-0.3-0.6-0.6-0.6S4.4,7.8,4.4,8.1v8.8
	c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V8.1z M9.4,8.1c0-0.3-0.3-0.6-0.6-0.6S8.1,7.8,8.1,8.1v8.8c0,0.3,0.3,0.6,0.6,0.6
	s0.6-0.3,0.6-0.6V8.1z M13.1,8.1c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v8.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
	V8.1z"
        />
      </svg>
    );
  }
}

interface PenIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class PenIcon extends React.Component<PenIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 20 20"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4,3.6l5,5L5.5,19.5L1,20c-0.6,0.1-1.1-0.4-1-1l0.5-4.5C0.5,14.5,11.4,3.6,11.4,3.6z M19.5,2.9l-2.3-2.3
          c-0.7-0.7-1.9-0.7-2.7,0l-2.2,2.2l5,5l2.2-2.2C20.2,4.8,20.2,3.6,19.5,2.9z"
        />
      </svg>
    );
  }
}

interface ProfileIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ProfileIcon extends React.Component<ProfileIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 24 24"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,4.6c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3
          s-4.3-1.9-4.3-4.3S9.6,4.6,12,4.6z M12,21.3c-2.8,0-5.4-1.3-7.1-3.3c0.9-1.7,2.7-2.9,4.8-2.9c0.1,0,0.2,0,0.3,0.1
          c0.6,0.2,1.3,0.3,2,0.3s1.4-0.1,2-0.3c0.1,0,0.2-0.1,0.3-0.1c2.1,0,3.9,1.2,4.8,2.9C17.4,20,14.8,21.3,12,21.3z"
        />
      </svg>
    );
  }
}

interface EllipsisIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class EllipsisIcon extends React.Component<EllipsisIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 20 5.81"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m12.9,2.9c0,1.6-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9,1.3-2.9,2.9-2.9,2.9,1.3,2.9,2.9Zm4.19-2.9c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9,2.9-1.3,2.9-2.9-1.3-2.9-2.9-2.9ZM2.9,0C1.3,0,0,1.3,0,2.9s1.3,2.9,2.9,2.9,2.9-1.3,2.9-2.9S4.51,0,2.9,0Z" />
      </svg>
    );
  }
}

interface HelpIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class HelpIcon extends React.Component<HelpIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15,20.1c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7S15.9,20.1,15,20.1z M15.5,18.6h-1
	c-0.4,0-0.7-0.3-0.7-0.7v0c0-4.3,4.7-3.8,4.7-6.5c0-1.2-1.1-2.4-3.5-2.4c-1.8,0-2.7,0.6-3.6,1.7c-0.2,0.3-0.7,0.4-1,0.1l-0.8-0.6
	c-0.3-0.2-0.4-0.7-0.2-1c1.3-1.6,2.8-2.7,5.5-2.7c3.2,0,5.9,1.8,5.9,4.9c0,4.1-4.7,3.9-4.7,6.5v0C16.2,18.3,15.9,18.6,15.5,18.6
	L15.5,18.6z M15,1.9c7.2,0,13.1,5.8,13.1,13.1c0,7.2-5.8,13.1-13.1,13.1C7.8,28.1,1.9,22.2,1.9,15C1.9,7.8,7.8,1.9,15,1.9 M15,0
	C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15s15-6.7,15-15C30,6.7,23.3,0,15,0z"
        />
      </svg>
    );
  }
}

interface UploadIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class UploadIcon extends React.Component<UploadIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 22.5 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m21.67,5.74L16.75.83c-.53-.53-1.24-.82-1.98-.83H2.81C1.26,0,0,1.26,0,2.82v24.37c0,1.55,1.26,2.81,2.81,2.81h16.87c1.55,0,2.81-1.26,2.82-2.81V7.73c0-.75-.3-1.46-.83-1.99Zm-6.67-2.7l4.45,4.45h-4.45V3.05Zm4.69,24.14H2.81V2.82h9.37v6.09c0,.77.62,1.4,1.39,1.41,0,0,0,0,.01,0h6.1v16.87Zm-8.97-13.84l-4.24,4.2c-.28.27-.28.71-.01.99.13.14.32.21.51.21h2.86v4.92c0,.39.31.7.7.7h1.41c.39,0,.7-.31.7-.7h0v-4.92h2.87c.39,0,.7-.3.71-.69,0-.19-.07-.38-.21-.51l-4.24-4.2c-.29-.29-.76-.29-1.06,0h0Z" />
      </svg>
    );
  }
}

interface LogoutIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class LogoutIcon extends React.Component<LogoutIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 22.5"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m10.55,22.5h-4.92c-3.11,0-5.62-2.52-5.62-5.62V5.62C0,2.52,2.52,0,5.62,0h4.92c.39,0,.7.32.7.7v2.34c0,.39-.32.7-.7.7h-4.92c-1.04,0-1.87.84-1.87,1.87v11.25c0,1.04.84,1.87,1.87,1.87h4.92c.39,0,.7.32.7.7v2.34c0,.39-.32.7-.7.7Zm6.91-17.76l4.55,4.17h-11.22c-.78,0-1.41.63-1.41,1.41v1.87c0,.78.63,1.41,1.41,1.41h11.22l-4.55,4.17c-.59.54-.61,1.46-.05,2.03l1.28,1.28c.54.54,1.44.55,1.99,0l8.91-8.84c.56-.55.56-1.45,0-2L20.68,1.42c-.55-.54-1.44-.54-1.99,0l-1.28,1.28c-.57.56-.54,1.49.04,2.03h0Z" />
      </svg>
    );
  }
}

interface ChatIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ChatIcon extends React.Component<ChatIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 26.25"}
        xmlns="http://www.w3.org/2000/svg">
        <path d="m8.44,10.31c-1.04,0-1.87.84-1.87,1.87s.84,1.87,1.87,1.87,1.87-.84,1.87-1.87c0-1.03-.84-1.87-1.87-1.87h0Zm6.56,0c-1.04,0-1.87.84-1.87,1.87,0,1.04.84,1.87,1.87,1.87s1.87-.84,1.87-1.87c0-1.03-.84-1.87-1.87-1.87h0Zm6.56,0c-1.04,0-1.87.84-1.87,1.87s.84,1.87,1.87,1.87,1.87-.84,1.87-1.87c0-1.03-.84-1.87-1.87-1.87h0ZM15,0C6.72,0,0,5.46,0,12.19c.04,2.77,1.15,5.42,3.1,7.4-.65,1.57-1.57,3.03-2.72,4.29-.53.56-.51,1.46.06,1.99.26.25.61.38.97.38,2.93-.04,5.78-.99,8.15-2.71,1.76.56,3.6.84,5.44.84,8.29,0,15-5.46,15-12.19S23.29,0,15,0Zm0,21.56c-1.56,0-3.11-.24-4.59-.71l-1.33-.43-1.15.81c-1.03.73-2.17,1.31-3.37,1.7.46-.75.85-1.54,1.17-2.36l.62-1.65-1.2-1.27c-1.45-1.46-2.28-3.42-2.33-5.47C2.82,7.02,8.28,2.81,15,2.81s12.19,4.21,12.19,9.38-5.47,9.38-12.19,9.38Z" />
      </svg>
    );
  }
}

interface ToolIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ToolIcon extends React.Component<ToolIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.4,23.2l-6.9-6.9c-1.4-1.4-3.4-1.6-5-0.8l-6.2-6.2V5.6L3.8,0L0,3.7l5.6,7.5h3.6l6.2,6.2c-0.8,1.6-0.5,3.7,0.8,5l6.9,6.9
	c0.9,0.9,2.2,0.9,3.1,0l3.1-3.1C30.2,25.4,30.2,24,29.4,23.2L29.4,23.2z M19.4,13.2c1.7,0,3.2,0.6,4.4,1.8l1.1,1.1
	c0.9-0.4,1.8-1,2.6-1.7c2.2-2.2,2.9-5.2,2.2-8c-0.1-0.5-0.8-0.7-1.2-0.3l-4.4,4.4l-4-0.7l-0.7-4l4.4-4.4c0.4-0.4,0.2-1-0.3-1.2
	c-2.8-0.7-5.8,0.1-8,2.2c-1.7,1.7-2.5,3.9-2.4,6.1l4.8,4.8C18.5,13.2,19,13.2,19.4,13.2L19.4,13.2z M13.3,18L10,14.7l-8.9,8.9
	c-1.5,1.5-1.5,3.8,0,5.3s3.8,1.5,5.3,0l7.2-7.2C13.2,20.5,13.1,19.2,13.3,18L13.3,18z M3.8,27.7c-0.8,0-1.4-0.6-1.4-1.4
	c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C5.2,27,4.5,27.7,3.8,27.7z"
        />
      </svg>
    );
  }
}

interface ProjectIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ProjectIcon extends React.Component<ProjectIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 34.3 30"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.1,0H3.2C1.4,0,0,1.4,0,3.2v5.4c0,0.6,0.5,1.1,1.1,1.1h1.1v18.2c0,1.2,1,2.1,2.1,2.1H30c1.2,0,2.1-1,2.1-2.1V9.6h1.1
	c0.6,0,1.1-0.5,1.1-1.1V3.2C34.3,1.4,32.8,0,31.1,0z M28.9,26.8H5.4V9.6h23.6V26.8z M31.1,6.4H3.2V3.2h27.9V6.4z M13.7,16.1h7
	c0.4,0,0.8-0.4,0.8-0.8v-1.6c0-0.4-0.4-0.8-0.8-0.8h-7c-0.4,0-0.8,0.4-0.8,0.8v1.6C12.9,15.7,13.2,16.1,13.7,16.1z"
        />
      </svg>
    );
  }
}

interface MenuHelpIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class MenuHelpIcon extends React.Component<MenuHelpIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15,0C10.4,0,7.3,1.9,4.9,5.3C4.5,6,4.6,6.8,5.2,7.3l2.5,1.9c0.6,0.5,1.5,0.4,1.9-0.2C11.2,7.1,12.3,6,14.6,6
            c1.8,0,4,1.2,4,2.9c0,1.3-1.1,2-2.9,3c-2.1,1.2-4.8,2.6-4.8,6.2v0.6c0,0.8,0.6,1.4,1.4,1.4h4.2c0.8,0,1.4-0.6,1.4-1.4v-0.3
            c0-2.5,7.3-2.6,7.3-9.4C25.3,3.9,20,0,15,0z M14.4,21.9c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1
            S16.7,21.9,14.4,21.9z"
        />
      </svg>
    );
  }
}

interface EnvelopeIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class EnvelopeIcon extends React.Component<EnvelopeIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.4,11.2c0.2-0.2,0.6,0,0.6,0.3v12c0,1.6-1.3,2.8-2.8,2.8H2.8C1.3,26.2,0,25,0,23.4v-12
            c0-0.3,0.3-0.5,0.6-0.3c1.3,1,3.1,2.3,9,6.7c1.2,0.9,3.3,2.8,5.4,2.8c2.1,0,4.2-1.9,5.4-2.8C26.4,13.5,28.1,12.2,29.4,11.2z
             M15,18.8c1.4,0,3.3-1.7,4.3-2.4c7.8-5.6,8.4-6.1,10.2-7.5C29.8,8.5,30,8.1,30,7.7V6.6c0-1.6-1.3-2.8-2.8-2.8H2.8C1.3,3.7,0,5,0,6.6
            v1.1c0,0.4,0.2,0.8,0.5,1.1c1.8,1.4,2.4,1.9,10.2,7.5C11.7,17,13.6,18.8,15,18.8L15,18.8z"
        />
      </svg>
    );
  }
}

interface FolderIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class FolderIcon extends React.Component<FolderIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-46.2-144.8L8.4-90.2c6,6,14.1,9.4,22.6,9.4h192c8.8,0,16,7.2,16,16v224c0,8.8-7.2,16-16,16h-416
	c-8.8,0-16-7.2-16-16v-288c0-8.8,7.2-16,16-16H-46.2 M-193-176.8c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416
	c26.5,0,48-21.5,48-48v-224c0-26.5-21.5-48-48-48H31l-54.6-54.6c-6-6-14.1-9.4-22.6-9.4C-46.2-176.8-193-176.8-193-176.8z"
        />
        <path
          d="M-46.2-144.8L8.4-90.2c6,6,14.1,9.4,22.6,9.4h192c8.8,0,16,7.2,16,16v224c0,8.8-7.2,16-16,16h-416
	c-8.8,0-16-7.2-16-16v-288c0-8.8,7.2-16,16-16H-46.2 M-193-176.8c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416
	c26.5,0,48-21.5,48-48v-224c0-26.5-21.5-48-48-48H31l-54.6-54.6c-6-6-14.1-9.4-22.6-9.4C-46.2-176.8-193-176.8-193-176.8z"
        />
        <path
          d="M11.4,5.6l3.2,3.2c0.4,0.4,0.8,0.5,1.3,0.5h11.2c0.5,0,0.9,0.4,0.9,0.9v13.1c0,0.5-0.4,0.9-0.9,0.9H2.8
	c-0.5,0-0.9-0.4-0.9-0.9V6.6c0-0.5,0.4-0.9,0.9-0.9H11.4 M2.8,3.8C1.3,3.8,0,5,0,6.6v16.9c0,1.6,1.3,2.8,2.8,2.8h24.4
	c1.6,0,2.8-1.3,2.8-2.8V10.3c0-1.6-1.3-2.8-2.8-2.8H15.9l-3.2-3.2c-0.4-0.4-0.8-0.5-1.3-0.5C11.4,3.8,2.8,3.8,2.8,3.8z"
        />
      </svg>
    );
  }
}

interface FolderOpenIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class FolderOpenIcon extends React.Component<FolderOpenIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.5,13.3H25v-2.5c0-1.4-1.1-2.5-2.5-2.5h-8.3L10.8,5H2.5C1.1,5,0,6.1,0,7.5v15C0,23.9,1.1,25,2.5,25h20.1
           c1.5,0,2.8-0.8,3.6-2l3.5-5.9C30.6,15.5,29.4,13.3,27.5,13.3z M2.5,6.7h7.6l3.3,3.3h9c0.5,0,0.8,0.4,0.8,0.8v2.5H8.9
           c-1.5,0-2.8,0.8-3.6,2l-3.7,6.1v-14C1.7,7,2,6.7,2.5,6.7z M28.2,16.3l-3.5,5.9c-0.5,0.8-1.3,1.2-2.1,1.2H2.3l4.3-7.1
           C7.1,15.5,7.9,15,8.8,15h18.7C28.1,15,28.5,15.7,28.2,16.3L28.2,16.3z"
        />
      </svg>
    );
  }
}

interface ArrowRightIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class ArrowRightIcon extends React.Component<ArrowRightIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8,2.3l1.5-1.5c0.6-0.6,1.6-0.6,2.3,0l13,13c0.6,0.6,0.6,1.6,0,2.3l-13,13c-0.6,0.6-1.6,0.6-2.3,0l-1.5-1.5
            c-0.6-0.6-0.6-1.7,0-2.3l8.1-7.7H1.6C0.7,17.7,0,17,0,16.1v-2.1c0-0.9,0.7-1.6,1.6-1.6h19.2l-8.1-7.7C12.1,4,12.1,3,12.8,2.3z"
        />
      </svg>
    );
  }
}

interface XIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class XIcon extends React.Component<XIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.2,15l9.6-9.6l2-2c0.3-0.3,0.3-0.8,0-1.1l-2.1-2.1c-0.3-0.3-0.8-0.3-1.1,0L15,11.8L3.4,0.2
            c-0.3-0.3-0.8-0.3-1.1,0L0.2,2.3c-0.3,0.3-0.3,0.8,0,1.1L11.8,15L0.2,26.6c-0.3,0.3-0.3,0.8,0,1.1l2.1,2.1c0.3,0.3,0.8,0.3,1.1,0
            L15,18.2l9.6,9.6l2,2c0.3,0.3,0.8,0.3,1.1,0l2.1-2.1c0.3-0.3,0.3-0.8,0-1.1L18.2,15z"
        />
      </svg>
    );
  }
}

interface CreditsIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class CreditsIcon extends React.Component<CreditsIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 39.98 34"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="coin"
          d="m9.99,0C4.48,0,0,2.52,0,5.62v4.37c0,2.76,4.48,5,9.99,5s9.99-2.24,9.99-5v-4.37c0-3.11-4.48-5.62-9.99-5.62ZM2.5,11.81c-.68-.38-1.14-1.05-1.25-1.82v-1.65c.38.38.8.71,1.25.99v2.48Zm3.12,1.38c-.64-.17-1.27-.39-1.87-.66v-2.52c.61.27,1.23.49,1.87.66v2.52Zm3.75.53c-.84-.03-1.67-.11-2.5-.26v-2.51c.83.15,1.66.23,2.5.25v2.51Zm3.75-.26c-.83.15-1.66.23-2.5.26v-2.51c.84-.02,1.67-.11,2.5-.25v2.51Zm3.12-.94c-.61.27-1.23.49-1.87.66v-2.52c.64-.17,1.27-.39,1.87-.66v2.53Zm2.5-2.54c-.11.77-.57,1.44-1.25,1.82v-2.48c.45-.28.87-.61,1.25-.99v1.65Zm-8.74,0C4.84,9.99,1.25,7.69,1.25,5.62S4.84,1.25,10,1.25s8.74,2.3,8.74,4.37-3.59,4.37-8.75,4.37h0Z"
        />
        <path
          id="coin-2"
          d="m29.98,10c-5.52,0-9.99,2.52-9.99,5.62v4.37c0,2.76,4.48,5,9.99,5s9.99-2.24,9.99-5v-4.37c0-3.11-4.48-5.62-9.99-5.62Zm-7.49,11.81c-.68-.38-1.14-1.05-1.25-1.82v-1.65c.38.38.8.71,1.25.99v2.48Zm3.12,1.38c-.64-.17-1.27-.39-1.87-.66v-2.52c.61.27,1.23.49,1.87.66v2.52Zm3.75.53c-.84-.03-1.67-.11-2.5-.26v-2.51c.83.15,1.66.23,2.5.25v2.51Zm3.75-.26c-.83.15-1.66.23-2.5.26v-2.51c.84-.02,1.67-.11,2.5-.25v2.51Zm3.12-.94c-.61.27-1.23.49-1.87.66v-2.52c.64-.17,1.27-.39,1.87-.66v2.53Zm2.5-2.54c-.11.77-.57,1.44-1.25,1.82v-2.48c.45-.28.87-.61,1.25-.99v1.65Zm-8.74,0c-5.15,0-8.74-2.3-8.74-4.37s3.59-4.37,8.74-4.37,8.74,2.3,8.74,4.37-3.59,4.37-8.75,4.37h0Z"
        />
        <path
          id="coin-3"
          d="m9.99,19c-5.52,0-9.99,2.52-9.99,5.62v4.37c0,2.76,4.48,5,9.99,5s9.99-2.24,9.99-5v-4.37c0-3.11-4.48-5.62-9.99-5.62Zm-7.49,11.81c-.68-.38-1.14-1.05-1.25-1.82v-1.65c.38.38.8.71,1.25.99v2.48Zm3.12,1.38c-.64-.17-1.27-.39-1.87-.66v-2.52c.61.27,1.23.49,1.87.66v2.52Zm3.75.53c-.84-.03-1.67-.11-2.5-.26v-2.51c.83.15,1.66.23,2.5.25v2.51Zm3.75-.26c-.83.15-1.66.23-2.5.26v-2.51c.84-.02,1.67-.11,2.5-.25v2.51Zm3.12-.94c-.61.27-1.23.49-1.87.66v-2.52c.64-.17,1.27-.39,1.87-.66v2.53Zm2.5-2.54c-.11.77-.57,1.44-1.25,1.82v-2.48c.45-.28.87-.61,1.25-.99v1.65Zm-8.74,0c-5.15,0-8.74-2.3-8.74-4.37s3.59-4.37,8.74-4.37,8.74,2.3,8.74,4.37-3.59,4.37-8.75,4.37h0Z"
        />
      </svg>
    );
  }
}

interface CalendarIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class CalendarIcon extends React.Component<CalendarIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 29.42 33.63"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="calendar-alt"
          d="m26.27,4.2h-3.15V.79c0-.44-.35-.79-.79-.79h-.53c-.44,0-.79.35-.79.79v3.41h-12.61V.79c0-.44-.35-.79-.79-.79h-.53c-.44,0-.79.35-.79.79v3.41h-3.15C1.41,4.2,0,5.61,0,7.35c0,0,0,0,0,0v23.12c0,1.74,1.41,3.15,3.15,3.15h23.12c1.74,0,3.15-1.41,3.15-3.15V7.36c0-1.74-1.41-3.15-3.15-3.15ZM3.15,6.31h23.12c.58,0,1.05.47,1.05,1.05v3.15H2.1v-3.15c0-.58.47-1.05,1.05-1.05Zm23.12,25.22H3.15c-.58,0-1.05-.47-1.05-1.05V12.61h25.22v17.86c0,.58-.47,1.05-1.05,1.05Zm-16.55-10.51h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.44-.35.79-.79.79Zm6.31,0h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.44-.35.79-.79.79h0Zm6.31,0h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.44-.35.79-.79.79h0Zm-6.31,6.31h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.44-.35.79-.79.79h0Zm-6.31,0h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.43-.35.79-.79.79h0Zm12.61,0h-2.63c-.44,0-.79-.35-.79-.79v-2.63c0-.44.35-.79.79-.79h2.63c.44,0,.79.35.79.79v2.63c0,.44-.35.79-.79.79h0Z"
        />
      </svg>
    );
  }
}

interface BonusIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class BonusIcon extends React.Component<BonusIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        stroke="#a1a0a0"
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={"0 0 29.42 34.63"}
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="certificate"
          d="m26.48,14.99l2.6-2.55c.54-.52.55-1.37.03-1.91-.17-.18-.39-.31-.64-.37l-3.55-.91,1-3.51c.19-.73-.24-1.48-.98-1.68-.23-.06-.47-.06-.7,0l-3.51,1-.91-3.55c-.19-.73-.93-1.16-1.66-.97-.24.06-.46.19-.63.36l-2.55,2.62-2.55-2.62c-.52-.54-1.38-.55-1.92-.02-.18.17-.3.39-.36.63l-.9,3.55-3.52-1c-.73-.19-1.48.25-1.67.98-.06.23-.06.47,0,.7l1,3.51-3.55.91c-.72.18-1.16.92-.97,1.64.06.24.19.46.37.64l2.6,2.55-2.6,2.54c-.54.51-.57,1.37-.06,1.91.18.19.41.32.66.39l3.55.91-1,3.51c-.19.73.24,1.48.98,1.68.23.06.47.06.69,0l3.51-1,.91,3.55c.18.72.91,1.16,1.63.98.25-.06.48-.19.65-.38l2.55-2.6,2.55,2.6c.52.54,1.37.55,1.91.04.18-.17.31-.4.37-.64l.91-3.55,3.51,1c.73.19,1.48-.24,1.68-.97.06-.23.06-.48,0-.71l-1-3.51,3.55-.91c.72-.18,1.16-.91.98-1.64-.06-.25-.19-.47-.38-.65l-2.6-2.55Z"
        />
      </svg>
    );
  }
}

interface PersonIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class PersonIcon extends React.Component<PersonIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        stroke="#a1a0a0"
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 26.25 30"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.12,15c4.14,0,7.5-3.36,7.5-7.5S17.27,0,13.12,0s-7.5,3.36-7.5,7.5,3.36,7.5,7.5,7.5ZM18.38,16.88h-.98c-1.3.6-2.75.94-4.27.94s-2.96-.34-4.27-.94h-.98c-4.35,0-7.88,3.53-7.88,7.87v2.44c0,1.55,1.26,2.81,2.81,2.81h20.62c1.55,0,2.81-1.26,2.81-2.81v-2.44c0-4.35-3.53-7.87-7.87-7.87Z" />
      </svg>
    );
  }
}

interface LogoIconProps {
  width?: string;
  height: string;
  color: string; // This prop is defined but not used in your provided SVG.
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class LogoIcon extends React.Component<LogoIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    viewBox: PropTypes.string,
    transform: PropTypes.string,
    filter: PropTypes.string
  };

  public override render(): JSX.Element {
    const { width, height, className, style, viewBox, transform, filter } = this.props;

    return (
      <svg
        className={className}
        style={{ ...style, transform: transform, filter: filter }}
        height={height}
        width={width}
        viewBox={viewBox || "0 0 25.11 30.5"}
        xmlns="http://www.w3.org/2000/svg">
        <g id="evenAL9zhoc2">
          <path
            id="evenAL9zhoc3"
            className="cls-4"
            style={{
              strokeMiterlimit: "10",
              strokeWidth: ".6px",
              fill: "#8052fd",
              stroke: "#8052fd"
            }}
            d="M.26,30.13C6.01,19.93,5.45,10.01,2.36,6.03c3.72.26,6.95.2,9.71-.19,4.61-.66,8.87-2.53,12.78-5.6C17.47,12.94,9.28,22.91.26,30.13"
          />
          <path
            id="evenAL9zhoc4"
            className="cls-3"
            style={{ fill: "#ae82fd", stroke: "#ae82fd" }}
            d="M.58,29.85c5.23-8.73,6.83-15.74,4.79-21.03.14.02,3.42,1.33,9.61.91,3.94-.51,6.83-1.27,8.68-2.28C17.07,16.53,9.38,24,.58,29.85"
          />
          <path
            id="evenAL9zhoc5"
            className="cls-1"
            style={{ fill: "#3cacdf", stroke: "#3cacdf" }}
            d="M.42,30.24c5.73-7.55,8.18-13.42,7.36-17.61,2.81.97,5.39,1.55,7.72,1.74,2.83.23,5.28.14,7.37-.28C16.53,20.26,9.05,25.64.42,30.24"
          />
          <path
            id="evenAL9zhoc6"
            className="cls-2"
            style={{ fill: "#05d9da", stroke: "#05d9da" }}
            d="M.51,30.21c5.56-4.51,8.64-9.16,9.25-13.93,1.42,1.13,3.18,1.94,5.28,2.43,2.36.56,4.52.74,6.47.56-5.7,4.4-12.7,8.05-21,10.94"
          />
        </g>
      </svg>
    );
  }
}

interface RightArrowProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class RightArrow extends React.Component<RightArrowProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6,0.4l-1.3,1.3C13,2,13,2.6,13.3,2.9l10.4,10.4H0.8c-0.4,0-0.8,0.4-0.8,0.8v1.9c0,0.4,0.4,0.8,0.8,0.8h22.9
	L13.3,27.1c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.3c0.3,0.3,0.8,0.3,1.1,0l14-14c0.3-0.3,0.3-0.8,0-1.1l-14-14C15.4,0.1,14.9,0.1,14.6,0.4z"
        />
      </svg>
    );
  }
}

interface DownloadIconProps {
  width?: string;
  height: string;
  color: string;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  transform?: string;
  filter?: string;
}

export class DownloadIcon extends React.Component<DownloadIconProps> {
  public static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  };

  public override render(): JSX.Element {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M16.4,13.8c0-.4-.3-.7-.7-.7h-1.4c-.4,0-.7.3-.7.7v4.9h-2.9c-.6,0-.9.8-.5,1.2l4.2,4.2c.3.3.8.3,1.1,0l4.2-4.2c.4-.4.1-1.2-.5-1.2h-2.9v-4.9ZM25.4,5.7L20.5.8c-.5-.5-1.2-.8-2-.8H6.6c-1.6,0-2.8,1.3-2.8,2.8v24.4c0,1.6,1.3,2.8,2.8,2.8h16.9c1.6,0,2.8-1.3,2.8-2.8V7.7c0-.7-.3-1.5-.8-2ZM18.7,3l4.5,4.5h-4.5V3h0ZM23.4,27.2H6.6V2.8h9.4v6.1c0,.8.6,1.4,1.4,1.4h6.1v16.9Z" />
      </svg>
    );
  }
}
