import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWritingModel } from "src/interfaces/IWriting";
import { AppDispatch } from "src/configureStore";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { ILinkPreview } from "src/interfaces/ILinkPreview";
import { IUser, IClient, TeamUser } from "src/interfaces/IUser";

interface ClientState {
  userRecents: IWritingModel[];
  linkPreviews: { [url: string]: ILinkPreview };
  helpLinks: IHelpLink[];
  clientData: IClient | null;
  fetchedUsers: { [id: string]: IUser };
  campaignUsage: number;
  maxCampaignUsage: number;
  // fetchedUsers: TeamUser[];
}

export interface IHelpLink {
  videoUrl: string;
  caption: string;
  platform: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
}

const initialState: ClientState = {
  userRecents: [],
  linkPreviews: {},
  helpLinks: [],
  clientData: null,
  fetchedUsers: {},
  campaignUsage: 0,
  maxCampaignUsage: 0
  // fetchedUsers: []
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    getUserRecentsSuccess: (state, action: PayloadAction<any[]>) => {
      state.userRecents = action.payload;
    },
    getLinkPreviewSuccess: (
      state,
      action: PayloadAction<{ url: string; metadata: ILinkPreview }>
    ) => {
      state.linkPreviews[action.payload.url] = action.payload.metadata;
    },
    getHelpLinksSuccess: (state, action: PayloadAction<IHelpLink[]>) => {
      state.helpLinks = action.payload;
    },
    getClientData: (state, action: PayloadAction<IClient>) => {
      state.clientData = action.payload;
    },
    updateClientName: (state, action: PayloadAction<IClient>) => {
      state.clientData = action.payload;
    },
    removeUserFromClient: (state, action: PayloadAction<{ userId: string; clientId: string }>) => {
      if (state.clientData && state.clientData._id === action.payload.clientId) {
        // state.clientData.users = state.clientData.users.filter(userId => userId !== action.payload.userId);
        state.clientData.users = state.clientData.users.filter(
          (user) => user.id !== action.payload.userId
        );
      }
    },
    setCampaignUsage: (state, action: PayloadAction<number>) => {
      state.campaignUsage = action.payload;
    },
    setMaxCampaignUsage: (state, action: PayloadAction<number>) => {
      state.maxCampaignUsage = action.payload;
    }
  }
});

export const {
  getUserRecentsSuccess,
  getLinkPreviewSuccess,
  getHelpLinksSuccess,
  getClientData,
  updateClientName,
  removeUserFromClient,
  setCampaignUsage,
  setMaxCampaignUsage
} = clientSlice.actions;

export const fetchUserRecents = () => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/client/userRecents";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getUserRecentsSuccess(response.data));
  } catch (error: any) {
    console.log(error);
  }
};

export const fetchLinkPreview =
  (link: string, authToken: string | null) => async (dispatch: AppDispatch) => {
    try {
      if (!authToken) {
        throw new Error("Token not found");
      }

      const url =
        process.env["REACT_APP_API_SERVER"] +
        `/api/client/link-preview?url=${encodeURIComponent(link)}`;

      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${authToken}` }
      });

      dispatch(getLinkPreviewSuccess({ url: link, metadata: response.data }));
    } catch (error: any) {
      console.log(error);
    }
  };

export const fetchHelpLinks = () => async (dispatch: AppDispatch) => {
  // Add this function
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/client/getHelpLinks";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    dispatch(getHelpLinksSuccess(response.data));
  } catch (error: any) {
    console.log(error);
  }
};

export const fetchClientData = (clientId: string) => async (dispatch: AppDispatch) => {
  try {
    const url = `${process.env["REACT_APP_API_SERVER"]}/api/client/getClient/${clientId}`;

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getClientData(response.data));
  } catch (error: any) {
    console.log(error);
  }
};

export const updateClientNameAction =
  (clientId: string, newName: string) => async (dispatch: AppDispatch) => {
    try {
      const url = `${process.env["REACT_APP_API_SERVER"]}/api/client/updateClientName`;

      const response = await axiosInstance.put(
        url,
        { clientId, newName },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );

      dispatch(updateClientName(response.data));
    } catch (error: any) {
      console.log(error);
    }
  };

export const removeUserFromClientAction =
  (clientId: string, userId: string) => async (dispatch: AppDispatch) => {
    try {
      const url = `${process.env["REACT_APP_API_SERVER"]}/api/client/removeUserFromClient`;

      const response = await axiosInstance.put(
        url,
        { clientId, userId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );

      dispatch(removeUserFromClient({ clientId, userId }));
      return { success: true, message: "User removed successfully!" };
    } catch (error: any) {
      console.log(error);
      return { success: false, message: error.message || "Failed to remove user!" };
    }
  };

export const fetchCampaignUsage = () => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/client/getCampaignUsage";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    setCampaignUsage(response.data.campaignUsage);
    setMaxCampaignUsage(response.data.maxCampaignUsage);
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
};

export default clientSlice.reducer;
