import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { IUser, IClient } from "src/interfaces/IUser";

interface AdminUserState {
  users: IUser[];
  clients: IClient[];
  userStatus: "idle" | "loading" | "succeeded" | "failed";
  clientStatus: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
}
interface IClientIndexable extends IClient {
  [key: string]: any;
}
interface UpdateClientPayload {
  client: IClient;
  newValue: string;
  column: string;
}

const initialState: AdminUserState = {
  users: [],
  clients: [],
  userStatus: "idle",
  clientStatus: "idle",
  error: null
};

const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    adminUpdateClientAttr(state, action) {
      const updatedClient = action.payload;
      const index = state.clients.findIndex((client) => client._id === updatedClient._id);

      if (index !== -1) {
        state.clients[index] = updatedClient;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AdminFetchUsers.pending, (state) => {
        state.userStatus = "loading";
      })
      .addCase(AdminFetchUsers.fulfilled, (state, action: PayloadAction<IUser[]>) => {
        state.userStatus = "succeeded";
        state.users = action.payload;
        state.error = null;
      })
      .addCase(AdminFetchUsers.rejected, (state, action) => {
        state.userStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(AdminGetClients.pending, (state) => {
        // When fetch request starts
        state.clientStatus = "loading";
      })
      .addCase(AdminGetClients.fulfilled, (state, action: PayloadAction<IClient[]>) => {
        // When fetch request is successful
        state.clientStatus = "succeeded";
        state.clients = action.payload; // Store fetched clients in the state
        state.error = null;
      })
      .addCase(AdminGetClients.rejected, (state, action) => {
        // When fetch request fails
        state.clientStatus = "failed";
        state.error = action.error.message;
      });
    // .addCase(AdminUpdateClient.pending, (state) => {
    //   state.status = "loading";
    // })
    // .addCase(AdminUpdateClient.fulfilled, (state, action: PayloadAction<IClient>) => {
    //   state.status = "succeeded";
    //   // assuming that the server returns the updated client
    //   const updatedClientIndex = state.clients.findIndex(
    //     (client) => client._id === action.payload._id
    //   );
    //   if (updatedClientIndex !== -1) {
    //     state.clients[updatedClientIndex] = action.payload;
    //   }
    //   state.error = null;
    // })
    // .addCase(AdminUpdateClient.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action.error.message;
    // });
  }
});
export const AdminFetchUsers = createAsyncThunk("adminUsers/fetchUsers", async () => {
  console.log("FETCHING USERS");
  const response: any = await axiosInstance
    .get(process.env["REACT_APP_API_SERVER"] + "/api/admin/users", {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    })
    .then((result) => {
      console.log("GOT USERS");
      return result;
    })
    .catch((err) => {
      console.log("ERROR GRABBING USERS");
      console.log(err);
    });
  console.log("DONE FETCHING USERS");
  return response.data;
});
export const AdminDeleteUsers = createAsyncThunk(
  "adminUsers/deleteUsers",
  async (userIds: string[]) => {
    const response = await axiosInstance.delete(
      process.env["REACT_APP_API_SERVER"] + "/api/admin/deleteUsers",

      {
        data: { userIds },
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    return response.data;
  }
);
export const AdminGetClients = createAsyncThunk("adminUsers/getClients", async () => {
  const response = await axiosInstance.get(
    process.env["REACT_APP_API_SERVER"] + "/api/admin/clients",

    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    }
  );
  return response.data;
});
export const AdminDeleteClients = createAsyncThunk(
  "adminUsers/deleteClients",
  async (clientIds: string[]) => {
    const response = await axiosInstance.delete(
      process.env["REACT_APP_API_SERVER"] + "/api/admin/deleteClients",

      {
        data: { clientIds },
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    return response.data;
  }
);

export const AdminUpdateClient = createAsyncThunk(
  "adminUsers/updateClient",
  async ({ client, newValue, column }: UpdateClientPayload) => {
    const saveClient = { ...client } as IClientIndexable;
    saveClient[column] = newValue;
    const response = await axiosInstance.put(
      process.env["REACT_APP_API_SERVER"] + "/api/admin/updateClient",

      { client: saveClient },

      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    return response.data;
  }
);

export default adminUserSlice.reducer;
export const { adminUpdateClientAttr } = adminUserSlice.actions;
