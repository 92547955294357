// src/reducers/outputReducer.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import axios from "axios";
import { AppDispatch } from "src/configureStore";
import axiosInstance from "src/lib/axiosConfig";

interface OutputState {
  text: string;
  chatTextArr: any[];
  outputObj: any;
  chatOutputObj: any;
  status: string;
  error: string | null;
}
const initialState: OutputState = {
  text: "",
  chatTextArr: [],
  outputObj: {},
  chatOutputObj: {},
  error: null,
  status: "idle"
};
function parseNode(node: any) {
  const result: any = [];

  node.childNodes.forEach((child: any) => {
    if (child.nodeType === Node.TEXT_NODE) {
      result.push({ type: "text", text: child.textContent });
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      switch (child.tagName.toLowerCase()) {
        case "p":
          result.push({ type: "paragraph", content: parseNode(child) });
          break;
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          result.push({
            type: "heading",
            attrs: { level: parseInt(child.tagName[1]) },
            content: parseNode(child)
          });
          break;
        case "ul":
          result.push({ type: "bulletList", content: parseNode(child) });
          break;
        case "li":
          result.push({ type: "listItem", content: parseNode(child) });
          break;
        case "br":
          result.push({ type: "hardBreak" });
          break;
        // Add more cases as needed for other HTML elements
      }
    }
  });

  return result;
}

// Output slice
const outputSlice = createSlice({
  name: "output",
  initialState: initialState,
  reducers: {
    getOutputSuccess: (state, action: PayloadAction<any>) => {
      state.outputObj = action.payload.data;
      state.text = action.payload.text;

      state.status = "loaded";
    },
    updateOutput: (state, action: PayloadAction<any>) => {
      state.text = action.payload;
    },
    appendOutputText: (state, action: PayloadAction<string>) => {
      if (action.payload.includes("\n")) {
        console.log("New line");
        console.log(action.payload);
      }

      state.text = state.text.replace("Start Your Document Here ...", "");
      // state.text = state.text.replace(/\n/g, "<br>");
      // state.text = state.text.replace(/<\/p>$/, "") + action.payload;
      state.text = state.text + action.payload;
    },
    getChatOutputSuccess: (state, action: PayloadAction<any>) => {
      state.chatTextArr = action.payload;
    },
    appendChatOutputText: (state, action: PayloadAction<{ text: string; role: string }>) => {
      if (action.payload.text.includes("\n")) {
        console.log("New line");
        console.log(action.payload.text);
      }
      const textArrLength = state.chatTextArr.length;
      console.log(state.chatTextArr);
      if (state.chatTextArr !== undefined && state.chatTextArr.length > 0) {
        if (state.chatTextArr[0].content.includes("Start Your Chat Here ...")) {
          //remove the first element from the array
          state.chatTextArr.shift();
        }
        if (action.payload) {
          if (action.payload.role === "user") {
            state.chatTextArr.push({ role: "user", content: action.payload.text });
          } else {
            if (state.chatTextArr[textArrLength - 1].role === "user") {
              state.chatTextArr.push({ role: "assistant", content: action.payload.text });
            } else {
              state.chatTextArr[textArrLength - 1].content =
                state.chatTextArr[textArrLength - 1].content + action.payload.text;
            }
          }
        }
      } else {
        state.chatTextArr = [{ role: "user", content: action.payload.text }];
      }
    },
    insertOutputText: (state, action: PayloadAction<{ text: string; position: number }>) => {
      const { text, position } = action.payload;
      state.text = state.text.slice(0, position) + text + state.text.slice(position);
    }
  }
});
export const {
  getOutputSuccess,
  appendOutputText,
  getChatOutputSuccess,
  appendChatOutputText,
  insertOutputText,
  updateOutput
} = outputSlice.actions;
// Async thunk to fetch output data
export const fetchOutput = (documentAssetId: string) => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/outputs/getOutput/" + documentAssetId;
    const response: any = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    dispatch(getOutputSuccess({ data: response.data, text: response.data.text }));

    return { success: true, output: response.data };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
export const fetchCampaignPlanOutput =
  (documentAssetId: string) => async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/outputs/getCampaignPlanOutput";
      const response: any = await axiosInstance.post(
        url,
        { documentAssetId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(getOutputSuccess({ data: response.data, text: response.data.text }));

      return { success: true, output: response.data };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };
export const saveOutput =
  (projectId: string, documentTypeId: string, documentAssetId: string, text: string) =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/outputs/setOutput";

      const response: any = await axiosInstance.post(
        url,
        { projectId, documentTypeId, documentAssetId, text, type: "full" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      //await dispatch(getOutputSuccess({ data: { text } }));

      return { success: true, output: response.data };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };
export const fetchChatOutput = (documentAssetId: string) => async (dispatch: AppDispatch) => {
  try {
    const url =
      process.env["REACT_APP_API_SERVER"] + "/api/outputs/getChatOutput/" + documentAssetId;
    const response: any = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getChatOutputSuccess(response.data));

    return { success: true, output: response.data };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
export const resetChatOutput = (documentAssetId: string) => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/clearChatHistory";
    const response: any = await axiosInstance.post(
      url,
      { documentAssetId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );

    dispatch(getChatOutputSuccess(response.data));

    return { success: true, output: response.data };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
export default outputSlice.reducer;
