import React, { useState, useRef, useEffect } from "react";
import { Toolbar, IconButton, Button, Tooltip, SelectChangeEvent } from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Link as LinkIcon,
  Undo,
  Redo,
  FormatColorFill,
  FormatColorText
} from "@mui/icons-material";

import { SketchPicker } from "react-color";
import { RightArrow } from "src/lib/icons/index";
import "./customToolbar.scss";

const CustomToolbar: React.FC<{
  editor: any;
  writeMore: any;
  coPilotActions: any;
  setCoPilotModalIsOpen: (value: boolean) => void;
}> = ({ editor, writeMore, coPilotActions, setCoPilotModalIsOpen }) => {
  const [fontSize, setFontSize] = useState<number>(14);
  const [textColor, setTextColor] = useState<string>("#000000");
  const [highlightColor, setHighlightColor] = useState<string>("#ffffff");
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [showHighlightColorPicker, setShowHighlightColorPicker] = useState<boolean>(false);
  const [instructValue, setInstructValue] = useState<string>("");

  const [showCoPilotMenu, setShowCoPilotMenu] = useState(false);
  const copilotButtonRef = useRef<HTMLButtonElement>(null);
  const copilotMenuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleClickOutside: any = (event: MouseEvent) => {
      if (
        copilotMenuRef.current &&
        !copilotMenuRef.current.contains(event.target as Node) &&
        copilotButtonRef.current &&
        !copilotButtonRef.current.contains(event.target as Node)
      ) {
        setShowCoPilotMenu(false);
        handleCopilotChange("unselectText");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleBoldClick = () => {
    editor.chain().focus().toggleBold().run();
  };

  const handleItalicClick = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const handleUnderlineClick = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const handleLinkClick = () => {
    const url = window.prompt("Enter the URL:");
    if (url) {
      editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    }
  };

  const handleUndoClick = () => {
    editor.chain().focus().undo().run();
  };

  const handleRedoClick = () => {
    editor.chain().focus().redo().run();
  };

  const handleFontSizeChange = (event: SelectChangeEvent<number>) => {
    const newFontSize = event.target.value as number;
    setFontSize(newFontSize);
    editor.chain().focus().setFontSize(newFontSize).run();
  };
  const handleCopilotChange = (event: string, inputValue = "") => {
    const { empty } = editor.state.selection;

    coPilotActions(event, inputValue);

    // setCopilotValue(event.target.value);
    handleCloseMenu();
  };
  const handleOpenMenu = async () => {
    const { empty } = editor.state.selection;

    if (!empty || !showCoPilotMenu) {
      await handleCopilotChange("selectText");
      await setShowCoPilotMenu(!showCoPilotMenu);
    } else {
      await handleCopilotChange("unselectText");
      await setShowCoPilotMenu(!showCoPilotMenu);
    }
    // setAnchorEl(event.currentTarget);
  };
  const handleOpenCopilot = () => {
    // Open the CoPilot menu when the button is clicked
    setShowColorPicker(false); // Close the color picker if it's open
  };

  const handleCloseMenu = async () => {
    // await new Promise((r) => setTimeout(r, 100));
    // setAnchorEl(null);
    setShowCoPilotMenu(false);
  };

  const handleTextColorChange = (color: any, type = "") => {
    console.log(color);
    if (color) {
      if (type === "highlight") {
        setHighlightColor(color.hex);
        editor.chain().focus().setBackColor(color.hex).run();
        // editor.commands.setHighlight({ color: colors.hex });
        setShowHighlightColorPicker(false);
      } else {
        setTextColor(color.hex);

        editor.chain().focus().setColor(color.hex).run();
        setShowColorPicker(false);
      }
    }
  };

  const handleAddLinkClick = () => {
    editor.chain().focus().unsetLink().run();
  };
  const handleWriteMore = () => {
    writeMore();
  };

  return (
    <>
      {showColorPicker && (
        <div style={{ position: "absolute", zIndex: 1, background: "white" }}>
          <SketchPicker
            color={textColor}
            onChangeComplete={(color: any) => {
              handleTextColorChange(color, "");
            }}
          />
        </div>
      )}
      {showHighlightColorPicker && (
        <div style={{ position: "absolute", zIndex: 1, background: "white" }}>
          <SketchPicker
            color={highlightColor}
            onChange={(color: any) => {
              handleTextColorChange(color, "highlight");
            }}
          />
        </div>
      )}
      <Toolbar>
        <Tooltip title="Use CoPilot to modify the selected text">
          <Button
            style={{
              fontSize: "14px",
              textTransform: "none",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              padding: "0px",
              color: "rgb(107, 114, 128)",
              border: "1px solid rgb(107, 114, 128)"
            }}
            ref={copilotButtonRef}
            variant="outlined"
            onClick={handleOpenMenu}>
            CoPilot
          </Button>
        </Tooltip>

        {showCoPilotMenu && (
          <ul
            className="copilot-menu"
            ref={copilotMenuRef}
            style={{ position: "absolute", left: "25px", top: "45px", zIndex: 10 }}>
            <li className="menu-item" onClick={() => handleCopilotChange("expand")}>
              Expand
            </li>
            <li className="menu-item" onClick={() => handleCopilotChange("simplify")}>
              Simplify
            </li>
            <li className="menu-item" onClick={() => handleCopilotChange("summarize")}>
              Summarize
            </li>
            <li className="menu-item" onClick={() => handleCopilotChange("de-jargon")}>
              De-Jargon
            </li>

            <li>
              <div className="instruct-box" style={{ display: "flex" }}>
                <input
                  className="input-instruct"
                  type="text"
                  placeholder="Instruct (e.g., shorten with friendly tone)"
                  onChange={(e) => setInstructValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleCloseMenu();
                      handleCopilotChange("instruct", instructValue);
                    }
                  }}
                />
                <button
                  onClick={() => {
                    handleCloseMenu();
                    handleCopilotChange("instruct", instructValue);
                  }}
                  style={{ justifyContent: "flex-end", marginLeft: "8px", marginRight: "4px" }}>
                  <RightArrow height={"16px"} width={"16px"} color={"rgba(88, 89, 91, 1)"} />
                </button>
              </div>
            </li>
            {/* <li>
              <input
                className="instruct-box"
                style={{ width: "100%" }}
                type="text"
                placeholder="Instruct (e.g., shorten with friendly tone)"
                onChange={(e) => setInstructValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleCloseMenu();
                    handleCopilotChange("instruct", instructValue);
                  }
                }}
              />
            </li> */}
          </ul>
        )}

        {/* <Tooltip title="Write More" style={{ marginLeft: "5px" }}>
          <Button
            style={{
              fontSize: "14px",
              textTransform: "none",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              padding: "0px",
              paddingRight: "4px",
              paddingLeft: "4px",
              marginLeft: "8px",
              color: "rgb(107, 114, 128)",
              border: "1px solid rgb(107, 114, 128)"
            }}
            variant="outlined"
            onClick={handleWriteMore}>
            Write More
          </Button>
        </Tooltip> */}
        <Tooltip title="Bold">
          <IconButton onClick={handleBoldClick}>
            <FormatBold />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italic">
          <IconButton onClick={handleItalicClick}>
            <FormatItalic />
          </IconButton>
        </Tooltip>
        <Tooltip title="Underline">
          <IconButton onClick={handleUnderlineClick}>
            <FormatUnderlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="Link">
          <IconButton onClick={handleLinkClick}>
            <LinkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Undo">
          <IconButton onClick={handleUndoClick}>
            <Undo />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo">
          <IconButton onClick={handleRedoClick}>
            <Redo />
          </IconButton>
        </Tooltip>
        {/* <Select value={fontSize} onChange={handleFontSizeChange}>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={16}>16</MenuItem>
          <MenuItem value={18}>18</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select> */}
        <Tooltip title="Text Color">
          <IconButton
            onClick={() => {
              setShowColorPicker(true);
            }}>
            <FormatColorText />
          </IconButton>
        </Tooltip>
        <Tooltip title="Background Color">
          <IconButton
            onClick={() => {
              setShowHighlightColorPicker(true);
            }}>
            <FormatColorFill />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Remove Link">
          <IconButton onClick={handleAddLinkClick}>
            <TextFields />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    </>
  );
};

export default CustomToolbar;
