import React from "react";
import { HtmlTooltip } from "src/components/tooltip";

interface CardProps {
  title: string;
  image: string;
  description: string;
  color: string;
  textColor: string;
  borderColor: string;
  onClick?: () => void;
}

export const WMCard: React.FC<CardProps> = ({
  title,
  image,
  description,
  color,
  textColor,
  borderColor,
  onClick
}) => {
  return (
    <div
      className={`text-black shadow-lg w-1/5 `}
      style={{ width:"200px", backgroundColor: "white", borderRadius: "5px", borderWidth: "2px", borderStyle: "solid", borderColor: borderColor, cursor:"pointer"}}
      onClick={onClick}>
      <div className={`p-2 pb-0 items-center justify-center text-center`}>
        <HtmlTooltip title={title} placement="top" arrow>
        <div className="text-md font-bold long-text-ellipse" style={{ color: "#707070", height:"2.75rem" }}>
          {title}
        </div>
        </HtmlTooltip>
        <div className="mt-4 justify-center" style={{display:"flex"}}>
          <img src={image} height={100} width={100} alt={title} />
        </div>
      </div>
      <div
        className="bg-white p-2"
        style={{ borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
        <hr className="my-2" />
        <div className="text-center text-sm long-text-ellipse" style={{color:"#707070",fontStyle:"italic",height:"2.35rem"}}>
          {description}
        </div>
      </div>
    </div>
  );
};
