import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";

import { processFile } from "../reducers/documentTypeReducer";
import { deleteAssets } from "src/reducers/projectReducer";
import { setPollingStatus } from "../reducers/documentAssetReducer";
import { useAppDispatch } from "src/configureStore";
import { SearchIcon, XIcon, DeleteIcon } from "src/lib/icons/index";
import _ from "lodash";
import axios from "axios";
import "./fileUploadComponent.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { HtmlTooltip } from "src/components/tooltip";

interface FileUploadComponentProps {
  projectId: string | undefined;
  documentTypeId: string | undefined;
  projectFiles: any[];
  setProjectFiles: (projectFiles: any) => void;
  setInitialFiles?: (initialFiles: any) => void;
}

type FileWithAttributes = {
  file: File;
  referenceType: string;
  progress: number;
  status: string;
  _id: string;
  name: string;
  selected: boolean;
  isGlobal: boolean;
};

const StyledTableCell = styled(TableCell)({
  paddingTop: "4px !important",
  paddingBottom: "4px !important",
  whiteSpace: "nowrap",
  "&.centered": {
    textAlign: "center"
  },
  "&.right-aligned": {
    textAlign: "right"
  },
  "&.wide-cell": {
    minWidth: "180px" // Set minimum width based on dropdown content
  }
});

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  projectId,
  documentTypeId,
  projectFiles,
  setProjectFiles,
  setInitialFiles
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
  const [showDeleteSingleFileDialog, setShowDeleteSingleFileDialog] = useState(false);
  const [searchInputFiles, setSearchInputFiles] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  // const [filteredFiles, setFilteredFiles] = useState<string[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<FileWithAttributes[]>([]);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [areAllFilesProcessed, setAreAllFilesProcessed] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileWithAttributes | null>(null);
  const dropdownWidth = 200;
  const atLeastOneFileSelected = (): boolean => {
    return projectFiles.some((file) => file.selected);
  };

  const atLeastOneFileUpload = (): boolean => {
    return files.length > 0;
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (projectFiles !== undefined) {
      setFilteredFiles(
        projectFiles.filter(
          (file: any) =>
            file.name && file.name.toLowerCase().includes(searchInputFiles.toLowerCase())
        )
      );
    }
  }, [projectFiles, searchInputFiles]);

  const handleSearchInputChange = (e: any, type: string) => {
    setSearchInputFiles(e.target.value);
  };

  // const handleAttributeChange = (index: number, referenceType: string) => {
  //   const updatedProjectFiles = projectFiles.map((file, i) =>
  //     i === index ? { ...file, referenceType } : file
  //   );
  //   setProjectFiles(updatedProjectFiles);
  // };
  const handleIsGlobalChange = (index: number, isChecked: boolean) => {
    setFiles(files.map((file, i) => (i === index ? { ...file, isGlobal: isChecked } : file)));
  };
  const handleAttributeChange = (file: FileWithAttributes, referenceType: string) => {
    const index = projectFiles.findIndex((f) => f === file);
    const updatedProjectFiles = projectFiles.map((file, i) => {
      if (i === index) {
        const nFile = _.cloneDeep(file);
        nFile.selected = false;
        return {
          ...nFile,
          referenceType
        };
      } else {
        return file;
      }
    });
    setProjectFiles(updatedProjectFiles);
  };
  const handleFileAttributeChange = (index: number, newReferenceType: string) => {
    setFiles(
      files.map((file, i) => (i === index ? { ...file, referenceType: newReferenceType } : file))
    );
  };

  // const updateReferenceType = async (id: string, referenceType: string) => {
  //   await dispatch(updateReferenceFileType(id, referenceType));
  // };

  const handleRemove = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).map((file) => ({
        file,
        referenceType: "",
        isGlobal: false,
        progress: 0,
        status: "",
        _id: "",
        name: file.name,
        selected: false
      }));
      setFiles([...files, ...newFiles]);
    }
  };

  const areFilesDoneProcessing = () => {
    return files.every((file) => file.progress === 100 || file.status === "Unsupported File");
  };

  const handleUpload = async () => {
    const newFiles = [...files];
    for (const [index, fileData] of files.entries()) {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/uploadReferenceFile";
      // const url = `https://24ys2lqke6.execute-api.us-east-1.amazonaws.com/prod/fundwriter-file-processing-upload/${fileData.file.name}`;

      if (
        fileData.file.type === "application/pdf" ||
        fileData.file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        console.log("FILE TYPE SUCCESS");
      } else {
        toast.error("Only PDF files or DOCX are allowed");
        newFiles[index].status = "Unsupported File";
        setFiles(newFiles);
        setAreAllFilesProcessed(areFilesDoneProcessing());
        return;
      }
      const formData = new FormData();
      formData.append("file", fileData.file);
      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total !== undefined) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              newFiles[index].progress = percentCompleted;
              setFiles((prevFiles) => {
                return prevFiles.map((file, i) =>
                  i === index ? { ...file, progress: percentCompleted } : file
                );
              });
            }
          }
        })
        .then(async () => {
          toast.success("File Uploaded successfully!");
          // dispatch(setPollingStatus("running"));
          if (projectId) {
            const pFile: any = await dispatch(
              processFile(
                projectId,
                fileData.file.name,
                fileData.file.type,
                documentTypeId,
                fileData.referenceType,
                fileData.isGlobal
              )
            );
            const newPFile = { ...pFile, selected: true };

            setProjectFiles((prevProjectFiles: any) => [...prevProjectFiles, newPFile]);
            if (setInitialFiles) {
              setInitialFiles((prevInitialFiles: any) => [...prevInitialFiles, newPFile]);
            }
          }
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setSearchInputFiles("");
          setAreAllFilesProcessed(areFilesDoneProcessing());
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error with file upload");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setSearchInputFiles("");
          setAreAllFilesProcessed(areFilesDoneProcessing());
        });
    }
    setFiles(newFiles);
  };

  const handleRemoveSelectedFiles = () => {
    const removedFiles = projectFiles.filter((file: any) => file.selected === true);
    setProjectFiles((prevProjectFiles: any) =>
      prevProjectFiles.filter((file: any, i: number) => file.selected === false)
    );
    if (projectId) dispatch(deleteAssets(projectId, removedFiles));
    setShowDeleteFileDialog(false);
  };

  //Deletes single file from the trashcan icon in the table
  const handleDeleteSingleFile = (fileToRemove: any) => {
    setShowDeleteSingleFileDialog(true);
    setFileToDelete(fileToRemove);
  };

  //Confirm single file delete from the trashcan icon in the table
  const handleDeleteConfirmed = () => {
    setProjectFiles((prevProjectFiles: any) =>
      prevProjectFiles.filter((file: any) => file !== fileToDelete)
    );
    if (projectId && fileToDelete) {
      dispatch(deleteAssets(projectId, [fileToDelete._id]));
    }
    setShowDeleteSingleFileDialog(false);
    setFileToDelete(null);
  };

  //Cancel single file delete from the trashcan icon in the table
  const handleDeleteCancelled = () => {
    setShowDeleteSingleFileDialog(false);
    setFileToDelete(null);
  };

  const handleToggleFiles = (file: any) => {
    if (file.referenceType === "grantGuidelines") {
      const selectedFiles = projectFiles.filter((item) => {
        return item.selected;
      });
      const grantGuidelinesFiles = selectedFiles.filter((item) => {
        return item.referenceType === "grantGuidelines";
      });
      // if (grantGuidelinesFiles.length === 0 || grantGuidelinesFiles[0]._id === file._id) {
      setProjectFiles(
        _.cloneDeep(projectFiles).map((sFile: any, i: number) => {
          if (sFile._id === file._id) {
            sFile.selected = !sFile.selected;
            return sFile;
          }
          return sFile;
        })
      );
      // } else {
      //   toast.error("You can only select one grant guidelines file at a time.");
      // }
    } else if (file.referenceType === "funderInfo") {
      const selectedFiles = projectFiles.filter((item) => {
        return item.selected;
      });
      const funderInfoFiles = selectedFiles.filter((item) => {
        return item.referenceType === "funderInfo";
      });
      // if (funderInfoFiles.length === 0 || funderInfoFiles[0]._id === file._id) {
      setProjectFiles(
        _.cloneDeep(projectFiles).map((sFile: any, i: number) => {
          if (sFile._id === file._id) {
            sFile.selected = !sFile.selected;
            return sFile;
          }
          return sFile;
        })
      );
      // } else {
      //   toast.error("You can only select one funder info file at a time.");
      // }
    } else {
      setProjectFiles(
        _.cloneDeep(projectFiles).map((sFile: any, i: number) => {
          if (sFile._id === file._id) {
            sFile.selected = !sFile.selected;
            return sFile;
          }
          return sFile;
        })
      );
    }
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };
  const handleCancelButton = () => {
    setUploadIsOpen(false);
    setFiles([]);
  };
  const handleAddButtonClick = () => {
    fileInputRef.current?.click();
    setUploadIsOpen(true);
  };
  const areAllFilesAssignedReferenceType = () => {
    return files.every(
      (file) => file.referenceType !== "" && file.referenceType !== "Select a Reference Type"
    );
  };

  return (
    <div
      className="fileComponent mb-2"
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        // border: "1px solid #B3B3B3",
        // borderRadius: "4px",
        borderBottom: "1px solid #B3B3B3",
        borderTop: "1px solid #B3B3B3",
        overflowY: "hidden"
      }}>
      <ToastContainer autoClose={10000} />
      <input
        id="file-upload"
        type="file"
        accept=".pdf,.docx"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        multiple
      />
      <div
        style={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "hidden"
        }}>
        {uploadIsOpen ? (
          <>
            <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 auto",
                zIndex: 102,
                background: "white",
                overflowY: "hidden",
                position: "absolute",
                top: "10vh",
                bottom: "10vh",
                left: "20vw",
                right: "20vw"
              }}>
              <div className="font-semibold mb-2">Upload Reference Files</div>
              {/* <input
                id="file-upload"
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                multiple
              /> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                  overflowY: "hidden",
                  fontSize: "14px"
                }}>
                <div
                  style={{
                    width: "100%",
                    minHeight: "75px",
                    flex: "1 1 auto",
                    // overflowY: "hidden"
                    display: "flex",
                    flexDirection: "column"
                  }}
                  className="p-1 mb-2 border border-gray-300 divide-y divide-gray-300 rounded-md">
                  <div
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column"
                      // maxHeight: "200px"
                    }}>
                    {/* <TableContainer style={{ maxHeight: "100vh" }}> */}
                    {/* <Table style={{}} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell size="small" className="table-cell-first-col" style={{}}>
                            Filename
                          </TableCell>
                          <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                            Upload %
                          </TableCell>
                          <TableCell
                            size="small"
                            className="table-cell-last-col"
                            style={{ width: "25%" }}>
                            Reference Type
                          </TableCell>
                          <TableCell size="small" className="table-cell">
                            Global
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table> */}
                    <TableContainer
                    // style={{overflowY:"auto",display:"flex",flexDirection:"column",flex:"1 1 auto"}}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell size="small">Filename</StyledTableCell>
                            <StyledTableCell size="small" className="right-aligned" style={{}}>
                              Upload %
                            </StyledTableCell>
                            <StyledTableCell size="small" className="centered wide-cell" style={{}}>
                              Reference Type
                            </StyledTableCell>
                            <StyledTableCell size="small" className="centered ">
                              Global
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {files.map((file, index) => (
                            <TableRow key={index}>
                              <StyledTableCell
                                size="small"
                                className="table-cell-first-col"
                                style={{ minWidth: `${dropdownWidth + 40}px` }}>
                                {file.file.name}
                              </StyledTableCell>
                              <StyledTableCell size="small" className="table-cell" style={{}}>
                                {file.status ? file.status : `${file.progress}%`}
                              </StyledTableCell>
                              <StyledTableCell
                                size="small"
                                className="table-cell-last-col"
                                style={{}}>
                                <select
                                  value={file.referenceType || ""}
                                  onChange={(e) => {
                                    handleFileAttributeChange(index, e.target.value);
                                  }}
                                  className={`appearance-none block w-full px-2 py-0.5 border ${
                                    file.referenceType ? "border-gray-300" : "border-red-500"
                                  } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
                                  <option value="" disabled hidden>
                                    Select a Reference Type
                                  </option>
                                  <option value="exampleGrantProposals">
                                    Example Grant Proposals&nbsp;
                                  </option>
                                  <option value="funderInfo">Funder Info</option>
                                  <option value="grantGuidelines">Grant Guidelines</option>
                                  <option value="organizationInfo">Organization Info</option>
                                  <option value="storyElement">Story Element</option>
                                </select>
                              </StyledTableCell>
                              <StyledTableCell size="small" className="table-cell">
                                <HtmlTooltip
                                  title="Make this reference content available in all projects"
                                  placement="top"
                                  arrow>
                                  <input
                                    type="checkbox"
                                    checked={file.isGlobal}
                                    onChange={(e) => handleIsGlobalChange(index, e.target.checked)}
                                    className="custom-checkbox"
                                  />
                                </HtmlTooltip>
                              </StyledTableCell>
                              <StyledTableCell size="small" className="table-cell" style={{}}>
                                <button onClick={() => handleRemove(index)}>
                                  <XIcon
                                    className="mr-2"
                                    height={"15px"}
                                    width={"15px"}
                                    color={"rgba(88, 89, 91, 1)"}
                                  />
                                </button>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column", flex: "0 0 auto" }}>
                <div className="descriptive-text mb-1" style={{ textAlign: "left", width: "100%" }}>
                  Select one or more PDF or DOCX files to upload
                </div>
                <div style={{ flexDirection: "row" }}>
                  <button
                    disabled={!atLeastOneFileUpload() || !areAllFilesAssignedReferenceType()}
                    className={`mr-4 py-0.5 px-2 rounded focus:outline-none focus:shadow-outline font-semibold text-white font-semibold ${
                      areAllFilesProcessed
                        ? "bg-gray-500 hover:bg-gray-700"
                        : atLeastOneFileUpload() && areAllFilesAssignedReferenceType()
                        ? "bg-fw-med-blue hover:bg-fw-dark-blue"
                        : "py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={handleUpload}>
                    Upload All
                  </button>
                  <button
                    className="ml-4 mr-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded"
                    onClick={handleUploadButtonClick}>
                    Select Files
                  </button>
                  <button
                    className={`ml-4 font-semibold py-0.5 px-2 rounded ${
                      areAllFilesProcessed
                        ? "bg-fw-med-blue hover:bg-fw-dark-blue"
                        : "bg-gray-500 hover:bg-gray-700"
                    } text-white`}
                    onClick={() => handleCancelButton()}>
                    {areAllFilesProcessed ? "Done" : "Cancel"}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflowY: "hidden"
            }}>
            <div className="font-semibold">Reference Files</div>
            <div className="mb-2" style={{ color: "#323232", fontSize: "small" }}>
              To upload files from your computer, click the &quot;Add Files&quot; button, browse and
              select the documents you need, and choose a reference type for each one.
            </div>
            <div className="flex justify-between items-center mb-2">
              <span style={{ flex: "0 0 auto" }}>
                <SearchIcon
                  className="mr-2"
                  height={"15px"}
                  width={"15px"}
                  color={"rgba(88, 89, 91, 1)"}
                />
              </span>
              <input
                type="text"
                value={searchInputFiles}
                onChange={(e) => {
                  handleSearchInputChange(e, "file");
                }}
                className="w-full appearance-none block w-full placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Search..."
                style={{
                  borderBottom: "1px solid rgba(88, 89, 91, 1)",
                  width: "250px",
                  marginRight: "auto"
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                minHeight: "75px",
                flex: "1 1 auto",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
              className="p-1 overflow-y-auto mb-2 border border-gray-300 divide-y divide-gray-300 rounded-md">
              {/* {filteredFiles.map((file: any, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={file.selected}
                    onChange={() => handleToggleFiles(file)}
                    className="m-2 custom-checkbox"
                  />
                  <span>{file.name}</span>
                </div>
              ))} */}

              <Table style={{}} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-select-col table-header-cell">
                      Select
                    </TableCell>
                    <TableCell
                      size="small"
                      className="table-cell-first-col table-header-cell"
                      style={{}}>
                      Filename
                    </TableCell>
                    {/* <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                            Upload %
                          </TableCell> */}
                    <TableCell
                      size="small"
                      className="table-cell-last-col table-header-cell"
                      style={{ width: "25%" }}>
                      Reference Type
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <TableContainer
              // style={{overflowY:"auto",display:"flex",flexDirection:"column",flex:"1 1 auto"}}
              >
                <Table>
                  <TableBody>
                    {/* {filteredFiles.map((file: any, index) => ( */}
                    {[...filteredFiles]
                      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                      .map((file: any, index) => (
                        <TableRow key={index}>
                          <StyledTableCell size="small" className="table-cell-select-col">
                            <input
                              type="checkbox"
                              checked={file.selected}
                              onChange={() => handleToggleFiles(file)}
                              className="m-2 custom-checkbox"
                            />
                          </StyledTableCell>
                          <StyledTableCell size="small" className="table-cell-first-col" style={{}}>
                            {file.name}
                            {file.isGlobal && (
                              <HtmlTooltip
                                title="This reference content is available in all projects"
                                placement="top"
                                arrow>
                                <span
                                  className="ml-2 p-1 text-xs font-semibold text-white rounded-md"
                                  style={{ display: "inline-block", backgroundColor: "#3fa1d7" }}>
                                  Global
                                </span>
                              </HtmlTooltip>
                            )}
                          </StyledTableCell>
                          {/* <TableCell
                                size="small"
                                className="table-cell"
                                style={{ width: "10%" }}>
                                {file.progress}%
                              </TableCell> */}
                          <StyledTableCell
                            size="small"
                            className="table-cell-last-col"
                            style={{ width: "25%" }}>
                            <select
                              value={file.referenceType || ""}
                              onChange={(e) => {
                                // handleAttributeChange(index, e.target.value);
                                handleAttributeChange(file, e.target.value);

                                // updateReferenceType(file._id, e.target.value);
                              }}
                              className="appearance-none block w-full px-2 py-0.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option value="exampleGrantProposals">Example Grant Proposals</option>
                              <option value="funderInfo">Funder Info</option>
                              <option value="grantGuidelines">Grant Guidelines</option>
                              <option value="organizationInfo">Organization Info</option>
                              <option value="storyElement">Story Element</option>
                            </select>
                          </StyledTableCell>
                          <StyledTableCell size="small" className="table-cell" style={{}}>
                            {/* <button onClick={() => handleDeleteFile(file)}> */}
                            <button onClick={() => handleDeleteSingleFile(file)}>
                              <DeleteIcon
                                className="mr-2"
                                height={"15px"}
                                width={"15px"}
                                color={"rgba(88, 89, 91, 1)"}
                              />
                            </button>
                          </StyledTableCell>
                          {/* <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                          <button onClick={() => handleRemove(index)}>
                            <XIcon
                              className="mr-2"
                              height={"15px"}
                              width={"15px"}
                              color={"rgba(88, 89, 91, 1)"}
                            />
                          </button>
                        </TableCell> */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="flex items-center">
              <button
                // className={
                //   "text-white font-bold py-1 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none"
                // }
                className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded mr-4"
                onClick={() => {
                  handleAddButtonClick();
                }}>
                Add Files
              </button>
              <button
                disabled={!atLeastOneFileSelected()}
                className={`ml-4 py-0.5 px-2 rounded focus:outline-none focus:shadow-outline font-semibold ${
                  atLeastOneFileSelected()
                    ? "bg-gray-500 hover:bg-gray-700 text-white"
                    : "text-white font-semibold py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed"
                }`}
                onClick={() => setShowDeleteFileDialog(true)}>
                Remove File
              </button>
            </div>
            {showDeleteFileDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>Are you sure you want to delete the selected Files?</p>
                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleRemoveSelectedFiles}>
                      Delete
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={() => setShowDeleteFileDialog(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showDeleteSingleFileDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>
                    Are you sure you want to delete {fileToDelete ? fileToDelete.name : "this file"}
                    ?
                  </p>

                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleDeleteConfirmed}>
                      Delete
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={handleDeleteCancelled}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default FileUploadComponent;
