import { Route, Routes } from "react-router-dom";
import ModelEditorPage from "./editModels";
import ModelEditorFullView from "./editModelFullView";
import InspectPromptPage from "./inspectPrompt";

import "./adminMain.scss";
import UserManagement from "src/components/admin/userManagement";
import ClientManagement from "src/components/admin/clientManagement";

const Main = () => {
  return (
    <div className="main adminMain flex flex-col h-full w-full">
      <Routes>
        <Route path="/modelEditor" element={<ModelEditorPage />} />
        <Route path="/modelEditorFullView" element={<ModelEditorFullView />} />
        <Route path="/inspectPrompt" element={<InspectPromptPage />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/clientManagement" element={<ClientManagement />} />
      </Routes>
    </div>
  );
};

export default Main;
