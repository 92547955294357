// TextInput.tsx
import React from "react";
import { IModelInput } from "../interfaces/IWriting";

interface TextInputProps {
  input: IModelInput;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, order: number) => void;
}

const TextInput: React.FC<TextInputProps> = ({ input, value, onChange }) => {
  return (
    <>
      <input
        id={`input-${input.order}`}
        value={value}
        onChange={(e) => onChange(e, input.order)}
        maxLength={input.maxCharacters}
        className="text-xs w-full border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
        style={{ height: "30px" }}
      />
      <div className="text-right text-xs text-gray-500">
        {value?.length || 0}/{input.maxCharacters}
      </div>
    </>
  );
};

export default TextInput;
