import React, { useRef, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IWritingModel } from "../interfaces/IWriting";
import { SearchIcon } from "src/lib/icons/index";
import { useAppDispatch } from "src/configureStore";
import { fetchWritingSets, getWritingSetById } from "../reducers/writingSetReducer";
import { useSelector } from "react-redux";
import { RootState } from "../configureStore";
import { getAllWritingModels } from "src/reducers/writingSetReducer";
import "./writingModelSelector.scss";
import { fetchCampaignUsage } from "src/reducers/clientReducer";

interface WritingModelSelectorProps {
  selectedModels: IWritingModel[];
  projectId: string | undefined | null;
  writingSetId: string | undefined;
  setSelectedModels: (models: IWritingModel[]) => void;
}

export const WritingModelSelector: React.FC<WritingModelSelectorProps> = ({
  selectedModels,
  projectId,
  writingSetId,
  setSelectedModels
}) => {
  const modelRefs = useRef<Record<string, React.RefObject<HTMLLIElement>>>({});
  modelRefs.current = {};
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const { documentAssetId } = useParams();
  const { selectedWritingSet, isLoaded } = useSelector((state: RootState) => state.writingSets);
  const { allWritingModels, loadedModels } = useSelector((state: RootState) => state.writingSets);
  const [campaignUsage, setCampaignUsage] = useState<number | null>(null); // Campaign usage state
  const [maxCampaignUsage, setMaxCampaignUsage] = useState<number | null>(null); // Max usage state

  const handleSelectedModelClick = () => {
    if (selectedModels[0] && modelRefs.current[selectedModels[0]._id]) {
      modelRefs.current[selectedModels[0]._id].current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const filteredWritingModels = useMemo(() => {
    if (selectedWritingSet?.writingModels && selectedWritingSet.writingModels.length > 0) {
      return selectedWritingSet?.writingModels.filter((model: any) => {
        const { modelName, description, category } = model;

        if (!modelRefs.current[model._id]) {
          modelRefs.current[model._id] = React.createRef<HTMLLIElement>();
        }

        const modelNameMatch =
          typeof modelName === "string"
            ? modelName.toLowerCase().includes(search.toLowerCase())
            : false;
        const descriptionMatch =
          typeof description === "string"
            ? description.toLowerCase().includes(search.toLowerCase())
            : false;
        const categoryMatch =
          typeof category === "string"
            ? category.toLowerCase().includes(search.toLowerCase())
            : false;

        return modelNameMatch || descriptionMatch || categoryMatch;
      });
    } else {
      return selectedWritingSet?.pipelineModels?.filter((model: any) => {
        const { modelName, description, category } = model;

        if (!modelRefs.current[model._id]) {
          modelRefs.current[model._id] = React.createRef<HTMLLIElement>();
        }

        const modelNameMatch =
          typeof modelName === "string"
            ? modelName.toLowerCase().includes(search.toLowerCase())
            : false;
        const descriptionMatch =
          typeof description === "string"
            ? description.toLowerCase().includes(search.toLowerCase())
            : false;
        const categoryMatch =
          typeof category === "string"
            ? category.toLowerCase().includes(search.toLowerCase())
            : false;

        return modelNameMatch || descriptionMatch || categoryMatch;
      });
    }
  }, [search, selectedWritingSet, modelRefs]);

  const handleModelToggle = (model: IWritingModel) => {
    setSelectedModels([model]);
  };

  useEffect(() => {
    // console.log("filtredWritingModels: ", filteredWritingModels);
    filteredWritingModels?.forEach((model: any) => {
      if (!modelRefs.current[model._id]) {
        modelRefs.current[model._id] = React.createRef<HTMLLIElement>();
      }
    });
  }, [filteredWritingModels]);

  useEffect(() => {
    const fetchUsage = async () => {
      const usageData = await dispatch(fetchCampaignUsage());
      if (usageData) {
        setCampaignUsage(usageData.campaignUsage);
        setMaxCampaignUsage(usageData.maxCampaignUsage);
      }
    };

    fetchUsage();
  }, [dispatch]);
  // useEffect(() => {
  //   if (selectedWritingSet?.writingModels) {
  //     const sortedModels = [...selectedWritingSet.writingModels].sort((a, b) => a.order - b.order);
  //     const firstModel = sortedModels[0];
  //     setSelectedModels([firstModel]);
  //   }
  // }, [selectedWritingSet, loadedModels, allWritingModels, writingSetId, setSelectedModels]);

  useEffect(() => {
    if (selectedWritingSet) {
      let defaultModel: IWritingModel | null = null;

      // Check writingModels first
      if (selectedWritingSet.writingModels?.length > 0) {
        const sortedModels = [...selectedWritingSet.writingModels].sort(
          (a, b) => a.order - b.order
        );
        defaultModel = sortedModels[0];
      }

      // Fallback to pipelineModels if writingModels are unavailable
      const pipelineModels = selectedWritingSet.pipelineModels || [];
      if (!defaultModel && pipelineModels.length > 0) {
        const sortedPipelineModels = [...pipelineModels].sort((a, b) => a.order - b.order);
        defaultModel = sortedPipelineModels[0] as IWritingModel; // Cast to IWritingModel
      }

      // Set the default model
      if (defaultModel) {
        setSelectedModels([defaultModel]);
      }
    }
  }, [selectedWritingSet, loadedModels, allWritingModels, writingSetId, setSelectedModels]);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchWritingSets());
      if (!loadedModels && documentAssetId) {
        dispatch(getAllWritingModels(documentAssetId));
      }
    }
    if (writingSetId) {
      dispatch(getWritingSetById(writingSetId));
    }
  }, [dispatch, isLoaded, writingSetId, projectId, loadedModels]);

  return (
    <>
      {/* <h1 className="text-md font-bold" style={{ color: "rgb(78, 78, 78)" }}>
        Add a New Document
      </h1> */}
      <div className="flex flex-row items-center">
        <div className="text-sm font-semibold mr-2" style={{ color: "rgb(78, 78, 78)" }}>
          Select a writing model to create a new document
          {/* <button className="inline-flex items-center p-1 ml-1 text-gray-500">
              <i className="fas fa-info-circle"></i>
            </button> */}
        </div>
        {selectedWritingSet?.name !== "One-Click Campaign" ? (
          <div className="descriptive-text-light">(you can add more models later)</div>
        ) : null}
      </div>
      {/* <div className="descriptive-text-light">(you can add more models later)</div> */}
      <div className="flex flex-row items-center">
        <div className="text-sm flex" style={{ color: "rgb(78, 78, 78)" }}>
          Selected model:{" "}
          {selectedModels[0] ? (
            <div
              className="selected-model ml-2"
              style={{
                backgroundColor: selectedModels[0].backgroundColor,
                borderColor: selectedModels[0].borderColor,
                borderWidth: "1px",
                borderStyle: "solid",
                color: "black",
                cursor: "pointer"
              }}
              onClick={handleSelectedModelClick}>
              {selectedModels[0].modelName || selectedModels[0].name}
            </div>
          ) : (
            <div className="descriptive-text ml-2">No model selected</div>
          )}
        </div>
        <div
          className="flex flex-row pt-3 pb-3"
          style={{ alignItems: "center", marginLeft: "auto" }}>
          <SearchIcon
            className="mr-2"
            height={"15px"}
            width={"15px"}
            color={"rgba(88, 89, 91, 1)"}
          />
          <input
            type="text"
            className="search-input focus:outline-none text-sm"
            value={search}
            onChange={handleSearchChange}
            style={{ background: "none", borderBottom: "1px #707070 solid", width: "250px" }}
            placeholder="Search writing models"
          />
        </div>
      </div>
      {/* </div> */}
      <div
        className="pr-4 pl-4 border border-gray-300 rounded-md"
        style={{
          overflowY: "auto",
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          border: "solid 1px lightgray",
          borderRadius: "4px"
        }}>
        <ul className="mt-4">
          {/* {selectedWritingSet?.writingModels
          ? [...selectedWritingSet.writingModels] */}
          {filteredWritingModels
            ? [...filteredWritingModels]
                .sort((a, b) => {
                  const categoryOrder = [
                    "Cat - Grants/Proposals",
                    "Cat - Appeals",
                    "Cat - Thank You",
                    "Cat - Personal Emails",
                    "Cat - Blackbaud Demo",
                    "Cat - Campaign"
                  ];

                  const aCategoryIndex = categoryOrder.indexOf(a.category);
                  const bCategoryIndex = categoryOrder.indexOf(b.category);

                  if (aCategoryIndex !== -1 || bCategoryIndex !== -1) {
                    if (aCategoryIndex === -1) {
                      return 1;
                    }
                    if (bCategoryIndex === -1) {
                      return -1;
                    }
                    if (aCategoryIndex !== bCategoryIndex) {
                      return aCategoryIndex - bCategoryIndex;
                    }

                    // If the categories are the same, sort by order
                    if (a.order !== undefined && b.order !== undefined) {
                      return a.order - b.order;
                    }
                    if (a.order !== undefined) {
                      return -1;
                    }
                    if (b.order !== undefined) {
                      return 1;
                    }

                    // If there's no order, sort by modelName
                    return a.modelName.localeCompare(b.modelName);
                  }

                  const categoryComparison = a.category.localeCompare(b.category);
                  if (categoryComparison !== 0) {
                    return categoryComparison;
                  }

                  // If the categories are the same and there's no categoryOrder, sort by order
                  if (a.order !== undefined && b.order !== undefined) {
                    return a.order - b.order;
                  }
                  if (a.order !== undefined) {
                    return -1;
                  }
                  if (b.order !== undefined) {
                    return 1;
                  }

                  // If there's no order, sort by modelName
                  return a.modelName.localeCompare(b.modelName);
                })
                .map((model: any) => (
                  <li
                    key={model._id}
                    ref={modelRefs.current[model._id]}
                    className="flex items-center mb-1 ">
                    <div className="flex mt-1 w-full">
                      {/* <input
                    type="checkbox"
                    style={{ flex: "0 0 auto" }}
                    checked={selectedModels.includes(model)}
                    onChange={() => handleModelToggle(model)}
                    className="m-2 custom-checkbox"
                  /> */}
                      <div
                        // className="p-2 w-full cursor-pointer mb-2 rounded-md drop-shadow-md text-sm font-semibold"
                        className={`p-2 w-full cursor-pointer mb-1 rounded-md drop-shadow-md text-sm font-semibold ${
                          selectedModels[0] === model ? "selected-model" : ""
                        }`}
                        style={{
                          flexDirection: "column",
                          backgroundColor: model.backgroundColor,
                          borderColor: model.borderColor,
                          borderWidth: "1px",
                          borderStyle: "solid",
                          boxShadow:
                            selectedModels[0] === model ? `0 0 12px ${model.borderColor}` : "",
                          outline:
                            selectedModels[0] === model ? `2px solid ${model.borderColor}` : ""
                        }}
                        onClick={() => {
                          handleModelToggle(model);
                        }}>
                        <div className="flex flex-row items-center mb-1">
                          <div className="text-md">{model.modelName}</div>
                          {model.category && typeof model.category === "string" ? (
                            <div className="text-xs" style={{ marginLeft: "auto" }}>
                              <span style={{ fontWeight: 100 }}>Category: </span>
                              <span style={{ fontWeight: 500 }}>
                                {model.category.replace("Cat - ", "")}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        {selectedWritingSet?.name === "One-Click Campaign" ? (
                          <div
                            className="text-sm"
                            style={{ fontWeight: "500", fontStyle: "italic" }}>
                            {model.description}
                          </div>
                        ) : (
                          <div className="descriptive-text">{model.description}</div>
                        )}

                        {model.idealFor ? (
                          <div>
                            <div
                              className="text-sm"
                              style={{ display: "flex", alignItems: "center" }}>
                              <span className="font-bold">Ideal for:</span>&nbsp;
                              <span style={{ fontWeight: "500" }}>{model.idealFor}</span>
                            </div>
                          </div>
                        ) : null}

                        {selectedWritingSet?.name === "One-Click Campaign" &&
                          model.paidOnly === true &&
                          // selectedModels[0]?.["paidOnly"] === true &&
                          campaignUsage !== null &&
                          maxCampaignUsage !== null && (
                            <div>
                              <span style={{ fontWeight: "600" }}>Availability:</span>&nbsp;
                              <span style={{ fontWeight: "500" }}>
                                You have {maxCampaignUsage - campaignUsage} full campaigns remaining
                                on your plan
                              </span>
                              {/* // className={`flex text-xs ${
                              //   campaignUsage === maxCampaignUsage
                              //     ? "bg-red-100 border border-l-4 border-red-500 text-red-700"
                              //     : campaignUsage === maxCampaignUsage - 1
                              //     ? "bg-yellow-100 border border-l-4 border-yellow-500 text-yellow-700"
                              //     : "bg-blue-100 border border-l-4 border-blue-500 text-blue-700"
                              // } pl-2 pr-2 pt-1 pb-1 rounded-md mt-2`}
                              // style={{
                              //   justifyContent: "center",
                              //   maxWidth: "fit-content",
                              //   marginBottom: "10px"
                              // }}
                              // role="alert">
                              // <div
                              //   style={{ display: "flex", alignItems: "center" }}
                              //   className="font-bold">
                              //   {maxCampaignUsage - campaignUsage} of {maxCampaignUsage} One-Click
                              //   campaigns remaining for this month
                              // </div> */}
                            </div>
                          )}
                        {/* {model.category && typeof model.category === "string" ? (
                          <div className="text-xs mt-1">
                            <span style={{ fontWeight: 100 }}>Category: </span>
                            <span style={{ fontWeight: 500 }}>
                              {model.category.replace("Cat - ", "")}
                            </span>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </li>
                ))
            : null}
        </ul>
      </div>
    </>
  );
};
