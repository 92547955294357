/* eslint-disable no-control-regex */
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axiosInstance from "src/lib/axiosConfig";
import Select from "react-select";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

import { useNavigate } from "react-router-dom";
import { useEditor, EditorContent, EditorProvider } from "@tiptap/react";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";

import StarterKit from "@tiptap/starter-kit";
import { Editor, Range } from "@tiptap/core";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import { Markdown } from "tiptap-markdown";
// import Image from "@tiptap/extension-image";
import ResizableImage from "src/components/ResizableImage";

// import Placeholder from "@tiptap/extension-placeholder";

import Button from "@mui/material/Button";

import SlashCommand from "../extensions/slashCommand";
import DropDownExtension from "../extensions/dropDownExtension";
import CustomToolbar from "src/components/customToolbar";
import { BackColor } from "src/components/back-color";
import { AnimatedLogoNode } from "src/components/animatedLogoNode";
import ChatBar from "src/components/chatBar";
import {
  fetchDocumentAsset,
  fetchServerResponse,
  checkUsage,
  fetchChatResponse,
  pollUrlForCompletion,
  fetchFunds,
  saveInputs,
  createDocumentAsset,
  setPollingStatus
} from "src/reducers/documentAssetReducer";
import { useAppDispatch, RootState } from "src/configureStore";
import { IModelInput, IInputValues, IWritingModel } from "../interfaces/IWriting";
import TextInput from "src/components/textInput";
import WritingTools from "../components/writingTools";
import { InfoIcon, UploadIcon, ToolIcon, DownloadIcon } from "src/lib/icons/index";
import SideScrollingTextDisplay from "src/components/sideScrollingTextDisplay";
import { HtmlTooltip } from "src/components/tooltip";
import {
  fetchOutput,
  saveOutput,
  appendOutputText,
  appendChatOutputText,
  fetchChatOutput,
  updateOutput,
  resetChatOutput,
  fetchCampaignPlanOutput
} from "src/reducers/outputReducer";

import { ReferenceContentDialog } from "src/components/referenceContentDialog";
import AnimatedLogo from "../components/animatedLogo";

import "./writingPage.scss";
import ResizePanel from "src/components/resizePanel";
import { getAssetsForProject, fetchProjects } from "src/reducers/projectReducer";
import Modal from "react-modal";
import { WritingModelSelector } from "src/components/writingModelSelector";
import { getAllWritingModels } from "src/reducers/writingSetReducer";
import {
  setSelectedDocumentTypeById,
  getDocumentTypesSuccess
} from "src/reducers/documentTypeReducer";
// import SlashCommand from "../extensions/slashCommand";
// import { useContextMenu } from "src/utils/context";
import OneClickImage from "src/assets/images/oneClickCampaign.svg";
import remarkGfm from "remark-gfm";
import { fetchCampaignUsage } from "src/reducers/clientReducer";
Modal.setAppElement("#root");

interface Command {
  editor: Editor;
  range: Range;
}

const POLLING_INTERVAL = 10000;
export const WritingPage: React.FC = () => {
  // const editorRef = useRef(null);

  const [showWritingTools, setShowWritingTools] = useState(false);
  const [noCreditsModalIsOpen, setNoCreditsModalIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [typing, setTyping] = useState(false);
  const [inputTyping, setInputTyping] = useState(false);
  const [rModalIsOpen, setRModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [retryErrorModalIsOpen, setRetryErrorModalIsOpen] = useState(false);
  const [retryErrorMessage, setRetryErrorMessage] = useState("");
  const [writingType, setWritingType] = useState("");
  const [coPilotModalIsOpen, setCoPilotModalIsOpen] = useState(false);
  const [sseConnection, setSseConnection] = useState<EventSource | null>(null);
  const [isWriting, setIsWriting] = useState(false);
  const [isChatWriting, setIsChatWriting] = useState<boolean>(false);
  const [chatContent, setChatContent] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedFundOption, setSelectedFundOption] = useState<any>({
    label: "Select Fund",
    value: "Select Fund"
  });
  const [selectedHeadingOption, setSelectedHeadingOption] = useState<any>({
    label: "Select Heading",
    value: "Select Heading"
  });
  // const [selectedText, setSelectedText] = useState<string>("");
  const [previousDocumentAssetId, setPreviousDocumentAssetId] = useState<string | null>(null);
  const [textToInsert, setTextToInsert] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState({ from: 0, to: 0 });
  const selectedRangeRef = useRef({ from: 0, to: 0 });
  const [showInsertTextDialog, setShowInsertTextDialog] = useState(false);
  const [editorContentObj, setEditorContentObj] = useState<Record<number, string>>({});
  const [campaignFiles, setCampaignFiles] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [fileFetchError, setFileFetchError] = useState("");
  const [assetFileNames, setAssetFileNames] = useState<any[]>([]);

  const editorRefs = useRef<any>({});
  const { projectId, documentAssetId, documentId, writingSetId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedDocumentAsset, pollingStatus, isLoading } = useSelector(
    (state: RootState) => state.documentAssets
  );
  const [modalIsOpen, setIsOpen] = useState(false);

  const selectedModelInputs = useSelector(
    (state: RootState) => state.documentAssets.selectedDocumentAsset?.modelInputs
  );
  const funds = useSelector((state: RootState) => state.documentAssets.funds);
  const assetType = useSelector((state: RootState) => state.documentAssets.assetType);
  const { selectedDocumentType } = useSelector((state: RootState) => state.documentTypes);
  const outputText = useSelector((state: RootState) => state.outputReducer.text);
  const chatTextArr = useSelector((state: RootState) => state.outputReducer.chatTextArr);
  const [bookmark, setBookmark] = useState<any>(null);
  const [updateBookmark, setUpdateBookmark] = useState<any>(null);
  const [showProcessingOverlay, setShowProcessingOverlay] = useState<boolean>(false);
  const [processDocumentName, setProcessDocumentName] = useState<string>("");
  const [reRun, setReRun] = useState<boolean>(false);
  const [dropDownInitted, setDropDownInitted] = useState<boolean>(false);
  const [campaignStatus, setCampaignStatus] = useState<string>("");
  const [campaignStep, setCampaignStep] = useState<string>("Generating Campaign Files");
  const [isPolling, setIsPolling] = useState(false);
  const [currSelectedDocumentAsset, setCurrSelectedDocumentAsset] = useState<any>(null);
  const pollingStartedRef = useRef(false);
  // const [editor, setEditor] = useState<any>(null);
  const [campaignUsage, setCampaignUsage] = useState<number | null>(null); // Campaign usage state
  const [maxCampaignUsage, setMaxCampaignUsage] = useState<number | null>(null); // Max usage state
  const { fileAssets, urlAssets, refTextAssets, projects, updatedChildren } = useSelector(
    (state: RootState) => state.projectsReducer
  );

  const modelInputs = useMemo(() => {
    if (selectedModelInputs && selectedModelInputs?.length >= 3) {
      //TODO THIS IS VERY SPECIFIC FOR THE BLACKBAUD DEMO - NEED TO MAKE THIS MORE GENERIC
      if (
        selectedModelInputs[3] !== undefined &&
        selectedModelInputs[3].inputName === "Fund Name"
      ) {
        setSelectedFundOption({
          label: selectedModelInputs[3].text,
          value: selectedModelInputs[3].text
        });
      }
      selectedModelInputs.forEach((input: IModelInput) => {
        if (input.dropDownValues && input.dropDownValues.length > 0) {
          setSelectedHeadingOption({
            label: input.text,
            value: input.text
          });
        }
      });
    } else if (selectedModelInputs) {
      selectedModelInputs.forEach((input: IModelInput) => {
        if (input.dropDownValues && input.dropDownValues.length > 0) {
          setSelectedHeadingOption({
            label: input.text,
            value: input.text
          });
        }
      });
    }
    return selectedModelInputs || [];
  }, [selectedModelInputs]);

  // Create initial input values
  const initialInputValues = useMemo(() => {
    return modelInputs.reduce((acc: IInputValues, input) => {
      acc[input.order] = "";
      return acc;
    }, {});
  }, [modelInputs]);
  const [inputValues, setInputValues] = useState<IInputValues>(initialInputValues);
  // const { selectedWritingSet, writingSets } = useSelector((state: RootState) => state.writingSets);

  const { allWritingModels, loadedModels, loadingModels } = useSelector(
    (state: RootState) => state.writingSets
  );

  // const [selectedModels, setSelectedModels] = useState<IWritingModel[]>([]);
  const [selectedModels, setSelectedModels] = useState<IWritingModel[]>(allWritingModels);
  const [lastPolledAssetId, setLastPolledAssetId] = useState<any>([]);
  const [v2GuidelineTexts, setV2GuidelineTexts] = useState<any>([]);

  useEffect(() => {
    const fetchUsage = async () => {
      const usageData = await dispatch(fetchCampaignUsage());
      if (usageData) {
        setCampaignUsage(usageData.campaignUsage);
        setMaxCampaignUsage(usageData.maxCampaignUsage);
      }
    };

    fetchUsage();
  }, [dispatch]);

  useEffect(() => {
    // console.log("FILE ASSETS");
    // console.log(fileAssets);
    let showNoGuidelines = true;
    let setToRunning = false;

    if (
      selectedDocumentAsset &&
      selectedDocumentAsset["modelId"] !== null &&
      selectedDocumentAsset["modelId"].modelName.includes("Guideline-Driven")
    ) {
      [...fileAssets, ...urlAssets, ...refTextAssets].forEach((item) => {
        if (item.status === "running" && item.referenceType === "grantGuidelines") {
          setToRunning = true;
        }
        if (item.referenceType === "grantGuidelines" && item.selected === true) {
          showNoGuidelines = false;
        }
      });

      // if (setToRunning) {
      //   dispatch(setPollingStatus("running"));
      // }
      if (showNoGuidelines) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(false);
    }
    if (
      selectedDocumentAsset &&
      selectedDocumentAsset["modelId"] !== null &&
      selectedDocumentAsset["modelId"]._id === "6604e9a009a56ea3b09bc1dd" &&
      v2GuidelineTexts.length === 0
    ) {
      if (selectedDocumentAsset["assets"].length > 0) {
        //find the document with referenceType = "grantGuidelines"
        const grantGuidelines = selectedDocumentAsset["assets"].find(
          (item: any) => item.referenceType === "grantGuidelines"
        );
        const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/getGrantText";
        axiosInstance
          .post(
            url,
            { documentAssetId: selectedDocumentAsset._id, assetId: grantGuidelines._id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
              }
            }
          )
          .then(async (response: any) => {
            console.log(response);
            setV2GuidelineTexts(response.data.fileParts);
          });
      }
    }
  }, [fileAssets, urlAssets, refTextAssets, selectedDocumentAsset]);

  useEffect(() => {
    if (selectedDocumentAsset) {
      const checkLOU = setInterval(() => {
        if (
          window.LOU &&
          window.LOU.startTour &&
          selectedDocumentAsset?.name !== "Guideline-Driven Proposal Writer"
        ) {
          window.LOU.startTour("039744607494");
          clearInterval(checkLOU);
        }
      }, 100); // checks every 100ms

      return () => clearInterval(checkLOU); // clear interval on component unmount
    }
  }, [selectedDocumentAsset]);

  useEffect(() => {
    async function runUpdate() {
      // console.log("RUNNING IT");
      // console.log(projects);
      if (documentId && projects && projects.length > 0) {
        const selectedProject = projects.find((project) => project._id === projectId);
        await dispatch(getDocumentTypesSuccess(selectedProject.children));
        await dispatch(setSelectedDocumentTypeById(documentId));
        if (documentAssetId) {
          if (previousDocumentAssetId !== documentAssetId) {
            await dispatch(fetchDocumentAsset(documentAssetId));
            // console.log("GETTING NEW ASSET HEREZ");
            setPreviousDocumentAssetId(documentAssetId);
          }
        }
      } else {
        await dispatch(fetchProjects());
      }
    }
    runUpdate();
  }, [dispatch, projects, documentId, projectId, documentAssetId]);

  useEffect(() => {
    if (!loadedModels) {
      if (documentAssetId) {
        dispatch(getAllWritingModels(documentAssetId));
      } else {
        dispatch(getAllWritingModels(""));
      }
    }
  }, [dispatch, loadedModels]);

  useEffect(() => {
    setSelectedModels(allWritingModels);
  }, [allWritingModels]);

  useEffect(() => {
    if (typing && !isWriting) {
      const timer = setTimeout(() => {
        let cursorPosition: any;
        // Save content every 2 seconds
        // saveContent(content);
        if (projectId !== undefined && documentId !== undefined && documentAssetId !== undefined)
          dispatch(saveOutput(projectId, documentId, documentAssetId, content));
        if (editor) {
          cursorPosition = editor.state.selection.anchor;
        }

        // Restore the cursor position after the content update

        dispatch(updateOutput(content));
        if (editor) {
          requestAnimationFrame(() => {
            // editor.view.focus();
            editor.commands.setTextSelection(cursorPosition);
          });
        }

        setTyping(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [content, typing]);

  useEffect(() => {
    if (inputTyping) {
      const timer = setTimeout(() => {
        // Save content every 3 seconds
        // saveContent(content);
        if (projectId !== undefined && documentId !== undefined && documentAssetId !== undefined)
          if (assetType === "model") {
            dispatch(saveInputs(projectId, documentId, documentAssetId, inputValues, assetType));
          } else if (assetType === "writingTools" && selectedDocumentAsset !== undefined) {
            dispatch(
              saveInputs(projectId, documentId, selectedDocumentAsset._id, inputValues, assetType)
            );
          }
        setInputTyping(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [inputValues, inputTyping]);
  useEffect(() => {
    setShowWritingTools(false);
  }, [documentAssetId]);

  useEffect(() => {
    if (
      documentId &&
      documentAssetId &&
      selectedDocumentAsset &&
      !lastPolledAssetId.includes(selectedDocumentAsset._id)
    ) {
      // console.log(selectedDocumentType);
      // console.log(selectedDocumentAsset);

      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/getAssetStatus";
      console.log("POLLING URL FOR COMLPETION " + selectedDocumentAsset._id);
      dispatch(pollUrlForCompletion(url, selectedDocumentAsset._id));
      const tmpLP = [...lastPolledAssetId, selectedDocumentAsset._id];

      setLastPolledAssetId(tmpLP);
    }
  }, [dispatch, pollingStatus, selectedDocumentAsset, lastPolledAssetId]);
  useEffect(() => {
    if (selectedDocumentAsset !== undefined) {
      setInputValues(
        modelInputs.reduce((acc: IInputValues, input) => {
          acc[input.order] = input.text || "";
          return acc;
        }, {})
      );
    }
    const doGetFunds = modelInputs.find((item) => {
      return item.type === "select" && item.inputName === "Fund Name";
    });
    if (doGetFunds) {
      dispatch(fetchFunds());
    }
  }, [projects, selectedDocumentAsset, modelInputs, dispatch]);

  useEffect(() => {
    return () => {
      // console.log("UNMOUNTING WRITING PAGE");
      sseConnection?.close();
      setSseConnection(null);
      setIsWriting(false);

      setIsChatWriting(false);
    };
  }, [dispatch, documentAssetId]);
  useEffect(() => {
    if (documentAssetId && selectedDocumentAsset) {
      console.log(selectedDocumentAsset);
      // console.log("GETTING NEW ASSET");
      sseConnection?.close();
      setSseConnection(null);
      setIsWriting(false);
      if (!selectedDocumentAsset["pipelineId"]) {
        dispatch(fetchOutput(documentAssetId));
        dispatch(fetchChatOutput(documentAssetId));
      } else {
        if (selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan") {
          dispatch(fetchCampaignPlanOutput(documentAssetId));
        }
        setCampaignStatus(selectedDocumentAsset["campaignStatus"]);
      }
    }
  }, [dispatch, documentAssetId, selectedDocumentAsset]);
  useEffect(() => {
    if (
      selectedDocumentAsset &&
      documentAssetId &&
      selectedDocumentAsset["pipelineId"] &&
      selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" &&
      campaignStatus === "complete"
    ) {
      dispatch(fetchCampaignPlanOutput(documentAssetId));
    }
  }, [campaignStatus]);
  useEffect(() => {
    // console.log("SETTING OUT PUT TEXT" + outputText);
    // setContent(outputText);
    // dispatch(updateOutput(outputText));
    editor?.commands.setContent(outputText);
  }, [dispatch, outputText]);
  useEffect(() => {
    // console.log("SETTING OUTPUT TEXT" + outputText);
    // setContent(outputText);
    editorReplace?.commands.setContent(textToInsert);
  }, [dispatch, textToInsert]);
  useEffect(() => {
    // console.log("SETTING OUT PUT TEXT" + outputText);
    setChatContent(chatTextArr);
  }, [dispatch, chatTextArr]);

  useEffect(() => {
    if (projectId !== undefined && documentId !== undefined) {
      dispatch(getAssetsForProject(projectId, documentAssetId));
    }
  }, [dispatch, projectId, documentId]);
  useEffect(() => {
    if (campaignStatus === "complete" && selectedDocumentAsset) {
      fetchCampaignFiles();
    }
  }, [campaignStatus, selectedDocumentAsset]);
  useEffect(() => {
    if (selectedDocumentAsset && selectedDocumentAsset["pipelineId"]) {
      if (campaignStatus !== "complete" && !pollingStartedRef.current) {
        console.log("POLLING FOR CAMPAIGN STATUS");
        setCurrSelectedDocumentAsset(selectedDocumentAsset._id);
        setIsPolling(true);
        pollingStartedRef.current = true;
        pollCampaignStatus();
      }
      const refFileNames = selectedDocumentAsset["assets"].map((item: any) => {
        if (item.name) {
          const name = stripGuid(item.name);
          return { name, referenceType: item.referenceType };
        } else {
          //grab item.referenceText and limit to 100 characters
          if (item.referenceText) {
            const text = item.referenceText.substring(0, 100);
            return {
              limitedText: text,
              text: item.referenceText,
              referenceType: item.referenceType
            };
          } else {
            return { name: "URL", referenceType: item.referenceType };
          }
        }
      });
      setAssetFileNames(refFileNames);
    }
    // Cleanup function to clear interval when component unmounts or dependencies change
    return () => {
      if (
        intervalRef.current &&
        selectedDocumentAsset &&
        selectedDocumentAsset._id !== currSelectedDocumentAsset
      ) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setIsPolling(false);
        pollingStartedRef.current = false;
      }
    };
  }, [selectedDocumentAsset]);
  const intervalRef = useRef<NodeJS.Timer | number | null>(null);
  const pollCampaignStatus = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env["REACT_APP_API_SERVER"]}/api/documentAssets/getCampaignStatus`,
        { documentAssetId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          }
        }
      );

      const status = response.data.campaignStatus;
      setCampaignStatus(status);
      setCampaignStep(response.data.campaignStep);

      if (status !== "complete") {
        intervalRef.current = setTimeout(pollCampaignStatus, POLLING_INTERVAL);
      } else {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        intervalRef.current = null;
        setIsPolling(false);
        console.log("POLLING COMPLETE CLEARING STATUS");
      }
    } catch (error) {
      console.error("Error fetching campaign status:", error);
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = null;
      setIsPolling(false);
    }
  };
  function stripGuid(filename: string) {
    if (!filename) {
      return "";
    }
    // Use a regular expression to match and remove the GUID at the beginning
    return filename.replace(/^[a-f0-9]{24}-/, "");
  }
  const authToken = localStorage.getItem("authToken");

  const editor = useEditor({
    extensions: [
      StarterKit.configure({}),
      BulletList,
      Paragraph,
      OrderedList.configure({ keepMarks: true }),
      ListItem,
      Color,
      TextStyle,
      Highlight,
      BackColor,
      AnimatedLogoNode,
      ResizableImage,
      Markdown.configure({})
    ],
    editorProps: {
      handleDrop: function (view, event, slice, moved) {
        if (
          !moved &&
          event.dataTransfer &&
          event.dataTransfer.files &&
          event.dataTransfer.files[0]
        ) {
          // if dropping external files
          // handle the image upload
          return true; // handled
        }
        return false; // not handled use default behaviour
      },

      attributes: {
        class:
          "text-editor-container prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none"
      }
    },
    // parseOptions: {
    //   preserveWhitespace: "full"
    // },
    content: outputText,
    onUpdate: (e: any) => {
      const html = e.editor.getHTML();
      handleEditorChange(html);
    },
    onTransaction: ({ editor }) => {
      // console.log(editor.state.selection.anchor);
      setBookmark(editor.state.selection.anchor);
    }
  });
  const editorReplace = useEditor({
    extensions: [
      StarterKit,
      Color,
      TextStyle,
      Markdown.configure({
        html: true
      })
    ],

    content: textToInsert,
    onUpdate: (e: any) => {
      const html = e.editor.getHTML();
      handleReplaceEditorChange(html);
    },
    onTransaction: ({ editor }) => {
      // console.log(editor.state.selection.anchor);
      setUpdateBookmark(editor.state.selection.anchor);
    }
  });
  const handleEditorChange = useCallback(
    async (newContent: string) => {
      if (editor) {
        const cursorPosition = editor.state.selection.anchor;
        await setContent(newContent);
        setTyping(true);

        requestAnimationFrame(() => {
          editor.commands.setTextSelection(cursorPosition);
        });
      }
    },
    [editor, setContent, setTyping]
  );

  const handleReplaceEditorChange = useCallback(
    async (newContent: string) => {
      await setTextToInsert(newContent);
    },
    [setTextToInsert]
  );
  const fetchCampaignFiles = async () => {
    setIsLoadingFiles(true);
    try {
      const response = await axiosInstance.post(
        `${process.env["REACT_APP_API_SERVER"]}/api/documentAssets/getFilesForDocumentAsset`,
        { documentAssetId: selectedDocumentAsset?._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          }
        }
      );
      setCampaignFiles(response.data.files);
      setFileFetchError("");
    } catch (error) {
      console.error("Error fetching campaign files:", error);
      console.error("Error fetching campaign files:", error);
      setFileFetchError("Failed to load campaign files.");
    } finally {
      setIsLoadingFiles(false);
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (isWriting && editor) {
      editor?.commands.insertContent({
        type: "animatedLogo"
      });
    }
  }, [isWriting]);

  const closeModal = () => {
    setIsOpen(false);
    setCoPilotModalIsOpen(false);
    setErrorModalIsOpen(false);
    setRetryErrorModalIsOpen(false);
  };

  const createNewDocument = async () => {
    //TODO: Create a new document
    // alert("TODO: Create a new document!");

    // console.log("selectedModels: ", selectedModels);
    if (projectId && documentId && selectedModels !== undefined && selectedModels.length > 0) {
      const newDocAsset = await dispatch(
        createDocumentAsset(
          projectId,
          documentId,
          selectedModels[0].modelName || "Blank Document",
          selectedModels[0]._id
        )
      );
      const selectedProject = projects.find((project) => project._id === projectId);
      await dispatch(getDocumentTypesSuccess(selectedProject.children));
      await dispatch(setSelectedDocumentTypeById(documentId));
      navigate(
        `/project/${projectId}/documentType/${documentId}/documentAsset/${newDocAsset.documentAsset._id}`
      );

      await dispatch(fetchProjects());
    }
    closeModal();
  };

  const setupSSE = useCallback(() => {
    if (!authToken) {
      console.error("No authToken found in localStorage.");
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}; // Return an empty cleanup function
    }
    const sse = new EventSource(
      `${
        process.env["REACT_APP_API_SERVER"]
      }/api/documentAssets/events?authToken=${encodeURIComponent(authToken)}`
    );
    setSseConnection(sse);
    sse.addEventListener("message", async (event: any) => {
      console.log("SSE Message:", event.data);
      setShowProcessingOverlay(false);
      const parsedData = JSON.parse(event.data);

      // const replacedNewLines = parsedData.replace(/\n/g, "<br>");

      await dispatch(appendOutputText(parsedData));
    });
    sse.addEventListener("progress", async (event: any) => {
      console.log("SSE PROGRESS Message:", event.data);
      if (
        JSON.parse(event.data).message === "Processing Guidelines" ||
        JSON.parse(event.data).message.includes("Processing") ||
        JSON.parse(event.data).message.includes("Summarizing")
      ) {
        console.log("SHOWING OVERLEAY");
        setProcessDocumentName(JSON.parse(event.data).message);
        setShowProcessingOverlay(true);
      } else {
        setShowProcessingOverlay(false);
      }
      const parsedData = JSON.parse(event.data);
    });

    sse.addEventListener("error", (event: any) => {
      console.error("SSE Error:", event);
      sse.close();
      setSseConnection(null);
    });
    sse.addEventListener("close", () => {
      console.log("SSE CLOSED");
      sse.close();
      setSseConnection(null);
    });
    sse.addEventListener("open", () => {
      console.log("SSE connection opened");
    });
    sse.addEventListener("completionEnd", (event: any) => {
      setIsWriting(false);
      sse.close();
      setSseConnection(null);
    });
    sse.addEventListener("chatData", async (event: any) => {
      console.log("SSE Chat Message:", event.data);

      const parsedData = JSON.parse(event.data);
      // const replacedNewLines = parsedData.replace(/\n/g, "<br>");
      // await sleep(500);
      dispatch(appendChatOutputText({ text: parsedData, role: "assistant" }));
    });
    sse.addEventListener("chatEnd", (event: any) => {
      console.log("SSE Chat End:", event.data);

      setIsChatWriting(false);
    });

    return () => {
      sse.close();
      setSseConnection(null);
    };
  }, [authToken]);

  useEffect(() => {
    const cleanup = setupSSE();
    return () => {
      cleanup();
    };
  }, [setupSSE]);

  useEffect(() => {
    if (bookmark && editor) {
      editor.commands.focus(bookmark);
      // editor.selection.moveToBookmark(bookmark);
    }
  }, [bookmark, editor]); // Dependencies: bookmark state and editor instance

  useEffect(() => {
    if (updateBookmark && editorReplace) {
      editorReplace.commands.focus(updateBookmark);
      // editor.selection.moveToBookmark(bookmark);
    }
  }, [updateBookmark, editorReplace]);

  const handleWTButtonClick = () => {
    setShowWritingTools(true);
  };
  // const handleEditorChange = (newContent: string, editor: any) => {
  //   const cursorPosition = editor.selection.getBookmark(2, true);

  //   console.log("! Cursor position:", cursorPosition);
  //   setContent(newContent);
  //   setTyping(true);

  //   // Restore the cursor position after the state update
  // };
  function sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (!editor) return;

    const update = () => {
      const { from, to } = editor.state.selection;
      selectedRangeRef.current = { from, to };
      // console.log("Current range set to:", selectedRangeRef.current);
    };

    // Subscribe to updates
    editor.on("update", update);

    // Cleanup on unmount
    return () => {
      editor.off("update", update);
    };
  }, [editor]);

  const coPilotActions = async (event: any, inputValue = "") => {
    if (editor) {
      const lEditor = editor;
      if (event === "selectText" || event === "unselectText") {
        editor.chain().focus();

        if (event === "selectText") {
          const { from, to } = editor.state.selection;
          await setSelectedRange({ from: from, to: to });
          // await setSelectedRange({ from, to }); // Ensure this is up-to-date
          // console.log("SETTED SELECTED RANGE");
          // console.log(selectedRange);
          editor.chain().setBackColor("#AFD0FC").run();
        } else {
          editor.commands.setTextSelection(selectedRangeRef.current);
          editor.chain().unsetBackColor().run();
        }
      } else {
        // console.log("SELECTED RANGE REF");
        // console.log(selectedRangeRef.current);
        // console.log("SELECTED RANGE");
        // console.log(selectedRange);
        let selectedText = editor.state.doc.textBetween(selectedRange.from, selectedRange.to, " ");
        if (selectedText === "") {
          setCoPilotModalIsOpen(true);
        } else {
          // console.log("INPUT VALUE " + inputValue);

          selectedText = editor.state.doc.textBetween(selectedRange.from, selectedRange.to, " ");

          editor.chain().focus().setBackColor("#AFD0FC").run();
          editor.commands.unsetHighlight();

          setShowInsertTextDialog(true);
          setTextToInsert("Updating ...");
          let modelName = "";
          switch (event) {
            case "expand":
              modelName = "Expand Idea";
              break;
            case "simplify":
              modelName = "Improve Passage";
              break;
            case "summarize":
              modelName = "Paraphrase";
              break;
            case "de-jargon":
              modelName = "Jargon Remover";
              break;
            case "instruct":
              // editor.commands.focus(selectedRange.from);
              // editor.commands.setTextSelection(selectedRange);

              modelName = "Custom Instruct";
              break;
            default:
              break;
          }
          const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/coPilotWrite";

          axiosInstance
            .post(
              url,
              { documentAssetId, selectedText, modelName, instructions: inputValue },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`
                }
              }
            )
            .then(async (response: any) => {
              const rTextToInsert = response.data.answer.replace(/\n/g, "<br>");
              setTextToInsert(rTextToInsert);
            });
        }
      }
    }
  };
  const writeMore = async () => {
    await handleFormSubmit("writeMore");
  };
  const handleFormSubmit = async (type: string, chatInput = "") => {
    // console.log("SUBMITTING FORM");
    // e.preventDefault(); // Prevent default form submission behavior
    if (type === "write" || type === "continueWrite" || type === "writeMore") {
      setIsButtonDisabled(true);
      await setupSSE();
      await sleep(1000);
      const documentAssetId = selectedDocumentAsset?._id || "";
      const sDocumentId = documentId || "";
      const success = await dispatch(checkUsage());

      if (!success) {
        console.log("Error: NOT ENOUGH CREDITS");
        setNoCreditsModalIsOpen(true);
        setIsWriting(false);
      } else {
        setIsWriting(true);
        let continueWriting = false;
        let writeMore = false;
        if (type === "writeMore") {
          writeMore = true;
          await dispatch(appendOutputText(" "));
        }
        if (type === "continueWrite") {
          continueWriting = true;
          dispatch(appendOutputText(" "));
        }
        if (type === "write" && outputText.length > 0) {
          dispatch(appendOutputText(" "));
        }
        const resp = await dispatch(
          fetchServerResponse(
            inputValues,
            documentAssetId,
            sDocumentId,
            assetType,
            writeMore,
            continueWriting
          )
        );
        if (!resp.success) {
          if (resp.message !== undefined && resp.message.includes("too long")) {
            setRetryErrorMessage(resp.message);
            setRetryErrorModalIsOpen(true);
            setWritingType(type);
          } else {
            if (resp.message) {
              setErrorMessage(resp.message);
            }
            setErrorModalIsOpen(true);
            setIsWriting(false);
          }
        }
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 1000);
      }
    } else if (type === "chat") {
      await setupSSE();
      await sleep(1000);
      const documentAssetId = selectedDocumentAsset?._id || "";
      dispatch(appendChatOutputText({ text: chatInput, role: "user" }));
      const success = await dispatch(checkUsage());

      if (!success) {
        console.log("Error: NOT ENOUGH CREDITS");
        setNoCreditsModalIsOpen(true);
        setIsChatWriting(false);
      } else {
        setIsChatWriting(true);
        const fChatResp: any = await dispatch(fetchChatResponse(chatInput, documentAssetId)).catch(
          (err) => {
            setIsChatWriting(false);
          }
        );
        if (!fChatResp.success) {
          setErrorMessage(fChatResp.message);
          setErrorModalIsOpen(true);

          setIsChatWriting(false);
        }
      }
    } else if (type === "cancelChat") {
      if (sseConnection) {
        await sseConnection.close();
        setSseConnection(null);
        setIsChatWriting(false);
      }
    } else if (type === "clearChat") {
      const documentAssetId = selectedDocumentAsset?._id || "";
      await dispatch(resetChatOutput(documentAssetId));
    } else {
      if (sseConnection) {
        await sseConnection.close();
        setSseConnection(null);
        setIsWriting(false);
        // setupSSE();
      }
    }
  };
  const handleInputChange = (e: any, order: number) => {
    let value = e;
    if (e.target) {
      value = e.target.value;
    }
    // console.log("VALUE");
    // console.log(value);
    // console.log("ORDER");
    // console.log(order);
    const newValues = { ...inputValues };
    newValues[order] = value;
    // console.log("NEW VALUES");
    // console.log(newValues);
    setInputValues(newValues);
    setInputTyping(true);
  };

  const customMenuStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      marginTop: "2px"
    })
  };
  // }
  function decodeHtmlEntities(str: any) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
  }
  function renderGuidelineSections() {
    return v2GuidelineTexts.map((item: any, index: number) => {
      return (
        <div key={index} style={{ border: "1px solid gray" }} className="mb-4">
          <h1 className="text-lg font-semibold">{item.title}</h1>
          <div
            className="text-base"
            dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(item.text) }}></div>
        </div>
      );
    });
  }
  function formatToReadableString(input: string): string {
    return (
      input
        // Insert a space before all uppercase letters not at the start of the string
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // Capitalize the first letter of each word
        .replace(/\b\w/g, (char) => char.toUpperCase())
    );
  }
  async function downloadDocx() {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/getDocumentInDocx";
      const response = await axiosInstance.post(
        url,
        { documentAssetId }, // Ensure documentAssetId is available in scope
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}` // Include if needed
          },
          responseType: "blob" // Important for handling binary data
        }
      );

      const blob = new Blob([response.data], { type: response.headers["content-type"] });

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "FundwriterDoc.docx";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the document:", error);
      // Handle error appropriately
    }
  }
  const renderSidebarForAdvGuidline = () => {
    return (
      <div
        className="flex writingPagePanel"
        style={{ overflowX: "hidden", height: "100%", flex: "1 1 auto" }}>
        <ReferenceContentDialog
          modalIsOpen={rModalIsOpen}
          setModalIsOpen={(e) => {
            setRModalIsOpen(e);
          }}
          projectId={projectId}
          selectedDocumentTypeId={documentId}
          selectedDocumentAssetId={documentAssetId}
          proposalName={""}
          inputUrlAssets={urlAssets}
          inputFileAssets={fileAssets}
          inputRefTextAssets={refTextAssets}
          setLastPolledAssetId={setLastPolledAssetId}
          setDropDownInitted={setDropDownInitted}
        />
        <ResizePanel
          direction="e"
          handleClass="customHandleEW"
          initialSize={275}
          minWidth="225px"
          draggingClass="drag-border-e"
          style={{ zIndex: 49 }}>
          <div
            className="border-r border-gray-300 pt-5 pb-2 documentAssetInputs relative"
            style={{
              backgroundColor: "rgb(246, 246, 246)",
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            {showWritingTools ? (
              <WritingTools setShowWritingTools={setShowWritingTools} />
            ) : (
              // </div>
              <>
                {/* chaning this to not show the polling for DEMO 6/8/2023 */}
                {pollingStatus === "complete1" && (
                  <div>
                    <div
                      className={`absolute inset-0 ${
                        pollingStatus === "complete1" ? "bg-gray-500 opacity-50" : ""
                      }`}
                      style={{ zIndex: 999 }}>
                      <div className="loading-svg-position" style={{ zIndex: 1000 }}>
                        <AnimatedLogo />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid #d3d3d3",
                    flex: "0 0 auto"
                  }}
                  className="">
                  <div className="flex flex-row pl-4 pr-4">
                    <div style={{ flex: "1 1 auto" }}>
                      <button
                        style={{ flexDirection: "row", display: "flex", fontSize: "0.9rem" }}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded mb-4"
                        onClick={handleWTButtonClick}>
                        <div style={{ alignSelf: "center", marginRight: "6px" }}>
                          <ToolIcon height={"16px"} color={"white"}></ToolIcon>
                        </div>
                        <div>Writing Models</div>
                      </button>
                    </div>
                    <div
                      className="flex justify-end"
                      style={{}}
                      onClick={() => {
                        setRModalIsOpen(!rModalIsOpen);
                      }}>
                      <HtmlTooltip title="Upload reference documents" placement="top" arrow>
                        <div>
                          <UploadIcon
                            height={"24px"}
                            color={"#606368"}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </HtmlTooltip>
                    </div>
                  </div>
                  <h1 className="pl-4 pr-4 text-md font-semibold mb-4" style={{ color: "#7D7D7D" }}>
                    {selectedDocumentAsset?.name || ""} Inputs
                  </h1>
                </div>
                <div
                  className="pl-4 pr-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    flex: "1 1 auto"
                  }}>
                  <div className="">
                    <form className="pt-2">
                      {modelInputs.map((input: IModelInput) => (
                        <div key={input.order} className="mb-1">
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                              <label
                                htmlFor={`input-${input.order}`}
                                className="block mb-1 text-sm">
                                {input.inputName}
                              </label>
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                display: "flex",
                                flexGrow: 1,
                                justifyContent: "flex-end",
                                cursor: "pointer"
                              }}>
                              <HtmlTooltip title={input.inputDesc} placement="top" arrow>
                                <div>
                                  <InfoIcon height={"16px"} width={"16px"} color={"#707070"} />
                                </div>
                              </HtmlTooltip>
                            </div>
                          </div>
                          {renderInput(input)}
                        </div>
                      ))}
                    </form>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "0 0 auto",
                    borderTop: "1px solid #d3d3d3"
                  }}>
                  <div
                    className=""
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      if (!isWriting) {
                        if (!isButtonDisabled) handleFormSubmit("write");
                      } else {
                        handleFormSubmit("cancel");
                      }
                    }}>
                    <button
                      className={`mt-2 text-white font-semibold py-0.5 px-2 rounded ${
                        isWriting ||
                        (editor &&
                          editor.getHTML() !== "<p>Start Your Document Here ...</p>" &&
                          editor.getHTML() !== "<p></p>" &&
                          editor.getHTML() !== "")
                          ? "bg-gray-500 hover:bg-gray-700 mr-4"
                          : "bg-fw-med-blue hover:bg-fw-dark-blue"
                      } ${
                        isButtonDisabled ? "disabled bg-gray-500 hover:bg-gray-700" : ""
                      } focus:outline-none`}>
                      {isWriting ? (
                        <>
                          Stop Writing<span className="dot">.</span>
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                        </>
                      ) : (
                        "Write"
                      )}
                    </button>
                  </div>
                  {!isWriting ? (
                    <>
                      {editor &&
                        editor.getHTML() !== "<p>Start Your Document Here ...</p>" &&
                        editor.getHTML() !== "<p></p>" &&
                        editor.getHTML() !== "" && (
                          <div
                            className=""
                            onClick={() => {
                              if (!isWriting) {
                                if (!isButtonDisabled) handleFormSubmit("continueWrite");
                              }
                            }}>
                            <button
                              // className="mt-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none ml-4">
                              className={`mt-2 text-white font-semibold py-0.5 px-2 rounded focus:outline-none ml-4 ${
                                isButtonDisabled
                                  ? "bg-gray-500 hover:bg-gray-700 disabled"
                                  : "bg-fw-med-blue hover:bg-fw-dark-blue"
                              }`}>
                              Continue
                            </button>
                          </div>
                        )}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </ResizePanel>
        {/* Center Writing Panel Begin */}
        {/* <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
        <div
          style={{ alignSelf: "center", display: "flex", overflowY: "hidden", flex: "1 1 auto" }}> */}
        <div
          className="pr-2 pl-2 pb-2 pt-5"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginLeft: "10px",
            marginRight: "10px",
            position: "relative",
            height: "100%"
          }}>
          {showProcessingOverlay && (
            <div className="overlay">
              <div className="dialog flex flex-row items-center">
                <div style={{ zIndex: 1 }}>
                  <AnimatedLogo height="40px" width="40px" />
                </div>
                <div className="flex flex-col ml-2">
                  <div className="font-semibold" style={{ color: "#4E4E4E" }}>
                    {processDocumentName} Document<span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    This might take a minute, please be patient
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedDocumentType && (
            <>
              <div className="flex flex-row items-center">
                <div className="flex flex-row text-md" style={{ color: "#4E4E4E" }}>
                  {selectedDocumentType.name} {/* <div className="flex items-center"> */}
                  <div style={{ marginLeft: "8px" }}>&gt;</div>
                  <SideScrollingTextDisplay />
                  {/* </div> */}
                </div>

                {/* {(isWriting || isChatWriting) && (
                <div style={{ height: "20px", zIndex: 1 }}>
                  <AnimatedLogo height="30px" width="30px" />
                </div>
              )} */}
              </div>
              {/* <div className="flex items-center"> */}
              {/* <HtmlTooltip title="Add a new document to this project" placement="top" arrow>
                <div style={{ cursor: "pointer", marginRight: "8px" }} onClick={openModal}>
                  <PlusCircle
                    height={"20px"}
                    width={"20px"}
                    color={"#3FA1D7"}
                    filter={"drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4))"}
                  />
                </div>
              </HtmlTooltip> */}
              {/* <SideScrollingTextDisplay />
                </div> */}
            </>
          )}
          <div style={{ display: "flex", alignItems: "center", maxWidth: "850px" }}>
            <CustomToolbar
              editor={editor}
              writeMore={writeMore}
              coPilotActions={coPilotActions}
              setCoPilotModalIsOpen={setCoPilotModalIsOpen}
            />
            <HtmlTooltip title="Download as .docx" placement="top" arrow>
              <div
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => {
                  downloadDocx();
                }}>
                <DownloadIcon height={"24px"} color={"#606368"} style={{ cursor: "pointer" }} />
              </div>
            </HtmlTooltip>
          </div>
          <div
            style={{
              flexGrow: 1,
              // padding: "0.5rem",
              marginLeft: "10px",
              marginRight: "10px",
              overflowY: "auto",
              border: "1px solid #ccc",
              justifyContent: "center",
              alignItems: "center"
              // display: "flex",
              // flexDirection: "column",
            }}
            className="text-editor-container text-sm">
            {/* className={`editor-container ${isWriting ? "editor-blinking-cursor" : ""}`}> */}
            {renderGuidelineSections()}
          </div>
          {showInsertTextDialog && (
            <div
              style={{
                width: "100%",
                maxWidth: "850px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)",
                marginTop: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                border: "1px solid rgb(63, 161, 215)",
                borderRadius: "4px"
              }}>
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "6px",
                  fontWeight: "600",
                  color: "rgb(78, 78, 78)",
                  borderBottom: "1px solid #ccc"
                }}>
                CoPilot Generated Text:
              </div>
              <div
                onClick={() => {
                  if (editor) {
                    // editor.commands.focus(selectedRange.from);
                    // editor.commands.setTextSelection(selectedRange);
                  }
                }}
                style={{
                  zIndex: 15, // Make sure the div appears above other elements,
                  fontSize: "13px",
                  color: "rgb(63, 161, 215)"
                }}>
                {textToInsert === "Updating ..." ? (
                  <>
                    <div className={`absolute inset-0`} style={{ zIndex: 999 }}>
                      <div className="loading-svg-position" style={{ zIndex: 1000 }}>
                        <AnimatedLogo />
                      </div>
                    </div>
                    <div
                      className="pulse"
                      style={{ marginTop: "6px", marginBottom: "6px", color: "#7758f9" }}>
                      Generating new content<span className="dot">.</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                    </div>
                  </>
                ) : (
                  <EditorContent
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      paddingTop: "8px",
                      paddingBottom: "8px"
                    }}
                    editor={editorReplace}
                  />
                )}
                <div
                  className="descriptive-text"
                  style={{ paddingTop: "6px", borderTop: "1px solid #ccc" }}>
                  Click Replace to replace the highlighted text in the document with the new text
                  from CoPilot
                </div>
                <Button
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    padding: "0px",
                    marginTop: "4px",
                    marginBottom: "6px",
                    border: "1px solid rgb(63, 161, 215)",
                    color: "rgb(63, 161, 215)"
                  }}
                  size="small"
                  onClick={() => {
                    // console.log("REPLACING");
                    if (editor) {
                      editor.commands.focus(selectedRange.from);
                      editor.commands.setTextSelection(selectedRange);
                      editor.chain().focus().unsetBackColor().run();
                      // editor.commands.unsetHighlight();
                      editor.commands.insertContentAt(selectedRange, textToInsert);
                      setShowInsertTextDialog(false);
                      setTextToInsert("");
                    }
                  }}>
                  Replace
                </Button>
                <Button
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    padding: "0px",
                    marginTop: "6px",
                    marginBottom: "6px",
                    marginLeft: "8px",
                    color: "rgb(107, 114, 128)",
                    border: "1px solid rgb(107, 114, 128)"
                  }}
                  size="small"
                  onClick={() => {
                    // console.log("REPLACING");
                    if (editor) {
                      editor.commands.focus(selectedRange.from);
                      editor.commands.setTextSelection(selectedRange);
                      // editor.commands.unsetHighlight();
                      editor.chain().focus().unsetBackColor().run();
                      setTextToInsert("");
                      setShowInsertTextDialog(false);
                    }
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  function sanitizeFileName(name: string): string {
    // Remove invalid characters and replace whitespace with underscores
    let sanitized = name.replace(/[<>:"/\\|?*\x00-\x1F]/g, "").replace(/\s+/g, "_");

    // If the filename ends with a period, remove it
    if (sanitized.endsWith(".")) {
      sanitized = sanitized.slice(0, -1);
    }

    return sanitized;
  }
  const handleAllFilesDownload = async (selectedDocumentAsset: any) => {
    try {
      const response = await axiosInstance.post(
        `${process.env["REACT_APP_API_SERVER"]}/api/documentAssets/downloadCampaignFiles`,
        { documentAssetId: selectedDocumentAsset._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          },
          responseType: "blob" // Important for handling binary data
        }
      );

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${sanitizeFileName(selectedDocumentAsset.name)}.zip`);

      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();

      if (link && link.parentNode) {
        // Clean up
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleRunCampaign = async (selectedDocumentAsset: any) => {
    try {
      const response = await axiosInstance.post(
        `${process.env["REACT_APP_API_SERVER"]}/api/pipeline/continueAppealPipeline`,
        { documentAssetId: selectedDocumentAsset._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          }
        }
      );
      pollCampaignStatus();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleFileDownload = async (fileId: any, fileName: string) => {
    try {
      const response = await axiosInstance.post(
        `${process.env["REACT_APP_API_SERVER"]}/api/documentAssets/downloadCampaignFile`,
        { fileId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          },
          responseType: "blob" // Important for handling binary data
        }
      );

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();

      if (link && link.parentNode) {
        // Clean up
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const renderCampaignFilesDiv = (isLoadingFiles: boolean, campaignFiles: any) => {
    if (isLoadingFiles) {
      return (
        <div>
          {" "}
          {/* <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <div style={{ fontWeight: "600", lineHeight: "28px" }}>Campaign Files</div>
            <HtmlTooltip title="Download all files in a .zip" placement="top" arrow>
              <button
                className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                style={{ marginLeft: "150px", cursor: "pointer" }}
                onClick={() => handleAllFilesDownload(selectedDocumentAsset)}>
                Download All
              </button>
            </HtmlTooltip>
          </div> */}
          <p>Loading files...</p>
        </div>
      );
    }

    if (fileFetchError !== "") {
      return <p style={{ color: "red" }}>{fileFetchError}</p>;
    }
    if (
      selectedDocumentAsset &&
      selectedDocumentAsset["pipelineId"] &&
      selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" &&
      campaignFiles.length === 1
    ) {
      return (
        <div>
          {" "}
          {/* <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <HtmlTooltip title="Download all files in a .zip" placement="top" arrow>
              <button
                className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                style={{ marginLeft: "150px", cursor: "pointer" }}
                onClick={() => handleAllFilesDownload(selectedDocumentAsset)}>
                Download Plan
              </button>
            </HtmlTooltip>
          </div> */}
          <div style={{ color: "rgb(78,78,78)" }} className="text-sm">
            {/* <ReactMarkdown>{outputText}</ReactMarkdown> */}

            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                ul: ({ children }) => (
                  <ul className="list-disc ml-5 mb-2 mt-2 text-sm">{children}</ul>
                ),
                ol: ({ children }) => (
                  <ol className="list-decimal ml-5 mb-2 mt-2 text-sm">{children}</ol>
                ),
                h1: ({ children }) => <h1 className="text-xl font-bold mt-4 mb-4">{children}</h1>,
                h2: ({ children }) => (
                  <h2 className="text-lg font-semibold mt-3 mb-3">{children}</h2>
                ),
                h3: ({ children }) => (
                  <h3 className="text-base font-semibold mt-2 mb-2">{children}</h3>
                )
              }}>
              {outputText}
            </ReactMarkdown>
          </div>
        </div>
      );
    }
    if (campaignFiles.length > 0) {
      // Create a list of files
      return (
        <div className="mt-6">
          <div
            style={{
              color: "rgb(78,78,78)",
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px"
            }}>
            <div style={{ fontWeight: "600", lineHeight: "28px" }}>Campaign Files</div>
            {/* <HtmlTooltip title="Download all files in a .zip" placement="top" arrow>
              <button
                className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                style={{ marginLeft: "150px", cursor: "pointer" }}
                onClick={() => handleAllFilesDownload(selectedDocumentAsset)}>
                Download All
              </button>
            </HtmlTooltip> */}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {campaignFiles.map((file: any, index: number) => (
              <li
                style={{ listStyleType: "none" }}
                className="campaign-files  hover:underline hover:text-blue-800"
                key={file.id}>
                <button onClick={() => handleFileDownload(file.id, file.name)}>
                  {`${index + 1}. ${file.name}`}
                </button>
              </li>
            ))}
          </div>
        </div>
      );
    }

    return <p>No files available.</p>;
  };
  const renderCampaignFileList = () => {
    console.log(selectedDocumentAsset);

    return (
      <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            overflowY: "hidden"
          }}>
          {campaignStatus === "running" && (
            <div
              // className={`absolute inset-0 ${
              //   pollingStatus === "complete" ? "bg-gray-500 opacity-50" : ""
              // }`}
              style={{
                // position: "absolute",
                // top: 0,
                // left: 0,
                // right: 0,
                // bottom: 0,
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                padding: "40px"
              }}>
              <div
                className="section-title"
                style={{
                  fontSize: "20px",
                  fontWeight: "600"
                }}>
                {campaignStep}
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </div>
              <div style={{ marginTop: "5px" }}>This might take a few minutes</div>
              <div style={{}}>
                <AnimatedLogo height="200px" width="200px" />
              </div>
              <div style={{ overflowY: "auto" }}>
                {assetFileNames.length > 0 && (
                  <div className="text-sm" style={{ color: "rgb(78,78,78)" }}>
                    <div
                      className="mb-2"
                      style={{ paddingTop: "20px", borderBottom: "1px solid #CCC", width: "40%" }}>
                      Reference Files
                    </div>
                    {assetFileNames.map((file: any, index: number) => {
                      if (file.name) {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <div style={{ fontWeight: "600" }}>{file.name}</div>
                            <div style={{ marginLeft: "20px" }}>
                              Reference Type: {formatToReadableString(file.referenceType)}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <div style={{ fontWeight: "600" }}>{file.limitedText}...</div>
                            <div style={{ marginLeft: "20px" }}>
                              Reference Type: {formatToReadableString(file.referenceType)}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                {selectedDocumentAsset &&
                  selectedDocumentAsset["pipelineInputs"] &&
                  selectedDocumentAsset["pipelineInputs"].length > 0 && (
                    <div className="text-sm mt-4" style={{ color: "rgb(78,78,78)" }}>
                      <div
                        className="mb-2"
                        style={{ borderBottom: "1px solid #CCC", width: "40%" }}>
                        Inputs
                      </div>
                      {selectedDocumentAsset["pipelineInputs"].map((file: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                            maxWidth: "80%"
                          }}>
                          <div style={{ fontWeight: "600", whiteSpace: "nowrap" }}>{file.name}</div>
                          <div style={{ marginLeft: "20px" }}>{file.value}</div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          )}
          {campaignStatus === "complete" && (
            <div
              style={{
                padding: "40px",
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column"
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "20px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #CCC"
                }}>
                <img src={OneClickImage} height={80} width={80} alt={"One-Click Campaign Image"} />
                <div style={{ marginLeft: "20px", alignSelf: "center" }}>
                  <div
                    className="section-title"
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      alignSelf: "center"
                    }}>
                    One-Click Campaign Results
                  </div>
                  {selectedDocumentAsset &&
                  selectedDocumentAsset["pipelineId"] &&
                  selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" &&
                  campaignFiles.length === 1 ? (
                    <div style={{ color: "#707070", fontSize: "14px" }}>
                      Download your campaign plan or click the Run Campaign button to create the
                      files recommend by the plan.
                    </div>
                  ) : (
                    <div style={{ color: "#707070", fontSize: "14px" }}>
                      Download all of your campaign content in a ZIP file by clicking on the button
                      or click individual files to download.
                    </div>
                  )}
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #CCC", paddingBottom: "10px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {selectedDocumentAsset &&
                  selectedDocumentAsset["pipelineId"] &&
                  selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" &&
                  campaignFiles.length === 1 ? (
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                      <HtmlTooltip title="Download the campaign plan" placement="top" arrow>
                        <button
                          className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAllFilesDownload(selectedDocumentAsset)}>
                          Download Plan
                        </button>
                      </HtmlTooltip>
                    </div>
                  ) : (
                    campaignFiles.length > 0 && (
                      <HtmlTooltip title="Download all files in a .zip" placement="top" arrow>
                        <button
                          className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAllFilesDownload(selectedDocumentAsset)}>
                          Download All
                        </button>
                      </HtmlTooltip>
                    )
                  )}
                  {selectedDocumentAsset &&
                    selectedDocumentAsset["pipelineId"] &&
                    selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" && (
                      <div>
                        <HtmlTooltip
                          title="Run a campaign based on this Plan"
                          placement="top"
                          arrow>
                          <button
                            // className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"

                            className={`text-white font-semibold py-0.5 px-2 rounded ${
                              campaignUsage !== null &&
                              maxCampaignUsage !== null &&
                              campaignUsage >= maxCampaignUsage
                                ? "bg-gray-400 cursor-not-allowed"
                                : "bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none"
                            }`}
                            disabled={
                              campaignUsage !== null &&
                              maxCampaignUsage !== null &&
                              campaignUsage >= maxCampaignUsage
                            }
                            style={{ marginLeft: "40px" }}
                            onClick={() => handleRunCampaign(selectedDocumentAsset)}>
                            Run Campaign
                          </button>
                        </HtmlTooltip>

                        {campaignUsage !== null && maxCampaignUsage !== null && (
                          <span
                            className="text-sm"
                            style={{ marginLeft: "20px", color: "rgb(78,78,78)" }}>
                            You have {maxCampaignUsage - campaignUsage} full campaigns remaining on
                            your plan
                          </span>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div style={{ overflowY: "auto" }}>
                {assetFileNames.length > 0 && (
                  <div className="text-sm" style={{ color: "rgb(78,78,78)" }}>
                    <div
                      className="mb-2"
                      style={{ paddingTop: "20px", borderBottom: "1px solid #CCC", width: "40%" }}>
                      Reference Files
                    </div>
                    {assetFileNames.map((file: any, index: number) => {
                      if (file.name) {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <div style={{ fontWeight: "600" }}>{file.name}</div>
                            <div style={{ marginLeft: "20px" }}>
                              Reference Type: {formatToReadableString(file.referenceType)}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <div style={{ fontWeight: "600" }}>{file.limitedText}...</div>
                            <div style={{ marginLeft: "20px" }}>
                              Reference Type: {formatToReadableString(file.referenceType)}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                {selectedDocumentAsset &&
                  selectedDocumentAsset["pipelineInputs"] &&
                  selectedDocumentAsset["pipelineInputs"].length > 0 && (
                    <div className="text-sm mt-4" style={{ color: "rgb(78,78,78)" }}>
                      <div
                        className="mb-2"
                        style={{ borderBottom: "1px solid #CCC", width: "40%" }}>
                        Inputs
                      </div>
                      {selectedDocumentAsset["pipelineInputs"].map((file: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                            maxWidth: "80%"
                          }}>
                          <div style={{ fontWeight: "600", whiteSpace: "nowrap" }}>{file.name}</div>
                          <div style={{ marginLeft: "20px" }}>{file.value}</div>
                        </div>
                      ))}
                    </div>
                  )}

                {renderCampaignFilesDiv(isLoadingFiles, campaignFiles)}
                {/* {selectedDocumentAsset &&
                  selectedDocumentAsset["pipelineId"].modelName === "Outreach Campaign Plan" && (
                    <HtmlTooltip title="Run a campaign based on this Plan" placement="top" arrow>
                      <button
                        className="text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue  focus:outline-none"
                        style={{ marginLeft: "150px", cursor: "pointer" }}
                        onClick={() => handleRunCampaign(selectedDocumentAsset)}>
                        Run Campaign
                      </button>
                    </HtmlTooltip>
                  )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderInput = (input: any) => {
    if (input.inputName === "Grant Proposal Section") {
      let showNoGuidelines = true;
      let guideLineComplete = false;
      [...fileAssets, ...urlAssets, ...refTextAssets].forEach((item) => {
        if (item.referenceType === "grantGuidelines" && item.selected === true) {
          showNoGuidelines = false;
          if (item.status === "complete") {
            guideLineComplete = true;
          }
        }
      });

      if (showNoGuidelines) {
        return (
          <div
            className="text-xs bg-yellow-100 border border-l-4 border-yellow-500 text-yellow-700 p-2 rounded-md mt-1 mb-2"
            style={{ cursor: "pointer" }}
            role="alert"
            onClick={() => {
              setRModalIsOpen(!rModalIsOpen);
            }}>
            <div className="font-bold">No Grant Guideline Reference</div>
            <div>Please select a Grant Guideline Reference document</div>
          </div>
        );
      } else if (pollingStatus === "running" && !guideLineComplete) {
        return (
          <div
            className="text-sm bg-blue-100 border border-l-4 border-blue-400 text-blue-700 p-2 mt-1 mb-2 rounded-md"
            role="alert">
            {/* <strong className="font-bold">Info!</strong> */}
            <span className="block sm:inline">
              Processing Guidelines<span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </span>
          </div>
        );
      }
    }
    if (input.type === "select" && input.inputName === "Fund Name") {
      //TODO THIS IS VERY SPECIFIC FOR THE BLACKBAUD DEMO - NEED TO MAKE THIS MORE GENERIC
      return (
        <Select
          className="text-xs mb-4"
          options={funds}
          value={selectedFundOption}
          isSearchable={true}
          styles={customMenuStyles}
          menuPosition="fixed"
          onChange={(selectedOption: any) => {
            setSelectedFundOption(selectedOption);
            setInputValues({
              ...inputValues,
              [input.order]: selectedOption.value,
              [input.link]: selectedOption.description
            });
            setInputTyping(true);
          }}
        />
      );
    }
    let eContent = inputValues[input.order];

    // console.log(typeof eContent);
    if (eContent) {
      eContent = eContent.toString();
    } else {
      eContent = "";
    }
    if (input.maxCharacters > 90 && input.dropDownValues && input.dropDownValues.length > 0) {
      // console.log("INPUT VALUEWS");
      // console.log(inputValues[input.order]);
      // console.log(inputValues);
      // console.log("INPUT DD VALUES");
      // console.log(input.dropDownValues);

      const dInputValues = input.dropDownValues.map((result: any) => {
        return { label: result, value: result };
      });
      return (
        <Select
          className="text-xs mb-4"
          options={dInputValues}
          value={selectedHeadingOption}
          isSearchable={true}
          styles={customMenuStyles}
          menuPosition="fixed"
          onChange={(selectedOption: any) => {
            setSelectedHeadingOption(selectedOption);
            setInputValues({
              ...inputValues,
              [input.order]: selectedOption.value,
              [input.link]: selectedOption.description
            });
            setInputTyping(true);
          }}
        />
      );
      // if (inputValues[input.order] || inputValues[input.order] === "") {
      //   if (input.dropDownValues && input.dropDownValues.length > 0) {
      //     const ddValues = input.dropDownValues.join("|");

      //     let messageString = input.dropDownValues[0];
      //     let hasReactComponentDiv = eContent.includes("<react-component");
      //     if (hasReactComponentDiv) {
      //       //grab the options parameter from the react component
      //       const optionsRegex = /options="([^"]+)"/;
      //       const options = eContent.match(optionsRegex);
      //       const messageRegex = /message="([^"]+)"/;
      //       const message = eContent.match(messageRegex);
      //       //grab the message paramater from the react component

      //       //check to see if options are the same as the ddValues for the string values

      //       if (options) {
      //         const normalizedString1 = decodeHtmlEntities(options[1]);
      //         const normalizedString2 = decodeHtmlEntities(ddValues);
      //         if (normalizedString1 !== normalizedString2) {
      //           const messageRegex = /message="([^"]+)"/;
      //           const message = eContent.match(messageRegex);
      //           //check to see if the message is in the ddValues
      //           if (message) {
      //             const normalizedString1 = decodeHtmlEntities(message[1]);
      //             const normalizedString2 = decodeHtmlEntities(ddValues);
      //             if (normalizedString2.includes(normalizedString1)) {
      //               messageString = message[1];
      //             }
      //           }

      //           hasReactComponentDiv = false;
      //           //remove the old react component
      //           eContent = eContent.replace(/<react-component(.*?)<\/react-component>/, "");
      //         } else if (message) {
      //           const normalizedString1 = decodeHtmlEntities(message[1]);
      //           const normalizedString2 = decodeHtmlEntities(ddValues);
      //           if (normalizedString2.includes(normalizedString1)) {
      //             messageString = message[1];
      //           } else {
      //             messageString = input.dropDownValues[0];
      //             hasReactComponentDiv = false;
      //             //remove the old react component
      //             eContent = eContent.replace(/<react-component(.*?)<\/react-component>/, "");
      //           }
      //         }
      //       }
      //     }
      //     if (!hasReactComponentDiv && !dropDownInitted) {
      //       console.log("NOT REACT COMPONENT INPUT CHANGE");

      //       handleInputChange(
      //         `<react-component message="${messageString}" options="${ddValues}"></react-component><p></p>` +
      //           eContent,
      //         input.order
      //       );
      //       setDropDownInitted(true);
      //     }
      //     const contextMenuItems = input.dropDownValues.map((item: string) => {
      //       return {
      //         title: item,
      //         command: ({ editor, range }: Command) => {
      //           let content = editor.getHTML().replace("<p>/</p>", "");
      //           const slashCommand = editor.options.extensions.find((item: any) => {
      //             return item.name === "slash-command";
      //           });
      //           if (slashCommand) {
      //             const options = slashCommand.options.items.map((item: any) => {
      //               return item.title;
      //             });
      //             content = content.replace("<p>/", "<p>").replace("/</p>", "</p>");
      //             editor.commands.setContent(
      //               `${content} <react-component message="${item}" options="${options.join(
      //                 "|"
      //               )}">${item}</react-component><p></p>`
      //             );
      //             eContent = `${content} <react-component message="${item}" options="${options.join(
      //               "|"
      //             )}">${item}</react-component><p></p>`;
      //             // console.log("ECONTENT SLASH COMMAND INPUT CHANGE");
      //             handleInputChange(
      //               `${content} <react-component message="${item}" options="${options.join(
      //                 "|"
      //               )}">${item}</react-component><p></p>`,
      //               input.order
      //             );
      //           }
      //         }
      //       };
      //     });

      //     return (
      //       <>
      //         <div
      //           className="input-editor text-xs edpDiv"
      //           style={{
      //             backgroundColor: "white",
      //             border: "1px solid rgb(209, 213, 219)",
      //             borderRadius: "6px",
      //             padding: "0.5rem"
      //           }}>
      //           <EditorProvider
      //             extensions={[
      //               StarterKit,
      //               Placeholder.configure({
      //                 placeholder: ({ node }) => {
      //                   if (node.type.name === "heading") {
      //                     return `Heading ${node.attrs["level"]}`;
      //                   }
      //                   return "Press '/' for options";
      //                 },
      //                 includeChildren: true
      //               }),
      //               SlashCommand.configure({
      //                 items: contextMenuItems
      //               }),
      //               DropDownExtension
      //             ]}
      //             content={eContent + "\n"}
      //             onUpdate={(e: any) => {
      //               const html = e.editor.getHTML();
      //               const newEC = _.cloneDeep(editorContentObj);
      //               newEC[input.order] = html;
      //               setEditorContentObj(newEC);
      //               // console.log("OnUPdate INPUT CHANGE");
      //               handleInputChange(html, input.order);
      //             }}
      //             onCreate={(editor) => {
      //               // Save the editor instance in refs
      //               editorRefs.current[input.order] = editor;
      //             }}>
      //             {null}
      //           </EditorProvider>
      //         </div>
      //         <div
      //           className="text-right text-xs text-gray-500"
      //           style={{
      //             textAlign: "right",
      //             fontSize: "0.75rem",
      //             color: "#6B7280"
      //           }}>
      //           {inputValues[input.order]?.length || 0}/{input.maxCharacters}
      //         </div>
      //       </>
      //     );
      //   } else {
      //     return (
      //       <>
      //         <div style={{ backgroundColor: "white" }}>
      //           <EditorProvider
      //             extensions={[StarterKit]}
      //             content={eContent}
      //             onUpdate={(e: any) => {
      //               const html = e.editor.getHTML();
      //               const newEC = _.cloneDeep(editorContentObj);
      //               newEC[input.order] = html;
      //               setEditorContentObj(newEC);
      //               // console.log("EP OnUPdate INPUT CHANGE");
      //               handleInputChange(html, input.order);
      //             }}
      //             onCreate={(editor) => {
      //               // Save the editor instance in refs
      //               editorRefs.current[input.order] = editor;
      //             }}>
      //             {null}
      //           </EditorProvider>
      //         </div>
      //         <div
      //           className="text-right text-xs text-gray-500"
      //           style={{
      //             textAlign: "right",
      //             fontSize: "0.75rem",
      //             color: "#6B7280"
      //           }}>
      //           {inputValues[input.order]?.length || 0}/{input.maxCharacters}
      //         </div>
      //       </>
      //     );
      //   }
      // }
      // return null;
    } else if (eContent.includes("<react-component")) {
      // console.log("ECONTENT INPUT CHANGE");
      handleInputChange("", input.order);
    } else if (input.maxCharacters > 100) {
      return (
        <>
          <textarea
            id={`input-${input.order}`}
            value={inputValues[input.order]}
            onChange={(e) => {
              // console.log("TEXT AREA INPUT CHANGE");
              handleInputChange(e, input.order);
            }}
            maxLength={input.maxCharacters}
            className="text-xs w-full border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
            style={{
              height: "90px",
              fontSize: "0.75rem",
              width: "100%",
              // border: "1px solid #e5e7eb",
              padding: "0.5rem",
              borderRadius: "0.375rem",
              outline: "none"
              // transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
              // boxShadow: "0 0 0 3px rgba(99, 102, 241, 0.5)",
              // borderColor: "#6366F1"
            }}
          />
          <div
            className="text-right text-xs text-gray-500"
            style={{
              textAlign: "right",
              fontSize: "0.75rem",
              color: "#6B7280"
            }}>
            {inputValues[input.order]?.length || 0}/{input.maxCharacters}
          </div>
        </>
      );
    } else {
      return (
        <TextInput input={input} onChange={handleInputChange} value={inputValues[input.order]} />
      );
    }
  };
  if (isLoading) {
    return (
      <div style={{ padding: "40px" }}>
        Loading<span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </div>
    );
  }
  //TODO add some stuff for CAMPAIGNS
  if (
    selectedDocumentAsset &&
    selectedDocumentAsset["modelId"] &&
    selectedDocumentAsset["modelId"]._id === "6604e9a009a56ea3b09bc1dd"
  ) {
    return <div>{renderSidebarForAdvGuidline()}</div>;
  }
  if (selectedDocumentAsset && selectedDocumentAsset["pipelineId"]) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden"
        }}>
        {renderCampaignFileList()}
      </div>
    );
  }
  // console.log("RENDERING THIS");
  return (
    <>
      <Modal
        isOpen={coPilotModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="CoPilot Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <div style={{ fontSize: "14px", marginBottom: "20px" }}>
          Please select text in the editor first and then choose a CoPilot tool
        </div>
        <div className="flex" style={{ flexDirection: "row", justifyContent: "center" }}>
          <button
            onClick={() => {
              setCoPilotModalIsOpen(false);
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white">
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={noCreditsModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="You have no credits left"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            height: "130px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <h2 style={{ marginBottom: "5px" }}>
          You do not have any credits left and your trial has expired. Please contact
          support@fundwriter.ai
        </h2>
        <div className="flex" style={{ flexDirection: "row", justifyContent: "center" }}>
          <button
            onClick={() => {
              setNoCreditsModalIsOpen(false);
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white">
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={errorModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Error"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            height: "140px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <h2 style={{ marginBottom: "5px" }}>There was an error in processing</h2>
        <h2 style={{ marginBottom: "5px" }}>{errorMessage}</h2>
        <div className="flex" style={{ flexDirection: "row", justifyContent: "center" }}>
          <button
            onClick={() => {
              setErrorModalIsOpen(false);
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white">
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={retryErrorModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Error"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <div
          className="flex"
          style={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2 style={{ marginBottom: "5px" }}>{retryErrorMessage}</h2>
          <h2 style={{ marginBottom: "5px" }}>Retry With Less data</h2>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input type="checkbox" id="refFiles" name="Remove Reference Files" value="refFiles" />
              <label htmlFor="refFiles">Remove Reference Files</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input type="checkbox" id="docHistory" name="Remove Doc History" value="docHistory" />
              <label htmlFor="docHistory">Remove Doc History</label>
            </div>
          </div>
        </div>
        <div
          className="flex"
          style={{ marginTop: "20px", flexDirection: "row", justifyContent: "center" }}>
          <button
            onClick={async () => {
              const refFilesChecked = (document.getElementById("refFiles") as HTMLInputElement)
                ?.checked;
              const docHistoryChecked = (document.getElementById("docHistory") as HTMLInputElement)
                ?.checked;

              // Use the checked values as needed
              // console.log("refFilesChecked:", refFilesChecked);
              // console.log("docHistoryChecked:", docHistoryChecked);
              let continueWriting = false;
              let writeMore = false;
              const documentAssetId = selectedDocumentAsset?._id || "";
              const sDocumentId = documentId || "";
              if (writingType === "writeMore") {
                writeMore = true;
              }
              if (writingType === "continueWrite") {
                continueWriting = true;
              }
              const resp = await dispatch(
                fetchServerResponse(
                  inputValues,
                  documentAssetId,
                  sDocumentId,
                  assetType,
                  writeMore,
                  continueWriting,
                  refFilesChecked,
                  docHistoryChecked
                )
              );
              setRetryErrorModalIsOpen(false);
              if (!resp.success) {
                if (resp.message !== undefined && resp.message.includes("too long")) {
                  setRetryErrorMessage(resp.message);
                  setRetryErrorModalIsOpen(true);
                  setWritingType(writingType);
                } else {
                  if (resp.message) {
                    setErrorMessage(resp.message);
                  }
                  setErrorModalIsOpen(true);
                  setIsWriting(false);
                }
              }
              setTimeout(() => {
                setIsButtonDisabled(false);
              }, 1000);
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4">
            Rerun
          </button>
          <button
            onClick={() => {
              setRetryErrorModalIsOpen(false);
              setIsWriting(false);
              setWritingType("");
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-gray-500 hover:bg-gray-700 text-white ml-4">
            Cancel
          </button>
        </div>
      </Modal>

      <div
        className="flex writingPagePanel"
        style={{ overflowX: "hidden", height: "100%", flex: "1 1 auto" }}>
        <ReferenceContentDialog
          modalIsOpen={rModalIsOpen}
          setModalIsOpen={(e) => {
            setRModalIsOpen(e);
          }}
          projectId={projectId}
          selectedDocumentTypeId={documentId}
          selectedDocumentAssetId={documentAssetId}
          proposalName={""}
          inputUrlAssets={urlAssets}
          inputFileAssets={fileAssets}
          inputRefTextAssets={refTextAssets}
          setLastPolledAssetId={setLastPolledAssetId}
          setDropDownInitted={setDropDownInitted}
        />
        <ResizePanel
          direction="e"
          handleClass="customHandleEW"
          initialSize={275}
          minWidth="225px"
          draggingClass="drag-border-e"
          style={{ zIndex: 49 }}>
          <div
            className="border-r border-gray-300 pt-5 pb-2 documentAssetInputs relative"
            style={{
              backgroundColor: "rgb(246, 246, 246)",
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            {showWritingTools ? (
              <WritingTools setShowWritingTools={setShowWritingTools} />
            ) : (
              // </div>
              <>
                {/* chaning this to not show the polling for DEMO 6/8/2023 */}
                {pollingStatus === "complete1" && (
                  <div>
                    <div
                      className={`absolute inset-0 ${
                        pollingStatus === "complete1" ? "bg-gray-500 opacity-50" : ""
                      }`}
                      style={{ zIndex: 999 }}>
                      <div className="loading-svg-position" style={{ zIndex: 1000 }}>
                        <AnimatedLogo />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid #d3d3d3",
                    flex: "0 0 auto"
                  }}
                  className="">
                  <div className="flex flex-row pl-4 pr-4">
                    <div style={{ flex: "1 1 auto" }}>
                      <button
                        style={{ flexDirection: "row", display: "flex", fontSize: "0.9rem" }}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded mb-4"
                        onClick={handleWTButtonClick}>
                        <div style={{ alignSelf: "center", marginRight: "6px" }}>
                          <ToolIcon height={"16px"} color={"white"}></ToolIcon>
                        </div>
                        <div>Writing Models</div>
                      </button>
                    </div>
                    <div
                      className="flex justify-end"
                      style={{}}
                      onClick={() => {
                        setRModalIsOpen(!rModalIsOpen);
                      }}>
                      <HtmlTooltip title="Upload reference documents" placement="top" arrow>
                        <div>
                          <UploadIcon
                            height={"24px"}
                            color={"#606368"}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </HtmlTooltip>
                    </div>
                  </div>
                  <h1 className="pl-4 pr-4 text-md font-semibold mb-4" style={{ color: "#7D7D7D" }}>
                    {selectedDocumentAsset?.name || ""} Inputs
                  </h1>
                </div>
                <div
                  className="pl-4 pr-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    flex: "1 1 auto"
                  }}>
                  <div className="">
                    <form className="pt-2">
                      {modelInputs.map((input: IModelInput) => (
                        <div key={input.order} className="mb-1">
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                              <label
                                htmlFor={`input-${input.order}`}
                                className="block mb-1 text-sm">
                                {input.inputName}
                              </label>
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                display: "flex",
                                flexGrow: 1,
                                justifyContent: "flex-end",
                                cursor: "pointer"
                              }}>
                              <HtmlTooltip title={input.inputDesc} placement="top" arrow>
                                <div>
                                  <InfoIcon height={"16px"} width={"16px"} color={"#707070"} />
                                </div>
                              </HtmlTooltip>
                            </div>
                          </div>
                          {renderInput(input)}
                        </div>
                      ))}
                      {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className="text-white font-bold py-2 px-4 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                    Write
                  </button>
                </div> */}
                    </form>
                  </div>
                  {/* <div className="mt-8">
                <label htmlFor="server-response" className="block mb-1">
                  Output:
                </label>
                <textarea
                  id="server-response"
                  value={serverResponse}
                  onChange={(e) => dispatch(setServerResponse(e.target.value))}
                  className="w-full h-32 border border-gray-300 p-2"
                />
              </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "0 0 auto",
                    borderTop: "1px solid #d3d3d3"
                  }}>
                  <div
                    className=""
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      if (!isWriting) {
                        if (!isButtonDisabled) handleFormSubmit("write");
                      } else {
                        handleFormSubmit("cancel");
                      }
                    }}>
                    <button
                      className={`mt-2 text-white font-semibold py-0.5 px-2 rounded ${
                        isWriting ||
                        (editor &&
                          editor.getHTML() !== "<p>Start Your Document Here ...</p>" &&
                          editor.getHTML() !== "<p></p>" &&
                          editor.getHTML() !== "")
                          ? "bg-gray-500 hover:bg-gray-700 mr-4"
                          : "bg-fw-med-blue hover:bg-fw-dark-blue"
                      } ${
                        isButtonDisabled ? "disabled bg-gray-500 hover:bg-gray-700" : ""
                      } focus:outline-none`}>
                      {isWriting ? (
                        <>
                          Stop Writing<span className="dot">.</span>
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                        </>
                      ) : (
                        "Write"
                      )}
                    </button>
                  </div>
                  {!isWriting ? (
                    <>
                      {editor &&
                        editor.getHTML() !== "<p>Start Your Document Here ...</p>" &&
                        editor.getHTML() !== "<p></p>" &&
                        editor.getHTML() !== "" && (
                          <div
                            className=""
                            onClick={() => {
                              if (!isWriting) {
                                if (!isButtonDisabled) handleFormSubmit("continueWrite");
                              }
                            }}>
                            <button
                              // className="mt-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none ml-4">
                              className={`mt-2 text-white font-semibold py-0.5 px-2 rounded focus:outline-none ml-4 ${
                                isButtonDisabled
                                  ? "bg-gray-500 hover:bg-gray-700 disabled"
                                  : "bg-fw-med-blue hover:bg-fw-dark-blue"
                              }`}>
                              Continue
                            </button>
                          </div>
                        )}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </ResizePanel>
        {/* Center Writing Panel Begin */}
        {/* <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
          <div
            style={{ alignSelf: "center", display: "flex", overflowY: "hidden", flex: "1 1 auto" }}> */}
        <div
          className="pr-2 pl-2 pb-2 pt-5"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginLeft: "10px",
            marginRight: "10px",
            position: "relative",
            height: "100%"
          }}>
          {showProcessingOverlay && (
            <div className="overlay">
              <div className="dialog flex flex-row items-center">
                <div style={{ zIndex: 1 }}>
                  <AnimatedLogo height="40px" width="40px" />
                </div>
                <div className="flex flex-col ml-2">
                  <div className="font-semibold" style={{ color: "#4E4E4E" }}>
                    {processDocumentName} Document<span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </div>
                  <div style={{ marginTop: "5px", color: "#707070", fontSize: "14px" }}>
                    This might take a minute, please be patient
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedDocumentType && (
            <>
              <div className="flex flex-row items-center">
                <div className="flex flex-row text-md" style={{ color: "#4E4E4E" }}>
                  {selectedDocumentType.name} {/* <div className="flex items-center"> */}
                  <div style={{ marginLeft: "8px" }}>&gt;</div>
                  <SideScrollingTextDisplay />
                  {/* </div> */}
                </div>

                {/* {(isWriting || isChatWriting) && (
                  <div style={{ height: "20px", zIndex: 1 }}>
                    <AnimatedLogo height="30px" width="30px" />
                  </div>
                )} */}
              </div>
              {/* <div className="flex items-center"> */}
              {/* <HtmlTooltip title="Add a new document to this project" placement="top" arrow>
                  <div style={{ cursor: "pointer", marginRight: "8px" }} onClick={openModal}>
                    <PlusCircle
                      height={"20px"}
                      width={"20px"}
                      color={"#3FA1D7"}
                      filter={"drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4))"}
                    />
                  </div>
                </HtmlTooltip> */}
              {/* <SideScrollingTextDisplay />
                  </div> */}
            </>
          )}
          <div style={{ display: "flex", alignItems: "center", maxWidth: "850px" }}>
            <CustomToolbar
              editor={editor}
              writeMore={writeMore}
              coPilotActions={coPilotActions}
              setCoPilotModalIsOpen={setCoPilotModalIsOpen}
            />
            <HtmlTooltip title="Download as .docx" placement="top" arrow>
              <div
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => {
                  downloadDocx();
                }}>
                <DownloadIcon height={"24px"} color={"#606368"} style={{ cursor: "pointer" }} />
              </div>
            </HtmlTooltip>
          </div>
          <div
            style={{
              flexGrow: 1,
              // padding: "0.5rem",
              marginLeft: "10px",
              marginRight: "10px",
              overflowY: "auto",
              border: "1px solid #ccc",
              justifyContent: "center",
              alignItems: "center"
              // display: "flex",
              // flexDirection: "column",
            }}
            className="text-editor-container text-sm">
            {/* className={`editor-container ${isWriting ? "editor-blinking-cursor" : ""}`}> */}
            <EditorContent editor={editor} />
          </div>
          {showInsertTextDialog && (
            <div
              style={{
                width: "100%",
                maxWidth: "850px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)",
                marginTop: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                border: "1px solid rgb(63, 161, 215)",
                borderRadius: "4px"
              }}>
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "6px",
                  fontWeight: "600",
                  color: "rgb(78, 78, 78)",
                  borderBottom: "1px solid #ccc"
                }}>
                CoPilot Generated Text:
              </div>
              <div
                onClick={() => {
                  if (editor) {
                    // editor.commands.focus(selectedRange.from);
                    // editor.commands.setTextSelection(selectedRange);
                  }
                }}
                style={{
                  zIndex: 15, // Make sure the div appears above other elements,
                  fontSize: "13px",
                  color: "rgb(63, 161, 215)"
                }}>
                {textToInsert === "Updating ..." ? (
                  <>
                    <div className={`absolute inset-0`} style={{ zIndex: 999 }}>
                      <div className="loading-svg-position" style={{ zIndex: 1000 }}>
                        <AnimatedLogo />
                      </div>
                    </div>
                    <div
                      className="pulse"
                      style={{ marginTop: "6px", marginBottom: "6px", color: "#7758f9" }}>
                      Generating new content<span className="dot">.</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                    </div>
                  </>
                ) : (
                  <EditorContent
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      paddingTop: "8px",
                      paddingBottom: "8px"
                    }}
                    editor={editorReplace}
                  />
                )}
                <div
                  className="descriptive-text"
                  style={{ paddingTop: "6px", borderTop: "1px solid #ccc" }}>
                  Click Replace to replace the highlighted text in the document with the new text
                  from CoPilot
                </div>
                <Button
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    padding: "0px",
                    marginTop: "4px",
                    marginBottom: "6px",
                    border: "1px solid rgb(63, 161, 215)",
                    color: "rgb(63, 161, 215)"
                  }}
                  size="small"
                  onClick={() => {
                    // console.log("REPLACING");
                    if (editor) {
                      editor.commands.focus(selectedRange.from);
                      editor.commands.setTextSelection(selectedRange);
                      editor.chain().focus().unsetBackColor().run();
                      // editor.commands.unsetHighlight();
                      editor.commands.insertContentAt(selectedRange, textToInsert);
                      setShowInsertTextDialog(false);
                      setTextToInsert("");
                    }
                  }}>
                  Replace
                </Button>
                <Button
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    padding: "0px",
                    marginTop: "6px",
                    marginBottom: "6px",
                    marginLeft: "8px",
                    color: "rgb(107, 114, 128)",
                    border: "1px solid rgb(107, 114, 128)"
                  }}
                  size="small"
                  onClick={() => {
                    // console.log("REPLACING");
                    if (editor) {
                      editor.commands.focus(selectedRange.from);
                      editor.commands.setTextSelection(selectedRange);
                      // editor.commands.unsetHighlight();
                      editor.chain().focus().unsetBackColor().run();
                      setTextToInsert("");
                      setShowInsertTextDialog(false);
                    }
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* </div>
        </div> */}
        {/* Center Writing Panel End */}
      </div>
      <ChatBar onSubmit={handleFormSubmit} chatContent={chatContent} isWriting={isChatWriting} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add New Document Dialog"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "500px",
            // height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <WritingModelSelector
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
            writingSetId={"blank"}
            projectId={projectId}
          />

          <div className="flex mt-1" style={{ justifyContent: "center" }}>
            <button
              // disabled={!validateEmail(userData.email)}
              className="mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
              onClick={createNewDocument}>
              Add Document
            </button>
            <button
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ml-4"
              onClick={() => {
                closeModal();
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
