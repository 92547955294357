import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { fetchDocumentAsset } from "src/reducers/documentAssetReducer";
import { useAppDispatch, RootState } from "src/configureStore";
// import { IDocumentType } from "src/interfaces/IProject";
import { renameItem } from "src/reducers/projectReducer";
import "./sideScrollingTextDisplay.scss";
import { PenIcon } from "src/lib/icons/index";
// import EllipsisMenu from "./ellipsisMenu";
// import { setSelectedProjectById } from "src/reducers/projectReducer";
// import { fetchProjects } from "src/reducers/projectReducer";
import {
  setSelectedDocumentTypeById,
  getDocumentTypesSuccess
} from "src/reducers/documentTypeReducer";
import { HtmlTooltip } from "src/components/tooltip";

// interface SideScrollingProps {
//   exportToDocx: () => void;
// }
const SideScrollingTextDisplay: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState("");
  // const [showLeftArrow, setShowLeftArrow] = useState(false);
  // const [showRightArrow, setShowRightArrow] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [origName, setOrigName] = useState("");
  const [newName, setNewName] = useState("");
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [renamingItemId, setRenamingItemId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { projectId, documentId, documentAssetId } = useParams();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // const leftArrowRef = useRef<HTMLButtonElement>(null);
  // const rightArrowRef = useRef<HTMLButtonElement>(null);
  // const [scrollContainerWidth, setScrollContainerWidth] = useState(0);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { selectedDocumentType } = useSelector((state: RootState) => state.documentTypes);

  const { projects, updatedChildren } = useSelector((state: RootState) => state.projectsReducer);
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (!scrollContainerRef.current) return;

  //     const containerWidth = scrollContainerRef.current.parentElement?.clientWidth;
  //     const leftArrowWidth = leftArrowRef.current?.clientWidth || 0;
  //     const rightArrowWidth = rightArrowRef.current?.clientWidth || 0;

  //     if (containerWidth) {
  //       const availableWidth = containerWidth - leftArrowWidth - rightArrowWidth - 30; // Additional 30px for padding and margin
  //       scrollContainerRef.current.style.width = `${availableWidth}px`;
  //     }
  //     handleScroll();
  //   };

  //   const initialResize = () => {
  //     handleResize();
  //     window.requestAnimationFrame(initialResize);
  //   };

  //   const requestId = window.requestAnimationFrame(initialResize);

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.cancelAnimationFrame(requestId);
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    if (selectedDocumentType.children) {
      setLoading(false);
    }
  }, [selectedDocumentType.children]);

  // useEffect(() => {
  //   if (selectedItem && itemRefs.current[selectedItem]) {
  //     const itemElement = itemRefs.current[selectedItem];
  //     const scrollContainer = scrollContainerRef.current;

  //     if (itemElement && scrollContainer) {
  //       const itemRect = itemElement.getBoundingClientRect();
  //       const containerRect = scrollContainer.getBoundingClientRect();

  //       if (itemRect.left < containerRect.left) {
  //         // Item is off to the left, scroll to the left
  //         scrollContainer.scrollTo({
  //           left: scrollContainer.scrollLeft - (containerRect.left - itemRect.left),
  //           behavior: "smooth"
  //         });
  //       } else if (itemRect.right > containerRect.right) {
  //         // Item is off to the right, scroll to the right
  //         scrollContainer.scrollTo({
  //           left: scrollContainer.scrollLeft + (itemRect.right - containerRect.right),
  //           behavior: "smooth"
  //         });
  //       }
  //     }
  //   }
  // }, [selectedItem]);

  useEffect(() => {
    if (documentAssetId) {
      setSelectedItem(documentAssetId);
    }
  }, [documentAssetId]);

  useEffect(() => {
    async function runUpdate() {
      if (documentId) {
        const selectedProject = projects.find((project) => project._id === projectId);
        await dispatch(getDocumentTypesSuccess(selectedProject.children));
        await dispatch(setSelectedDocumentTypeById(documentId));
      }
    }
    runUpdate();
  }, [updatedChildren, dispatch]);

  // const handleScroll = () => {
  //   if (!scrollContainerRef.current) return;

  //   const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
  //   setShowLeftArrow(scrollLeft > 0);
  //   setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
  // };

  // useEffect(() => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (scrollContainerRef.current) {
  //       scrollContainerRef.current.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);
  // useEffect(() => {
  //   const scrollContainer = scrollContainerRef.current;
  //   if (scrollContainer) {
  //     scrollContainer.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (scrollContainer) {
  //       scrollContainer.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);

  // const handleItemClick = (itemId: any) => {
  //   dispatch(fetchDocumentAsset(itemId));
  //   navigate(
  //     `/project/${selectedDocumentType.project}/documentType/${selectedDocumentType._id}/documentAsset/${itemId}`
  //   );
  //   setSelectedItem(itemId);
  // };

  // const handleArrowClick = (direction: "left" | "right") => {
  //   const scrollContainer = scrollContainerRef.current;
  //   if (scrollContainer) {
  //     const scrollAmount = direction === "left" ? -200 : 200;
  //     const startTime = performance.now();
  //     const startScroll = scrollContainer.scrollLeft;

  //     const smoothScroll = (currentTime: number) => {
  //       const elapsedTime = currentTime - startTime;
  //       const progress = Math.min(elapsedTime / 300, 1); // 300ms for smooth scrolling duration
  //       const easeProgress = progress * (2 - progress); // easeInOutQuad easing function
  //       const currentScroll = startScroll + scrollAmount * easeProgress;

  //       scrollContainer.scrollTo(currentScroll, 0);

  //       if (progress < 1) {
  //         requestAnimationFrame(smoothScroll);
  //       }
  //     };

  //     requestAnimationFrame(smoothScroll);
  //   }
  // };
  const handleRename = (id: string, event: React.MouseEvent, newName: string) => {
    event?.stopPropagation?.();
    // if (Object.entries(event).length > 0) {
    //   event.stopPropagation();
    // }
    console.log("Renaming item:", id);
    setIsRenaming(true);
    setRenamingItemId(id);
    setNewName(newName);
    setOrigName(newName);
  };
  const handleRenameSubmit = async (
    event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    // updateProject();
    if (event.type === "blur" || (event as React.KeyboardEvent<HTMLInputElement>).key === "Enter") {
      console.log(`Rename submitted for ${id} with new name: ${newName}`);
      // Call the API to rename the item, and update the state with the new name
      await dispatch(renameItem(id, newName, "documentAssets"));

      setIsRenaming(false);
    } else if ((event as React.KeyboardEvent<HTMLInputElement>).key === "Escape") {
      // Cancel renaming
      setIsRenaming(false);
      setNewName(origName);
    }
  };

  return (
    <div className="side-scrolling-container">
      <div className="flex flex-row items-center">
        {/* {showLeftArrow && (
          <button
            ref={leftArrowRef}
            className="arrow-btn arrow-left"
            onClick={() => handleArrowClick("left")}>
            <RightChevron
              className="rotate-chevron"
              height={"20px"}
              width={"20px"}
              color={"rgba(63, 161, 215, 1)"}
            />
          </button>
        )} */}
        <div
          className="mr-3 text-md font-semibold"
          id="horizontal-scroll-container"
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
            overflowX: "hidden",
            marginLeft: "4px"
            // scrollSnapType: "x mandatory",
            // // width: `${scrollContainerWidth}px`
          }}
          ref={scrollContainerRef}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            // <div style={{ display: "flex" }}>
            //   {selectedDocumentType.children
            //     ? [...selectedDocumentType.children]
            //         .filter((item) => item._id === selectedItem) // Only process the selected item
            //         .sort((a, b) => a.order - b.order)
            //         .map((item, index) => {
            //           return (
            //             <div
            //               key={index}
            //               ref={(el) => (itemRefs.current[item._id] = el)}
            //               className="flex"
            //               onMouseEnter={() => setHoveredItemId(item._id)} // add this line
            //               onMouseLeave={() => setHoveredItemId(null)}>
            //               <div
            //                 // key={index}
            //                 className={`cursor-pointer text-sm pl-1 pr-1 assetItem ${
            //                   item._id === selectedItem
            //                     ? "selectedAssetItem"
            //                     : "unselectedAssetItem"
            //                 }`}
            //                 onClick={() => handleItemClick(item._id)}
            //                 style={{
            //                   marginLeft: "16px",
            //                   cursor: "pointer",
            //                   whiteSpace: "nowrap",
            //                   display: "flex",
            //                   justifyContent: "space-between"
            //                 }}
            //                 // onMouseEnter={() => setHoveredItemId(item._id)} // add this line
            //                 // onMouseLeave={() => setHoveredItemId(null)}
            //                 // data-item={item.name}
            //               >
            //                 {isRenaming && renamingItemId === item._id ? (
            //                   <input
            //                     style={{ color: "black", minWidth: "200px" }}
            //                     className="appearance-none block w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            //                     ref={nameInputRef}
            //                     type="text"
            //                     value={newName}
            //                     onChange={(e) => setNewName(e.target.value)}
            //                     onBlur={(e) => handleRenameSubmit(e, item._id)}
            //                     onKeyDown={(e) => handleRenameSubmit(e, item._id)}
            //                     autoFocus
            //                   />
            //                 ) : (
            //                   <div>{item.name}</div>
            //                 )}
            //               </div>
            //               <div
            //                 className="ml-auto"
            //                 style={{
            //                   marginLeft: "5px",
            //                   width: "20px"
            //                 }}>
            //                 {!isRenaming && hoveredItemId === item._id && (
            //                   <HtmlTooltip title="Rename document" placement="top" arrow>
            //                     <div
            //                       style={{
            //                         zIndex: 100,
            //                         position: "relative",
            //                         width: "20px",
            //                         height: "20px",
            //                         display: "flex"
            //                       }}
            //                       onClick={(event: React.MouseEvent) =>
            //                         handleRename(item._id, event, item.name)
            //                       }>
            //                       {/* <EllipsisMenu
            //                       onRename={(event: React.MouseEvent) =>
            //                         handleRename(item._id, event, item.name)
            //                       }
            //                       onDelete={(event: React.MouseEvent) =>
            //                         handleDelete(event, item._id, "documentAssets", item)
            //                       }
            //                     /> */}
            //                       <button style={{ alignItems: "center", display: "flex" }}>
            //                         <PenIcon height={"12px"} color={"#606368"} />
            //                       </button>
            //                     </div>
            //                   </HtmlTooltip>
            //                 )}
            //               </div>
            //             </div>
            //           );
            //         })
            //     : null}
            // </div>
            <div style={{ display: "flex" }}>
              {selectedDocumentType.children
                ? (() => {
                    // Find the selected item in the array
                    const item = selectedDocumentType.children.find(
                      (child: { _id: string }) => child._id === selectedItem
                    );
                    if (!item) return null; // If not found, don't render anything
                    return (
                      <div
                        key={item._id}
                        ref={(el) => (itemRefs.current[item._id] = el)}
                        className="flex"
                        onMouseEnter={() => setHoveredItemId(item._id)} // add this line
                        onMouseLeave={() => setHoveredItemId(null)}>
                        <div
                          // key={index}
                          // className={`cursor-pointer text-sm pl-1 pr-1 assetItem ${
                          //   item._id === selectedItem ? "selectedAssetItem" : "unselectedAssetItem"
                          // }`}
                          // onClick={() => handleItemClick(item._id)}
                          onDoubleClick={(e) => handleRename(item._id, e, item.name)}
                          style={{
                            marginLeft: "4px",
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                          // onMouseEnter={() => setHoveredItemId(item._id)} // add this line
                          // onMouseLeave={() => setHoveredItemId(null)}
                          // data-item={item.name}
                        >
                          {isRenaming && renamingItemId === item._id ? (
                            <input
                              style={{ color: "black", minWidth: "200px" }}
                              className="appearance-none block w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-md"
                              ref={nameInputRef}
                              type="text"
                              value={newName}
                              onChange={(e) => setNewName(e.target.value)}
                              onBlur={(e) => handleRenameSubmit(e, item._id)}
                              onKeyDown={(e) => handleRenameSubmit(e, item._id)}
                              autoFocus
                            />
                          ) : (
                            <div>{item.name}</div>
                          )}
                        </div>
                        <div
                          className="ml-auto"
                          style={{
                            marginLeft: "5px",
                            width: "20px"
                          }}>
                          {!isRenaming && hoveredItemId === item._id && (
                            <HtmlTooltip title="Rename document" placement="top" arrow>
                              <div
                                style={{
                                  zIndex: 100,
                                  position: "relative",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex"
                                }}
                                onClick={(event: React.MouseEvent) =>
                                  handleRename(item._id, event, item.name)
                                }>
                                {/* <EllipsisMenu
                                    onRename={(event: React.MouseEvent) =>
                                      handleRename(item._id, event, item.name)
                                    }
                                    onDelete={(event: React.MouseEvent) =>
                                      handleDelete(event, item._id, "documentAssets", item)
                                    }
                                  /> */}
                                <button style={{ alignItems: "center", display: "flex" }}>
                                  <PenIcon height={"12px"} color={"#606368"} />
                                </button>
                              </div>
                            </HtmlTooltip>
                          )}
                        </div>
                      </div>
                    );
                    // ... Render the item here, e.g. return (<div>{item.name}</div>);
                  })()
                : null}
              {/* <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  exportToDocx();
                }}>
                Export to Docx
              </div> */}
            </div>
          )}
        </div>
        {/* {showRightArrow && (
          <button
            ref={rightArrowRef}
            className="arrow-btn arrow-right"
            onClick={() => handleArrowClick("right")}>
            <RightChevron height={"20px"} width={"20px"} color={"rgba(63, 161, 215, 1)"} />
          </button>
        )} */}
      </div>
    </div>
  );
};

export default SideScrollingTextDisplay;
