import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { DocumentType } from "./documentType";
import { WritingWizard } from "./writingWizard";
import { WritingPage } from "./writingPage";
import LandingPage from "./landingPage";
import Project from "./project";
import UserProfile from "./userProfile";
import { useLocation } from "react-router-dom";

import "./main.scss";

const Main = () => {
  const location = useLocation();
  // console.log("Current Path is", location);
  return (
    <div className="main" style={{ width: "100%", overflowY: "auto", display: "flex" }}>
      {/* <LandingPage /> */}

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/project/:projectId" element={<Project />} />
        <Route path="/documentType/:documentId" element={<DocumentType />} />
        <Route path="/project/:projectId/writingWizard/:writingSetId" element={<WritingWizard />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route
          path="/project/:projectId/documentType/:documentId/documentAsset/:documentAssetId"
          element={<WritingPage />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Main;
