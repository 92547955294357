import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../configureStore";
import { fetchLinkPreview } from "../reducers/clientReducer";

interface LinkPreviewProps {
  url: string;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({ url }) => {
  const dispatch = useDispatch<AppDispatch>();
  const linkPreview = useSelector((state: RootState) => state.clientReducer.linkPreviews[url]);
  const authToken = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (!linkPreview && authToken) {
      dispatch(fetchLinkPreview(url, authToken));
    }
  }, [url, linkPreview, dispatch, authToken]);
  

  const handleThumbnailClick = () => {
    window.open(url, "_blank");
  };

  return (
    <div className="link-preview" onClick={handleThumbnailClick} style={{ cursor: "pointer" }}>
      {linkPreview && (
        <>
          <div className="mb-2 text-sm">{linkPreview.title}</div>
          {linkPreview.image && (
            <img
              src={linkPreview.image}
              alt={linkPreview.title}
            />
          )}
        </>
      )}
    </div>
  );
};

export default LinkPreview;