import React, { useState, useEffect } from "react";
import { setPollingStatus } from "../reducers/documentAssetReducer";
import { deleteAssets } from "src/reducers/projectReducer";
import { useAppDispatch } from "src/configureStore";
import { SearchIcon, DeleteIcon } from "src/lib/icons/index";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { processRefText, updateReferenceText } from "src/reducers/documentTypeReducer";
import { styled } from "@mui/material/styles";

interface ReferenceTextComponentProps {
  projectId: string | undefined;
  documentTypeId: string | undefined;
  projectRefText: any[];
  setProjectRefText: (projectRefText: any) => void;
  setInitialRefText?: (initialRefText: any) => void;
}

type ReferenceTextWithAttributes = {
  referenceText: string;
  referenceType: string;
  isGlobal: boolean;
  _id: string;
};

const StyledTableCell = styled(TableCell)({
  paddingTop: "4px !important",
  paddingBottom: "4px !important"
});

const ReferenceTextComponent: React.FC<ReferenceTextComponentProps> = ({
  projectId,
  documentTypeId,
  projectRefText,
  setProjectRefText,
  setInitialRefText
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [filteredRefText, setFilteredRefText] = useState<ReferenceTextWithAttributes[]>([]);
  const [newRefTextReferenceType, setNewRefTextReferenceType] = useState("");
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();
  // const [invalidRefText, setInvalidRefText] = useState(false);
  const [refTextToDelete, setRefTextToDelete] = useState<ReferenceTextWithAttributes | null>(null);
  const [showDeleteSingleRefTextDialog, setShowDeleteSingleRefTextDialog] = useState(false);
  // const [selectedRefTextType, setSelectedRefTextType] = useState<string | null>(null);
  const [refTextIdToUpdate, setRefTextIdToUpdate] = useState<string | null>(null);
  const [isRefTextUpdate, setIsRefTextUpdate] = useState(false);
  // const [refTextToUpdate, setRefTextToUpdate] = useState<ReferenceTextWithAttributes | null>(null);
  const [isUpdateTriggered, setIsUpdateTriggered] = useState(false);
  const atLeastOneRefTextSelected = (): boolean => {
    return projectRefText.some((referenceText) => referenceText.selected);
  };

  useEffect(() => {
    if (projectRefText !== undefined) {
      setFilteredRefText(
        projectRefText.filter(
          (referenceText) =>
            referenceText.referenceText &&
            referenceText.referenceText.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  }, [projectRefText, searchInput]);

  useEffect(() => {
    if (isUpdateTriggered) {
      handleUpdateButtonClick();
      setIsUpdateTriggered(false); // Reset the trigger
    }
  }, [isUpdateTriggered]);

  const handleSearchInputChange = (e: any, type: string) => {
    if (type === "referenceText") {
      setSearchInput(e.target.value);
    }
  };

  const handleToggleRefText = (refText: any) => {
    setProjectRefText(
      _.cloneDeep(projectRefText).map((sRefText: any, i: number) => {
        if (sRefText._id === refText._id) {
          sRefText.selected = !sRefText.selected;
        }
        return sRefText;
      })
    );
  };

  const handleAddProjectRefText = async (addRefText: ReferenceTextWithAttributes) => {
    if (projectId !== undefined) {
      dispatch(setPollingStatus("running"));
      const pURL = await dispatch(
        processRefText(
          projectId,
          addRefText.referenceText,
          documentTypeId,
          addRefText.referenceType,
          addRefText.isGlobal
        )
      );
      const newPURL = { ...pURL, selected: true, referenceType: newRefTextReferenceType };

      setProjectRefText((prevProjectRefText: any) => [...prevProjectRefText, newPURL]);
      if (setInitialRefText) {
        setInitialRefText((prevInitialRefText: any) => [...prevInitialRefText, newPURL]);
      }
    }
  };

  // const handleKeyPress = (e: React.KeyboardEvent) => {
  //   if (e.key === "Enter") {
  //     // handleAddButtonClick();
  //   }
  // };
  function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(event.target.value);
  }

  const handleAttributeChange = (refText: ReferenceTextWithAttributes, referenceType: string) => {
    const index = projectRefText.findIndex((u) => u === refText);
    const updatedProjectRefText = projectRefText.map((currentRefText, i) =>
      i === index ? { ...currentRefText, referenceType } : currentRefText
    );
    setProjectRefText(updatedProjectRefText);
  };

  async function handleAddButtonClick() {
    if (inputValue) {
      handleAddProjectRefText({
        _id: "",
        referenceText: inputValue,
        referenceType: newRefTextReferenceType,
        isGlobal
      });
      setInputValue("");
      setModalIsOpen(false);
      setNewRefTextReferenceType("");
    }
    // else {
    // setInvalidRefText(true);
    // }
  }

  const handleUpdateButtonClick = async () => {
    if (projectId !== undefined && refTextIdToUpdate !== null) {
      dispatch(setPollingStatus("running"));
      const pRefText = await dispatch(
        updateReferenceText(refTextIdToUpdate, inputValue, newRefTextReferenceType)
      );
      const newPRefText = { ...pRefText, selected: true, referenceType: newRefTextReferenceType };

      setProjectRefText((prevProjectRefText: any) => {
        return prevProjectRefText.map((item: any) => {
          if (item._id === refTextIdToUpdate) {
            return newPRefText;
          } else {
            return item;
          }
        });
      });

      if (setInitialRefText) {
        setInitialRefText((prevInitialRefText: any) => {
          return prevInitialRefText.map((item: any) => {
            if (item._id === refTextIdToUpdate) {
              return newPRefText;
            } else {
              return item;
            }
          });
        });
      }
    } else {
      console.log("Error: projectId or refTextIdToUpdate is undefined");
    }
    closeModalWithContent();
  };

  const handleRemoveSelectedRefText = () => {
    const removedRefText = projectRefText.filter(
      (referenceText: any) => referenceText.selected === true
    );
    setProjectRefText((prevProjectRefText: any) =>
      prevProjectRefText.filter((referenceText: any, i: any) => referenceText.selected === false)
    );
    if (projectId) dispatch(deleteAssets(projectId, removedRefText));
    setShowDeleteDialog(false);
  };

  //Deletes single refText from the trashcan icon in the table
  const handleDeleteSingleRefText = (RefTextToRemove: any) => {
    setShowDeleteSingleRefTextDialog(true);
    setRefTextToDelete(RefTextToRemove);
  };

  //Confirm single refText delete from the trashcan icon in the table
  const handleDeleteConfirmed = () => {
    setProjectRefText((prevProjectRefText: any) =>
      prevProjectRefText.filter((referenceText: any) => referenceText !== refTextToDelete)
    );
    if (projectId && refTextToDelete) {
      dispatch(deleteAssets(projectId, [refTextToDelete._id]));
    }
    setShowDeleteSingleRefTextDialog(false);
    setRefTextToDelete(null);
  };

  //Cancel single refText delete from the trashcan icon in the table
  const handleDeleteCancelled = () => {
    setShowDeleteSingleRefTextDialog(false);
    setRefTextToDelete(null);
  };

  const openModalWithContent = (refId: string, refText: string, refType: string) => {
    setRefTextIdToUpdate(refId);
    // setSelectedRefTextType(refType);
    setInputValue(refText);
    setNewRefTextReferenceType(refType);
    setIsRefTextUpdate(true);
    setModalIsOpen(true);
  };

  const closeModalWithContent = () => {
    setRefTextIdToUpdate("");
    // setSelectedRefTextType("");
    setInputValue("");
    setIsRefTextUpdate(false);
    setModalIsOpen(false);
  };

  return (
    <div
      className="urlComponent mb-2"
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        // border: "1px solid #B3B3B3",
        // borderRadius: "4px",
        borderBottom: "1px solid #B3B3B3",
        borderTop: "1px solid #B3B3B3",
        overflowY: "hidden"
      }}>
      <div
        className=""
        style={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "hidden"
        }}>
        {modalIsOpen ? (
          <div style={{ flex: "0 1 auto", display: "flex", flexDirection: "column" }}>
            <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
            <div
              className="bg-white p-6 rounded shadow-md"
              style={{
                padding: "20px",
                display: "flex",
                flex: "1 1 auto",
                zIndex: 102,
                flexDirection: "column",
                backgroundColor: "white",
                overflowY: "hidden"
              }}>
              {isRefTextUpdate ? (
                <>
                  <div className="font-semibold mb-2">
                    Update reference text to use in the AI writing model
                  </div>
                  <label
                    htmlFor="website-address"
                    className="block mt-4"
                    style={{ color: "rgb(78, 78, 78)" }}>
                    Reference Text
                  </label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <textarea
                      style={{ height: "200px" }}
                      id="reference-text"
                      value={inputValue || ""}
                      onChange={handleInputChange}
                      // onKeyPress={handleKeyPress}
                      className="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <select
                      disabled={!inputValue}
                      value={newRefTextReferenceType || ""}
                      onChange={(e) => {
                        setNewRefTextReferenceType(e.target.value);
                      }}
                      style={{ flex: "0 1 auto", maxHeight: "32px" }}
                      className={`ml-2 appearance-none block px-2 py-0.5 ${
                        !inputValue
                          ? "text-white rounded bg-gray-400 cursor-not-allowed"
                          : `border ${"border-gray-300"} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`
                      }`}>
                      <option value="" disabled hidden>
                        Select a Reference Type
                      </option>
                      <option value="exampleGrantProposals">Example Grant Proposals</option>
                      <option value="funderInfo">Funder Info</option>
                      <option value="grantGuidelines">Grant Guidelines</option>
                      <option value="organizationInfo">Organization Info</option>
                      <option value="storyElement">Story Element</option>
                    </select>
                  </div>

                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      disabled={!inputValue}
                      className={`mr-2 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                        inputValue
                          ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                          : "text-white font-semibold rounded bg-gray-400 cursor-not-allowed"
                      }`}
                      onClick={() => {
                        setIsUpdateTriggered(true);
                      }}>
                      Update
                    </button>
                    <button
                      className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={closeModalWithContent}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="font-semibold mb-2">
                    Add reference text to use in the AI writing model
                  </div>
                  <label
                    htmlFor="website-address"
                    className="block mt-4"
                    style={{ color: "rgb(78, 78, 78)" }}>
                    Reference Text
                  </label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <textarea
                      style={{ height: "200px" }}
                      id="reference-text"
                      value={inputValue}
                      onChange={handleInputChange}
                      // onKeyPress={handleKeyPress}
                      className="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <select
                      disabled={!inputValue}
                      value={newRefTextReferenceType || ""}
                      onChange={(e) => {
                        setNewRefTextReferenceType(e.target.value);
                      }}
                      style={{ flex: "0 1 auto", maxHeight: "32px" }}
                      className={`ml-2 appearance-none block px-1 py-0.5 ${
                        !inputValue
                          ? "text-white rounded bg-gray-400 cursor-not-allowed"
                          : `border ${
                              newRefTextReferenceType ? "border-gray-300" : "border-red-500"
                            } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`
                      }`}>
                      <option value="" disabled hidden>
                        Select a Reference Type
                      </option>
                      <option value="exampleGrantProposals">Example Grant Proposals</option>
                      <option value="funderInfo">Funder Info</option>
                      <option value="grantGuidelines">Grant Guidelines</option>
                      <option value="organizationInfo">Organization Info</option>
                      <option value="storyElement">Story Element</option>
                    </select>
                  </div>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={isGlobal}
                        onChange={(e) => setIsGlobal(e.target.checked)}
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <span className="ml-2">Make this reference text global</span>
                    </label>
                  </div>
                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      disabled={!inputValue || !newRefTextReferenceType}
                      className={`mr-2 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                        inputValue && newRefTextReferenceType
                          ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                          : "text-white font-semibold rounded bg-gray-400 cursor-not-allowed"
                      }`}
                      onClick={handleAddButtonClick}>
                      Add
                    </button>
                    <button
                      className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        setModalIsOpen(false);
                        setInputValue("");
                        setNewRefTextReferenceType("");
                      }}>
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflowY: "hidden"
            }}>
            <div className="font-semibold">Reference Text</div>
            <div className="mb-2" style={{ color: "#323232", fontSize: "small" }}>
              To add new reference text, click the &quot;Add Text&quot; button, paste the text, and
              pick the reference type for the text in the provided dropdown.
            </div>
            <div className="flex justify-between items-center mb-2">
              <span style={{ flex: "0 0 auto" }}>
                <SearchIcon
                  className="mr-2"
                  height={"15px"}
                  width={"15px"}
                  color={"rgba(88, 89, 91, 1)"}
                />
              </span>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => {
                  handleSearchInputChange(e, "referenceText");
                }}
                className="w-full appearance-none block w-full placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                style={{
                  borderBottom: "1px solid rgba(88, 89, 91, 1)",
                  width: "250px",
                  marginRight: "auto"
                }}
                placeholder="Search..."
              />
            </div>
            <div
              style={{
                width: "100%",
                flex: "1 1 auto",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
              className="p-1 overflow-y-auto mb-2 border border-gray-300 divide-y divide-gray-300 rounded-md">
              <Table stickyHeader>
                <TableHead style={{ height: "10px" }}>
                  <TableRow>
                    <TableCell className="table-cell-select-col table-header-cell">
                      Select
                    </TableCell>
                    <TableCell size="small" className="table-cell-first-col table-header-cell">
                      Reference Text
                    </TableCell>
                    <TableCell
                      size="small"
                      className="table-cell-last-col table-header-cell"
                      style={{ width: "25%" }}>
                      Reference Type
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <TableContainer>
                <Table>
                  <TableBody>
                    {[...filteredRefText]
                      .sort((a, b) =>
                        a.referenceText.toLowerCase().localeCompare(b.referenceText.toLowerCase())
                      )
                      .map((refText: any, index) => (
                        <TableRow key={index}>
                          <StyledTableCell size="small" className="table-cell-select-col">
                            <input
                              type="checkbox"
                              checked={refText.selected}
                              onChange={() => handleToggleRefText(refText)}
                              className="m-2 custom-checkbox"
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className="table-cell-first-col table-cell-long-txt"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openModalWithContent(
                                refText._id,
                                refText.referenceText,
                                refText.referenceType
                              )
                            }>
                            {refText.referenceText}
                            {refText.isGlobal && (
                              <span
                                className="ml-2 p-1 text-xs font-semibold text-white rounded-md"
                                style={{ display: "inline-block", backgroundColor: "#3fa1d7" }}>
                                Global
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className="table-cell-last-col"
                            style={{ width: "25%" }}>
                            <select
                              value={refText.referenceType || ""}
                              onChange={(e) => {
                                handleAttributeChange(refText, e.target.value);
                              }}
                              className={`appearance-none block w-full px-2 py-0.5 border ${
                                refText.referenceType ? "border-gray-300" : "border-red-500"
                              } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
                              <option value="" disabled hidden>
                                Select a Reference Type
                              </option>
                              <option value="exampleGrantProposals">Example Grant Proposals</option>
                              <option value="funderInfo">Funder Info</option>
                              <option value="grantGuidelines">Grant Guidelines</option>
                              <option value="organizationInfo">Organization Info</option>
                              <option value="storyElement">Story Element</option>
                            </select>
                          </StyledTableCell>
                          <StyledTableCell size="small" className="table-cell">
                            <button onClick={() => handleDeleteSingleRefText(refText)}>
                              <DeleteIcon
                                className="mr-2"
                                height={"15px"}
                                width={"15px"}
                                color={"rgba(88, 89, 91, 1)"}
                              />
                            </button>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="flex items-center">
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded mr-4"
                onClick={() => setModalIsOpen(true)}>
                Add Text
              </button>
              <button
                disabled={!atLeastOneRefTextSelected()}
                className={`ml-4 py-0.5 px-2 rounded ml-2 focus:outline-none focus:shadow-outline font-semibold ${
                  atLeastOneRefTextSelected()
                    ? "bg-gray-500 hover:bg-gray-700 text-white"
                    : "text-white font-semibold py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed"
                }`}
                onClick={() => setShowDeleteDialog(true)}>
                Remove Text
              </button>
            </div>
            {showDeleteDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>Are you sure you want to remove the selected reference text?</p>
                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleRemoveSelectedRefText}>
                      Remove
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={() => setShowDeleteDialog(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showDeleteSingleRefTextDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>Are you sure you want to delete this Reference Text?</p>

                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleDeleteConfirmed}>
                      Delete
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={handleDeleteCancelled}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceTextComponent;
