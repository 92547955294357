// import cloneDeep from "clone-deep";
import { Dispatch } from "redux";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import axios from "axios";
import { AppDispatch } from "src/configureStore";
import axiosInstance from "src/lib/axiosConfig";
import { setPollingStatus } from "../reducers/documentAssetReducer";

interface ProjectState {
  projects: any[];
  selectedProject: any;
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  fileAssets: any[];
  urlAssets: any[];
  refTextAssets: any[];
  updatedChildren: boolean;
}
const initialState: ProjectState = {
  projects: [],
  selectedProject: undefined,
  isLoading: false,
  isLoaded: false,
  error: null,
  fileAssets: [],
  urlAssets: [],
  refTextAssets: [],
  updatedChildren: false
};
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    getProjectsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getProjectsSuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.projects = action.payload;
      state.updatedChildren = !state.updatedChildren;
    },
    getProjectsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    setSelectedProjectById: (state, action: PayloadAction<string>) => {
      const projectId = action.payload;

      const project = state.projects.find((project) => project._id === projectId);
      state.selectedProject = project;
    },

    renameItemSuccess: (
      state,
      action: PayloadAction<{
        id: string;
        newName: string;
        objectType: string;
      }>
    ) => {
      const { id, newName, objectType } = action.payload;

      const recursiveRename = (items: any[], id: string, newName: string): boolean => {
        const itemIndex = items.findIndex((item) => item._id === id);
        if (itemIndex !== -1) {
          items[itemIndex].name = newName;
          return true;
        } else {
          for (const item of items) {
            if (item.children && recursiveRename(item.children, id, newName)) {
              return true;
            }
          }
        }
        return false;
      };

      if (objectType === "projects") {
        recursiveRename(state.projects, id, newName);
      } else if (objectType === "documentTypes" || objectType === "documentAssets") {
        state.projects.forEach((project) => {
          recursiveRename(project.children, id, newName);
        });
      }
      state.updatedChildren = !state.updatedChildren;
    },

    deleteItemSuccess: (state, action: PayloadAction<{ id: string; objectType: string }>) => {
      const { id, objectType } = action.payload;

      const recursiveDelete = (children: any[], id: string): boolean => {
        const index = children.findIndex((child) => child._id === id);
        if (index !== -1) {
          children.splice(index, 1);
          return true;
        } else {
          for (const child of children) {
            if (child.children && recursiveDelete(child.children, id)) {
              return true;
            }
          }
        }
        return false;
      };

      if (objectType === "projects") {
        state.projects = state.projects.filter((project) => project._id !== id);
      } else if (objectType === "documentTypes" || objectType === "documentAssets") {
        state.projects.forEach((project) => {
          recursiveDelete(project.children, id);
        });
      }
      state.updatedChildren = !state.updatedChildren;
    },
    setAssetsSuccess: (
      state,
      action: PayloadAction<{ urlAssets: any[]; fileAssets: any[]; refTextAssets: any[] }>
    ) => {
      const { urlAssets, fileAssets, refTextAssets } = action.payload;
      state.urlAssets = urlAssets;
      state.fileAssets = fileAssets;
      state.refTextAssets = refTextAssets;
    },
    updateAssetsState: (state, action: PayloadAction<any>) => {
      //go through the action.payload and update the corresponding asset in one of urlAssets, fileAssets, or refTextAssets
      const tUrlAssets = state.urlAssets;
      const tFileAssets = state.fileAssets;
      const tRefTextAssets = state.refTextAssets;
      action.payload.forEach((asset: any) => {
        if (asset.type === "URL") {
          const index = tUrlAssets.findIndex((a) => a._id === asset._id);
          if (index !== -1) {
            tUrlAssets[index].status = asset.status;
            if (asset.selected !== undefined) {
              tUrlAssets[index].selected = asset.selected;
            }
          } else {
            tUrlAssets.push(asset);
          }
        } else if (asset.type === "File") {
          const index = tFileAssets.findIndex((a) => a._id === asset._id);
          if (index !== -1) {
            tFileAssets[index].status = asset.status;
            if (asset.selected !== undefined) {
              tFileAssets[index].selected = asset.selected;
            }
          } else {
            tFileAssets.push(asset);
          }
        } else if (asset.type === "referenceText") {
          const index = tRefTextAssets.findIndex((a) => a._id === asset._id);
          if (index !== -1) {
            tRefTextAssets[index].status = asset.status;
            if (asset.selected !== undefined) {
              tRefTextAssets[index].selected = asset.selected;
            }
          } else {
            tRefTextAssets.push(asset);
          }
        }
      });

      //since pass by reference we dont need to assign these
      // state.urlAssets = tUrlAssets;
      // state.fileAssets = tFileAssets;
      // state.refTextAssets = tRefTextAssets;
    },
    doUpdateProjectIsPrivate: (
      state,
      action: PayloadAction<{ id: string; isPrivate: boolean }>
    ) => {
      const { id, isPrivate } = action.payload;
      const project = state.projects.find((project) => project._id === id);
      if (project) {
        project.isPrivate = isPrivate;
      }
      if (state.selectedProject && state.selectedProject._id === id) {
        state.selectedProject.isPrivate = isPrivate;
      }
    }
  }
});
export const {
  getProjectsRequest,
  getProjectsSuccess,
  getProjectsFailure,
  renameItemSuccess,
  deleteItemSuccess,
  setSelectedProjectById,
  setAssetsSuccess,
  updateAssetsState,
  doUpdateProjectIsPrivate
} = projectsSlice.actions;

export const fetchProjects = () => async (dispatch: Dispatch) => {
  dispatch(getProjectsRequest());
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/projects/projects";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getProjectsSuccess(response.data));
  } catch (error: any) {
    dispatch(getProjectsFailure(error.message));
  }
};
// export const fetchDocumentAsset = () => async (dispatch: Dispatch) => {
//   dispatch(getProjectsRequest());
//   try {
//     const url = process.env["REACT_APP_API_SERVER"] + "/api/projects/projects";

//     const response = await axiosInstance.get(url, {
//       headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
//     });

//     dispatch(getProjectsSuccess(response.data));
//   } catch (error: any) {
//     dispatch(getProjectsFailure(error.message));
//   }
// };
export const setProjectAssets =
  (urlAssets: any[], fileAssets: any[], refTextAssets: any[]) => (dispatch: Dispatch) => {
    dispatch(
      setAssetsSuccess({
        urlAssets,
        fileAssets,
        refTextAssets
      })
    );
  };
export const getAssetsForProject =
  (projectId: string, documentAssetId = "") =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/projects/assets";
      const response = await axiosInstance.post(
        url,
        { projectId, documentAssetId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );

      await dispatch(
        setAssetsSuccess({
          urlAssets: response.data.urlAssets,
          fileAssets: response.data.fileAssets,
          refTextAssets: response.data.refTextAssets
        })
      );

      return {
        urlAssets: response.data.urlAssets,
        fileAssets: response.data.fileAssets,
        refTextAssets: response.data.refTextAssets
      };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };
export const deleteAssets =
  (projectId: string, assetIds: string[]) => async (dispatch: Dispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/projects/assets";
      const response = await axiosInstance.delete(url, {
        data: { assetIds, projectId },
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      await dispatch(
        setAssetsSuccess({
          urlAssets: response.data.urlAssets,
          fileAssets: response.data.fileAssets,
          refTextAssets: response.data.refTextAssets
        })
      );

      return { success: true };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };

export const createProject = (name: string) => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/projects/projects";
    const response = await axiosInstance.post(
      url,
      { name },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );

    let uProjects = response.data.projects;

    uProjects = uProjects.map((item: any) => {
      console.log(item);
      if (item.name === "New Folder") {
        item.isNew = true;
      }
      return item;
    });
    dispatch(getProjectsSuccess(uProjects));
    return { success: true, project: uProjects };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
function capitalizeFirstLetterAndRemoveLast(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1, -1);
}
export const renameItem =
  (id: string, newName: string, objectType: string) => async (dispatch: AppDispatch) => {
    try {
      const url =
        process.env["REACT_APP_API_SERVER"] +
        `/api/${objectType}/rename${capitalizeFirstLetterAndRemoveLast(objectType)}`;
      const response = await axiosInstance.post(
        url,
        {
          id,
          newName
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` } }
      );

      if (response.status === 200) {
        await dispatch(renameItemSuccess({ id, newName, objectType }));
      } else {
        console.error("Failed to rename item");
      }
    } catch (error) {
      console.error("Error renaming item:", error);
    }
  };
export const deleteItem = (id: string, objectType: string) => async (dispatch: AppDispatch) => {
  try {
    const url =
      process.env["REACT_APP_API_SERVER"] +
      `/api/${objectType}/delete${capitalizeFirstLetterAndRemoveLast(objectType)}/${id}`;
    await axiosInstance.delete(url, {
      data: { id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    // const objectType = level === 0 ? "projects" : level === 1 ? "documentTypes" : "documentAssets";
    // Dispatch the deleteItemSuccess action

    dispatch(deleteItemSuccess({ id, objectType }));
  } catch (error) {
    console.error("Error deleting item:", error);
  }
};
export const updateProjectIsPrivate =
  (id: string, isPrivate: boolean) => async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + `/api/projects/setPrivacy`;
      const response = await axiosInstance.post(
        url,
        {
          projectId: id,
          isPrivate
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` } }
      );
      console.log(response);

      dispatch(doUpdateProjectIsPrivate({ id, isPrivate }));
    } catch (error) {
      console.error("Error updating project privacy:", error);
    }
  };

export default projectsSlice.reducer;
