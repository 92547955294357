import { useEffect } from "react";

// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";

const AuthSuccess = () => {
  useEffect(() => {
    if (window) {
      //window.opener.postMessage("auth-success", process.env["REACT_APP_API_SERVER"]);

      const searchParams = new URLSearchParams(window.location.search);
      const authorizationCode = searchParams.get("code");
      const state = searchParams.get("state");
      const error = searchParams.get("error");
      console.log("AUTH CODE", authorizationCode);

      if (authorizationCode) {
        const url = process.env["REACT_APP_API_SERVER"] + "/api/blackbaud/get-token";

        axiosInstance
          .post(
            url,
            {
              grant_type: "authorization_code",
              client_id: process.env["REACT_APP_BLACKBAUD_CLIENT_ID"],
              redirect_uri: encodeURIComponent(`${process.env["REACT_APP_SERVER"]}/auth-success`),
              code: authorizationCode,
              code_verifier: state
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
              }
            }
          )
          .then(async (result) => {
            console.log(result);
            await localStorage.setItem("blackbaudAuthToken", result.data.access_token);
            window.postMessage("auth-success", "*");
            window.close();
          });
      }
    } else {
      console.error("window.opener is null");
    }
  }, []);

  return (
    <div>
      <h1>Login Successful</h1>
    </div>
  );
};

export default AuthSuccess;
