import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
import { Dispatch } from "redux";
import { ToastContainer, toast } from "react-toastify";

import ModelEditor from "../../components/admin/modelEditor";
import ModelInputEditor from "../../components/admin/modelInputEditor";
import {
  getAdminDataModels,
  updateWritingModel,
  updateWritingModelInputs,
  deleteWritingModelInput
} from "src/reducers/admin/adminWritingModelReducer";
import { useAppDispatch } from "src/configureStore";
import { useSelector } from "react-redux";
import { RootState } from "src/configureStore";
import { IWritingModel } from "src/interfaces/IWriting";

const ModelEditorPage: React.FC = () => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const writingModels = useSelector((state: RootState) => state.adminWritingModelReducer.models);
  const modelInputs = useSelector((state: RootState) => state.adminWritingModelReducer.modelInputs);
  const status = useSelector((state: RootState) => state.adminWritingModelReducer.status);
  const error = useSelector((state: RootState) => state.adminWritingModelReducer.error);
  const [selectedModel, setSelectedModel] = useState<IWritingModel | null>(null);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [editingInputs, setEditingInputs] = useState<boolean>(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAdminDataModels());
    }
  }, [status, dispatch]);
  const handleSave = async (updatedModel: IWritingModel) => {
    //setModel(updatedModel);
    console.log("Model saved:", updatedModel);

    if (updatedModel._id) {
      await dispatch(
        updateWritingModel(
          updatedModel._id,
          updatedModel.prompt,
          updatedModel.temperature,
          updatedModel.frequencyPenalty,
          updatedModel.presencePenalty,
          updatedModel.maxTokens,
          updatedModel.description,
          updatedModel.modelType
        )
      );
      const updatedOption = {
        value: updatedModel.modelName,
        label: updatedModel.modelName
      };
      setSelectedOption(updatedOption);
      setSelectedModel(updatedModel);
    }
    // Save the updated model data to your desired storage
  };
  const handleSaveInputs = async (updatedInputs: any) => {
    //setModel(updatedModel);
    console.log("Inputs saved:", updatedInputs);
    if (updatedInputs && updatedInputs.length > 0) {
      await dispatch(updateWritingModelInputs(updatedInputs));
      toast.success("Inputs Saved!");
      const newModelInputs = _.cloneDeep(selectedModel);
      if (newModelInputs) {
        newModelInputs.modelInputs = updatedInputs;
        setSelectedModel(newModelInputs);
      }

      // const newInputs = (selectedModel["modelInputs"] = updatedInputs);
    }
  };
  const handleDeleteInput = async (inputId: string) => {
    await dispatch(deleteWritingModelInput(inputId));
  };

  const handleCancel = () => {
    console.log("Cancel edit");
    // Navigate back or perform other actions on cancel
  };
  const modelOptions = writingModels.map((model: IWritingModel) => ({
    value: model.modelName,
    label: model.modelName
  }));

  return (
    <div className="flex flex-col h-full w-full p-4">
      {status === "loading" && <p>Loading...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div className="flex-grow flex flex-col">
          <ToastContainer autoClose={10000} />
          <h1>Writing Models</h1>
          <Select
            options={modelOptions}
            value={selectedOption}
            isSearchable={true}
            onChange={(selectedOption: any) => {
              const selected = writingModels.find(
                (model) => model.modelName === selectedOption.value
              );
              setSelectedModel(selected ? selected : null);
              setSelectedOption(selectedOption);
            }}
          />
          {selectedModel && (
            <>
              <div className="mt-6">
                {!editingInputs ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0.5 px-2 rounded"
                    onClick={async () => {
                      // await dispatch(getInputsForModel(selectedModel._id));
                      setEditingInputs(true);
                    }}>
                    Edit Inputs
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0.5 px-2 rounded"
                    onClick={async () => {
                      // await dispatch(getInputsForModel(selectedModel._id));
                      setEditingInputs(false);
                    }}>
                    Back
                  </button>
                )}
              </div>
              {!editingInputs ? (
                <ModelEditor model={selectedModel} onSave={handleSave} onCancel={handleCancel} />
              ) : (
                <ModelInputEditor
                  inputs={selectedModel["modelInputs"]}
                  modelId={selectedModel._id}
                  onSave={handleSaveInputs}
                  onCancel={handleCancel}
                  onDelete={handleDeleteInput}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ModelEditorPage;
