/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState, useAppDispatch } from "src/configureStore";
import Modal from "react-modal";
import moment from "moment";
import { updateUser, updateClient, updateClients } from "src/reducers/authReducer";
import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from 'react-router-dom';
import "./userProfile.scss";
import isEmail from "validator/lib/isEmail";
import StripePricingTable from "src/components/stripePricingTable";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  DeleteIcon,
  EnvelopeIcon,
  CreditsIcon,
  CalendarIcon,
  BonusIcon
} from "src/lib/icons/index";
import {
  fetchClientData,
  updateClientNameAction,
  removeUserFromClientAction,
  getClientData
} from "src/reducers/clientReducer";
import { HtmlTooltip } from "src/components/tooltip";
import { TeamUser } from "src/interfaces/IUser";

Modal.setAppElement("#root");

export interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  jobRole: string;
  industry: string;
  _id: string;
}

interface EmailAccount {
  email: string;
  accountAdmin: boolean;
}

const UserProfile: React.FC = () => {
  const authToken: string = localStorage.getItem("authToken") as string;
  const location = useLocation();
  const user = useSelector((state: RootState) => state.auth.user);
  const clientData = useSelector((state: RootState) => state.clientReducer.clientData);
  const [pricingModelBlackbaudModalIsOpen, setPricingModelBlackbaudModalIsOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [password, setPassword] = useState("");
  const [deleteModalIsOpen, setDeleteIsOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useAppDispatch();
  const [isAttributesSaving, setIsAttributesSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [pricingModalIsOpen, setPricingModalIsOpen] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState("none");
  const [accountStatus, setAccountStatus] = useState<string>("");
  const [currentTab, setCurrentTab] = useState(0);
  const [newClientName, setNewClientName] = useState("");
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [tempUserData, setTempUserData] = useState<{ clientId?: string; userId?: string }>({});
  // const [emails, setEmails] = useState(["", "", ""]);
  const initialEmailAccountsState = [
    { email: "", accountAdmin: false },
    { email: "", accountAdmin: false },
    { email: "", accountAdmin: false }
  ];
  const [emailAccounts, setEmailAccounts] = useState<EmailAccount[]>(initialEmailAccountsState);
  const [isUserTeamMember, setIsUserTeamMember] = useState(false);
  const [credits, setCredits] = useState(0);
  const [spentCredits, setSpentCredits] = useState(0);
  const [bonusCredits, setBonusCredits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [renewalDate, setRenewalDate] = useState(new Date());

  const percentageUsed = (spentCredits / credits) * 100;
  const wordsConversionFactor = 1.34;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [userData, setUserData] = useState<UserData>({
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    jobRole: user?.jobRole || "",
    industry: user?.industry || "",
    _id: user?._id || ""
  });
  const handleEmailChange = (index: number, newEmail: string, newAccountAdmin?: boolean) => {
    const updatedEmailAccounts = emailAccounts.map((emailAccount, i) =>
      i === index
        ? {
            ...emailAccount,
            email: newEmail,
            accountAdmin: newAccountAdmin ?? emailAccount.accountAdmin
          }
        : emailAccount
    );

    setEmailAccounts(updatedEmailAccounts);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentTab(newValue);
  };

  type SubscriptionType =
    | "enterprise"
    | "enterprise-annual"
    | "blackbaud-gold"
    | "blackbaud-gold-annual"
    | "blackbaud-diamond"
    | "blackbaud-diamond-annual"
    | "pilot";

  const isEnterpriseSubscriptionType = (type?: string): type is SubscriptionType => {
    return type
      ? [
          "enterprise",
          "enterprise-annual",
          "blackbaud-gold",
          "blackbaud-gold-annual",
          "blackbaud-diamond",
          "blackbaud-diamond-annual",
          "pilot"
        ].includes(type)
      : false;
  };

  const isAccountAdmin = (currentUser?: string): boolean => {
    // Check if the currentUser's _id exists in the clientData's users array with userType 'admin'
    // if (clientData && currentUser) {
    //   return clientData.users.some(
    //     (user: TeamUser) => user.id === currentUser && user.userType === "admin"
    //   );
    // } else {
    //   return false;
    // }
    if (clientData && clientData.users) {
      // Return true if the users array's length is 0 or 1
      if (clientData.users.length <= 1) {
        return true;
      }

      // Check if the currentUser's _id exists in the clientData's users array with userType 'admin'
      if (currentUser) {
        return clientData.users.some(
          (user: TeamUser) => user.id === currentUser && user.userType === "admin"
        );
      }
    }

    return false;
  };

  const isTeamUser = (currentUser?: string): boolean => {
    // Check if the currentUser's _id exists in the clientData's users array with userType 'user'
    if (clientData && currentUser) {
      return clientData.users.some(
        (user: TeamUser) => user.id === currentUser && user.userType === "user"
      );
    } else {
      return false;
    }
  };

  const handleRemoveUser = (clientId?: string, userId?: string) => {
    // Store the clientId and userId temporarily and open the modal
    if (clientId && userId) {
      setTempUserData({ clientId, userId });
      setIsRemoveUserModalOpen(true);
    } else {
      alert("Client ID or User ID is not defined, please try again.");
    }
  };

  const confirmRemoveUser = async () => {
    if (tempUserData.clientId && tempUserData.userId) {
      const result = await dispatch(
        removeUserFromClientAction(tempUserData.clientId, tempUserData.userId)
      );

      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } else {
      alert("Client ID or User ID is not defined, please try again.");
    }
    setIsRemoveUserModalOpen(false); // Close the modal
    setTempUserData({}); // Discard the temporary data
  };

  const cancelRemoveUser = () => {
    setIsRemoveUserModalOpen(false); // Close the modal
    setTempUserData({}); // Discard the temporary data
  };

  const formatDateToLocal = (dateString: Date) => {
    return moment(dateString).format("MM/DD/YYYY");
    // const date = new Date(dateString);
    // return new Intl.DateTimeFormat(navigator.language, {
    //   year: "2-digit",
    //   month: "2-digit",
    //   day: "2-digit"
    // }).format(date);
  };

  function capitalizeFirstLetter(string?: string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  type AccountStatusProps = {
    accountStatus: "failed_payment" | "paused" | "canceled" | string;
  };

  const renderAccountStatus = (props: AccountStatusProps): JSX.Element | null => {
    const { accountStatus } = props;

    const statusStyle: React.CSSProperties = {
      textTransform: "capitalize",
      fontWeight: 600,
      color: "#575757",
      marginTop: "10px",
      marginLeft: "8px"
    };

    if (accountStatus === "failed_payment") {
      return (
        <>
          <div style={{ ...statusStyle, color: "#dc2626" }}>Payment Failed</div>
          <div
            className="text-sm mt-2 text-blue-500 hover:text-blue-800"
            style={{ cursor: "pointer" }}
            onClick={modifySubscription}>
            Update Payment Information
          </div>
        </>
      );
    } else if (accountStatus === "paused" || accountStatus === "canceled") {
      return (
        <>
          <div style={statusStyle}>
            {accountStatus.charAt(0).toUpperCase() + accountStatus.slice(1)}
          </div>
          <div
            className="text-sm mt-2 text-blue-500 hover:text-blue-800"
            style={{ cursor: "pointer" }}
            onClick={modifySubscription}>
            Activate Subscription
          </div>
        </>
      );
    } else if (accountStatus) {
      return <div style={statusStyle}>{accountStatus}</div>;
    }

    return null;
  };

  useEffect(() => {
    setUserData((prevUserData) => {
      const newUserData = { ...prevUserData };
      if (user?.email) {
        newUserData.email = user.email;
      }
      if (user?.firstName) {
        newUserData.firstName = user.firstName;
      }
      if (user?.lastName) {
        newUserData.lastName = user.lastName;
      }
      if (user?.jobRole) {
        newUserData.jobRole = user.jobRole;
      }
      if (user?.industry) {
        newUserData.industry = user.industry;
      }
      if (user?._id) {
        newUserData._id = user._id;
      }
      return newUserData;
    });
  }, [user?.firstName, user?.lastName, user?.jobRole, user?.industry, user?.email, user]);

  useEffect(() => {
    async function runUpdate() {
      const searchParams = new URLSearchParams(location.search);
      const checkoutSession = searchParams.get("checkout_session");
      console.log("CHECKOUT SESSION");
      console.log(checkoutSession);
      if (checkoutSession && checkoutSession !== null && userData.email) {
        // console.log("USER EMAIL");
        // console.log(userData.email);
        // window.fpr("referral", { email: userData.email });
        const url = process.env["REACT_APP_API_SERVER"] + "/api/updateSubscription";

        const response = await axiosInstance.post(
          url,
          { checkoutId: checkoutSession },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
          }
        );
        if (response.status === 200 && response.data.success) {
          setHasSubscription(true);
          return true;
        } else {
          setHasSubscription(false);
          return false;
        }
      }
      return false;
    }
    runUpdate();
  }, [location, userData.email]);

  useEffect(() => {
    async function runCheck() {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/checkSubscription";

      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });

      if (response.status === 200 && response.data.isSubscribed) {
        setHasSubscription(true);
        setSubscriptionLevel(response.data.level);
        setAccountStatus(response.data.accountStatus);
        return true;
      } else {
        setHasSubscription(false);
        setSubscriptionLevel(response.data.level);
        setAccountStatus(response.data.accountStatus);
        return false;
      }
    }
    runCheck();
  }, []);

  useEffect(() => {
    async function fetchAndSetClientData() {
      if (user && user.client && user.client._id) {
        try {
          dispatch(fetchClientData(user.client._id));
          // console.log("users", setPricingModalIsOpenclientData?.users);
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      }
    }

    fetchAndSetClientData();
  }, [user, dispatch]);

  useEffect(() => {
    setNewClientName(clientData?.name || "");
  }, [clientData]);

  useEffect(() => {
    const result = isTeamUser(user?._id);
    setIsUserTeamMember(result);
  }, [clientData, user]);

  useEffect(() => {
    async function runCheck() {
      const userUrl = process.env["REACT_APP_API_SERVER"] + "/api/getUserClients";
      const userResponse = await axiosInstance.get(userUrl, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      if (userResponse.status === 200) {
        const clients = userResponse.data.clients;
        dispatch(updateClients(clients));
      }
      const url = process.env["REACT_APP_API_SERVER"] + "/api/checkSubscription";

      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      });
      if (response.status === 200 && response.data.isSubscribed) {
        setHasSubscription(true);
        setSubscriptionLevel(response.data.level);
        setCredits(response.data.reocurringCredits / wordsConversionFactor);
        setSpentCredits(response.data.spentCredits / wordsConversionFactor);
        setBonusCredits(response.data.bonusCredits / wordsConversionFactor);
        setTotalCredits(response.data.credits / wordsConversionFactor);
        setRenewalDate(response.data.renewalDate);
        setAccountStatus(response.data.accountStatus);
        return true;
      } else {
        setHasSubscription(false);
        setSubscriptionLevel(response.data.level);
        setCredits(response.data.reocurringCredits / wordsConversionFactor);
        setSpentCredits(response.data.spentCredits / wordsConversionFactor);
        setBonusCredits(response.data.bonusCredits / wordsConversionFactor);
        setTotalCredits(response.data.credits / wordsConversionFactor);
        setRenewalDate(response.data.renewalDate);
        setAccountStatus(response.data.accountStatus);
        // console.log("SUSCRIPTION LEVEL: ", response.data.level);
        return false;
      }
    }

    runCheck();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setPricingModalIsOpen(false);
    setPricingModelBlackbaudModalIsOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteIsOpen(false);
  };
  const validatePassword = async () => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/login";
      const data = { email: user?.email, password };
      const response = await axios.post(url, data);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const isPasswordFormValid = () => {
    return (
      currentPassword !== "" &&
      passwordsMatch &&
      newPassword.length >= 8 &&
      confirmPassword.length >= 8
    );
  };

  const userNameLookup = (userId?: string) => {
    if (clientData && userId) {
      const user = clientData.users.find((user) => user.id === userId);
      return user?.email || "";
    } else {
      return "";
    }
  };

  function validateEmail(email: string) {
    return isEmail(email);
  }

  const handlePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = await validatePassword();
    if (isValid) {
      setEditMode(true);
      setIsOpen(false);
    } else {
      setPasswordIsValid(false);
    }
  };

  const handleUsernameChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsAttributesSaving(true);

    // Create new object with only email attribute
    const emailData = { email: userData.email.toLowerCase() };

    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/updateUserAttributes";

      const response = await axiosInstance.put(url, emailData, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      if (response.status === 200) {
        toast.success("Successfully updated email");
        setIsAttributesSaving(false);
        dispatch(updateUser(response.data));
        await localStorage.setItem("user", JSON.stringify(response.data));
        setEditMode(false);
        setPassword("");
      } else {
        throw new Error("Unsuccessful response status");
      }
    } catch (error) {
      toast.error("Error updating username, please try again later");
      setIsAttributesSaving(false);
      console.error("Error updating user: ", error);
    }
  };

  const modifySubscription = async () => {
    if (!hasSubscription) {
      setPricingModalIsOpen(true);
      return;
    }

    // console.log("Modify subscription plan");
    // navigate(process.env["STRIPE_CUSTOMER_PORTAL"] || "");
    // window.location.href = process.env["STRIPE_CUSTOMER_PORTAL"] || "";
    // getStripeBillingPortalSession
    const url = process.env["REACT_APP_API_SERVER"] + "/api/getStripeBillingPortalSession";

    const response: any = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    // console.log(response);
    if (response.status === 200 && response.data.url) {
      window.open(response.data.url);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(newPassword === e.target.value);
  };

  const handleSaveChangesClick = async () => {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/changePassword";

    const response: any = await axiosInstance
      .post(
        url,
        { newPassword, currentPassword },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      )
      .then((result) => {
        if (result.data.success) {
          toast.success("Successfully updated password");
        } else {
          toast.error("Error updating password, please try again later");
        }
      });
  };

  const handleGenerateApiKey = async () => {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/client/createIntegrationKey";

    const response: any = await axiosInstance
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      })
      .then((result) => {
        if (result.status === 200) {
          dispatch(updateClient(result.data));
          const user = localStorage.getItem("user");
          const jsonUser = user ? JSON.parse(user) : null;
          if (jsonUser) {
            jsonUser.client = result.data;
            localStorage.setItem("user", JSON.stringify(jsonUser));
          }
        }
      });
  };

  const handleSendInvites = async (resend?: boolean, emailAccountToResend?: EmailAccount) => {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/client/sendTeamInvites";

    let accountsToSend = [];
    if (resend && emailAccountToResend) {
      // Handle resending to a single email account
      if (emailAccountToResend.email !== "" && validateEmail(emailAccountToResend.email)) {
        accountsToSend.push(emailAccountToResend);
      }
    } else {
      // Handle normal invites
      accountsToSend = emailAccounts.filter(
        (emailAccount) => emailAccount.email !== "" && validateEmail(emailAccount.email)
      );
    }

    // Check if there are any valid emails to send
    if (accountsToSend.length === 0) {
      toast.error("Please enter valid email addresses before sending invites.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        url,
        { emails: accountsToSend, resend: resend },
        { headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` } }
      );

      if (response.status === 200) {
        const inviteStatuses = response.data.inviteStatuses;

        inviteStatuses.forEach((status: any) => {
          if (status.success) toast.success(`${status.email}: ${status.status}`);
          else toast.error(`${status.email}: ${status.status}`);
        });
        try {
          if (user?.client?._id) {
            dispatch(fetchClientData(user.client._id));
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        }

        // Reset emailAccounts only if not resending
        if (!resend) setEmailAccounts(initialEmailAccountsState);
      }
    } catch (error) {
      toast.error("Failed to send invites. Please try again.");
      console.error("Error sending invites:", error);
    }
  };

  const handleUserInfoChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsAttributesSaving(true);
    try {
      // Update the user attribute information in the database
      const url = process.env["REACT_APP_API_SERVER"] + "/api/updateUserAttributes";
      const response = await axiosInstance.put(url, userData, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      if (response.status === 200) {
        // Update the state with the new user information
        toast.success("Successfully updated profile");
        setIsAttributesSaving(false);
        dispatch(updateUser(response.data));
        await localStorage.setItem("user", JSON.stringify(response.data));
      } else {
        throw new Error("Unsuccessful response status");
      }
    } catch (error) {
      // Handle error here
      toast.error("Error updating profile, please try again later");
      setIsAttributesSaving(false);
      console.error("Error updating user: ", error);
    }
  };

  const handleAccountDeletion = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = await validatePassword();
    if (isValid) {
      // TODO: Delete the account.
      alert("Good password, now need to handle account deletion!");
    } else {
      setPasswordIsValid(false);
    }
  };
  const formatDate = (dateString: string) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  const saveClientName = async (clientId: string, newName: string) => {
    if (clientId) {
      dispatch(updateClientNameAction(clientId, newName));
    } else {
      console.log("Can't update client name, no client ID");
    }
  };

  const handleAdminChange = (index: number, isAdmin: boolean) => {
    const updatedEmailAccounts = emailAccounts.map((emailAccount, i) =>
      i === index ? { ...emailAccount, accountAdmin: isAdmin } : emailAccount
    );

    setEmailAccounts(updatedEmailAccounts);
  };

  const apiKey = user?.client?.integrationKey || "";
  return (
    <div
      style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}
      className="flex flex-col items-center min-h-screen">
      <ToastContainer autoClose={10000} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column"
          // overflow: "hidden"
        }}>
        <div
          style={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            flex: "0 0 auto",
            marginBottom: "10px"
          }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="Reference Content Tabs"
            TabIndicatorProps={{ style: { backgroundColor: "rgb(63, 161, 215)" } }}>
            <Tab label="User Profile" style={{ textTransform: "none" }} />
            {isAccountAdmin(user?._id) && (
              <Tab label="Account Management" style={{ textTransform: "none" }} />
            )}
          </Tabs>
        </div>
        {currentTab === 0 && (
          <div>
            {/* <div style={{ color: "#575757" }} className="text-lg mb-4 font-semibold">
              User Profile
            </div> */}
            <div className="w-full max-w-md">
              <div
                className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-4"
                style={editMode ? { backgroundColor: "rgba(63,161,215,0.25)" } : {}}>
                {editMode ? (
                  <div className="mb-4">
                    <label
                      className="text-sm block text-sm font-medium text-gray-700"
                      htmlFor="username">
                      Changing Username / Email Address
                    </label>
                    <div className="mt-2">
                      <input
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="username"
                        type="email"
                        placeholder={user?.email}
                        value={userData.email}
                        maxLength={254}
                        autoComplete="email"
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      />
                    </div>
                    <div className="flex mt-4" style={{ justifyContent: "center" }}>
                      <button
                        disabled={!validateEmail(userData.email)}
                        className={`mr-4 mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                          validateEmail(userData.email)
                            ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                            : "text-white rounded bg-gray-400 cursor-not-allowed"
                        }`}
                        onClick={handleUsernameChange}>
                        Save Changes
                      </button>
                      <button
                        className="ml-4 mt-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => {
                          setEditMode(false);
                          setPassword("");
                        }}>
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                      Username / Email Address
                    </label>
                    <div style={{ color: "#747474" }} className="mt-2">
                      {user?.email}
                    </div>
                    <div
                      className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                      style={{ cursor: "pointer" }}
                      onClick={openModal}>
                      Change username
                    </div>
                  </div>
                )}
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Password Confirmation Modal"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      zIndex: 150
                    },
                    content: {
                      width: "350px",
                      height: "200px",
                      margin: "auto",
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "#fff",
                      overflow: "auto",
                      WebkitOverflowScrolling: "touch",
                      outline: "none"
                    }
                  }}>
                  <h2>Please enter your password to proceed</h2>
                  <form
                    className="mt-4"
                    onSubmit={handlePasswordSubmit}
                    autoComplete="new-password">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      type="password"
                      value={password}
                      maxLength={50}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p style={{ height: "20px", color: passwordIsValid ? "#fff" : "red" }}>
                      {!passwordIsValid && "Password is incorrect"}
                    </p>
                    <div className="flex mt-4" style={{ justifyContent: "center" }}>
                      <button
                        className="flex justify-center font-semibold py-0.5 px-2 border border-transparent rounded-md shadow-sm text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none mr-4"
                        type="submit">
                        Submit
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                        onClick={() => {
                          closeModal();
                          setPasswordIsValid(true);
                          setPassword("");
                        }}>
                        Cancel
                      </button>
                    </div>
                  </form>
                </Modal>
                {/* <Modal
                  isOpen={pricingModalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Pricing Modal"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      zIndex: 150
                    },
                    content: {
                      height: "550px",
                      margin: "auto",
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "#fff",
                      overflow: "auto",
                      WebkitOverflowScrolling: "touch",
                      outline: "none"
                    }
                  }}>
                  <StripePricingTable
                    pricingTableId={process.env["STRIPE_PRICING_TABLE_ID"]}
                    email={userData.email}
                    customerId={userData._id}
                  />
                </Modal> */}
              </div>

              <div className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  {/* <label className="block text-sm font-medium text-gray-700" htmlFor="currentPassword"> */}
                  <label className="block text-sm font-medium text-gray-700">
                    Current Password
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="currentPassword"
                      type="password"
                      // value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      autoComplete="new-password"
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="mb-4 relative">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="newPassword">
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="newPassword"
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      maxLength={50}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <button
                    type="button"
                    className="absolute right-0 top-0 text-blue-500 hover:text-blue-800"
                    style={{ fontSize: "14px" }}
                    onClick={togglePasswordVisibility}>
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
                <div className="mb-4 relative">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="confirmPassword">
                    Confirm New Password
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      maxLength={50}
                      onChange={handleConfirmPasswordChange}
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        height: "20px",
                        color: passwordsMatch ? "#fff" : "red"
                      }}>
                      {!passwordsMatch && "Passwords don't match"}
                    </p>
                  </div>
                </div>
                {/* <button className="bg-fw-med-blue hover:bg-fw-dark-blue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleSaveChangesClick}>Save Changes</button> */}
                <button
                  className={`mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                    isPasswordFormValid()
                      ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                      : "text-white rounded bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={handleSaveChangesClick}
                  disabled={!isPasswordFormValid()}>
                  Save Changes
                </button>
              </div>

              <div className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="firstName"
                    type="text"
                    value={userData.firstName}
                    maxLength={150}
                    autoComplete="given-name"
                    onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="lastName"
                    type="text"
                    value={userData.lastName}
                    maxLength={150}
                    autoComplete="family-name"
                    onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="role">
                    Role
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="role"
                      value={userData.jobRole}
                      onChange={(e) => setUserData({ ...userData, jobRole: e.target.value })}>
                      <option value="">Select a role</option>
                      <option value="Executive Director / CEO">Executive Director / CEO</option>
                      <option value="Development Professional">Development Professional</option>
                      <option value="Grant Writer">Grant Writer</option>
                      <option value="Program Manager">Program Manager</option>
                      <option value="Marketer">Marketer</option>
                      <option value="Board Member">Board Member</option>
                      <option value="Volunteer">Volunteer</option>
                      <option value="Consultant / Contractor">Consultant / Contractor</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <path d="M0 6l10 10 10-10z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="industry">
                    Industry
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="industry"
                      value={userData.industry}
                      onChange={(e) => setUserData({ ...userData, industry: e.target.value })}>
                      <option value="">Select an industry</option>
                      <option value="Education">Education</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="International Aid/Development">
                        International Aid/Development
                      </option>
                      <option value="Science & Research">Science & Research</option>
                      <option value="For-profit Business">For-profit Business</option>
                      <option value="Other">Other</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <path d="M0 6l10 10 10-10z" />
                      </svg>
                    </div>
                  </div>
                </div>
                {isAttributesSaving ? (
                  <button
                    disabled
                    className="text-white font-semibold py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed">
                    Saving<span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </button>
                ) : (
                  <button
                    className="bg-fw-med-blue hover:bg-fw-dark-blue text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleUserInfoChange}>
                    Save Changes
                  </button>
                )}
                {/* <div
                  className="mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "0 0 auto",
                    borderTop: "1px solid #d3d3d3"
                  }}></div> */}
                {/* <div className="font-semibold text-sm mt-4">Current Subscription Plan:</div> */}

                {/* {subscriptionLevel !== "none" && (
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#575757",
                      marginTop: "10px",
                      marginLeft: "8px"
                    }}>
                    {subscriptionLevel}
                  </div>
                )} */}
                {/* {hasSubscription ? (
                  <>
                    <div
                      className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                      style={{ cursor: "pointer" }}
                      onClick={modifySubscription}>
                      Modify Subscription Plan
                    </div>
                    
                  </>
                ) : (
                  <>
                    <div className="font-semibold text-xl mt-4">No subscription</div>
                    <div
                      className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                      style={{ cursor: "pointer" }}
                      onClick={modifySubscription}>
                      Subscribe
                    </div>
                  </>
                )} */}
              </div>

              {/* DO NOT DELETE, We'll bring this back later */}
              {/* <div
          className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8"
          style={{ marginBottom: "80px" }}>
          <p className="mb-4">If you wish to delete your account, click the button below:</p>
          <button
            className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-[#B93F34] hover:bg-red-800"
            onClick={openDeleteModal}>
            Delete Account
          </button>
          <Modal
            isOpen={deleteModalIsOpen}
            onRequestClose={closeDeleteModal}
            contentLabel="Delete Confirmation Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                zIndex: 150
              },
              content: {
                width: "400px",
                height: "340px",
                margin: "auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                outline: "none"
              }
            }}>
            <h2 style={{ textAlign: "center", fontWeight: "500", color: "#575757" }}>
              Confirm Account Deletion
            </h2>
            <form className="mt-4" onSubmit={handleAccountDeletion} autoComplete="new-password">
              <label className="block text-sm font-medium text-gray-700" htmlFor="industry">
                Please enter your password to proceed
              </label>
              <input
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="password"
                value={password}
                maxLength={50}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p
                className="text-sm"
                style={{ height: "20px", color: passwordIsValid ? "#fff" : "red" }}>
                {!passwordIsValid && "Password is incorrect"}
              </p>
              <label className="mt-4 block text-sm font-medium text-gray-700" htmlFor="industry">
                Type DELETE into the text box below to confirm
              </label>
              <input
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                placeholder="DELETE"
                maxLength={6}
                value={deleteConfirm}
                onChange={(e) => setDeleteConfirm(e.target.value)}
              />
              <div className="text-sm italic mt-4" style={{ color: "#747474" }}>
                This action is permanent and cannot be undone
              </div>
              <div className="flex mt-1" style={{ justifyContent: "center" }}>
                <button
                  className={`mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    deleteConfirm === "DELETE"
                      ? "bg-[#B93F34] hover:bg-red-800 text-white"
                      : "text-white font-bold bg-gray-400 cursor-not-allowed"
                  }`}
                  type="submit"
                  disabled={deleteConfirm !== "DELETE"}>
                  Delete Account
                </button>
                <button
                  className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    closeDeleteModal();
                    setPasswordIsValid(true);
                    setPassword("");
                    setDeleteConfirm("");
                  }}>
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div> */}
            </div>
          </div>
        )}
        {currentTab === 1 && (
          <div>
            {!isUserTeamMember && (
              <>
                <div className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-4">
                  {!subscriptionLevel.includes("pro") && (
                    <>
                      <div className="usage-meter">
                        <div className="credits-info">
                          <div className="flex flex-col" style={{ marginRight: "40px" }}>
                            <div className="metric-set">
                              <div className="metric-icon">
                                <CreditsIcon
                                  className="mr-1 ml-1"
                                  height={"36px"}
                                  color={"#E0C424"}
                                />
                              </div>
                              <div className="credit-headline">
                                {(credits - spentCredits).toLocaleString(undefined, {
                                  maximumFractionDigits: 0 // This will drop any decimals
                                })}
                              </div>
                            </div>
                            <div className="info-block">
                              {/* <div className="credit-headline">
                            {(credits - spentCredits).toLocaleString(undefined, {
                              maximumFractionDigits: 0 // This will drop any decimals
                            })}
                          </div> */}
                              <div>
                                {subscriptionLevel === "trial"
                                  ? "trial words remaining"
                                  : "monthly words remaining"}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="metric-set">
                              <div className="metric-icon">
                                <CalendarIcon
                                  className="mr-1 ml-1"
                                  height={"30px"}
                                  color={"#395b9c"}
                                />
                              </div>
                              <div className="credit-headline">
                                {renewalDate ? formatDateToLocal(renewalDate) : ""}
                              </div>
                            </div>
                            <div className="info-block">
                              <div>
                                {subscriptionLevel === "trial"
                                  ? "trial end date"
                                  : "word reset date"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="progress-bar-background">
                          <div className="progress-bar" style={{ width: `${percentageUsed}%` }} />
                        </div>
                        <div className="credits-status">
                          <span>
                            <span className="font-bold">
                              {spentCredits.toLocaleString(undefined, {
                                maximumFractionDigits: 0 // This will drop any decimals
                              })}
                            </span>{" "}
                            <span> of </span>
                            <span className="font-bold">
                              {totalCredits.toLocaleString(undefined, {
                                maximumFractionDigits: 0 // This will drop any decimals
                              })}
                            </span>{" "}
                            total words used
                          </span>
                        </div>

                        <div className="bonus-credits">
                          {/* <div> */}
                          <BonusIcon className="mr-1 ml-1" height={"24px"} color={"#C0C0C0"} />
                          <span className="font-bold">
                            {" "}
                            {bonusCredits.toLocaleString(undefined, {
                              maximumFractionDigits: 0 // This will drop any decimals
                            })}{" "}
                          </span>
                          &nbsp;
                          <span>bonus words remaining</span>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="divider"></div>
                    </>
                  )}
                  <div className=" font-medium text-gray-700 font-semibold text-sm">
                    Current Subscription Plan:
                  </div>
                  {subscriptionLevel !== "none" && (
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#575757",
                        marginTop: "10px",
                        marginLeft: "8px"
                      }}>
                      {subscriptionLevel}
                    </div>
                  )}
                  {accountStatus !== "paused" &&
                  accountStatus !== "canceled" &&
                  accountStatus !== "failed_payment" ? (
                    <>
                      {hasSubscription ? (
                        <>
                          <div
                            className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                            style={{ cursor: "pointer" }}
                            onClick={modifySubscription}>
                            Modify Subscription Plan
                          </div>
                          {/* <div className="font-semibold text-xl mt-4">$35/month paid annually</div> */}
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-xl mt-4">No subscription</div>
                          <div
                            className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                            style={{ cursor: "pointer" }}
                            onClick={modifySubscription}>
                            Subscribe
                          </div>
                        </>
                      )}{" "}
                    </>
                  ) : null}
                  {accountStatus ? (
                    // <>
                    //   <div className=" font-medium text-gray-700 font-semibold text-sm mt-2">
                    //     Account Status:
                    //   </div>
                    //   <div
                    //     style={{
                    //       textTransform: "capitalize",
                    //       fontWeight: 600,
                    //       color: "#575757",
                    //       marginTop: "10px",
                    //       marginLeft: "8px"
                    //     }}>
                    //     {accountStatus === "failed_payment" ? "Payment Failed" : accountStatus}
                    //   </div>
                    //   <div
                    //     className="text-sm mt-2 text-blue-500 hover:text-blue-800"
                    //     style={{ cursor: "pointer" }}
                    //     onClick={modifySubscription}>
                    //     Modify Subscription Plan
                    //   </div>
                    // </>
                    <>
                      <div className=" font-medium text-gray-700 font-semibold text-sm mt-2">
                        Account Status:
                      </div>
                      {renderAccountStatus({ accountStatus: accountStatus })}
                    </>
                  ) : null}
                </div>
                <div className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
                      Account Name
                    </label>
                    <input
                      className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="accountName"
                      type="text"
                      value={newClientName}
                      maxLength={150}
                      onChange={(e) => setNewClientName(e.target.value)}
                    />
                  </div>
                  <button
                    className={`mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                      newClientName === ""
                        ? "text-white rounded bg-gray-400 cursor-not-allowed"
                        : "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                    }`}
                    onClick={() => {
                      if (clientData && clientData._id) {
                        saveClientName(clientData._id, newClientName);
                      } else {
                        console.error("Client ID is missing.");
                      }
                    }}
                    disabled={newClientName === ""}>
                    Save
                  </button>
                </div>
              </>
            )}
            {isEnterpriseSubscriptionType(clientData?.subscriptionLevel) &&
              isAccountAdmin(user?._id) && (
                <div
                  className="profile-cards  shadow-md rounded px-8 pt-6 pb-8 mb-4"
                  // style={{ backgroundColor: "#F2F2F2" }}
                >
                  <div className="mb-4">
                    <div style={{ display: "flex" }}>
                      <label className="block text-sm font-medium text-gray-700">
                        Invite additional users to this Fundwriter.ai account
                      </label>
                      <label
                        className="block text-sm mt-4 font-medium text-gray-700"
                        style={{ marginLeft: "auto" }}>
                        Admin
                      </label>
                    </div>
                    {emailAccounts.map((emailAccount, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <input
                          style={{
                            color: "#747474",
                            height: "30px",
                            flexGrow: 1,
                            marginRight: "10px"
                          }}
                          placeholder="Email address"
                          className={`mt-2 appearance-none block w-full px-3 py-2 border ${
                            emailAccount.email !== "" && !validateEmail(emailAccount.email)
                              ? "border-red-500"
                              : "border-gray-300"
                          } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          value={emailAccount.email}
                          onChange={(e) => handleEmailChange(index, e.target.value)}
                        />
                        <div
                          className="ml-2"
                          style={{ textAlign: "center", marginTop: "10px", marginRight: "12px" }}>
                          <input
                            type="checkbox"
                            checked={emailAccount.accountAdmin}
                            onChange={(e) => handleAdminChange(index, e.target.checked)}
                            style={{}}
                            className="custom-checkbox"
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      className={`mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                        emailAccounts.some(
                          (emailAccount) =>
                            emailAccount.email !== "" && validateEmail(emailAccount.email)
                        ) &&
                        emailAccounts.every(
                          (emailAccount) =>
                            emailAccount.email === "" || validateEmail(emailAccount.email)
                        )
                          ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                          : "text-white rounded bg-gray-400 cursor-not-allowed"
                      }`}
                      onClick={() => handleSendInvites(false)}
                      disabled={
                        !emailAccounts.some(
                          (emailAccount) =>
                            emailAccount.email !== "" && validateEmail(emailAccount.email)
                        ) ||
                        !emailAccounts.every(
                          (emailAccount) =>
                            emailAccount.email === "" || validateEmail(emailAccount.email)
                        )
                      }>
                      Send Invites
                    </button>
                  </div>
                  <TableContainer
                    style={{ maxHeight: 400 }}
                    // style={{overflowY:"auto",display:"flex",flexDirection:"column",flex:"1 1 auto"}}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell size="small" className="table-cell-first-col" style={{}}>
                            User
                          </TableCell>
                          <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                            Last Active
                          </TableCell>
                          <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                            Usage this Month
                          </TableCell>
                          <TableCell size="small">Role</TableCell>
                          <TableCell size="small">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientData?.users?.map((teamUser: TeamUser, index) => (
                          <TableRow key={index}>
                            <TableCell size="small" className="table-cell-first-col" style={{}}>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <div
                                  style={{
                                    maxWidth: "150px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                  }}>
                                  {teamUser.firstName}&nbsp;{teamUser.lastName}
                                </div>
                                <div style={{ fontSize: "12px" }}>{teamUser.email}</div>
                              </div>
                            </TableCell>
                            <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                              {teamUser.teamInviteStatus === "Invited"
                                ? "Invited"
                                : teamUser.lastActiveDate
                                ? formatDate(teamUser.lastActiveDate)
                                : "-"}
                            </TableCell>
                            <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                              {teamUser.monthlyCreditsUsed != null &&
                                `${(
                                  teamUser.monthlyCreditsUsed / wordsConversionFactor
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 0
                                })} words`}
                            </TableCell>
                            <TableCell
                              size="small"
                              className="table-cell-left"
                              style={{ width: "10%" }}>
                              <div
                                style={{
                                  maxWidth: "50px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}>
                                {capitalizeFirstLetter(teamUser.userType)}
                              </div>
                            </TableCell>
                            <TableCell size="small" className="table-cell" style={{ width: "10%" }}>
                              {teamUser.id !== user?._id && (
                                <>
                                  {teamUser.teamInviteStatus === "Invited" && (
                                    <HtmlTooltip title="Resend invite" placement="top" arrow>
                                      <button
                                        onClick={() =>
                                          handleSendInvites(true, {
                                            email: teamUser.email,
                                            accountAdmin: teamUser.userType === "admin"
                                          })
                                        }>
                                        <EnvelopeIcon
                                          className="mr-1 ml-1"
                                          height={"15px"}
                                          width={"15px"}
                                          color={"rgba(88, 89, 91, 1)"}
                                        />
                                      </button>
                                    </HtmlTooltip>
                                  )}
                                  <HtmlTooltip title="Remove user" placement="top" arrow>
                                    <button
                                      onClick={() => handleRemoveUser(clientData._id, teamUser.id)}>
                                      <DeleteIcon
                                        className="mr-1 ml-1"
                                        height={"15px"}
                                        width={"15px"}
                                        color={"rgba(88, 89, 91, 1)"}
                                      />
                                    </button>
                                  </HtmlTooltip>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Modal
                    isOpen={isRemoveUserModalOpen}
                    onRequestClose={cancelRemoveUser}
                    contentLabel="Remove User Modal"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        zIndex: 150
                      },
                      content: {
                        width: "max-content",
                        height: "max-content",
                        margin: "auto",
                        padding: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        outline: "none"
                      }
                    }}>
                    <h4 className="mb-1" style={{ fontWeight: "600" }}>
                      Confirm Removal
                    </h4>
                    <p>
                      Are you sure you want to remove <b>{userNameLookup(tempUserData.userId)}</b>{" "}
                      from your team?
                    </p>
                    <div></div>
                    <div className="flex mt-4" style={{ justifyContent: "center" }}>
                      <button
                        className="flex justify-center font-semibold py-0.5 px-2 border border-transparent rounded-md shadow-sm text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none mr-4"
                        onClick={confirmRemoveUser}>
                        Remove
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                        onClick={cancelRemoveUser}>
                        Cancel
                      </button>
                    </div>
                  </Modal>
                </div>
              )}
            {/* <div className="profile-cards bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">API KEY</label>
                <div
                  style={{ color: "#747474", height: "30px" }}
                  className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {apiKey}
                </div>
                <button
                  className={`mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                    apiKey === ""
                      ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                      : "text-white rounded bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={handleGenerateApiKey}
                  disabled={apiKey !== ""}>
                  Generate API KEY
                </button>
              </div>
            </div> */}
            <Modal
              isOpen={pricingModalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Pricing Modal"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  zIndex: 150
                },
                content: {
                  height: "550px",
                  margin: "auto",
                  padding: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  outline: "none"
                }
              }}>
              <StripePricingTable
                pricingTableId={process.env["STRIPE_PRICING_TABLE_ID"]}
                email={userData.email}
                customerId={userData._id}
              />
              <div
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setPricingModalIsOpen(false);
                  setPricingModelBlackbaudModalIsOpen(true);
                }}>
                Switch To Blackbaud Pricing
              </div>
            </Modal>
            <Modal
              isOpen={pricingModelBlackbaudModalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Pricing Modal"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  zIndex: 150
                },
                content: {
                  height: "550px",
                  margin: "auto",
                  padding: "20px",
                  border: "1px solid rgb(0, 17, 46)",
                  borderRadius: "4px",
                  backgroundColor: "rgb(0, 17, 46)",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  outline: "none"
                }
              }}>
              <StripePricingTable
                pricingTableId={process.env["STRIPE_BLACKBAUD_PRICING_TABLE_ID"]}
                email={userData.email}
                customerId={userData._id}
              />
              <div
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setPricingModalIsOpen(true);
                  setPricingModelBlackbaudModalIsOpen(false);
                }}>
                Switch To Base Pricing
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
