import React, { useState } from "react";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";
import axios from "axios";
import isEmail from "validator/lib/isEmail";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEmailValid = () => {
    return isEmail(email);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true); // Use a timer to prevent spamming of submits
    setTimeout(() => setIsSubmitting(false), 3000);
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/resetPassword";
      // send a POST request to your API endpoint
      // const response = await axios.post(url, { email });
      const response = await axios.post(url, { email: email.toLowerCase() });
      // if password reset email was sent successfully, show success message
      setMessage(response.data.message);
    } catch (error: any) {
      // show error message
      // setMessage(`Error: ${error.response.data.message}`);

      setMessage("There was an error processing your request. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img src={fundwriterLogo} alt="Logo" className="logo mb-4" />
            <h2 className="mt-6 mb-6 text-center text-xl font-bold text-gray-700">
              Reset your password
            </h2>
          </div>

          {/* <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={254}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                // type="submit"
                // className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                className={`font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                  isEmailValid() && !isSubmitting
                    ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                    : "text-white rounded bg-gray-400 cursor-not-allowed"
                }`}
                type="submit"
                disabled={!isEmailValid() || isSubmitting}>
                {isEmailValid() && isSubmitting ? (
                  <>
                    Sending<span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </>
                ) : (
                  "Reset password"
                )}
              </button>
            </div>
          </form>
          <p className="mt-2" style={{ height: "30px" }}>
            {message && !isSubmitting ? message : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
