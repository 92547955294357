import React, { useState, useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBoxArchive } from "@fortawesome/free-solid-svg-icons";
import { ChatIcon } from "src/lib/icons/index";
import { useSelector } from "react-redux";
// import { BeatLoader } from "react-spinners";
import { RootState, useAppDispatch } from "src/configureStore";
import "./chatBar.scss";
import { ChevronCircleIcon, RightChevron, PersonIcon, LogoIcon } from "src/lib/icons/index";
import ResizePanel from "src/components/resizePanel";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeFormat from "rehype-format";
import remarkGfm from "remark-gfm";
import AnimatedLogo from "../components/animatedLogo";

export interface ChatMessage {
  role: string;
  content: string;
}
interface ChatBarProps {
  isWriting: boolean;
  chatContent: ChatMessage[];
  onSubmit: (string: string, chat: string) => void;
  // setIsChatWriting: (state: boolean) => void;
}
const ChatBar: React.FC<ChatBarProps> = ({
  isWriting,
  chatContent,
  onSubmit
  // setIsChatWriting
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [userInput, setUserInput] = useState("");
  const user = useSelector((state: RootState) => state.auth.user);
  const initials = `${user?.firstName[0]}${user?.lastName[0]}`.toUpperCase();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  // const dispatch = useAppDispatch();

  // const projects = useSelector((state: RootState) => state.projectsReducer.projects);

  //   useEffect(() => {
  //     if (localStorage.getItem("authToken") !== null) {
  //       dispatch(fetchProjects());
  //     }
  //   }, [dispatch]);
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatContent]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const formatContentWithNewlines = (content: string) => {
    return content.replace(/\n(?=\n|$)/g, "\n\n&nbsp;\n");
  };

  return (
    <>
      {isOpen ? (
        <ResizePanel
          direction="w"
          handleClass="customHandleEW"
          draggingClass="drag-border-w"
          initialSize={350}
          minWidth="100px">
          <div
            className={"chatBar"}
            style={{
              height: "100%",
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%"
            }}>
            <div className="chat-arrow-icon" onClick={toggleSidebar}>
              <ChevronCircleIcon
                className="rotate-chevron"
                height={"20px"}
                width={"20px"}
                color={"rgba(88, 89, 91, 1)"}
              />
            </div>
            <div className="flex border-l border-gray-300" style={{ height: "100%" }}>
              <div className="" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div
                  className="flex flex-row pt-5 pl-3 pr-3 pb-3 ml-2"
                  style={{ flex: "0 0 auto", alignItems: "center" }}>
                  <ChatIcon height={"20px"} color={"#707070"} />
                  <div className="pl-2 pr-2 font-semibold text-gray-600">AI Chat</div>
                </div>
                <div className="flex flex-col p-3" style={{ overflowY: "auto", flex: "1 1 auto" }}>
                  <div
                    className="chat-content text-sm border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                    style={{
                      overflowY: "auto",
                      flex: "1 1 auto",
                      maxWidth: "100%"
                    }}>
                    {chatContent.map((chatMessage: any, index: any) => (
                      <>
                        <div key={index} className="chat-message flex flex-row">
                          <div
                            className="mr-1"
                            style={{
                              minWidth: "20px",
                              marginTop:
                                chatMessage.role === "assistant" && !isWriting
                                  ? "1px"
                                  : chatMessage.role === "user"
                                  ? "2px"
                                  : undefined
                            }}>
                            {isWriting && chatMessage.role === "assistant" ? (
                              <div className="editor-blinking-cursor"></div>
                            ) : chatMessage.role === "assistant" ? (
                              <LogoIcon height={"18px"} color={"rgb(38, 109, 159)"} />
                            ) : (
                              <div
                                className=""
                                style={{
                                  background: "rgb(38, 109, 159)",
                                  borderRadius: "50%",
                                  color: "white",
                                  fontSize: "11px",
                                  height: "18px",
                                  width: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: 500,
                                  cursor: "default"
                                }}>
                                {initials}
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <strong>
                              {chatMessage.role === "user" ? (
                                "You:"
                              ) : isWriting && chatMessage.role === "assistant" ? (
                                <span>
                                  Fundwriter.ai<span className="dot">.</span>
                                  <span className="dot">.</span>
                                  <span className="dot">.</span>
                                </span>
                              ) : chatMessage.role === "assistant" ? (
                                "Fundwriter.ai:"
                              ) : (
                                `${chatMessage.role}:`
                              )}
                            </strong>{" "}
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw, rehypeFormat]}>
                              {formatContentWithNewlines(chatMessage.content.replace())}
                            </ReactMarkdown>
                          </div>
                        </div>
                        <hr style={{ margin: "10px" }} />
                      </>
                    ))}
                    <div ref={endOfMessagesRef} />
                  </div>
                </div>
                <ResizePanel
                  direction="n"
                  handleClass="customHandleNS"
                  minHeight="106px"
                  draggingClass="drag-border">
                  <div
                    className="flex items-center p-3"
                    style={{ width: "100%", flex: "1 1 auto", minHeight: "80px" }}>
                    <div
                      className="flex-container"
                      style={{
                        width: "100%",
                        minHeight: "80px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}>
                      <textarea
                        className="userInput text-sm h-full w-full border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                        style={{ width: "100%", overflow: "hidden" }}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            e.stopPropagation();
                            onSubmit("chat", userInput);
                            setUserInput("");
                          }
                        }}
                      />
                    </div>
                  </div>
                </ResizePanel>
                <div className="flex items-center pb-2">
                  <div
                    className="w-full"
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      flex: "0 0 auto",
                      borderTop: "1px solid #d3d3d3",
                      flexDirection: "row",
                      justifyContent: "space-evenly"
                    }}>
                    <div
                      onClick={() => {
                        if (!isWriting) {
                          onSubmit("chat", userInput);
                          setUserInput("");
                        } else {
                          onSubmit("cancelChat", "");
                        }
                      }}>
                      {isWriting ? (
                        <button className="mt-2 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 bg-gray-500 hover:bg-gray-700 rounded focus:outline-none focus:shadow-outline">
                          Stop Writing
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                        </button>
                      ) : (
                        <button className="mt-2 text-white font-semibold py-0.5 px-2 bg-gray-500 hover:bg-gray-700 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                          Send
                        </button>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        onSubmit("clearChat", "");
                      }}>
                      <button className="mt-2 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline">
                        <>Clear Chat</>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ResizePanel>
      ) : (
        <div
          className="chatBar closed border-l border-gray-300"
          style={{
            height: "100%",
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column"
          }}>
          <div className="chat-arrow-icon chat-closed" onClick={toggleSidebar}>
            <RightChevron
              className="rotate-chevron"
              height={"16px"}
              width={"16px"}
              color={"rgba(88, 89, 91, 1)"}
            />
          </div>
          <div className="">
            <div className="flex flex-row pl-4 rotate-90" style={{ marginTop: "30px" }}>
              <div>
                <ChatIcon height={"20px"} color={"#707070"} />
              </div>
              <div
                className="pl-2 pr-2 font-semibold text-gray-600"
                style={{ whiteSpace: "nowrap" }}>
                AI Chat
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBar;
