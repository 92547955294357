import React, { useEffect } from "react";

interface StripePricingTableProps {
  email: string;
  customerId: string;
  pricingTableId: string | undefined;
}

const StripePricingTable: React.FC<StripePricingTableProps> = ({
  email,
  customerId,
  pricingTableId
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  console.log("customerId " + customerId);
  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={process.env["STRIPE_PUB_KEY"]}
      customer-email={email}
      client-reference-id={customerId}></stripe-pricing-table>
  );
};

export default StripePricingTable;
