import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
// import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";
import { login } from "src/reducers/authReducer";
import { fetchProjects } from "src/reducers/projectReducer";
import { fetchUserRecents } from "src/reducers/clientReducer";
import { useAppDispatch } from "src/configureStore";

// import "./main.scss";

function LoginPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userEmail = query.get("email") || "";
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [verified, setVerified] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setError("");
    const url = process.env["REACT_APP_API_SERVER"] + "/api/login";
    const data = { email, password, resetTrial: searchParams.get("resetTrial") };
    const response: any = await axios.post(url, data).catch((err) => {
      console.log("NOTE 200");
      console.log(err);
      setError("Invalid login attempt. Please try again.");
      return { status: 401 };
    });
    if (response.status === 200) {
      console.log(response.data);
      if (response.data.user.isEmailVerified) {
        setVerified(true);
        window.LOU.identify(response.data.user._id, {
          subscriptionLevel: response.data.user.subscriptionLevel,
          role: response.data.user.role,
          jobRole: response.data.user.jobRole
        });

        await dispatch(login({ user: response.data.user, token: response.data.token }));
        await dispatch(fetchProjects());
        await dispatch(fetchUserRecents());

        navigate("/");
      } else {
        setVerified(false);
        // setError("Please verify your email before you can log in.");
      }
    } else {
      console.log("NOTE 201");
      console.log(response);
      setError("Invalid login attempt. Please try again.");
    }
    // Your login logic here
  }
  async function resendVerification() {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/resendVerificationEmail";
    const response = await axios.post(url, { email });
    if (response.status === 200) {
      setModalIsOpen(true);
    } else {
      setError("Failed to resend verification email.");
    }
  }
  if (verified) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        {/* <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          <img src={fundwriterLogo} alt="Logo" className="logo" />
        </h2>
      </div> */}

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleLogin}>
              {error && <div className="text-red-500">{error}</div>}
              <img src={fundwriterLogo} alt="Logo" className="logo mb-4" />
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    maxLength={254}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    maxLength={100}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                {/* TODO: add remember me feature */}
                {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div> */}

                <div className="text-sm">
                  <a
                    href="/reset-password"
                    className="font-medium text-blue-500 hover:text-blue-800">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 font-bold border border-transparent rounded-md shadow-sm text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                  Sign in
                </button>
              </div>
            </form>
            <div className="text-center mt-2">
              <span className="text-gray-600 mr-2">Don&apos;t have an account?</span>
              <a className="font-bold text-blue-500 hover:text-blue-800" href="/signup">
                Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        {/* <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          <img src={fundwriterLogo} alt="Logo" className="logo" />
        </h2>
      </div> */}
        <Modal
          isOpen={modalIsOpen}
          contentLabel="Password Verification Email Sent"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              zIndex: 150
            },
            content: {
              width: "350px",
              height: "200px",
              margin: "auto",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              outline: "none"
            }
          }}>
          <h2>Password Verification Email Sent</h2>
        </Modal>
        {error && <div className="text-red-500">{error}</div>}
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center mt-2">
              <div>Please verify your email before you can log in.</div>
              <div
                className="cursor-pointer font-bold text-blue-500 hover:text-blue-800"
                onClick={resendVerification}>
                Resend Verification Email
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
