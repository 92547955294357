import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
import Modal from "react-modal";
Modal.setAppElement("#root");
interface ModelInputEditorProps {
  modelId?: string;
  inputs: any;
  onSave: (model: any) => void;
  onCancel: () => void;
  onDelete: (id: string) => void;
}
interface SelectedOption {
  value: string;
  label: string;
}

const ModelInputEditor: React.FC<ModelInputEditorProps> = ({
  modelId,
  inputs,
  onSave,
  onCancel,
  onDelete
}) => {
  const [localInputs, setLocalInputs] = useState(inputs);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedAutoInput, setSelectedAutoInput] = useState<any | null>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deletedInputId, setDeletedInputId] = useState("");
  const [selectedDropDownValue, setSelectedDropDownValue] = useState<any | null>([]);

  const [autoInputValues] = useState<any[]>([
    { value: "", label: "None" },
    { value: "Organization", label: "Organization" },
    { value: "Mission", label: "Mission" },
    { value: "Programs and Activities - Simple", label: "Programs and Activities - Simple" },
    { value: "Programs and Activities - Details", label: "Programs and Activities - Details" },
    { value: "Core Beliefs", label: "Core Beliefs" },
    { value: "Key Statistics", label: "Key Statistics" },
    { value: "Tone", label: "Tone" },
    { value: "Key Staff or Leaders", label: "Key Staff or Leaders" },
    { value: "Personal Story", label: "Personal Story" },
    { value: "Thank Supporters", label: "Thank Supporters" },
    { value: "Amount Requested", label: "Amount Requested" },
    { value: "Target Program", label: "Target Program" },
    { value: "Funder", label: "Funder" },
    { value: "Impact Details", label: "Impact Details" },
    { value: "Outcomes - Measurable", label: "Outcomes - Measurable" },
    { value: "Call-to-Action", label: "Call-to-Action" },
    { value: "Incentives & Offers", label: "Incentives & Offers" },
    { value: "Request Details", label: "Request Details" },
    { value: "Budget", label: "Budget" },
    { value: "Community", label: "Community" },
    { value: "Need or Problem", label: "Need or Problem" }
  ]);

  const handleSave = () => {
    onSave(localInputs);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    const sortedInputs = [...inputs].sort((a, b) => a.order - b.order);
    setLocalInputs(sortedInputs);

    const selectedAI: (SelectedOption | null)[] = [];
    const selectedDD: (SelectedOption | null)[] = [];
    inputs.forEach((input: any) => {
      selectedAI[input._id] = { label: input.autoInput, value: input.autoInput };
      if (input.useDropDowns === undefined || input.useDropDowns === "Off") {
        selectedDD[input._id] = { label: "Off", value: "Off" };
      } else {
        selectedDD[input._id] = { label: input.useDropDowns, value: input.useDropDowns };
      }
    });

    setSelectedAutoInput(selectedAI);
    setSelectedDropDownValue(selectedDD);
  }, [inputs]);

  const handleChange = (field: string, value: string, idx: number) => {
    let mValue = value;

    if (field === "useDropDowns") {
      if (value === "Off") {
        mValue = "false";
      } else {
        mValue = value;
      }
    }

    const modifiedInputs = localInputs.map((input: any, i: number) => {
      if (i === idx) {
        if (field === "dropDownValues") {
          return { ...input, [field]: value.split("|") };
        }
        return { ...input, [field]: mValue };
      }
      return input;
    });
    // console.log(modifiedInputs);
    setLocalInputs(modifiedInputs);
    setHasUnsavedChanges(true);
  };

  const handleDelete = (inputId: string) => {
    console.log("Delete");
    onDelete(inputId);
  };
  const addInputs = (maxCharsAndTone: boolean) => {
    let order = 1;
    if (localInputs.length > 0) {
      order = localInputs[localInputs.length - 1].order + 1;
    }
    if (maxCharsAndTone) {
      const newInputs = _.cloneDeep(localInputs);
      newInputs.push({
        modelId,
        inputName: "Tone",
        maxCharacters: 50,
        autoInput: "",
        useDropDowns: false,
        placeHolderText: "Tone",
        order
      });
      newInputs.push({
        modelId,
        inputName: "Max Characters",
        maxCharacters: 15,
        autoInput: "",
        useDropDowns: false,
        placeHolderText: "Max Characters",
        order: localInputs[localInputs.length - 1].order + 2
      });
      setLocalInputs(newInputs);
    } else {
      const newInputs = _.cloneDeep(localInputs);
      newInputs.push({
        modelId,
        inputName: "",
        maxCharacters: 1000,
        autoInput: "",
        useDropDowns: false,
        placeHolderText: "",
        order
      });
      setLocalInputs(newInputs);
    }
  };
  return (
    <div className="flex flex-col h-full">
      <Modal
        isOpen={showDeleteConfirm}
        onRequestClose={() => {
          setShowDeleteConfirm(false);
        }}
        contentLabel="Confrim Delete"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "500px",
            height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <div style={{ textAlign: "center" }}>Are you sure you want to delete this input?</div>

          <div className="flex mt-1" style={{ justifyContent: "center" }}>
            <button
              // disabled={!validateEmail(userData.email)}
              className="mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
              onClick={() => {
                handleDelete(deletedInputId);
                setLocalInputs(localInputs.filter((input: any) => input._id !== deletedInputId));
                setShowDeleteConfirm(false);
              }}>
              Delete
            </button>
            <button
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-4"
              onClick={() => {
                setShowDeleteConfirm(false);
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="mt-6">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              addInputs(true);
            }}>
            Add Tone and Max Chars
          </button>
        </div>
        <div className="mt-6" style={{ marginLeft: "10px" }}>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              addInputs(false);
            }}>
            Add Input
          </button>
        </div>
      </div>
      <div className="flex-grow flex flex-col h-full">
        {localInputs && (
          <>
            {localInputs.map((input: any, idx: number) => {
              return (
                <div className="flex flex-row" key={idx}>
                  <div className="flex flex-row" style={{ padding: "15px" }} key={idx}>
                    <div>#{input.order}</div>
                  </div>
                  <div className="m-1">
                    <div>Input Name</div>
                    <input
                      style={{ width: "300px" }}
                      className="border border-gray-300"
                      value={input.inputName}
                      onChange={(e) => handleChange("inputName", e.target.value, idx)}
                    />
                  </div>
                  <div className="m-1">
                    <div>Max Characters</div>
                    <input
                      style={{ width: "100px" }}
                      className="border border-gray-300"
                      value={input.maxCharacters}
                      onChange={(e) => handleChange("maxCharacters", e.target.value, idx)}
                    />
                  </div>
                  <div className="m-1">
                    <div>Drop Downs</div>
                    <Select
                      options={[
                        { value: "Off", label: "Off" },
                        { value: "DropDown", label: "DropDown" },
                        { value: "Checkboxes", label: "Checkboxes" }
                      ]}
                      value={selectedDropDownValue[input._id]}
                      onChange={(selectedOption: SelectedOption) => {
                        console.log(selectedOption);
                        setSelectedDropDownValue(
                          (prevState: ({ value: string; label: string } | null)[]) => ({
                            ...prevState,
                            [input._id]: selectedOption
                          })
                        );
                        handleChange("useDropDowns", selectedOption.value, idx);
                      }}
                    />
                  </div>
                  <div className="m-1">
                    <div>Drop Downs Values</div>
                    <input
                      style={{ width: "300px" }}
                      className="border border-gray-300"
                      value={
                        Array.isArray(input.dropDownValues) ? input.dropDownValues.join("|") : ""
                      }
                      onChange={(e) => handleChange("dropDownValues", e.target.value, idx)}
                    />
                  </div>
                  <div className="m-1" style={{ width: "200px" }}>
                    <div>AutoInput</div>
                    <Select
                      options={autoInputValues}
                      value={selectedAutoInput[input._id]}
                      isSearchable={true}
                      onChange={(selectedOption: SelectedOption) => {
                        // const selected = writingModels.find(
                        //   (model) => model.modelName === selectedOption.value
                        // );
                        // setSelectedModel(selected ? selected : null);
                        handleChange("autoInput", selectedOption.value, idx);
                        setSelectedAutoInput(
                          (prevSelectedAutoInput: ({ value: string; label: string } | null)[]) => ({
                            ...prevSelectedAutoInput,
                            [input._id]: selectedOption
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="m-1" style={{ width: "300px" }}>
                    <div>Help Text</div>
                    <input
                      style={{ width: "300px" }}
                      className="border border-gray-300"
                      value={input.inputDesc}
                      onChange={(e) => handleChange("inputDesc", e.target.value, idx)}
                    />
                  </div>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    style={{ height: "40px", marginTop: "25px", marginLeft: "20px" }}
                    onClick={() => {
                      setDeletedInputId(input._id);
                      setShowDeleteConfirm(true);
                    }}>
                    Delete
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="mt-6">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ModelInputEditor;
