// import cloneDeep from "clone-deep";
import { Dispatch } from "redux";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { AppDispatch } from "src/configureStore";
import { IWritingModel } from "src/interfaces/IWriting";
import { IInputValues } from "src/interfaces/IWriting";
import { fetchChatOutput } from "./outputReducer";
import { setAssetsSuccess, updateAssetsState, getAssetsForProject } from "./projectReducer";

interface DocumentAssetState {
  documentAssets: IWritingModel[];
  selectedDocumentAsset: IWritingModel | undefined;
  assetType: "writingTools" | "model";
  serverResponse: string;
  chatResponse: string;
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  pollingStatus: string;
  funds: any[];
}
const initialState: DocumentAssetState = {
  documentAssets: [],
  selectedDocumentAsset: undefined,
  serverResponse: "",
  chatResponse: "",
  isLoading: false,
  isLoaded: false,
  error: null,
  pollingStatus: "",
  funds: [],
  assetType: "model"
};
const documentAssetSlice = createSlice({
  name: "documentAssets",
  initialState,
  reducers: {
    getDocumentAssetsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getDocumentAssetsSuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.documentAssets = action.payload;
    },
    getDocumentAssetsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    getDocumentAssetById: (state, action: PayloadAction<string>) => {
      state.selectedDocumentAsset = state.documentAssets?.find((set) => set._id === action.payload);
    },
    // PayloadAction<{ text: string; type: string }>
    setSelectedDocumentAsset: (
      state,
      action: PayloadAction<{ wModel: IWritingModel | undefined; type: "writingTools" | "model" }>
    ) => {
      state.selectedDocumentAsset = action.payload.wModel;
      state.assetType = action.payload.type;
    },
    setServerResponse: (state, action: PayloadAction<string>) => {
      state.serverResponse = action.payload;
    },
    setChatResponse: (state, action: PayloadAction<string>) => {
      state.chatResponse = action.payload;
    },

    setPollingStatus: (state, action: PayloadAction<string>) => {
      // Only update the polling status if it's not currently running or a new status is provided
      if (!(state.pollingStatus === "running" && action.payload === "")) {
        console.log(`1 SETTING POLLING STATUS ${action.payload} + ${state.pollingStatus}`);
        state.pollingStatus = action.payload;
      }
    },
    setFunds(state, action: PayloadAction<any[]>) {
      state.funds = action.payload;
    }
  }
});
export const {
  getDocumentAssetsRequest,
  getDocumentAssetsSuccess,
  getDocumentAssetsFailure,
  getDocumentAssetById,
  setSelectedDocumentAsset,
  setServerResponse,
  setPollingStatus,
  setChatResponse,
  setFunds
} = documentAssetSlice.actions;

export const fetchDocumentAssets = (documentAssetId: string) => async (dispatch: Dispatch) => {
  dispatch(getDocumentAssetsRequest());
  try {
    const url =
      process.env["REACT_APP_API_SERVER"] +
      "/api/documentAssets/getDocumentAssetsForType/" +
      documentAssetId;

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getDocumentAssetsSuccess(response.data));

    const documentAsset = response.data?.find((set: any) => set._id === documentAssetId);
    dispatch(setSelectedDocumentAsset({ wModel: documentAsset, type: "model" }));

    await dispatch(updateAssetsState(documentAsset.assets));
  } catch (error: any) {
    dispatch(getDocumentAssetsFailure(error.message));
  }
};
export const fetchDocumentAsset = (documentAssetId: string) => async (dispatch: Dispatch) => {
  dispatch(getDocumentAssetsRequest());
  const url =
    process.env["REACT_APP_API_SERVER"] +
    "/api/documentAssets/getDocumentAssetsById/" +
    documentAssetId;
  const response = await axiosInstance.get(url, {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
  });

  dispatch(getDocumentAssetsSuccess(response.data.documentAsset));

  dispatch(setSelectedDocumentAsset({ wModel: response.data.documentAsset, type: "model" }));
  await dispatch(updateAssetsState(response.data.refTextAssets));

  // dispatch(getAssetsForProject(response.data.model.projectId, documentAssetId));
};

export const fetchServerResponse =
  (
    inputValues: IInputValues,
    documentAssetId: string,
    documentTypeId: string,
    assetType: string,
    writeMore = false,
    continueWriting = false,
    removeRefFiles = false,
    trimHistory = false
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/writeCompletion";

      const response = await axiosInstance.post(
        url,
        {
          documentAssetId,
          documentTypeId,
          inputValues,
          assetType,
          writeMore,
          continueWriting,
          removeRefFiles,
          trimHistory
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` } }
      );

      return response.data;
      // dispatch(setServerResponse(response.data));
      //dispatch(fetchOutput(documentAssetId));
    } catch (error: any) {
      console.log(error);
      return { success: false };
    }
  };
export const fetchChatResponse =
  (inputValues: IInputValues, documentAssetId: string) => async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/chatCompletion";

      const response = await axiosInstance.post(
        url,
        { documentAssetId, inputValues },
        { headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` } }
      );

      dispatch(setChatResponse(response.data));
      //dispatch(fetchChatOutput(documentAssetId));
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log("ERROR IN CHAT COMPLETION");
      console.log(error);
      return { success: false, message: error.message };
    }
  };
export const checkUsage = () => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/checkUsage";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    return true;
  } catch (error: any) {
    console.log(error);
    return false;
  }
};
export const pollUrlForCompletion =
  (url: string, documentAssetId: string) => async (dispatch: AppDispatch) => {
    let attempts = 0;
    const maxAttempts = 15;
    const delay = 10000;
    await dispatch(setPollingStatus("running"));
    const fetchData = async () => {
      try {
        attempts++;
        const response = await axiosInstance.post(
          url,
          { assetId: documentAssetId },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
          }
        );
        const data = await response.data;
        if (data.success === false) {
          return;
        }
        if (data.status === "complete" || attempts >= maxAttempts) {
          await dispatch(setPollingStatus("complete"));

          dispatch(fetchDocumentAsset(documentAssetId));
        } else {
          setTimeout(fetchData, delay);
        }
      } catch (error: any) {
        console.error("Error while polling:", error);
      }
    };

    fetchData();
  };

export const fetchFunds = () => async (dispatch: Dispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/blackbaud/fundAssets";

    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    const funds = response.data.map((fund: any) => {
      fund.label = fund.name;
      fund.value = fund.name;
      return fund;
    });
    dispatch(setFunds(funds));
    0;
  } catch (error: any) {
    console.log(error);
    // dispatch(getDocumentAssetsFailure(error.message));
  }
};
export const updateDocumentAssetAssets =
  (documentAssetId: string, fileIds: any, urlIds: any, refTextIds: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const url =
        process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/updateDocumentAssetAssets";
      const response = await axiosInstance.post(
        url,
        { fileIds, urlIds, refTextIds, documentAssetId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );

      const documentAsset = response.data.model;
      [...fileIds, ...urlIds, ...refTextIds].forEach((assetId: string) => {
        documentAsset.assets.map((asset: any) => {
          if (asset._id === assetId) {
            asset.selected = true;
          }
          return asset;
        });
      });

      await dispatch(setSelectedDocumentAsset({ wModel: documentAsset, type: "model" }));

      await dispatch(updateAssetsState(response.data.assets));

      // await dispatch(getAssetsForProject(documentAsset.project));

      return { success: true, documentType: response.data };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };
export const saveInputs =
  (
    projectId: string,
    documentTypeId: string,
    documentAssetId: string,
    inputValues: IInputValues,
    assetType: string
  ) =>
  async (dispatch: AppDispatch) => {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/saveInputs";
    const response: any = await axiosInstance.post(
      url,
      { projectId, documentTypeId, documentAssetId, inputValues, assetType },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    //dispatch(getOutputSuccess(response.text));
  };
export const createDocumentAsset =
  (projectId: string, documentTypeId: string, name: string, modelId: string) =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentAssets/createDocumentAsset";
      const response = await axiosInstance.post(
        url,
        {
          name,
          projectId,
          modelId,
          documentTypeId
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(fetchDocumentAsset(response.data.documentAsset._id));

      console.log(response);
      return response.data;
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };

export default documentAssetSlice.reducer;
