import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";

const VerifyEmail = () => {
  const [status, setStatus] = useState<string>("unchecked");
  const navigate = useNavigate();
  // Get the reset token from the URL

  const { verifyToken, email } = useParams();

  useEffect(() => {
    async function doCheck() {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/confirmVerifyEmail";
      const response = await axios.post(url, { token: verifyToken, email });
      if (response.status === 200) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    }
    doCheck();
  }, [verifyToken]);

  const loginClicked = () => {
    if (email) navigate("/login?email=" + encodeURIComponent(email));
  };
  if (status === "success") {
    return (
      <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex items-center justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <img src={fundwriterLogo} alt="Logo" className="logo mb-4" />
            <div className="text-center text-xl font-bold text-gray-700 mb-2">
              Success! Email Confirmed.
            </div>
            {/* <div className="text-center mt-2 mb-4"></div> */}
            <button
              type="submit"
              onClick={loginClicked}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
              Login
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex items-center justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div style={{ marginLeft: "30px" }}>Error in Confirmation</div>
            <button
              type="submit"
              onClick={loginClicked}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
              Resend Confirmation Email
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default VerifyEmail;
