import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import ProjectReducer from "./reducers/projectReducer";
import authReducer from "./reducers/authReducer";
// import writingModelReducer from "./reducers/writingModelReducer";
import WritingSetReducer from "./reducers/writingSetReducer";
import DocumentAssetReducer from "./reducers/documentAssetReducer";
import DocumentTypeReducer from "./reducers/documentTypeReducer";
import OutputReducer from "./reducers/outputReducer";
import AdminWritingModelReducer from "./reducers/admin/adminWritingModelReducer";
import AdminOutputReducer from "./reducers/admin/adminOutputReducer";
import ClientReducer from "./reducers/clientReducer";
import AdminUserReducer from "./reducers/admin/adminUserReducer";

// const store = configureStore({ reducer: rootReducer, middleware: [thunkMiddleware] });
const store = configureStore({
  reducer: {
    // writingModel: writingModelReducer,
    projectsReducer: ProjectReducer,
    auth: authReducer,
    writingSets: WritingSetReducer,
    documentAssets: DocumentAssetReducer,
    documentTypes: DocumentTypeReducer,
    outputReducer: OutputReducer,
    adminWritingModelReducer: AdminWritingModelReducer,
    adminOutputReducer: AdminOutputReducer,
    clientReducer: ClientReducer,
    adminUserReducer: AdminUserReducer
  }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
