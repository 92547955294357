import { Node, mergeAttributes } from "@tiptap/core";
// import React from "react";
// import ReactDOM from "react-dom";
import { ReactNodeViewRenderer } from "@tiptap/react";
import "../pages/writingPage.scss";

const AnimatedLogoComponent = () => {
  return (
    <div className="flex items-center font-semibold" style={{ fontSize: "14px", color: "#4E4E4E" }}>
      <span className="editor-blinking-cursor"></span>
      <span>
        Fundwriter.ai<span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </span>
    </div>
  );
};

const AnimatedLogoNode = Node.create({
  name: "animatedLogo",

  group: "block",

  content: "inline*",

  parseHTML() {
    return [
      {
        tag: 'div[class="animated-logo"]'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes, { class: "animated-logo" }), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AnimatedLogoComponent);
  }
});

export { AnimatedLogoNode };
