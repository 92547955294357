import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import Select from "react-select";
import isEmail from "validator/lib/isEmail";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { GridOptions } from "ag-grid-community";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";

// import { IOutput } from "src/interfaces/IProject";
import axiosInstance from "src/lib/axiosConfig";
import { RootState, useAppDispatch } from "src/configureStore";
import {
  AdminFetchUsers,
  AdminDeleteUsers,
  AdminGetClients
} from "src/reducers/admin/adminUserReducer";
import { IClient, IUser } from "src/interfaces/IUser";
import { login } from "src/reducers/authReducer";
import { fetchProjects } from "src/reducers/projectReducer";
import { fetchUserRecents } from "src/reducers/clientReducer";
import { SearchIcon } from "src/lib/icons/index";

const columnDefs = [
  {
    field: "email",
    headerName: "Username",
    sortable: true,
    filter: true,
    resizable: true,
    checkboxSelection: true,
    width: 300
  },
  { field: "firstName", headerName: "First Name", sortable: true, filter: true, resizable: true },
  { field: "lastName", headerName: "Last Name", sortable: true, filter: true, resizable: true },
  {
    field: "spentCredits",
    headerName: "Spent Credits",
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    field: "lastActiveAt",
    headerName: "Last Active",
    sortable: true,
    filter: true,
    resizable: true,
    valueGetter: (params: any) => {
      // Create a date object with the UTC date
      const date = moment.utc(params.data.lastActiveAt);

      // Convert to Pacific Time (US & Canada)
      return date.tz("America/Los_Angeles").format();
    }
  },
  { field: "bubbleId", headerName: "Bubble ID", sortable: true, filter: true, resizable: true }
];
Modal.setAppElement("#root");
const UserManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useSelector((state: RootState) => state.adminUserReducer.users);
  const userStatus = useSelector((state: RootState) => state.adminUserReducer.userStatus);
  const clientStatus = useSelector((state: RootState) => state.adminUserReducer.clientStatus);
  const clients = useSelector((state: RootState) => state.adminUserReducer.clients);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>(users);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showFirstNameReqs, setShowFirstNameReqs] = useState(false);
  const [showLastNameReqs, setShowLastNameReqs] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showClientError, setShowClientError] = useState(false);
  const [bonusCredits, setBonusCredits] = useState("100000");
  const [selectedClient, setSelectedClient] = useState<any>({
    label: "Select Client...",
    value: "Select Client"
  });
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  useEffect(() => {
    console.log("useEffect ran", { userStatus, dispatch, isFetchingUser });

    if (userStatus === "idle" && !isFetchingUser) {
      const fetchUsers = async () => {
        await setIsFetchingUser(true);
        await dispatch(AdminFetchUsers());
        await setIsFetchingUser(false);
      };

      fetchUsers();
    }
  }, [userStatus, dispatch, isFetchingUser]);
  useEffect(() => {
    if (clientStatus === "idle") {
      dispatch(AdminGetClients());
    }
  }, [clientStatus, dispatch]);
  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);
  useEffect(() => {
    setFilteredUsers(
      users.filter(
        (user: any) => user.email && user.email.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  const nameRegex = /^[a-zA-Z0-9-' ]+$/;

  const isFirstNameValid = (name: string) => {
    if (nameRegex.test(name)) {
      setShowFirstNameReqs(false);
      return true;
    } else {
      setShowFirstNameReqs(true);
      return false;
    }
  };

  const isLastNameValid = (name: string) => {
    if (nameRegex.test(name)) {
      setShowLastNameReqs(false);
      return true;
    } else {
      setShowLastNameReqs(true);
      return false;
    }
  };
  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFirstNameValid(firstName) && isLastNameValid(lastName)) {
      if (isPasswordValid()) {
        setIsSubmitting(true); // Use a timer to prevent spamming of submits
        setTimeout(() => setIsSubmitting(false), 3000);
        // handle successful form submission
        const url = process.env["REACT_APP_API_SERVER"] + "/api/admin/createUser";

        const clientIds: string[] = selectedClients.map((client) => client._id);
        const findClientInSelected = selectedClients.find(
          (client) => client._id === selectedClient.value
        );
        if (findClientInSelected) {
          const data = {
            email,
            password,
            firstName,
            lastName,
            client: selectedClient.value,
            clientIds,
            bonusCredits
          };
          const response = await axiosInstance.post(url, data, {
            headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
          });
          if (response.status === 200) {
            dispatch(AdminFetchUsers());
            closeShowCreateModal();
          }
        } else {
          setShowClientError(true);
        }
      } else {
        // handle invalid form submission
        setShowPasswordReqs(true);
      }
    }
  };
  const closeShowCreateModal = () => {
    setShowCreateModal(false);
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setSelectedClient({ label: "Select Client...", value: "Select Client" });
    setSelectedClients([]);
    setBonusCredits("100000");
  };
  const isPasswordValid = () => {
    const letterRegex = /[a-zA-Z]/;
    const digitRegex = /\d/;
    return letterRegex.test(password) && digitRegex.test(password) && password.length >= 8;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isEmailValid = () => {
    return isEmail(email);
  };
  const closeModal = () => {
    setShowDeleteModal(false);
    setShowCreateModal(false);
  };

  // Add the onCellClicked event to the grid options
  const gridOptions: GridOptions = {
    columnDefs,
    rowData: filteredUsers,
    rowSelection: "multiple",
    onRowSelected: (e) => {
      onRowSelected(e);
    }
  };
  const clientGridOptions: GridOptions = {
    columnDefs: [
      {
        field: "name",
        headerName: "Client Name",
        sortable: true,
        filter: true,
        flex: 1,
        resizable: true,
        checkboxSelection: true
      }
    ],
    rowData: clients,
    rowSelection: "multiple",
    onRowSelected: (e) => {
      setSelectedClients(e.api.getSelectedRows());
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const onRowSelected = (event: any) => {
    let runPush = true;
    if (event.node.selected) {
      selectedUsers.forEach((user: IUser) => {
        if (user._id === event.data._id) {
          runPush = false;
        }
      });
      if (event.data._id && runPush)
        setSelectedUsers([
          ...selectedUsers,
          {
            _id: event.data._id,
            email: event.data.email
          }
        ]);
    } else {
      setSelectedUsers(selectedUsers.filter((user) => user.id !== event?.data._id));
      // selectedUsers = selectedUsers.filter(user => {
      //   return user.id !== event.data._id;
      // });
    }
    // setSelectedUsers(selectedUsers);
  };
  const clientOptions = clients.map((client: IClient) => ({
    value: client._id,
    label: client.name
  }));
  return (
    <div className="relative w-full h-full" style={{ margin: "30px", width: "97%" }}>
      <Modal
        isOpen={showCreateModal}
        onRequestClose={closeShowCreateModal}
        contentLabel="Create User"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "700px",
            height: "100%",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        {" "}
        <form className="bg-white mb-4" onSubmit={handleSignUp}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
              First Name
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="firstName"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                maxLength={150}
                autoComplete="given-name"
              />
            </div>
            {showFirstNameReqs ? (
              <p className="input-guidance" style={{ height: "12px" }}>
                Allowed characters: A-Z,0-9,&apos;,- , and spaces
              </p>
            ) : (
              <p style={{ height: "12px" }}></p>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">
              Last Name
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="lastName"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                maxLength={150}
                autoComplete="family-name"
              />
            </div>
            {showLastNameReqs ? (
              <p className="input-guidance" style={{ height: "12px" }}>
                Allowed characters: A-Z,0-9,&apos;,- , and spaces
              </p>
            ) : (
              <p style={{ height: "12px" }}></p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Email
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                maxLength={254}
                autoComplete="email"
              />
            </div>
          </div>
          <div className="mb-4 relative">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                maxLength={50}
                autoComplete="new-password"
              />
            </div>
            <button
              type="button"
              className="absolute right-0 top-0 text-blue-500 hover:text-blue-800"
              style={{ fontSize: "14px" }}
              onClick={togglePasswordVisibility}>
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          {showPasswordReqs && (
            <p className="input-guidance">
              Password must have at least one letter, one digit, and be at least 8 characters long.
            </p>
          )}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Bonus Credits
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="credits"
                type="credits"
                placeholder="100000"
                value={bonusCredits}
                onChange={(e) => setBonusCredits(e.target.value)}
                required
                maxLength={20}
              />
            </div>
          </div>
          <div className={!showClientError ? "mb-4" : ""}>
            <Select
              options={clientOptions}
              value={selectedClient}
              isSearchable={true}
              onChange={(selectedOption: any) => {
                const selected = clients.find((client) => client._id === selectedOption.value);
                if (selected) {
                  const updatedSelected = {
                    ...selected,
                    label: selected.name,
                    value: selected._id
                  };
                  setSelectedClient(updatedSelected ? updatedSelected : null);
                }
              }}
            />
          </div>
          {showClientError ? (
            <p className="input-guidance" style={{ height: "12px", marginBottom: "16px" }}>
              You need to select this Client in the Client List
            </p>
          ) : (
            <p style={{ height: "12px" }}></p>
          )}
          <div className="mb-4 ag-theme-alpine" style={{ height: "350px", width: "100%" }}>
            <AgGridReact {...clientGridOptions} />
          </div>
          <div className="flex items-center justify-between">
            <button
              className={`font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                isEmailValid() && !isSubmitting && firstName && lastName && password
                  ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                  : "text-white rounded bg-gray-400 cursor-not-allowed"
              }`}
              type="submit"
              disabled={!isEmailValid() || isSubmitting || !password || !firstName || !lastName}>
              {isEmailValid() && isSubmitting ? (
                <>
                  Creating<span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </>
              ) : (
                "Create"
              )}
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete User"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "500px",
            height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <div>Confirm Delete Users</div>
          <div>
            {selectedUsers.map((item) => {
              return <div key={item._id}>{item.email}</div>;
            })}
          </div>
          <div className="flex mt-1" style={{ justifyContent: "center" }}>
            <button
              // disabled={!validateEmail(userData.email)}
              onClick={() => {
                const deleteIds = selectedUsers.map((user) => user._id);
                dispatch(AdminDeleteUsers(deleteIds)).then(() => {
                  dispatch(AdminFetchUsers());
                  setSelectedUsers([]);
                  closeModal();
                });
              }}
              className="mt-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4">
              Delete
            </button>
            <button
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-4"
              onClick={() => {
                closeModal();
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row mb-2 ">
        <div className="flex justify-between items-center mb-2 mr-2" style={{ marginTop: "5px" }}>
          <span style={{ flex: "0 0 auto" }}>
            <SearchIcon
              className="mr-2"
              height={"15px"}
              width={"15px"}
              color={"rgba(88, 89, 91, 1)"}
            />
          </span>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              handleInputChange(e);
            }}
            className="w-full appearance-none block w-full placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Search..."
            style={{ borderBottom: "1px solid rgba(88, 89, 91, 1)" }}
          />
        </div>
        <div
          onClick={() => {
            setShowCreateModal(true);
          }}
          className="mt-2 mr-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none cursor-pointer">
          Create User
        </div>
        <div
          className="mt-2 mr-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none cursor-pointer"
          onClick={() => {
            console.log(selectedUsers);
            if (selectedUsers.length > 0) {
              setShowDeleteModal(true);
            }
          }}>
          Delete User
        </div>
        <div
          onClick={async () => {
            if (selectedUsers.length === 1) {
              console.log("DO STUFF");
              const url = process.env["REACT_APP_API_SERVER"] + "/api/admin/loginAs";
              const data = { email: selectedUsers[0].email };
              const response: any = await axiosInstance
                .post(url, data, {
                  headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
                })
                .then(async (result) => {
                  console.log(result.data);
                  await dispatch(login({ user: result.data.user, token: result.data.token }));
                  await dispatch(fetchProjects());
                  await dispatch(fetchUserRecents());
                  const url = new URL("/", window.location.origin);
                  window.open(url.href, "_blank");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            console.log();
          }}
          className="mt-2 mr-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none cursor-pointer">
          Login As User
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "90%", width: "100%" }}>
        <AgGridReact {...gridOptions} />
      </div>
    </div>
  );
};

export default UserManagement;
