import React from "react";
import { HtmlTooltip } from "src/components/tooltip";

interface CardProps {
  title: string;
  image: string;
  color: string;
  textColor: string;
  borderColor: string;
  project: string;
  onClick?: () => void;
}

export const DocumentCard: React.FC<CardProps> = ({
  title,
  image,
  color,
  textColor,
  borderColor,
  project,
  onClick
}) => {
  return (
    <HtmlTooltip title={project + " > " + title} placement="top" arrow>
      <div
        className={`text-black shadow-lg w-1/5 `}
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: borderColor,
          cursor: "pointer",
          width: "150px",
          height: "150px"
        }}
        onClick={onClick}>
        <div
          className="flex justify-center items-center text-center"
          style={{
            height: "20px",
            backgroundColor: borderColor,
            color: "white",
            fontSize: "12px",
            fontWeight: 500,
            paddingLeft: "4px",
            paddingRight: "4px"
          }}>
          <div className="oneline-text-ellipse">{project}</div>
        </div>
        <div className={`pr-2 pl-2 pt-1 pb-0 items-center justify-center text-center`}>
          <div
            className="text-sm font-semibold long-text-ellipse mb-2"
            style={{ color: "#707070", height: "2.35rem" }}>
            {title}
          </div>
          <div className="justify-center" style={{ display: "flex" }}>
            <img src={image} height={65} width={65} alt={title} />
          </div>
        </div>
      </div>
    </HtmlTooltip>
  );
};
