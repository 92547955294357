import { useEffect } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginPage from "../pages/loginPage";
import ResetPasswordPage from "../pages/resetPasswordPage";
import SignUpPage from "../pages/signupPage";
import HomePage from "../pages/home";
import AdminHome from "../pages/admin/adminHome";
import ModelEditorPage from "../pages/admin/editModels";
import ResetPasswordForm from "src/pages/resetPasswordForm";
import VerifyEmail from "src/pages/verifyEmail";
// import { NotFound } from "../pages/notFound";

import { RootState } from "src/configureStore";
import Project from "src/pages/project";

import { DocumentType } from "src/pages/documentType";
import { login, logout } from "src/reducers/authReducer";
import { IUser } from "src/interfaces/IUser";
import { WritingWizard } from "src/pages/writingWizard";
import { useAppDispatch } from "src/configureStore";
import { WritingPage } from "src/pages/writingPage";
import AuthSuccess from "src/pages/blackbaudAuthSuccess";
import InspectPromptPage from "src/pages/admin/inspectPrompt";
import UserManagement from "./admin/userManagement";
import ClientManagement from "./admin/clientManagement";

// import WritingPage from "src/pages/writingPage";

export const RootRoute = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />
  },
  { path: "/resetPasswordForm/:resetToken", element: <ResetPasswordForm /> },
  { path: "/verifyEmail/:verifyToken/:email", element: <VerifyEmail /> },
  {
    path: "/signup",
    element: <SignUpPage />
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />
  }
]);

export const RootRouteLoggedIn = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    children: [
      {
        path: "/project/:projectId",
        element: <Project />
      },

      // {
      //   path: "/project/:projectId/documentType/:documentId/documentAsset/:documentAssetId",
      //   element: <WritingPage />
      // },
      {
        path: "/documentType/:documentId",
        element: <DocumentType />
      },
      {
        path: "/project/:projectId/documentType/:documentId/documentAsset/:documentAssetId",

        element: <WritingPage />
      },
      {
        path: "/project/:projectId/writingWizard/:writingSetId",

        element: <WritingWizard />
      }
    ]
  },
  {
    path: "/auth-success",
    element: <AuthSuccess />
  },
  {
    path: "/admin",
    element: <AdminHome />,
    children: [
      {
        path: "modelEditor",
        element: <ModelEditorPage />
      },
      { path: "inspectPrompt", element: <InspectPromptPage /> },
      { path: "userManagement", element: <UserManagement /> },
      { path: "clientManagement", element: <ClientManagement /> },
      { path: "*", element: <ModelEditorPage /> }
    ]
  },

  {
    path: "*",
    element: <HomePage />
  }
]);

export function RootRouter() {
  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const authToken: string = localStorage.getItem("authToken") as string;

  let decodedToken;
  try {
    decodedToken = JSON.parse(atob(authToken.split(".")[1]));
  } catch (error) {
    decodedToken = null;
  }
  const currentTime = Date.now() / 1000;
  let expired = false;
  if (decodedToken === null) {
    expired = true;
  } else {
    expired = decodedToken < currentTime;
  }
  useEffect(() => {
    const userString = localStorage.getItem("user");
    const authToken: string = localStorage.getItem("authToken") as string;
    if (userString && authToken) {
      const user: IUser = JSON.parse(userString);
      dispatch(login({ user, token: authToken }));

      // dispatch({ type: "LOGIN_SUCCESS", payload: user });
    } else {
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isAuthenticated || !expired) {
    // console.log("RootRouteLoggedIn");
    return <RouterProvider router={RootRouteLoggedIn} />;
  } else {
    // console.log("RootRoute");
    return <RouterProvider router={RootRoute} />;
  }
}
