import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "src/configureStore";

import { IWritingModel, IModelInput, IWritingSet } from "src/interfaces/IWriting";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { setSelectedDocumentAsset } from "./documentAssetReducer";

/* -------------------- INITIAL STATE -------------------- */
interface WritingSetState {
  writingSets: IWritingSet[] | [];
  selectedWritingSet: IWritingSet | undefined;
  selectedWritingModel: IWritingModel | undefined;
  allWritingModels: IWritingModel[] | [];
  modelInputs: IModelInput[] | [] | undefined;
  isLoading: boolean;
  isLoaded: boolean;
  loadingModels: boolean;
  loadedModels: boolean;
  error: string | null;
}

const initialState: WritingSetState = {
  writingSets: [],
  modelInputs: [],
  allWritingModels: [],
  selectedWritingSet: undefined,
  selectedWritingModel: undefined,
  isLoading: false,
  isLoaded: false,
  loadingModels: false,
  loadedModels: false,
  error: null
};

/* -------------------- SLICE -------------------- */
const writingSetSlice = createSlice({
  name: "writingSet",
  initialState,
  reducers: {
    getWritingSetSuccess: (state, action: PayloadAction<IWritingSet[]>) => {
      state.writingSets = action.payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    getWritingSetRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getWritingSetFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    getWritingSetById: (state, action: PayloadAction<string>) => {
      if (action.payload === "blank") {
        const blankWritingSet = state.writingSets.find((set) => set.name === "Blank Document") || {
          name: "Blank Document",
          backgroundColor: "white",
          borderColor: "rgb(112, 112, 112)",
          createdAt: "2023-04-19T05:52:32.755Z",
          description: "Start with a blank document",
          image: "plus.png",
          order: 1,
          textColor: "black",
          updatedAt: "2023-04-19T05:52:32.755Z",
          writingModels: [],
          _id: "blank",
          category: "blank"
        };
        if (blankWritingSet) blankWritingSet.writingModels = state.allWritingModels;
        state.selectedWritingSet = blankWritingSet;
      } else {
        state.selectedWritingSet = state.writingSets.find((set) => set._id === action.payload);
      }
    },
    getWritingModelById: (state, action: PayloadAction<string>) => {
      state.selectedWritingModel = state.selectedWritingSet?.writingModels.find(
        (set) => set._id === action.payload
      );
    },
    getWritingModelInputs: (state, action: PayloadAction<string>) => {
      state.modelInputs = state.selectedWritingSet?.writingModels.find(
        (set) => set._id === action.payload
      )?.modelInputs;
    },
    setWritingModels: (state, action: PayloadAction<IWritingModel[]>) => {
      state.allWritingModels = action.payload;
      state.loadedModels = true;
      state.loadingModels = false;
    },

    getWritingModelsRequest: (state) => {
      state.loadingModels = true;
      state.error = null;
    }
  }
});

export const {
  getWritingSetSuccess,
  getWritingSetRequest,
  getWritingSetFailure,
  getWritingSetById,
  getWritingModelById,
  getWritingModelInputs,
  setWritingModels,
  getWritingModelsRequest
} = writingSetSlice.actions;

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const fetchWritingSets = () => async (dispatch: AppDispatch) => {
  const url = process.env["REACT_APP_API_SERVER"] + "/api/writing/writingSets";
  dispatch(getWritingSetRequest());
  try {
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });

    dispatch(getWritingSetSuccess(response.data));
  } catch (error: any) {
    dispatch(getWritingSetFailure(error.message));
  }
};
export const getAllWritingModels = (documentAssetId: string) => async (dispatch: AppDispatch) => {
  dispatch(getWritingModelsRequest());
  const url = process.env["REACT_APP_API_SERVER"] + "/api/writing/getWritingModels";
  try {
    const response = await axiosInstance.post(
      url,
      { documentAssetId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );

    dispatch(setWritingModels(response.data));
  } catch (error: any) {
    console.log("FAILED TO GET WRITING MODELS");
  }
};
export const createOrGetWritingTools =
  (projectId: string, writingToolId: string, documentAssetId: string, documentTypeId: string) =>
  async (dispatch: AppDispatch) => {
    {
      const url =
        process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/createDocumentWritingTool";
      try {
        const response = await axiosInstance.post(
          url,
          { projectId, writingToolId, documentAssetId, documentTypeId },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
          }
        );
        console.log(response.data);

        await dispatch(setSelectedDocumentAsset({ wModel: response.data, type: "writingTools" }));

        return response.data;
      } catch (error: any) {
        console.log("FAILED TO GET WRITING MODELS");
        return null;
      }
    }
  };

export default writingSetSlice.reducer;
