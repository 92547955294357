import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAllWritingModels,
  createOrGetWritingTools,
  setWritingModels
} from "src/reducers/writingSetReducer";
import { useAppDispatch, RootState } from "src/configureStore";
import { SearchIcon, ArrowRightIcon } from "src/lib/icons/index";
import _ from "lodash";

interface WTProps {
  setShowWritingTools: (status: boolean) => void;
}
const WritingTools: React.FC<WTProps> = ({ setShowWritingTools }) => {
  const dispatch = useAppDispatch();
  const { projectId, documentId, documentAssetId } = useParams();
  const { allWritingModels, loadedModels, loadingModels } = useSelector(
    (state: RootState) => state.writingSets
  );
  const [search, setSearch] = useState("");
  const [filteredWritingModels, setFilteredWritingModels] = useState<any[]>([]);

  useEffect(() => {
    if (documentAssetId) {
      dispatch(getAllWritingModels(documentAssetId));
    } else {
      dispatch(getAllWritingModels(""));
    }
  }, [documentAssetId]);
  useEffect(() => {
    const filteredWritingModels = allWritingModels.filter((model: any) => {
      const { modelName, description, category } = model;

      const modelNameMatch =
        typeof modelName === "string"
          ? modelName.toLowerCase().includes(search.toLowerCase())
          : false;
      const descriptionMatch =
        typeof description === "string"
          ? description.toLowerCase().includes(search.toLowerCase())
          : false;
      const categoryMatch =
        typeof category === "string"
          ? category.toLowerCase().includes(search.toLowerCase())
          : false;

      return modelNameMatch || descriptionMatch || categoryMatch;
    });

    filteredWritingModels.forEach((model: any) => {
      console.log("ACTIVE MODELS");
      if (model.active) console.log(model);
    });

    setFilteredWritingModels(filteredWritingModels);
  }, [allWritingModels, search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  if (!loadedModels && loadingModels) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid #d3d3d3" }}
        className="pl-4 pr-4">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            style={{ display: "flex", flexDirection: "row", fontSize: "0.9rem" }}
            className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-1 px-2 rounded mb-4"
            onClick={() => {
              setShowWritingTools(false);
            }}>
            <div>Back</div>
            <div style={{ alignSelf: "center", marginLeft: "6px" }}>
              <ArrowRightIcon height={"14px"} color={"white"}></ArrowRightIcon>
            </div>
          </button>
        </div>
        <h1 className="text-lg font-semibold mb-2" style={{ color: "#7D7D7D" }}>
          Writing Models
        </h1>
        <div className="flex flex-row pt-3 pb-3" style={{ alignItems: "center" }}>
          <SearchIcon
            className="mr-2"
            height={"15px"}
            width={"15px"}
            color={"rgba(88, 89, 91, 1)"}
          />
          <input
            type="text"
            className="search-input focus:outline-none w-full"
            value={search}
            onChange={handleSearchChange}
            style={{ background: "none", borderBottom: "1px #707070 solid" }}
            placeholder="Search writing models"
          />
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflowY: "auto" }}
        className="pl-4 pt-2 pr-4">
        <ul className="">
          {filteredWritingModels
            .sort((a, b) => {
              const aIsActive = a.active;
              const bIsActive = b.active;

              // Sort active models to the top
              if (aIsActive && !bIsActive) {
                return -1;
              }
              if (!aIsActive && bIsActive) {
                return 1;
              }
              const categoryOrder = [
                "Cat - Grants/Proposals",
                "Cat - Appeals",
                "Cat - Thank You",
                "Cat - Personal Emails",
                "Cat - Blackbaud Demo"
              ];

              const aCategoryIndex = categoryOrder.indexOf(a.category);
              const bCategoryIndex = categoryOrder.indexOf(b.category);

              if (aCategoryIndex !== -1 || bCategoryIndex !== -1) {
                if (aCategoryIndex === -1) {
                  return 1;
                }
                if (bCategoryIndex === -1) {
                  return -1;
                }
                if (aCategoryIndex !== bCategoryIndex) {
                  return aCategoryIndex - bCategoryIndex;
                }
                return a.modelName.localeCompare(b.modelName);
              }

              const categoryComparison = a.category.localeCompare(b.category);
              if (categoryComparison !== 0) {
                return categoryComparison;
              }

              return a.modelName.localeCompare(b.modelName);
            })
            .map((asset: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 && !asset.active && filteredWritingModels[index - 1].active && (
                    <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
                  )}
                  <li
                    key={index}
                    onClick={async () => {
                      if (projectId !== undefined && documentAssetId !== undefined && documentId) {
                        const writingTool = await dispatch(
                          createOrGetWritingTools(projectId, asset._id, documentAssetId, documentId)
                        );
                        const nAllWritingModels = _.cloneDeep(allWritingModels).map((model) => {
                          if (writingTool.name === model.modelName) {
                            model.active = true;
                          }
                          return model;
                        });
                        await dispatch(setWritingModels(nAllWritingModels));
                        setShowWritingTools(false);
                      }
                    }}
                    className="p-4 cursor-pointer mb-2 rounded-md drop-shadow-md text-sm font-semibold"
                    style={{
                      backgroundColor: asset.backgroundColor,
                      borderColor: asset.borderColor,
                      borderWidth: "1px",
                      borderStyle: "solid"
                    }}>
                    <div>{asset.modelName}</div>
                    <div className="text-xs font-normal mt-1" style={{ fontStyle: "italic" }}>
                      {/* {asset.pillSubtext} */}
                      {asset.description}
                    </div>
                    {asset.category && typeof asset.category === "string" ? (
                      <div className="text-xs mt-1">
                        <span style={{ fontWeight: 100 }}>Category: </span>
                        <span style={{ fontWeight: 500 }}>
                          {asset.category.replace("Cat - ", "")}
                        </span>
                      </div>
                    ) : null}
                  </li>
                </React.Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default WritingTools;
