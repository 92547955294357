import { Node, mergeAttributes } from "@tiptap/core";
import Image, { ImageOptions } from "@tiptap/extension-image";

declare module "@tiptap/extension-image" {
  interface ImageOptions {
    resizable?: boolean;
  }
}
export default Image.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      resizable: true,
      HTMLAttributes: {}
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: "auto",
        parseHTML: (element) => element.getAttribute("width") || "auto",
        renderHTML: (attributes) => {
          if (!attributes["width"]) {
            return {};
          }
          return {
            width: attributes["width"]
          };
        }
      },
      height: {
        default: "auto",
        parseHTML: (element) => element.getAttribute("height") || "auto",
        renderHTML: (attributes) => {
          if (!attributes["height"]) {
            return {};
          }
          return {
            height: attributes["height"]
          };
        }
      }
    };
  },

  addNodeView() {
    return ({ node, getPos }) => {
      const dom = document.createElement("div");
      const contentDOM = document.createElement("img");

      contentDOM.src = node.attrs["src"];
      contentDOM.style.width = node.attrs["width"];
      contentDOM.style.height = node.attrs["height"];

      dom.appendChild(contentDOM);

      if (this.options.resizable && !dom.querySelector(".resize-handle")) {
        const handle = document.createElement("div");
        handle.className = "resize-handle";
        dom.appendChild(handle);

        handle.addEventListener("mousedown", (event) => {
          event.preventDefault();
          console.log("Resize started");

          const startX = event.pageX;
          const startY = event.pageY;
          const startWidth = parseInt(window.getComputedStyle(contentDOM).width, 10);
          const startHeight = parseInt(window.getComputedStyle(contentDOM).height, 10);

          const onMouseMove = (event: any) => {
            const newWidth = startWidth + (event.pageX - startX);
            const newHeight = startHeight + (event.pageY - startY);

            contentDOM.style.width = `${newWidth}px`;
            contentDOM.style.height = `${newHeight}px`;
            console.log(`Resizing to: ${newWidth}px x ${newHeight}px`);
          };

          const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            console.log("Resize ended");

            const transaction = this.editor.state.tr.setNodeMarkup(
              typeof getPos === "function" ? getPos() ?? 0 : 0,
              undefined,
              {
                ...node.attrs,
                width: contentDOM.style.width,
                height: contentDOM.style.height
              }
            );
            this.editor.view.dispatch(transaction);
          };

          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("mouseup", onMouseUp);
        });
      }

      return {
        dom,
        contentDOM,
        update: (node) => {
          contentDOM.src = node.attrs["src"];
          contentDOM.style.width = node.attrs["width"];
          contentDOM.style.height = node.attrs["height"];
          return true;
        }
      };
    };
  }
});
