import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
// import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateReferenceFileType, updateReferenceFileTypes } from "../reducers/documentTypeReducer";

import { IWritingModel } from "../interfaces/IWriting";
import {
  fetchWritingSets,
  getAllWritingModels,
  getWritingSetById
} from "../reducers/writingSetReducer";
import { createDocumentType } from "../reducers/documentTypeReducer";
import { getAssetsForProject, fetchProjects } from "../reducers/projectReducer";
import { RootState } from "../configureStore";
import { useAppDispatch } from "src/configureStore";
import { setSelectedProjectById } from "src/reducers/projectReducer";
import { setPollingStatus } from "src/reducers/documentAssetReducer";
import { Tabs, Tab } from "@material-ui/core";

import {
  setSelectedDocumentTypeById,
  getDocumentTypesSuccess
} from "src/reducers/documentTypeReducer";
import UrlComponent from "src/components/urlComponent";
import FileUploadComponent from "src/components/fileUploadComponent";
import ReferenceTextComponent from "src/components/referenceTextComponent";
import "./writingWizard.scss";

import { WritingModelSelector } from "src/components/writingModelSelector";
import { fetchCampaignUsage } from "src/reducers/clientReducer";

export const WritingWizard = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [proposalName, setProposalName] = useState("");
  const [constituentId, setConstituentId] = useState("");

  const [notification, setNotification] = useState("");
  const [constituentNotification, setConstituentNotification] = useState("");
  const navigate = useNavigate();

  const { writingSetId, projectId } = useParams();
  const dispatch = useAppDispatch();

  const { selectedProject, projects } = useSelector((state: RootState) => state.projectsReducer);
  const { selectedWritingSet, isLoaded } = useSelector((state: RootState) => state.writingSets);
  const { fileAssets, urlAssets, refTextAssets } = useSelector(
    (state: RootState) => state.projectsReducer
  );

  // const { allWritingModels, loadedModels } = useSelector((state: RootState) => state.writingSets);
  // const [selectedModels, setSelectedModels] = useState<IWritingModel[]>(
  //   selectedWritingSet?.writingModels ?? []
  // );
  const [selectedModels, setSelectedModels] = useState<IWritingModel[]>([]);
  const [modelInputValues, setModelInputValues] = useState<{ [key: string]: string }>({});
  const [projectFiles, setProjectFiles] = useState<any[]>([]);
  const [projectURLs, setProjectURLs] = useState<any[]>([]);
  const [projectRefText, setProjectRefText] = useState<any[]>([]);

  const [isWizardFinishing, setIsWizardFinishing] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  // const [search, setSearch] = useState("");
  const modelRefs = useRef<Record<string, React.RefObject<HTMLLIElement>>>({});
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentTab(newValue);
  };
  const { loadedModels } = useSelector((state: RootState) => state.writingSets);
  modelRefs.current = {};
  const inputRef = useRef<HTMLInputElement>(null);

  // const updateReferenceTypes = async (projectFiles: string[]) => {
  //   await dispatch(updateReferenceFileTypes(projectFiles));
  // };
  const updateReferenceType = async (id: string, referenceType: string) => {
    await dispatch(updateReferenceFileType(id, referenceType));
  };
  const [noGrantGuidelinesSelected, setNoGrantGuidelinesSelected] = useState(false);
  const [noOrganizationInfoSelected, setNoOrganizationInfoSelected] = useState(false);
  // const [campaignUsage, setCampaignUsage] = useState<number | null>(null); // Campaign usage state
  // const [maxCampaignUsage, setMaxCampaignUsage] = useState<number | null>(null); // Max usage state
  // useEffect(() => {
  //   if (selectedWritingSet) {
  //     setSelectedModels(selectedWritingSet.writingModels);
  //   }
  // }, [selectedWritingSet]);
  // useEffect(() => {
  //   if (selectedWritingSet?.writingModels) {
  //     const sortedModels = [...selectedWritingSet.writingModels].sort((a, b) => a.order - b.order);
  //     const firstModel = sortedModels[0];
  //     setSelectedModels([firstModel]);
  //   }
  // }, [selectedWritingSet, loadedModels, allWritingModels, writingSetId]);

  useEffect(() => {
    if (selectedWritingSet?.name === "One-Click Campaign") {
      let defaultModel: IWritingModel | null = null;

      const pipelineModels = selectedWritingSet.pipelineModels;
      if (!defaultModel && pipelineModels && pipelineModels.length > 0) {
        defaultModel = pipelineModels[0] as IWritingModel; // Cast to IWritingModel
      }
      // Set the default model
      if (defaultModel) {
        setSelectedModels([defaultModel]);
      }
    }
  }, [selectedWritingSet, writingSetId, setSelectedModels]);

  // useEffect(() => {
  //   const fetchUsage = async () => {
  //     const usageData = await dispatch(fetchCampaignUsage());
  //     if (usageData) {
  //       setCampaignUsage(usageData.campaignUsage);
  //       setMaxCampaignUsage(usageData.maxCampaignUsage);
  //     }
  //   };

  //   fetchUsage();
  // }, [dispatch]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (fileAssets) {
      setProjectFiles(fileAssets.map((file) => ({ ...file, selected: false })));
    }
    if (urlAssets) {
      setProjectURLs(urlAssets.map((url) => ({ ...url, selected: false })));
    }
    if (refTextAssets) {
      setProjectRefText(refTextAssets.map((refText) => ({ ...refText, selected: false })));
    }
  }, [fileAssets, urlAssets, refTextAssets]);
  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchWritingSets());
      dispatch(fetchCampaignUsage());

      if (projectId !== undefined) {
        dispatch(getAssetsForProject(projectId));
      }
    }
    if (!loadedModels) {
      dispatch(getAllWritingModels(""));
    }
    if (writingSetId) {
      dispatch(getWritingSetById(writingSetId));
    }
  }, [dispatch, isLoaded, writingSetId, projectId, loadedModels]);

  useEffect(() => {
    if (projects.length === 0) {
      dispatch(fetchProjects());
    }
    if (projectId !== undefined) {
      if (selectedProject === undefined && projects.length > 0 && projectId !== undefined) {
        dispatch(setSelectedProjectById(projectId));
      }
      dispatch(getAssetsForProject(projectId));
    }
  }, [dispatch, projectId, projects, selectedProject]);

  useEffect(() => {
    let checkLOU: number | undefined;

    if (activeStep === 0 && selectedWritingSet?.name === "Grants & Proposals") {
      checkLOU = window.setInterval(() => {
        if (window.LOU && window.LOU.startTour) {
          window.LOU.startTour("622464961077");
          if (checkLOU !== undefined) {
            clearInterval(checkLOU);
          }
        }
      }, 100); // checks every 100ms
    } else if (
      activeStep === 1 &&
      selectedModels[0]?.modelName === "Guideline-Driven Proposal Writer"
    ) {
      checkLOU = window.setInterval(() => {
        if (window.LOU && window.LOU.startTour) {
          window.LOU.startTour("132630976982");
          if (checkLOU !== undefined) {
            clearInterval(checkLOU);
          }
        }
      }, 100); // checks every 100ms
    }

    // Cleanup function to clear interval on component unmount or on effect re-run
    return () => {
      if (checkLOU !== undefined) {
        clearInterval(checkLOU);
      }
    };
  }, [activeStep, selectedWritingSet, selectedModels]);

  useEffect(() => {
    let showNoGuidelines = true;
    [...projectFiles, ...projectURLs, ...projectRefText].forEach((item) => {
      if (item.referenceType === "grantGuidelines" && item.selected === true) {
        showNoGuidelines = false;
      }
    });
    setNoGrantGuidelinesSelected(showNoGuidelines);
  }, [projectFiles, projectURLs, projectRefText]);

  useEffect(() => {
    let showNoOrganizationInfo = true;
    [...projectFiles, ...projectURLs, ...projectRefText].forEach((item) => {
      if (item.referenceType === "organizationInfo" && item.selected === true) {
        showNoOrganizationInfo = false;
      }
    });
    setNoOrganizationInfoSelected(showNoOrganizationInfo);
  }, [projectFiles, projectURLs, projectRefText]);
  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearch(e.target.value);
  // };

  // const filteredWritingModels = allWritingModels.filter((model: any) => {

  // const filteredWritingModels = selectedWritingSet?.writingModels.filter((model: any) => {
  //   const { modelName, description, category } = model;
  //   if (!modelRefs.current[model._id]) {
  //     // Add a new ref for this model
  //     modelRefs.current[model._id] = React.createRef<HTMLLIElement>();
  //   }
  //   const modelNameMatch =
  //     typeof modelName === "string"
  //       ? modelName.toLowerCase().includes(search.toLowerCase())
  //       : false;
  //   const descriptionMatch =
  //     typeof description === "string"
  //       ? description.toLowerCase().includes(search.toLowerCase())
  //       : false;
  //   const categoryMatch =
  //     typeof category === "string" ? category.toLowerCase().includes(search.toLowerCase()) : false;

  //   return modelNameMatch || descriptionMatch || categoryMatch;
  // });

  // filteredWritingModels.forEach((model) => {
  //   if (!modelRefs.current[model._id]) {
  //     // Add a new ref for this model
  //     modelRefs.current[model._id] = createRef();
  //   }
  // });

  // const handleSelectedModelClick = () => {
  //   if (selectedModels[0] && modelRefs.current[selectedModels[0]._id]) {
  //     // Scroll to the selected model
  //     modelRefs.current[selectedModels[0]._id].current?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'nearest',
  //     });
  //   }
  // };

  const handleNext = async () => {
    // Validation for the first step
    if (
      activeStep === 0 &&
      (proposalName.length === 0 ||
        selectedModels.length === 0 ||
        (constituentId.length === 0 && selectedWritingSet?.name === "Blackbaud Demo"))
    ) {
      if (constituentId.length === 0 && selectedWritingSet?.name === "Blackbaud Demo") {
        setConstituentNotification("Constituent ID is required.");
      } else {
        setConstituentNotification("");
      }

      if (proposalName.length === 0) {
        setNotification("Document name is required.");
      } else {
        setNotification("");
      }
      // Do not proceed to the next step
      return;
    }
    console.log("selectedModels", selectedModels[0]);
    // Validation for the "Additional Inputs" step, if present
    if (
      activeStep === steps.length - 1 && // Last step
      selectedModels[0]?.modelInputs &&
      selectedModels[0].modelInputs.length > 0 &&
      selectedModels[0].category === "Cat - Campaign"
    ) {
      // Check if all model inputs are filled
      const allInputsFilled = selectedModels[0].modelInputs.every(
        (input) => modelInputValues[toCamelCase(input.inputName)]?.trim().length > 0
      );
      if (!allInputsFilled) {
        // Notify user to fill all inputs
        toast.error("Please fill in all the additional inputs.");
        return;
      }
    }

    // If not on the last step, move to the next step
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Final submission logic
      const models = selectedModels.map((model) => {
        return {
          modelId: model._id,
          name: model.modelName,
          order: model.order,
          modelInputs: modelInputValues // Include model input values here
        };
      });

      const iFiles = _.cloneDeep(fileAssets);
      const iURLs = _.cloneDeep(urlAssets);
      const iRefText = _.cloneDeep(refTextAssets);

      // Prepare fileIds, urlIds, refTextIds
      const fileIds: string[] = [];
      const urlIds: string[] = [];
      const refTextIds: string[] = [];

      projectFiles.forEach((file: any) => {
        const fProject: any = iFiles.find((item: any) => {
          return item._id === file._id;
        });

        if (fProject && fProject?.referenceType !== file.referenceType) {
          updateReferenceType(file._id, file.referenceType);
        }
        if (file.selected === true) {
          fileIds.push(file._id);
        }
      });

      projectURLs.forEach((url: any) => {
        const URLProject: any = iURLs.find((item: any) => {
          return item._id === url._id;
        });

        if (URLProject && URLProject?.referenceType !== url.referenceType) {
          updateReferenceType(url._id, url.referenceType);
        }
        if (url.selected === true) {
          urlIds.push(url._id);
        }
      });

      projectRefText.forEach((refText: any) => {
        const refProject: any = iRefText.find((item: any) => {
          return item._id === refText._id;
        });
        if (refProject && refProject?.referenceType !== refText.referenceType) {
          updateReferenceType(refText._id, refText.referenceType);
        }
        if (refText.selected === true) {
          refTextIds.push(refText._id);
        }
      });

      // Check for grant guidelines and funder info constraints
      const filesGuidelines = projectFiles.filter(
        (file: any) => file.referenceType === "grantGuidelines" && file.selected === true
      );
      const urlGuidelines = projectURLs.filter(
        (file: any) => file.referenceType === "grantGuidelines" && file.selected === true
      );
      const textGuidelines = projectRefText.filter(
        (file: any) => file.referenceType === "grantGuidelines" && file.selected === true
      );

      const filesFunderInfo = projectFiles.filter(
        (file: any) => file.referenceType === "funderInfo" && file.selected === true
      );
      const urlFunderInfo = projectURLs.filter(
        (file: any) => file.referenceType === "funderInfo" && file.selected === true
      );
      const textFunderInfo = projectRefText.filter(
        (file: any) => file.referenceType === "funderInfo" && file.selected === true
      );

      if (filesGuidelines.length + urlGuidelines.length + textGuidelines.length > 1) {
        toast.error("You can only have one grant guidelines reference");
        return;
      }
      if (filesFunderInfo.length + urlFunderInfo.length + textFunderInfo.length > 1) {
        toast.error("You can only have one Funder info file at a time");
        return;
      }

      setIsWizardFinishing(true);

      if (projectId !== undefined && writingSetId !== undefined) {
        const { documentType } = await dispatch(
          createDocumentType(
            projectId,
            writingSetId,
            { name: proposalName, children: models },
            fileIds,
            urlIds,
            refTextIds,
            selectedModels[0]._id
          )
        );

        if (documentType !== undefined) {
          await dispatch(getDocumentTypesSuccess(selectedProject.children));
          await dispatch(setSelectedDocumentTypeById(documentType._id));

          navigate(
            `/project/${documentType.project}/documentType/${documentType._id}/documentAsset/${documentType.children[0]}`
          );
        } else {
          // Handle error in document creation
          setIsWizardFinishing(false);
        }
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/project/${projectId}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  // const handleModelToggle = (model: any) => {
  //   if (selectedModels.includes(model)) {
  //     setSelectedModels((prevSelectedModels) => prevSelectedModels.filter((m) => m !== model));
  //   } else {
  //     setSelectedModels((prevSelectedModels) => [...prevSelectedModels, model]);
  //   }
  // };
  // const handleModelToggle = (model: IWritingModel) => {
  //   setSelectedModels([model]);
  // };
  const handleSetSelectedModels = React.useCallback(
    (models: any) => {
      setSelectedModels(models);
    },
    [setSelectedModels]
  );
  // Function to convert a string to camelCase
  function toCamelCase(str: any) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match: any, index: number) {
      if (+match === 0) return ""; // Remove spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }
  const handleModelInputChange = (input: any, value: string) => {
    setModelInputValues((prevValues) => ({
      ...prevValues,
      [toCamelCase(input.inputName)]: value
    }));
  };
  const stepsDefault = [
    {
      title: selectedWritingSet?.name,
      subTitle: selectedWritingSet?.wizardStepText,
      content: (
        <>
          <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
            <label className="text-sm" style={{ color: "rgb(78, 78, 78)" }}>
              Enter a name for this project
            </label>
            <input
              ref={inputRef}
              id="proposal-name"
              value={proposalName}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setNotification("");
                } else {
                  setNotification("A project name is required");
                }
                setProposalName(e.target.value);
              }}
              className="text-sm w-full border border-gray-300 p-1 my-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />

            {notification && <div className="text-red-600">{notification}</div>}
            <WritingModelSelector
              selectedModels={selectedModels}
              setSelectedModels={handleSetSelectedModels}
              writingSetId={writingSetId}
              projectId={projectId}
            />
          </div>

          {selectedWritingSet?.name === "Blackbaud Demo" && (
            <>
              <label
                htmlFor="constituent-name"
                className="text-sm font-semibold mb-2 mt-1"
                style={{ color: "rgb(78, 78, 78)" }}>
                Constituent ID
              </label>
              <input
                id="constituent-id"
                value={constituentId}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setNotification("");
                  } else {
                    setConstituentNotification("Constituent ID is required");
                  }
                  setConstituentId(e.target.value);
                }}
                className="w-full border border-gray-300 p-2 my-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {constituentNotification && (
                <div className="text-red-600">{constituentNotification}</div>
              )}
            </>
          )}
          {/* </div> */}
        </>
      )
    },
    {
      title: selectedWritingSet?.name,
      subTitle: "Add Your Reference Materials",
      instructions:
        "Create tailored content by providing the context to help AI understand your project. You can do this by uploading relevant documents or adding website links.",
      content: (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            overflow: "hidden"
          }}>
          <div
            style={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              flex: "0 0 auto"
            }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Reference Document Tabs"
              TabIndicatorProps={{ style: { backgroundColor: "rgb(63, 161, 215)" } }}>
              <Tab label="Reference Files" style={{ textTransform: "none" }} />
              <Tab label="Reference Text" style={{ textTransform: "none" }} />
              <Tab label="URL Reference" style={{ textTransform: "none" }} />
            </Tabs>
          </div>
          {currentTab === 0 && (
            <FileUploadComponent
              projectId={projectId}
              documentTypeId={undefined}
              projectFiles={projectFiles}
              setProjectFiles={setProjectFiles}
            />
          )}
          {currentTab === 1 && (
            <ReferenceTextComponent
              projectId={projectId}
              documentTypeId={undefined}
              projectRefText={projectRefText}
              setProjectRefText={setProjectRefText}
              // projectReferenceText={""}
            />
          )}
          {currentTab === 2 && (
            <UrlComponent
              projectId={projectId}
              projectURLs={projectURLs}
              setProjectURLs={setProjectURLs}
              documentTypeId={undefined}
            />
          )}
        </div>
      )
    }
  ];
  const stepsAppealCampaign = [
    {
      title:
        selectedWritingSet?.name === "One-Click Campaign"
          ? "Welcome to Your Campaign Creator"
          : selectedWritingSet?.name,
      subTitle: selectedWritingSet?.wizardStepText,
      content: (
        <>
          <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
            {selectedWritingSet?.name === "One-Click Campaign" ? (
              <div className="text-sm mt-4" style={{ color: "rgb(78,78,78" }}>
                <div className="mb-2">
                  Effortlessly create impactful campaigns with our Campaign Creator. This tool
                  generates draft plans, schedules, and tailored content based on your
                  organization’s info, saving you weeks of planning. You can add the final touches
                  to each piece, from simple outreach to complex multi-channel initiatives.
                </div>
                <div className="bulleted-list mb-2">
                  <ul>
                    <li>
                      Save Time: Get campaign drafts in minutes, ready for your finishing touches.
                    </li>
                    <li>
                      Personalized Content: Upload your info and stories to create messages that
                      resonate.
                    </li>
                    <li>Flexible Options: Designed for both simple and multi-channel campaigns.</li>
                  </ul>
                </div>
                <div className="mb-2" style={{ fontStyle: "italic" }}>
                  Let’s build a campaign that makes an impact!
                </div>
              </div>
            ) : null}
            <label className="text-sm mt-2" style={{ color: "rgb(78, 78, 78)" }}>
              <span style={{ fontWeight: "600" }}>Enter a name for this project</span>
              <span>
                <span style={{ marginLeft: "60px", fontWeight: "600" }}>Examples:</span>
                &nbsp;&quot;Fall Fundraising Drive 2023,&quot; &quot;Community Outreach Plan&quot;
              </span>
            </label>
            <input
              ref={inputRef}
              id="proposal-name"
              value={proposalName}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setNotification("");
                } else {
                  setNotification("A project name is required");
                }
                setProposalName(e.target.value);
              }}
              className="text-sm w-full border border-gray-300 p-1 my-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />

            {notification && <div className="text-red-600">{notification}</div>}

            {/* <WritingModelSelector
              selectedModels={selectedModels}
              setSelectedModels={handleSetSelectedModels}
              writingSetId={writingSetId}
              projectId={projectId}
            />
            <div className="mt-2 text-sm" style={{ color: "rgb(78,78,78" }}>
              <div style={{ fontWeight: "600" }}>Note for Trial Users</div>
              <div>
                Trial users can create a Campaign Plan only. To generate the full campaign with
                content, consider upgrading your plan.
              </div>
              <div className="mt-2" style={{ fontStyle: "italic" }}>
                Not sure which to choose?{" "}
                <div>
                  For drafted content, select{" "}
                  <span style={{ fontWeight: "600" }}>Outreach Campaign Plan + Content</span>. For a
                  strategic roadmap and calendar, choose{" "}
                  <span style={{ fontWeight: "600" }}>Outreach Campaign Plan</span>.
                </div>
              </div>
            </div> */}
          </div>

          {selectedWritingSet?.name === "Blackbaud Demo" && (
            <>
              <label
                htmlFor="constituent-name"
                className="text-sm font-semibold mb-2 mt-1"
                style={{ color: "rgb(78, 78, 78)" }}>
                Constituent ID
              </label>
              <input
                id="constituent-id"
                value={constituentId}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setNotification("");
                  } else {
                    setConstituentNotification("Constituent ID is required");
                  }
                  setConstituentId(e.target.value);
                }}
                className="w-full border border-gray-300 p-2 my-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {constituentNotification && (
                <div className="text-red-600">{constituentNotification}</div>
              )}
            </>
          )}
          {/* </div> */}
        </>
      )
    },
    // {
    //   title: selectedWritingSet?.name,
    //   subTitle: selectedWritingSet?.wizardStepText,
    //   instructions: "",
    //   content: (
    //     <>
    //       <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
    //         <label className="text-sm" style={{ color: "rgb(78, 78, 78)" }}>
    //           Enter a name for this project
    //         </label>
    //         <input
    //           ref={inputRef}
    //           id="proposal-name"
    //           value={proposalName}
    //           onChange={(e) => {
    //             if (e.target.value.length > 0) {
    //               setNotification("");
    //             } else {
    //               setNotification("A project name is required");
    //             }
    //             setProposalName(e.target.value);
    //           }}
    //           className="text-sm w-full border border-gray-300 p-1 my-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
    //         />

    //         {notification && <div className="text-red-600">{notification}</div>}

    //         <div
    //           style={{
    //             width: "100%",
    //             display: "flex",
    //             flex: "1 1 auto",
    //             flexDirection: "column",
    //             overflow: "hidden"
    //           }}>
    //           <div
    //             style={{
    //               borderBottom: 1,
    //               borderColor: "divider",
    //               display: "flex",
    //               flexDirection: "column",
    //               overflow: "hidden",
    //               flex: "0 0 auto"
    //             }}>
    //             <Tabs
    //               value={currentTab}
    //               onChange={handleChange}
    //               aria-label="Reference Document Tabs"
    //               TabIndicatorProps={{ style: { backgroundColor: "rgb(63, 161, 215)" } }}>
    //               <Tab label="Reference Files" style={{ textTransform: "none" }} />
    //               <Tab label="Reference Text" style={{ textTransform: "none" }} />
    //               <Tab label="URL Reference" style={{ textTransform: "none" }} />
    //             </Tabs>
    //           </div>
    //           {currentTab === 0 && (
    //             <FileUploadComponent
    //               projectId={projectId}
    //               documentTypeId={undefined}
    //               projectFiles={projectFiles}
    //               setProjectFiles={setProjectFiles}
    //             />
    //           )}
    //           {currentTab === 1 && (
    //             <ReferenceTextComponent
    //               projectId={projectId}
    //               documentTypeId={undefined}
    //               projectRefText={projectRefText}
    //               setProjectRefText={setProjectRefText}
    //               // projectReferenceText={""}
    //             />
    //           )}
    //           {currentTab === 2 && (
    //             <UrlComponent
    //               projectId={projectId}
    //               projectURLs={projectURLs}
    //               setProjectURLs={setProjectURLs}
    //               documentTypeId={undefined}
    //             />
    //           )}
    //         </div>
    //       </div>
    //     </>
    //   )
    // }
    {
      title: selectedWritingSet?.name,
      subTitle: "Add Your Reference Materials",
      instructions:
        "Create tailored content by providing the context to help AI understand your project. You can do this by uploading relevant documents or adding website links.",
      content: (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            overflow: "hidden"
          }}>
          <div
            style={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              flex: "0 0 auto"
            }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Reference Document Tabs"
              TabIndicatorProps={{ style: { backgroundColor: "rgb(63, 161, 215)" } }}>
              <Tab label="Reference Files" style={{ textTransform: "none" }} />
              <Tab label="Reference Text" style={{ textTransform: "none" }} />
              <Tab label="URL Reference" style={{ textTransform: "none" }} />
            </Tabs>
          </div>
          {currentTab === 0 && (
            <FileUploadComponent
              projectId={projectId}
              documentTypeId={undefined}
              projectFiles={projectFiles}
              setProjectFiles={setProjectFiles}
            />
          )}
          {currentTab === 1 && (
            <ReferenceTextComponent
              projectId={projectId}
              documentTypeId={undefined}
              projectRefText={projectRefText}
              setProjectRefText={setProjectRefText}
              // projectReferenceText={""}
            />
          )}
          {currentTab === 2 && (
            <UrlComponent
              projectId={projectId}
              projectURLs={projectURLs}
              setProjectURLs={setProjectURLs}
              documentTypeId={undefined}
            />
          )}
        </div>
      )
    }
  ];

  // const steps = (() => {
  //   if (selectedWritingSet?.name === "One-Click Campaign") {
  //     const stepsArray = [...stepsAppealCampaign];

  //     const selectedModel = selectedModels[0];
  //     const alreadyHasAdditionalInputsStep = stepsArray.some(
  //       (step) => step.title === "Additional Inputs"
  //     );

  //     if (
  //       selectedModel?.modelInputs &&
  //       selectedModel.modelInputs.length > 0 &&
  //       !alreadyHasAdditionalInputsStep
  //     ) {
  //       stepsArray.push({
  //         title: "Additional Inputs",
  //         subTitle: "Provide additional inputs",
  //         content: (
  //           <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
  //             <label className="text-sm mb-2" style={{ color: "rgb(78, 78, 78)" }}>
  //               Please provide any additional required information:
  //             </label>
  //             {selectedModel.modelInputs.map((input: any, index: number) => (
  //               <div key={index} className="mb-2">
  //                 <label htmlFor={`input-${index}`} className="text-sm">
  //                   {input.inputName}
  //                 </label>
  //                 {input.maxCharacters > 100 ? (
  //                   <textarea
  //                     id={`input-${index}`}
  //                     className="w-full border border-gray-300 p-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
  //                     onChange={(e) => handleModelInputChange(input, e.target.value)}
  //                   />
  //                 ) : (
  //                   <input
  //                     id={`input-${index}`}
  //                     type="text"
  //                     className="w-full border border-gray-300 p-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
  //                     onChange={(e) => handleModelInputChange(input, e.target.value)}
  //                   />
  //                 )}
  //               </div>
  //             ))}
  //           </div>
  //         )
  //       });
  //     }

  //     return stepsArray;
  //   } else {
  //     return stepsDefault;
  //   }
  // })();
  const steps = (() => {
    if (selectedWritingSet?.name === "One-Click Campaign") {
      const stepsArray = [...stepsAppealCampaign];

      const selectedModel = selectedModels[0];
      const alreadyHasAdditionalInputsStep = stepsArray.some(
        (step) => step.title === "Describe Your Campaign"
      );

      if (
        selectedModel?.modelInputs &&
        selectedModel.modelInputs.length > 0 &&
        !alreadyHasAdditionalInputsStep
      ) {
        const additionalInputsStep = {
          title: "Describe Your Campaign",
          subTitle: "Provide additional inputs",
          content: (
            <div
              className="text-sm"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "hidden",
                color: "rgb(78,78,78)"
              }}>
              <div className="mt-2" style={{ fontWeight: "600" }}>
                Tell Us About Your Campaign
              </div>
              <div>
                Provide details about your campaign so we can create personalized, effective
                materials
              </div>
              <div className="mt-2 mb-2" style={{ fontWeight: "600" }}>
                Campaign Details
              </div>
              <div>Fill in the field below with relevant details:</div>
              <div className="bulleted-list mb-2">
                <ul>
                  <li>
                    <span style={{ fontWeight: "600" }}>Purpose:</span> e.g., new project, specific
                    cause, annual fund.
                  </li>
                  <li>
                    <span style={{ fontWeight: "600" }}>Goals:</span> e.g., raise $20,000, gain 100
                    new donors.
                  </li>
                  <li>
                    <span style={{ fontWeight: "600" }}>Target Audience:</span> e.g., previous
                    donors, local community.
                  </li>
                  <li>
                    <span style={{ fontWeight: "600" }}>Key Messages:</span> e.g., urgency,
                    gratitude, impact.
                  </li>
                  <li>
                    <span style={{ fontWeight: "600" }}>Important Dates:</span> e.g., campaign end
                    date.
                  </li>
                  <li>
                    <span style={{ fontWeight: "600" }}>Tone and Style:</span> e.g., formal,
                    inspirational, friendly.
                  </li>
                </ul>
              </div>
              <div className="mt-2" style={{ fontWeight: "600" }}>
                Example:
              </div>
              <div className="mb-2" style={{ fontStyle: "italic" }}>
                &quot;We are launching a year-end campaign to support our new after-school program.
                Our goal is to raise $25,000 by December 31. The campaign should appeal to previous
                donors and local businesses, with an inspirational tone highlighting our impact on
                underserved youth.&quot;
              </div>

              {/* <label className="text-sm mb-2" style={{ color: "rgb(78, 78, 78)" }}>
                Please provide any additional required information:
              </label> */}
              {selectedModel.modelInputs.map((input: any, index: number) => (
                <div key={index} className="mb-2">
                  {/* <label htmlFor={`input-${index}`} className="text-sm">
                    {input.inputName}
                  </label> */}
                  {input.maxCharacters > 100 ? (
                    <textarea
                      id={`input-${index}`}
                      className="w-full border border-gray-300 p-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => handleModelInputChange(input, e.target.value)}
                    />
                  ) : (
                    <input
                      id={`input-${index}`}
                      type="text"
                      className="w-full border border-gray-300 p-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => handleModelInputChange(input, e.target.value)}
                    />
                  )}
                </div>
              ))}
              <div className="mt-2" style={{ fontStyle: "italic" }}>
                Need assistance? Just provide as much information as possible, and we’ll handle the
                rest!
              </div>
            </div>
          )
        };

        const referenceContentIndex = stepsArray.findIndex(
          (step) => step.subTitle === "Add Your Reference Materials"
        );
        if (referenceContentIndex !== -1) {
          stepsArray.splice(referenceContentIndex, 0, additionalInputsStep);
        } else {
          stepsArray.push(additionalInputsStep);
        }
      }

      return stepsArray;
    } else {
      return stepsDefault;
    }
  })();

  return (
    <div
      className="min-w-[32rem] mx-auto mt-1 mb-1"
      style={{ display: "flex", flexDirection: "column", width: "75%" }}>
      <ToastContainer autoClose={10000} />
      <div style={{ display: "flex", flexDirection: "column", flex: "0 0 auto" }}>
        <h1 className="text-lg font-bold mt-2" style={{ color: "rgb(78, 78, 78)" }}>
          {steps[activeStep].title}
        </h1>
        {/* <h2 className="text-lg font-semibold mb-1" style={{ color: "rgb(78, 78, 78)" }}>
          {steps[activeStep].subTitle}
        </h2> */}
        <div style={{ color: "#323232", fontSize: "small" }}>{steps[activeStep].instructions}</div>
      </div>
      <div
        className=""
        style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", overflowY: "hidden" }}>
        {steps[activeStep].content}
      </div>
      <div
        className="flex justify-between mt-2 mb-2"
        style={{ alignItems: "flexEnd", flex: "0 0 auto" }}>
        <button
          onClick={handleBack}
          className={`bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded `}>
          Back
        </button>
        {isWizardFinishing ? (
          <button
            disabled
            className="text-white font-semibold py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed">
            Creating<span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </button>
        ) : (
          <>
            {activeStep === steps.length - 1 &&
            selectedModels[0]?.modelName === "Guideline-Driven Proposal Writer" &&
            noGrantGuidelinesSelected ? (
              <div
                className="flex text-xs bg-yellow-100 border border-l-4 border-yellow-500 text-yellow-700 pl-2 pr-2 rounded-md"
                style={{ justifyContent: "center" }}
                role="alert">
                <div style={{ display: "flex", alignItems: "center" }} className="font-bold">
                  Select a Grant Guideline Reference
                </div>
              </div>
            ) : activeStep === steps.length - 1 &&
              selectedWritingSet?.name === "One-Click Campaign" &&
              noOrganizationInfoSelected ? (
              <div
                className="flex text-xs bg-yellow-100 border border-l-4 border-yellow-500 text-yellow-700 pl-2 pr-2 rounded-md"
                style={{ justifyContent: "center" }}
                role="alert">
                <div style={{ display: "flex", alignItems: "center" }} className="font-bold">
                  Select an Organization Reference
                </div>
              </div>
            ) : (
              <button
                onClick={handleNext}
                className={`text-white font-semibold py-0.5 px-2 rounded ${
                  activeStep === 0 && (proposalName.length === 0 || selectedModels.length === 0)
                    ? //   ||
                      // (campaignUsage !== null &&
                      //   maxCampaignUsage !== null &&
                      //   campaignUsage >= maxCampaignUsage)
                      "bg-gray-400 cursor-not-allowed"
                    : "bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none"
                }`}
                disabled={
                  activeStep === 0 && (proposalName.length === 0 || selectedModels.length === 0)
                  //    ||
                  // (campaignUsage !== null &&
                  //   maxCampaignUsage !== null &&
                  //   campaignUsage >= maxCampaignUsage)
                }>
                {activeStep < steps.length - 1 ? "Next" : "Finish"}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
