import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import { IOutput } from "src/interfaces/IProject";
import { RootState, useAppDispatch } from "src/configureStore";
import { AdminFetchOutputs } from "src/reducers/admin/adminOutputReducer";

const columnDefs = [
  { field: "text", headerName: "Text", sortable: true, filter: true, resizable: true },
  { field: "prompt", headerName: "Prompt", sortable: true, filter: true, resizable: true },
  { field: "client.name", headerName: "Client", sortable: true, filter: true },
  { field: "project.name", headerName: "Project", sortable: true, filter: true, resizable: true },
  {
    field: "documentType.name",
    headerName: "Document",
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    field: "documentAsset.name",
    headerName: "Document Asset",
    sortable: true,
    filter: true,
    resizable: true
  },
  { field: "type", headerName: "Type", sortable: true, filter: true, resizable: true },
  { field: "updatedAt", headerName: "Updated At", sortable: true, filter: true }
] as any;

const OutputGrid: React.FC = () => {
  const dispatch = useAppDispatch();
  const outputs = useSelector((state: RootState) => state.adminOutputReducer.outputs);
  const status = useSelector((state: RootState) => state.adminOutputReducer.status);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState<string | null>(null);

  // Handle cell click and set the selected cell data
  const onCellClicked = (params: any) => {
    if (params.colDef.field === "text" || params.colDef.field === "prompt") {
      setSelectedCellData(params.value);
      setModalVisible(true);
    }
  };
  const closeModal = () => {
    setModalVisible(false);
    setSelectedCellData(null);
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(AdminFetchOutputs());
    }
  }, [status, dispatch]);

  // Add the onCellClicked event to the grid options
  const gridOptions = {
    columnDefs,
    rowData: outputs,
    enableColumnsMenuItem: true,
    onCellClicked
  };
  const displayContentWithNewlines = (content: string) => {
    return content.split("\\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };
  return (
    <div className="relative w-full h-full">
      <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
        <AgGridReact {...gridOptions} />
      </div>
      {modalVisible && (
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleClickOutside}>
          <div
            ref={modalContentRef}
            className="bg-white p-8 rounded-lg max-w-screen-lg max-h-screen overflow-auto">
            <button
              className="absolute top-2 right-2 bg-white text-black rounded-full p-2 shadow-md focus:outline-none"
              onClick={closeModal}>
              &times;
            </button>
            <pre className="text-sm whitespace-pre-wrap break-words">
              {selectedCellData && displayContentWithNewlines(selectedCellData)}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutputGrid;
