import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { BeatLoader } from "react-spinners";
import { Project } from "src/interfaces/IProject";
import { ProjectTreeView } from "./projectTreeView";
import { RootState, useAppDispatch } from "src/configureStore";
import "./sidebar.scss";
import { fetchProjects, createProject, getProjectsSuccess } from "src/reducers/projectReducer";
import {
  ChevronCircleIcon,
  PlusCircle,
  SearchIcon,
  RightChevron,
  FolderIcon
} from "src/lib/icons/index";
import { setSelectedDocumentType } from "src/reducers/documentTypeReducer";
import { HtmlTooltip } from "src/components/tooltip";
import ResizePanel from "src/components/resizePanel";
import { setSelectedProjectById } from "src/reducers/projectReducer";
import { setSelectedDocumentAsset } from "src/reducers/documentAssetReducer";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const { documentId, projectId } = useParams();
  const { projects, isLoading, isLoaded } = useSelector(
    (state: RootState) => state.projectsReducer
  );
  const { selectedDocumentType, processing } = useSelector(
    (state: RootState) => state.documentTypes
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const projects = useSelector((state: RootState) => state.projectsReducer.projects);

  useEffect(() => {
    if (localStorage.getItem("authToken") !== null && !isLoaded && !isLoading) {
      dispatch(fetchProjects());
    }
  }, [dispatch]);
  useEffect(() => {
    if (selectedDocumentType === undefined && documentId !== undefined && projects.length > 0) {
      let documentType;
      projects.forEach((item) => {
        if (item._id === projectId) {
          documentType = item.children.find((item: any) => {
            return item._id === documentId;
          });
        }
      });

      dispatch(setSelectedDocumentType(documentType));
    }
  }, [projects]);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const addNewProject = async () => {
    // console.log("add new project");

    let nProject: any = await dispatch(createProject("New Folder"));
    // console.log(nProject);
    nProject = nProject.project;
    navigate(`/project/${nProject[nProject.length - 1]._id}`);
    dispatch(setSelectedProjectById(nProject[nProject.length - 1]._id));
    await dispatch(setSelectedDocumentAsset({ wModel: undefined, type: "model" }));
    // setNewlyCreatedProjectId(nProject.project._id);
  };
  const updateProject = () => {
    const newProjects = projects.map((project) => {
      return { ...project, isNew: false };
    });

    dispatch(getProjectsSuccess(newProjects));
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const filterProjects = (projects: Project[]): Project[] => {
    if (!searchQuery) return projects;

    const filteredProjects = projects.map((project) => {
      const filteredChildren = project.children
        .map((child) => {
          const filteredGrandChildren = child.children.filter((grandChild) =>
            grandChild.name.toLowerCase().includes(searchQuery.toLowerCase())
          );

          const isChildMatch = child.name.toLowerCase().includes(searchQuery.toLowerCase());
          return {
            ...child,
            objectType: "documentType",
            children: isChildMatch
              ? child.children
              : filteredGrandChildren.map((grandChild) => ({
                  ...grandChild,
                  objectType: "documentAsset"
                }))
          };
        })
        .filter(
          (child) =>
            child.children.length > 0 ||
            child.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

      return { ...project, objectType: "project", children: filteredChildren };
    });

    return filteredProjects.filter((project) => project.children.length > 0);
  };
  let initialExpandedProjects: string[] = [];
  if (!projectId && projects.length > 0) {
    initialExpandedProjects = [projects[0]._id];
  }
  return (
    <>
      {isOpen ? (
        <ResizePanel
          direction="e"
          handleClass="customHandleEW"
          draggingClass="drag-border-e"
          initialSize={250}>
          <div
            className={`sidebar ${isOpen ? "open" : "closed"}`}
            style={{
              height: "100%",
              backgroundColor: "rgb(236,236,236)",
              display: "flex",
              flexDirection: "column",
              boxShadow: "inset -8px 0 8px -8px rgba(0, 0, 0, 0.2)"
            }}>
            <div className="arrow-icon" onClick={toggleSidebar}>
              <ChevronCircleIcon
                // className="rotate-chevron"
                height={"20px"}
                width={"20px"}
                color={"rgba(88, 89, 91, 1)"}
              />
            </div>
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #b0b0b0"
                }}>
                <div className="flex flex-row pr-3 pl-3 pb-3 pt-5" style={{ alignItems: "center" }}>
                  {/* <FontAwesomeIcon icon={faBoxArchive} color="gray" size="lg" /> */}
                  <FolderIcon height={"18px"} color={"rgba(88, 89, 91, 1)"} />
                  <div className="pl-2 pr-2 font-semibold text-gray-600">Folders</div>
                  <div onClick={addNewProject} style={{ cursor: "pointer" }}>
                    <HtmlTooltip title="Add new folder" placement="top" arrow>
                      <div>
                        <PlusCircle
                          height={"20px"}
                          width={"20px"}
                          color={"#3FA1D7"}
                          filter={"drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4))"}
                        />
                      </div>
                    </HtmlTooltip>
                  </div>
                </div>
                <div className="flex flex-row p-3" style={{ alignItems: "center" }}>
                  {/* Add search input */}
                  <span style={{ flex: "0 0 auto" }}>
                    <SearchIcon
                      className="mr-2"
                      height={"15px"}
                      width={"15px"}
                      color={"rgba(88, 89, 91, 1)"}
                    />
                  </span>
                  <input
                    id="inputsSearch"
                    name="inputsSearch"
                    type="text"
                    className="search-input focus:outline-none"
                    style={{
                      minWidth: "0px",
                      flex: "1 1 auto",
                      background: "none",
                      borderBottom: "1px #707070 solid"
                    }}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              {!isLoaded ? (
                <div className="spinner-container">
                  <BeatLoader color={"#333"} loading={isLoading} size={10} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto"
                  }}>
                  <ProjectTreeView
                    projects={filterProjects(projects)}
                    updateProject={updateProject}
                    initialExpandedProjects={initialExpandedProjects}
                    searchQuery={searchQuery}
                  />
                </div>
              )}
            </div>
          </div>
          {/* </div> */}
        </ResizePanel>
      ) : (
        <div
          className="sidebar closed border-l border-gray-300"
          style={{ backgroundColor: "rgb(236,236,236)" }}>
          <div className="arrow-icon sidebar-closed" onClick={toggleSidebar}>
            <RightChevron height={"16px"} width={"16px"} color={"rgba(88, 89, 91, 1)"} />
          </div>
          <div>
            <div className="flex flex-row p-3 rotate-90" style={{ marginTop: "30px" }}>
              {/* <FontAwesomeIcon icon={faBoxArchive} color="gray" size="lg" /> */}
              <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                <FolderIcon height={"20px"} color={"rgba(88, 89, 91, 1)"} />
              </div>
              <div className="pl-2 pr-2 font-semibold text-gray-600">Folders</div>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default Sidebar;
