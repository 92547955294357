import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { login } from "src/reducers/authReducer";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";
import { useAppDispatch } from "src/configureStore";
import { fetchProjects } from "src/reducers/projectReducer";
import isEmail from "validator/lib/isEmail";
import "./signupPage.scss";
Modal.setAppElement("#root");

const SignUpPage: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFirstNameReqs, setShowFirstNameReqs] = useState(false);
  const [showLastNameReqs, setShowLastNameReqs] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Extract the "email" parameter from the search parameters if it exists
    const emailParam = searchParams.get("email");
    if (emailParam && isEmail(emailParam)) {
      setEmail(emailParam);
    }
  }, [searchParams]);
  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFirstNameValid(firstName) && isLastNameValid(lastName)) {
      if (isPasswordValid()) {
        setIsSubmitting(true); // Use a timer to prevent spamming of submits
        setTimeout(() => setIsSubmitting(false), 3000);
        // handle successful form submission
        const url = process.env["REACT_APP_API_SERVER"] + "/api/signup";
        const data = {
          email,
          password,
          firstName,
          lastName,
          sType: searchParams.get("type"),
          joinGuid: searchParams.get("joinGuid")
        };
        const response = await axios.post(url, data);
        if (response.status === 201) {
          console.log(response.data.user.isEmailVerified);
          console.log("THE EMAIL");
          console.log(response.data.user.email);
          window.fpr("referral", { email: response.data.user.email });
          if (response.data.user.isEmailVerified === false) {
            setModalIsOpen(true);
          } else {
            setShowPasswordReqs(false);
            await dispatch(login({ user: response.data.user, token: response.data.token }));
            await localStorage.setItem("user", JSON.stringify(response.data.user));

            await localStorage.setItem("authToken", response.data.token);
            await dispatch(fetchProjects());
            navigate("/");
          }
        }
      } else {
        // handle invalid form submission
        setShowPasswordReqs(true);
      }
    }
  };
  const isPasswordValid = () => {
    const letterRegex = /[a-zA-Z]/;
    const digitRegex = /\d/;
    return (
      letterRegex.test(password) &&
      digitRegex.test(password) &&
      password.length >= 8 &&
      password.length <= 50
    );
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isEmailValid = () => {
    return isEmail(email);
  };

  const nameRegex = /^[a-zA-Z0-9-' ]+$/;

  const isFirstNameValid = (name: string) => {
    if (nameRegex.test(name)) {
      setShowFirstNameReqs(false);
      return true;
    } else {
      setShowFirstNameReqs(true);
      return false;
    }
  };

  const isLastNameValid = (name: string) => {
    if (nameRegex.test(name)) {
      setShowLastNameReqs(false);
      return true;
    } else {
      setShowLastNameReqs(true);
      return false;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple">
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Validate your email address"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "350px",
            height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <h2>
          Please activate your account by clicking on the link in the activation email we sent to
          your email address.
        </h2>
      </Modal>
      <div className="w-full max-w-md">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSignUp}>
          <img src={fundwriterLogo} alt="Logo" className="logo mb-4" />
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
              First Name
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="firstName"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                maxLength={150}
                autoComplete="given-name"
              />
            </div>
            {showFirstNameReqs ? (
              <p className="input-guidance" style={{ height: "12px" }}>
                Allowed characters: A-Z,0-9,&apos;,- , and spaces
              </p>
            ) : (
              <p style={{ height: "12px" }}></p>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">
              Last Name
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="lastName"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                maxLength={150}
                autoComplete="family-name"
              />
            </div>
            {showLastNameReqs ? (
              <p className="input-guidance" style={{ height: "12px" }}>
                Allowed characters: A-Z,0-9,&apos;,- , and spaces
              </p>
            ) : (
              <p style={{ height: "12px" }}></p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Email
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                maxLength={254}
                autoComplete="email"
              />
            </div>
          </div>
          <div className="mb-4 relative">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                maxLength={50}
                autoComplete="new-password"
              />
            </div>
            <button
              type="button"
              className="absolute right-0 top-0 text-blue-500 hover:text-blue-800"
              style={{ fontSize: "14px" }}
              onClick={togglePasswordVisibility}>
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          {showPasswordReqs && (
            <p className="input-guidance">
              Password must have at least one letter, one digit, be at least 8 characters long and
              at most 50 characters.
            </p>
          )}
          <div className="flex items-center justify-between">
            <button
              className={`font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                isEmailValid() && !isSubmitting && firstName && lastName && password
                  ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                  : "text-white rounded bg-gray-400 cursor-not-allowed"
              }`}
              type="submit"
              disabled={!isEmailValid() || isSubmitting || !password || !firstName || !lastName}>
              {isEmailValid() && isSubmitting ? (
                <>
                  Creating<span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </>
              ) : (
                "Sign Up"
              )}
            </button>
            <a
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              href="/login">
              Already have an account?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
