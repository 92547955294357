import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white text-gray-800 w-full py-4 px-6 border-t text-sm">
      <div className="flex justify-between items-center">
        <div>
          <a
            href="https://www.fundwriter.ai/utility/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-4 hover:underline hover:text-blue-800">
            Privacy Policy
          </a>
          <a
            href="https://www.fundwriter.ai/utility/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline hover:text-blue-800">
            Terms of Use
          </a>
        </div>
        <div>&copy; 2024 Fundwriter.ai</div>
      </div>
    </footer>
  );
};

export default Footer;
