import AdminMain from "./adminMain";
import Header from "../../components/header";
import AdminSidebarNav from "../../components/admin/adminSidebarNav";

const HomePage = () => {
  return (
    <div className="flex flex-col h-screen w-full" style={{ height: "100%", width: "100%" }}>
      <div style={{ flex: "0 0 40px" }}>
        <Header />
      </div>
      <div
        className="flex flex-row h-full"
        style={{
          flex: "1 1 auto",
          overflowY: "auto"
        }}>
        <AdminSidebarNav />
        <AdminMain />
      </div>
    </div>
  );
};
export default HomePage;
