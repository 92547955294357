import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env["REACT_APP_API_SERVER"] || "",
  timeout: 60000
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
      // Optionally, you can also redirect the user to the login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
