import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import fundwriterLogo from "assets/images/fundwriter-logo-full.png";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const navigate = useNavigate();

  // Get the reset token from the URL

  const { resetToken } = useParams<{ resetToken: string }>();

  const isPasswordValid = () => {
    const letterRegex = /[a-zA-Z]/;
    const digitRegex = /\d/;
    return letterRegex.test(password) && digitRegex.test(password) && password.length >= 8;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password and confirm password do not match.");
      return;
    } else {
      setMessage(null);
    }
    if (!isPasswordValid()) {
      setShowPasswordReqs(true);
      return;
    } else {
      setShowPasswordReqs(false);
    }

    try {
      // send a PATCH request to your API endpoint for resetting the password
      const url = process.env["REACT_APP_API_SERVER"] + "/api/confirmResetPassword";
      const response = await axios.post(url, { password, token: resetToken }); // use the correct API endpoint here

      // if password reset was successful, show success message
      setMessage(response.data.message);
      setShowLoginButton(true);
    } catch (error: any) {
      // show error message
      setMessage(`Error: ${error.response.data.message}`);
      setShowLoginButton(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-fw-light-purple to-fw-dark-purple flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <img src={fundwriterLogo} alt="Logo" className="logo mb-4" />
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="mt-4 relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="button"
                className="absolute right-0 top-0 text-blue-500 hover:text-blue-800"
                style={{ fontSize: "14px" }}
                onClick={togglePasswordVisibility}>
                {showPassword ? "Hide" : "Show"}
              </button>
              <div className="mt-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700">
                  Confirm New Password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            {showPasswordReqs && (
              <p className="input-guidance">
                Password must have at least one letter, one digit, and be at least 8 characters
                long.
              </p>
            )}
            {message && <p className="input-guidance">{message}</p>}
            <div className="w-full flex">
              {showLoginButton ? (
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                  Login
                </button>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none">
                  Reset Password
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
