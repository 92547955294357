import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import axios from "axios";
import { AppDispatch } from "src/configureStore";
import { fetchProjects, getAssetsForProject } from "./projectReducer";
import axiosInstance from "src/lib/axiosConfig";
import { setPollingStatus } from "../reducers/documentAssetReducer";

interface DocumentTypeState {
  documentTypes: any[];
  selectedDocumentType: any;
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  processing: boolean;
  referenceType: string | null;
}
const initialState: DocumentTypeState = {
  documentTypes: [],
  selectedDocumentType: undefined,
  isLoading: false,
  isLoaded: false,
  error: null,
  processing: false,
  referenceType: ""
};
const documentTypesSlice = createSlice({
  name: "documentTypes",
  initialState,
  reducers: {
    getDocumentTypesRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getDocumentTypesSuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.documentTypes = action.payload;
    },
    getDocumentTypesFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    runProcessing: (state) => {
      state.processing = true;
    },

    setSelectedDocumentType: (state, action: PayloadAction<any>) => {
      state.selectedDocumentType = action.payload;
      state.processing = false;
    },
    setSelectedDocumentTypeById: (state, action: PayloadAction<string>) => {
      state.selectedDocumentType = state.documentTypes?.find((set) => set._id === action.payload);
    }
  }
});
export const {
  getDocumentTypesRequest,
  getDocumentTypesSuccess,
  getDocumentTypesFailure,
  setSelectedDocumentType,
  runProcessing,
  setSelectedDocumentTypeById
} = documentTypesSlice.actions;
export const createDocumentType =
  (
    projectId: string,
    writingSetId: string,
    obj: ISendDocType,
    fileIds?: string[],
    urlIds?: string[],
    refTextIds?: string[],
    pipelineId?: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/createDocumentType";
      const response = await axiosInstance.post(
        url,
        {
          obj,
          projectId,
          writingSetId,
          fileIds,
          urlIds,
          refTextIds,
          pipelineId
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(fetchProjects());
      return { success: true, documentType: response.data };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };
export const updateDocumentType =
  (projectId: string, documentTypeId: string, obj: ISendDocType) =>
  async (dispatch: AppDispatch) => {
    try {
      const url = process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/updateDocumentType";
      const response = await axiosInstance.post(
        url,
        { obj, projectId, documentTypeId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(fetchProjects());

      return { success: true, documentType: response.data };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };

export interface ISendAssetType {
  name: string;
  modelId: string;
}
export interface ISendDocType {
  name: string;
  children: ISendAssetType[];
}

export const scrapeUrl =
  (projectId: string, url: string, documentTypeId = "", referenceType: string, isGlobal = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(runProcessing());
    const urlToProcess = process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/runUrlScrape";

    try {
      const response = await axiosInstance.post(
        urlToProcess,
        { projectId, url, documentTypeId, referenceType, isGlobal },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      // dispatch(setSelectedDocumentType(response.data.document));
      return response.data.asset;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  };
export const processFile =
  (
    projectId: string,
    file: string,
    mimeType: string,
    documentTypeId = "",
    referenceType: string,
    isGlobal = false
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(runProcessing());
    const urlToProcess = process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/runFileProcess";

    try {
      const response = await axiosInstance.post(
        urlToProcess,
        { projectId, file, mimeType, documentTypeId, referenceType, isGlobal },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      return response.data.asset;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  };
export const updateReferenceFileTypes = (projectFiles: string[]) => async () => {
  const urlToUpdate =
    process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/updateFileReferenceTypes";
  try {
    const response = await axiosInstance.patch(
      urlToUpdate,
      { projectFiles },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    return response.data.asset;
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};
export const updateReferenceFileType = (assetId: string, newReferenceType: string) => async () => {
  const urlToUpdate =
    process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/updateFileReferenceType";
  try {
    const response = await axiosInstance.patch(
      urlToUpdate,
      { assetId, newReferenceType },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    return response.data.asset;
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};
export const processRefText =
  (
    projectId: string,
    referenceText: string,
    documentTypeId = "",
    referenceType: string,
    isGlobal = false
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(runProcessing());
    const urlToProcess =
      process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/addReferenceText";
    try {
      const response = await axiosInstance.post(
        urlToProcess,
        { projectId, referenceText, documentTypeId, referenceType, isGlobal },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      return response.data.asset;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  };
export const updateReferenceText =
  (assetId: string, newReferenceText: string, newReferenceType: string) => async () => {
    const urlToUpdate =
      process.env["REACT_APP_API_SERVER"] + "/api/documentTypes/updateReferenceText";
    try {
      const response = await axiosInstance.patch(
        urlToUpdate,
        { assetId, newReferenceText, newReferenceType },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      return response.data.asset;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  };
export default documentTypesSlice.reducer;
