import Main from "./main";
import Header from "../components/header";
import Sidebar from "../components/sidebar";

const HomePage = () => {
  return (
    <div className="flex flex-col" style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          flex: "0 0 40px"
          // , zIndex: 152
        }}>
        <Header />
      </div>
      <div
        className="flex flex-row "
        style={{
          // display: "flex",
          // flexDirection: "row",
          // paddingBottom: "42px",
          // height: "100%",
          flex: "1 1 auto",
          overflowY: "auto"
        }}>
        <Sidebar />
        <Main />
      </div>
    </div>
  );
};
export default HomePage;
