import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
// import Select from "react-select";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  GridOptions,
  CellValueChangedEvent,
  CellEditingStoppedEvent,
  ValueSetterParams
} from "ag-grid-community";
import Modal from "react-modal";

// import { IOutput } from "src/interfaces/IProject";
import axiosInstance from "src/lib/axiosConfig";
import { RootState, useAppDispatch } from "src/configureStore";
import {
  AdminFetchUsers,
  AdminDeleteClients,
  AdminGetClients,
  AdminUpdateClient,
  adminUpdateClientAttr
} from "src/reducers/admin/adminUserReducer";
import { IClient, IUser } from "src/interfaces/IUser";

import { SearchIcon } from "src/lib/icons/index";

Modal.setAppElement("#root");
const ClientManagement: React.FC = () => {
  const dispatch = useAppDispatch();

  const userStatus = useSelector((state: RootState) => state.adminUserReducer.userStatus);
  const clientStatus = useSelector((state: RootState) => state.adminUserReducer.clientStatus);
  const clients = useSelector((state: RootState) => state.adminUserReducer.clients);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredClients, setFilteredClients] = useState<IClient[]>(clients);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  const [clientName, setClientName] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [bonusCredits, setBonusCredits] = useState("100000");
  const columnDefs = [
    {
      field: "name",
      headerName: "Client Name",
      sortable: true,
      filter: true,
      resizable: true,
      checkboxSelection: true,
      width: 300,
      editable: false
    },
    {
      field: "subscriptionLevel",
      headerName: "Level",
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
      cellEditor: "agSelectCellEditor", // Use a dropdown editor
      cellEditorParams: {
        values: ["beta", "trial", "canceled"] // Dropdown options
      }
    },
    {
      field: "reoccurringCredits",
      headerName: "Reoccurring Credits",
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
      width: 150,
      valueSetter: function (params: ValueSetterParams) {
        // Only update if the value has actually changed
        if (params.newValue !== params.oldValue) {
          const updatedClient = {
            ...params.data, // Keep all the existing data
            reoccurringCredits: parseInt(params.newValue, 10) // Update bonusCredits with the new value
          };

          // Dispatch the action to update the client in the state
          dispatch(adminUpdateClientAttr(updatedClient));
          return true; // This means the grid should refresh this cell
        } else {
          return false; // No changes, no refresh
        }
      }
    },
    {
      field: "bonusCredits",
      headerName: "Bonus Credits",
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
      width: 150,
      valueSetter: function (params: ValueSetterParams) {
        // Only update if the value has actually changed
        if (params.newValue !== params.oldValue) {
          const updatedClient = {
            ...params.data, // Keep all the existing data
            bonusCredits: parseInt(params.newValue, 10) // Update bonusCredits with the new value
          };

          // Dispatch the action to update the client in the state
          dispatch(adminUpdateClientAttr(updatedClient));
          return true; // This means the grid should refresh this cell
        } else {
          return false; // No changes, no refresh
        }
      }
    },
    {
      field: "spentCredits",
      headerName: "Spent Credits",
      sortable: true,
      filter: true,
      resizable: true,
      width: 150
    },
    { field: "stripeId", headerName: "Stripe Id", sortable: true, filter: true, resizable: true },
    { field: "bubbleId", headerName: "Bubble ID", sortable: true, filter: true, resizable: true },
    {
      field: "createdAt",
      headerName: "Created At",
      sortable: true,
      filter: true,
      resizable: true,
      width: 250,
      valueGetter: (params: any) => {
        // Create a date object with the UTC date
        const date = moment.utc(params.data.createdAt);

        // Convert to Pacific Time (US & Canada)
        return date.tz("America/Los_Angeles").format();
      }
    }
  ];

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(AdminFetchUsers());
    }
  }, [userStatus, dispatch]);
  useEffect(() => {
    if (clientStatus === "idle") {
      dispatch(AdminGetClients());
    }
  }, [userStatus, dispatch]);
  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);
  useEffect(() => {
    setFilteredClients(
      clients.filter(
        (client: any) =>
          client.name && client.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true); // Use a timer to prevent spamming of submits
    setTimeout(() => setIsSubmitting(false), 3000);
    const url = process.env["REACT_APP_API_SERVER"] + "/api/admin/createClient";
    const data = {
      name,
      bonusCredits
    };
    const response = await axiosInstance.post(url, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    if (response.status === 200) {
      dispatch(AdminGetClients());
      closeShowCreateModal();
    }
  };
  const closeShowCreateModal = () => {
    setShowCreateModal(false);
    setClientName("");
    setBonusCredits("100000");
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setShowCreateModal(false);
  };
  const cellEditingStopped = async (e: CellEditingStoppedEvent) => {
    console.log(e);
    if (e.newValue !== e.oldValue) {
      const colId = e.column.getColId();
      dispatch(AdminUpdateClient({ client: e.data, newValue: e.newValue, column: colId }));
    }
  };
  const cellValueChanged = async (e: CellValueChangedEvent) => {
    // console.log("CELL VALUE CHNGED");
    // if (e.newValue !== e.oldValue) {
    //   dispatch(AdminUpdateClient(e.data));
    // }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const onRowSelected = (event: any) => {
    let runPush = true;
    if (event.node.selected) {
      selectedClients.forEach((user: IUser) => {
        if (user._id === event.data._id) {
          runPush = false;
        }
      });
      if (event.data._id && runPush)
        setSelectedClients([
          ...selectedClients,
          {
            _id: event.data._id,
            email: event.data.email
          }
        ]);
    } else {
      setSelectedClients(selectedClients.filter((client) => client.id !== event?.data._id));
      // selectedUsers = selectedUsers.filter(user => {
      //   return user.id !== event.data._id;
      // });
    }
    // setSelectedUsers(selectedUsers);
  };
  const gridOptions: GridOptions = {
    columnDefs,
    rowData: _.cloneDeep(filteredClients),
    rowSelection: "multiple",
    onRowSelected: (e) => {
      onRowSelected(e);
    },
    onCellValueChanged: (e) => {
      cellValueChanged(e);
    },
    onCellEditingStopped: (event: CellEditingStoppedEvent) => {
      cellEditingStopped(event);
    }
  };

  return (
    <div className="relative w-full h-full" style={{ margin: "30px", width: "97%" }}>
      <Modal
        isOpen={showCreateModal}
        onRequestClose={closeShowCreateModal}
        contentLabel="Create Client"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "700px",
            height: "400px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        {" "}
        <form className="bg-white mb-4" onSubmit={handleSignUp}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="clientName">
              Client Name
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="clientName"
                type="text"
                placeholder="Client Name"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                required
                maxLength={150}
                autoComplete="given-name"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Bonus Credits
            </label>
            <div className="mt-1">
              <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="credits"
                type="credits"
                placeholder="100000"
                value={bonusCredits}
                onChange={(e) => setBonusCredits(e.target.value)}
                required
                maxLength={20}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <button
              className={`font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                !isSubmitting && clientName
                  ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                  : "text-white rounded bg-gray-400 cursor-not-allowed"
              }`}
              type="submit"
              disabled={isSubmitting || !clientName}>
              {isSubmitting ? (
                <>
                  Creating<span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </>
              ) : (
                "Create"
              )}
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete User"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "500px",
            height: "200px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <div>Confirm Delete Users</div>
          <div>
            {selectedClients.map((item) => {
              return <div key={item._id}>{item.email}</div>;
            })}
          </div>
          <div className="flex mt-1" style={{ justifyContent: "center" }}>
            <button
              // disabled={!validateEmail(userData.email)}
              onClick={() => {
                const deleteIds = selectedClients.map((client) => client._id);
                dispatch(AdminDeleteClients(deleteIds)).then(() => {
                  dispatch(AdminGetClients());
                  setSelectedClients([]);
                  closeModal();
                });
              }}
              className="mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4">
              Delete
            </button>
            <button
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ml-4"
              onClick={() => {
                closeModal();
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row mb-2 ">
        <div className="flex justify-between items-center mb-2 mr-2" style={{ marginTop: "5px" }}>
          <span style={{ flex: "0 0 auto" }}>
            <SearchIcon
              className="mr-2"
              height={"15px"}
              width={"15px"}
              color={"rgba(88, 89, 91, 1)"}
            />
          </span>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              handleInputChange(e);
            }}
            className="w-full appearance-none block w-full placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Search..."
            style={{ borderBottom: "1px solid rgba(88, 89, 91, 1)" }}
          />
        </div>
        <div
          onClick={() => {
            setShowCreateModal(true);
          }}
          className="mt-2 mr-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none cursor-pointer">
          Create Client
        </div>
        <div
          className="mt-2 text-white font-semibold py-0.5 px-2 rounded bg-fw-med-blue hover:bg-fw-dark-blue focus:outline-none cursor-pointer"
          onClick={() => {
            console.log(selectedClients);
            if (selectedClients.length > 0) {
              setShowDeleteModal(true);
            }
          }}>
          Delete Client
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "90%", width: "100%" }}>
        <AgGridReact {...gridOptions} />
      </div>
    </div>
  );
};

export default ClientManagement;
