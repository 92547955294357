import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useAppDispatch } from "src/configureStore";
import { setPollingStatus } from "../reducers/documentAssetReducer";
import FileUploadComponent from "./fileUploadComponent";
import UrlComponent from "./urlComponent";
import ReferenceTextComponent from "./referenceTextComponent";
import { updateReferenceFileType } from "../reducers/documentTypeReducer";
import { updateDocumentAssetAssets } from "../reducers/documentAssetReducer";
import Modal from "react-modal";
import { Tabs, Tab } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
Modal.setAppElement("#root");

interface RefContentModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  projectId: string | undefined;
  writingSetId?: string | undefined;
  proposalName: string;
  selectedDocumentTypeId: string | undefined;
  selectedDocumentAssetId: string | undefined;
  inputFileAssets: string[];
  inputUrlAssets: string[];
  inputRefTextAssets: string[];
  setLastPolledAssetId: (assetId: []) => void;
  setDropDownInitted: (init: boolean) => void;

  //   handleAddProjectURL: (url: string) => void;
}
export const ReferenceContentDialog: React.FC<RefContentModalProps> = ({
  modalIsOpen,
  setModalIsOpen,
  projectId,
  selectedDocumentTypeId,
  selectedDocumentAssetId,
  inputUrlAssets,
  inputFileAssets,
  inputRefTextAssets,
  setLastPolledAssetId,
  setDropDownInitted
}) => {
  const [projectFiles, setProjectFiles] = useState<string[]>([]);
  const [projectURLs, setProjectURLs] = useState<string[]>([]);
  const [initialFiles, setInitialFiles] = useState<string[]>([]);
  const [initialURLs, setInitialURLs] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [projectRefText, setProjectRefText] = useState<string[]>([]);
  const [initialRefText, setInitialRefText] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentTab(newValue);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const updateReferenceType = async (id: string, referenceType: string) => {
    await dispatch(updateReferenceFileType(id, referenceType));
  };

  useEffect(() => {
    if (modalIsOpen) {
      setCurrentTab(0);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    setProjectFiles(inputFileAssets);
    setProjectURLs(inputUrlAssets);
    setProjectRefText(inputRefTextAssets);
    if (initialFiles.length === 0) setInitialFiles(inputFileAssets);
    if (initialURLs.length === 0) setInitialURLs(inputUrlAssets);
    if (initialRefText.length === 0) setInitialRefText(inputRefTextAssets);
  }, [inputFileAssets, inputUrlAssets, inputRefTextAssets]);

  const dispatch = useAppDispatch();
  async function runAssetActions() {
    const iFiles = _.cloneDeep(initialFiles);
    const iURLs = _.cloneDeep(initialURLs);
    const iRefText = _.cloneDeep(initialRefText);

    const filesGuidelines = projectFiles.filter((file: any) => {
      return file.referenceType === "grantGuidelines" && file.selected === true;
    });
    const urlGuidelines = projectURLs.filter((file: any) => {
      return file.referenceType === "grantGuidelines" && file.selected === true;
    });
    const textGuidelines = projectRefText.filter((file: any) => {
      return file.referenceType === "grantGuidelines" && file.selected === true;
    });
    const filesFunderInfo = projectFiles.filter((file: any) => {
      return file.referenceType === "funderInfo" && file.selected === true;
    });
    const urlFunderInfo = projectURLs.filter((file: any) => {
      return file.referenceType === "funderInfo" && file.selected === true;
    });
    const textFunderInfo = projectRefText.filter((file: any) => {
      return file.referenceType === "funderInfo" && file.selected === true;
    });

    if (filesGuidelines.length + urlGuidelines.length + textGuidelines.length > 1) {
      toast.error("You can only have one grant guidelines reference");
      return;
    }
    if (filesFunderInfo.length + urlFunderInfo.length + textFunderInfo.length > 1) {
      toast.error("You can only have one Funder info file at a time");
      return;
    }
    setModalIsOpen(false);

    if (selectedDocumentTypeId && selectedDocumentAssetId) {
      setInitialFiles(projectFiles);
      setInitialURLs(projectURLs);
      setInitialRefText(projectRefText);
      const fileIds: string[] = [];
      const urlIds: string[] = [];
      const refTextIds: string[] = [];

      projectFiles.map((file: any) => {
        const fProject: any = iFiles.find((item: any) => {
          return item._id === file._id;
        });

        if (fProject && fProject?.referenceType !== file.referenceType) {
          updateReferenceType(file._id, file.referenceType);
        }
        if (file.selected === true) {
          fileIds.push(file._id);
        }
      });

      projectURLs.map((url: any) => {
        const URLProject: any = iURLs.find((item: any) => {
          return item._id === url._id;
        });

        if (URLProject && URLProject?.referenceType !== url.referenceType) {
          updateReferenceType(url._id, url.referenceType);
        }
        if (url.selected === true) {
          urlIds.push(url._id);
        }
      });

      projectRefText.map((refText: any) => {
        const refProject: any = iRefText.find((item: any) => {
          return item._id === refText._id;
        });

        if (refProject && refProject?.referenceType !== refText.referenceType) {
          updateReferenceType(refText._id, refText.referenceType);
        }
        if (refText.selected === true) {
          refTextIds.push(refText._id);
        }
      });

      await dispatch(
        updateDocumentAssetAssets(selectedDocumentAssetId, fileIds, urlIds, refTextIds)
      );
      setLastPolledAssetId([]);
      setDropDownInitted(false);
      await dispatch(setPollingStatus("running"));
    }
  }
  return (
    <>
      <ToastContainer autoClose={10000} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Reference Content Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            // width: "500px",
            // height: "200px",
            margin: "auto",
            padding: "10px 20px 10px 20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            // overflowY: "auto",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        {/* <Dialog
        open={modalIsOpen}
        onClose={() => {
          setProjectFiles(initialFiles);
          setProjectURLs(initialURLs);
          setModalIsOpen(false);
        }}
        className="fixed z-[100] inset-0 overflow-y-auto rounded">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50" />
        <div
          className="flex items-center justify-center min-h-screen"
          // style={{maxWidth: "450px"}}
        > */}
        {/* <Dialog.Panel style={{ zIndex: "101" }} className="w-full max-w-lg rounded bg-white">
            <div className="bg-white pt-6 pr-6 pl-6 rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold mb-4"
                style={{ color: "rgb(78, 78, 78)" }}>
                Choose reference data for writing inputs
              </Dialog.Title> */}
        <h1 className="text-xl font-bold mb-2" style={{ color: "rgb(78, 78, 78)" }}>
          Add Your Reference Materials
        </h1>
        <div style={{ color: "#323232", fontSize: "small" }}>
          Create tailored content by providing the context to help AI understand your project. You
          can do this by uploading relevant documents or adding website links.
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            overflow: "hidden"
          }}>
          <div
            style={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              flex: "0 0 auto"
            }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Reference Content Tabs"
              TabIndicatorProps={{ style: { backgroundColor: "rgb(63, 161, 215)" } }}>
              <Tab label="Reference Files" style={{ textTransform: "none" }} />
              <Tab label="Reference Text" style={{ textTransform: "none" }} />
              <Tab label="URL Reference" style={{ textTransform: "none" }} />
            </Tabs>
          </div>
          {currentTab === 0 && (
            <FileUploadComponent
              projectId={projectId}
              documentTypeId={selectedDocumentTypeId}
              projectFiles={projectFiles}
              setProjectFiles={setProjectFiles}
              setInitialFiles={setInitialFiles}
            />
          )}
          {currentTab === 1 && (
            <ReferenceTextComponent
              projectId={projectId}
              documentTypeId={selectedDocumentTypeId}
              projectRefText={projectRefText}
              setProjectRefText={(e) => {
                console.log(e);
                setProjectRefText(e);
              }}
              setInitialRefText={setInitialRefText}
              // projectReferenceText={""}
            />
          )}
          {currentTab === 2 && (
            <UrlComponent
              projectId={projectId}
              documentTypeId={selectedDocumentTypeId}
              projectURLs={projectURLs}
              setProjectURLs={setProjectURLs}
              setInitialURLs={setInitialURLs}
            />
          )}
        </div>
        {/* </div> */}
        <div className="flex" style={{ flexDirection: "row", justifyContent: "center" }}>
          {" "}
          <button
            onClick={() => {
              runAssetActions();
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4">
            Done
          </button>
          <button
            onClick={() => {
              setProjectFiles(initialFiles);
              setProjectURLs(initialURLs);
              setProjectRefText(initialRefText);
              setModalIsOpen(false);
            }}
            className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ml-4">
            Cancel
          </button>
        </div>
        {/* </Dialog.Panel> */}
        {/* </div> */}
        {/* </Dialog> */}
      </Modal>
    </>
  );
};
