import React, { useState, useEffect } from "react";
import OutputGrid from "src/components/admin/outputGrid";
const InspectPromptPage: React.FC = () => {
  return (
    <div className="flex flex-col h-full w-full p-4">
      <OutputGrid />
    </div>
  );
};
export default InspectPromptPage;
