import React, { useState, MouseEvent, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import useOutsideClick from "src/hooks/useOutsideClick";
import "./ellipsisMenu.scss";
import { RootState, useAppDispatch } from "src/configureStore";
import { DeleteIcon, PenIcon, EllipsisIcon, PlusCircle } from "src/lib/icons/index";
import { fetchClientData } from "src/reducers/clientReducer";
import Switch from "@mui/material/Switch";

interface EllipsisMenuProps {
  onRename: (event: MouseEvent) => void;
  onDelete: (event: MouseEvent) => void;
  currentItem?: any;
  color?: string | undefined;
  includeNewDocument?: boolean;
  includeNewProject?: boolean;
  openNewDocument: (event: MouseEvent) => void;
  createNewProject?: (event: MouseEvent, item: any) => void;
  setIsFolderPrivate: (folder: any, isPrivate: boolean) => void;
  isCurrentUserAdmin: () => boolean; // Indicates if the current user is an admin
  isCurrentUserOwner: (folder: any) => boolean; // Indicates if the current user is the owner∂
}

const EllipsisMenu: React.FC<EllipsisMenuProps> = ({
  onRename,
  onDelete,
  color,
  includeNewDocument,
  includeNewProject,
  openNewDocument,
  createNewProject,
  currentItem,
  setIsFolderPrivate,
  isCurrentUserAdmin, // Add prop to check if current user is admin
  isCurrentUserOwner // Add prop to check if current user is the owner
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const clientData = useSelector((state: RootState) => state.clientReducer.clientData);

  const dispatch = useAppDispatch();
  useOutsideClick(menuRef, () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  });

  useEffect(() => {
    async function fetchAndSetClientData() {
      if (clientData === null)
        if (user && user.client && user.client._id) {
          try {
            console.log("FETCH CLIENT EM 1");
            dispatch(fetchClientData(user.client._id));
            // console.log("users", setPricingModalIsOpenclientData?.users);
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
        }
    }

    fetchAndSetClientData();
  }, [user]);

  const handleMenuClick = (event: MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  type SubscriptionType =
    | "enterprise"
    | "enterprise-annual"
    | "blackbaud-gold"
    | "blackbaud-gold-annual"
    | "blackbaud-diamond"
    | "blackbaud-diamond-annual"
    | "pilot";

  const isEnterpriseSubscriptionType = (type?: string): type is SubscriptionType => {
    return type
      ? [
          "enterprise",
          "enterprise-annual",
          "blackbaud-gold",
          "blackbaud-gold-annual",
          "blackbaud-diamond",
          "blackbaud-diamond-annual",
          "pilot"
        ].includes(type)
      : false;
  };
  const defaultColor = "#606368";
  const iconColor = color || defaultColor;

  const handlePrivacyToggle = (event: MouseEvent) => {
    event.stopPropagation();
    // if (isCurrentUserAdmin() || isCurrentUserOwner()) {
    //   console.log("PRIVACY TOGGLE");
    //   // setIsFolderPrivate(!isFolderPrivate);
    // }
  };
  // console.log("currentItem", currentItem);
  // console.log("IS CURRENT USER ADMIN", isCurrentUserAdmin());
  // console.log("IS CURRENT USER OWNER", isCurrentUserOwner(currentItem));
  // console.log("currentItem", currentItem);
  // console.log("CURRENT USER ID", user?._id);
  return (
    <div className="ellipsis-menu-container" ref={menuRef} onClick={handleMenuClick}>
      <EllipsisIcon height={"5px"} color={iconColor} />
      {menuOpen && (
        <div className="ellipsis-menu" style={{ color: "#606368" }}>
          {includeNewDocument && (
            <div onClick={openNewDocument}>
              <span className="mr-2" style={{ width: "12px" }}>
                <PlusCircle height={"12px"} width={"12px"} color={defaultColor} />
              </span>
              <span>New Document</span>
            </div>
          )}
          {includeNewProject && (
            <div
              onClick={(e) => {
                if (createNewProject && currentItem) createNewProject(e, currentItem);
              }}>
              <span className="mr-2" style={{ width: "12px" }}>
                <PlusCircle height={"12px"} width={"12px"} color={defaultColor} />
              </span>
              <span>New Project</span>
            </div>
          )}
          <div onClick={onRename}>
            <span className="mr-2" style={{ width: "12px" }}>
              <PenIcon height={"12px"} width={"12px"} color={defaultColor} />
            </span>
            <span>Rename</span>
          </div>
          <div onClick={onDelete}>
            <span className="mr-2" style={{ width: "12px" }}>
              <DeleteIcon height={"12px"} color={defaultColor} />
            </span>
            <span>Delete</span>
          </div>
          {includeNewProject &&
            isEnterpriseSubscriptionType(clientData?.subscriptionLevel) &&
            (isCurrentUserAdmin() || isCurrentUserOwner(currentItem)) && (
              <div onClick={handlePrivacyToggle} className="privacy-toggle">
                <span className="mr-2">Private:</span>
                <Switch
                  checked={currentItem.isPrivate}
                  onChange={() => {
                    setIsFolderPrivate(currentItem, !currentItem.isPrivate);
                  }}
                />
                {/* <label className="switch">
                  <input
                    type="checkbox"
                    checked={currentItem.isPrivate}
                    onChange={() => {
                      setIsFolderPrivate(currentItem, !currentItem.isPrivate);
                    }}
                  />
                  <span className="slider round"></span>
                </label> */}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default React.memo(EllipsisMenu);
