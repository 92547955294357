import React, { CSSProperties, ReactNode, RefObject } from "react";
import {
  DraggableCore,
  DraggableData,
  DraggableEvent,
  DraggableEventHandler
} from "react-draggable";
import debounce from "lodash.debounce";
import $ from "cash-dom";
import classNames from "classnames/bind";
import style from "./resizePanel.module.scss";

const cx = classNames.bind(style);

interface ResizePanelProps {
  direction: "n" | "s" | "e" | "w";
  containerClass?: string;
  handleClass?: string;
  borderClass?: string;
  style?: CSSProperties;
  children?: ReactNode;
  minWidth?: string;
  minHeight?: string;
  draggingClass?: string;
  initialSize?: number;
  hoverClass?: string;
}

interface ResizePanelState {
  size: number;
  dragging: boolean;
}

class ResizePanel extends React.Component<ResizePanelProps, ResizePanelState> {
  contentRef: RefObject<HTMLDivElement>;
  wrapperRef: RefObject<HTMLDivElement>;
  handleRef: RefObject<HTMLDivElement>;
  private dragStarted: boolean;

  constructor(props: ResizePanelProps) {
    super(props);
    this.state = { size: 0, dragging: false };
    this.dragStarted = false;

    this.contentRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.handleRef = React.createRef();
    this.validateSize = debounce(this.validateSize, 100).bind(this);
  }

  isHorizontal = () => this.props.direction === "w" || this.props.direction === "e";

  override componentDidMount() {
    const content = this.contentRef.current;
    const actualContent = content?.children[0] as HTMLElement;
    let initialSize: number;

    if (this.props.initialSize !== undefined) {
      initialSize = this.props.initialSize;
    } else {
      // initialSize = this.isHorizontal()
      //   ? $(actualContent).outerWidth(true)
      //   : $(actualContent).outerHeight(true);
      initialSize = this.isHorizontal() ? actualContent.offsetWidth : actualContent.offsetHeight;
    }
    // Initialize the size value based on the content's current size
    this.setState({ size: initialSize });
    this.validateSize();
  }

  validateSize() {
    const isHorizontal = this.isHorizontal();
    const content = this.contentRef.current;
    const wrapper = this.wrapperRef.current;
    const actualContent = content?.children[0] as HTMLElement;
    if (this.props.initialSize !== undefined) {
      return;
    }
    const containerParent = wrapper?.parentElement as HTMLElement;

    const minSize = isHorizontal ? actualContent.scrollWidth : actualContent.scrollHeight;

    if (this.state.size !== minSize) {
      this.setState({
        ...this.state,
        size: minSize
      });
    } else {
      const overflow = isHorizontal
        ? containerParent.scrollWidth - containerParent.clientWidth
        : containerParent.scrollHeight - containerParent.clientHeight;

      if (overflow) {
        console.log("overflow", overflow);
        this.setState({
          ...this.state,
          size: isHorizontal
            ? actualContent.clientWidth + overflow
            : actualContent.clientHeight - overflow
        });
      }
    }
  }

  handleDrag: DraggableEventHandler = (e: DraggableEvent, ui: DraggableData) => {
    const { direction } = this.props;
    const factor = direction === "e" || direction === "s" ? -1 : 1;

    // modify the size based on the drag delta
    const delta = this.isHorizontal() ? ui.deltaX : ui.deltaY;
    this.setState((s, p) => ({ size: Math.max(10, s.size - delta * factor) }));
    this.dragStarted = true;
    this.setState({ dragging: true });
  };

  handleDragEnd: DraggableEventHandler = (e: DraggableEvent, ui: DraggableData) => {
    this.validateSize();
    this.resetDraggingState();
  };

  resetDraggingState = () => {
    this.dragStarted = false;
    this.setState({ dragging: false });
  };

  handleMouseUpOrLeave = () => {
    if (this.dragStarted) {
      this.resetDraggingState();
    }
  };

  handleMouseDown = () => {
    this.dragStarted = true;
    this.setState({ dragging: true });
  };

  override render() {
    const overlayStyle: CSSProperties = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 9999,
      pointerEvents: this.state.dragging ? "auto" : "none"
    };

    const dragHandlers = {
      onDrag: this.handleDrag,
      onStop: this.handleDragEnd
    };
    const { direction } = this.props;
    const isHorizontal = this.isHorizontal();

    let containerClass = cx({
      ContainerHorizontal: isHorizontal,
      ContainerVertical: !isHorizontal
    });

    if (this.props.containerClass) {
      containerClass += ` ${this.props.containerClass}`;
    }

    const containerStyle = { ...this.props.style } || {};
    if (this.state.size !== 0) {
      containerStyle.flexGrow = 0;
      containerStyle[isHorizontal ? "width" : "height"] = "auto";
    }

    const handleClasses =
      this.props.handleClass ||
      cx({
        ResizeHandleHorizontal: isHorizontal,
        ResizeHandleVertical: !isHorizontal
      });

    const resizeBarClasses =
      this.props.borderClass ||
      cx({
        ResizeBarHorizontal: isHorizontal,
        ResizeBarVertical: !isHorizontal
      });
    // console.log('setting the width to: ' + this.state.size);
    const contentStyle = isHorizontal
      ? { width: this.state.size + "px", minWidth: this.props.minWidth }
      : { height: this.state.size + "px", minHeight: this.props.minHeight };
    const contentClassName = cx("ResizeContent", {
      ResizeContentHorizontal: isHorizontal,
      ResizeContentVertical: !isHorizontal,
      [this.props.draggingClass || ""]: this.state.dragging
    });

    const content = [
      <div key="content" ref={this.contentRef} className={contentClassName} style={contentStyle}>
        {React.Children.only(this.props.children)}
      </div>
    ];

    const handle = (
      <DraggableCore key="handle" nodeRef={this.handleRef} {...dragHandlers}>
        <div ref={this.handleRef} className={resizeBarClasses} onMouseDown={this.handleMouseDown}>
          <div className={handleClasses}>
            <span />
          </div>
        </div>
      </DraggableCore>
    );

    // Insert the handle at the beginning of the content if our direction is west or north
    if (direction === "w" || direction === "n") {
      content.unshift(handle);
    } else {
      content.push(handle);
    }

    return (
      <>
        <div
          ref={this.wrapperRef}
          className={containerClass}
          style={containerStyle}
          onMouseUp={this.handleMouseUpOrLeave}>
          {content}
        </div>
        <div style={overlayStyle} onMouseUp={this.handleMouseUpOrLeave}></div>
      </>
    );
  }
}

export default ResizePanel;
