import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IClient } from "src/interfaces/IUser";

import { RootState } from "src/configureStore";

interface AuthState {
  isAuthenticated: boolean;
  user: IUser | null;
  token: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null
};
interface AuthPayload {
  user: IUser;
  token: string;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthPayload>) => {
      const { user, token } = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("authToken", token);
      state.isAuthenticated = true;

      state.user = user;
      state.token = token;
    },
    updateClient: (state, action: PayloadAction<any>) => {
      if (state.user && state.user.client) {
        state.user.client = action.payload;
      }
    },
    updateClients: (state, action: PayloadAction<IClient[]>) => {
      if (state.user && state.user.client) {
        state.user.clients = action.payload;
      }
    },

    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    }
  }
});

export const { login, logout, updateUser, updateClient, updateClients } = authSlice.actions;

export default authSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
