import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { IOutput } from "src/interfaces/IProject";

interface AdminOutputState {
  outputs: IOutput[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
}

const initialState: AdminOutputState = {
  outputs: [],
  status: "idle",
  error: null
};

const adminOutputSlice = createSlice({
  name: "adminOutput",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AdminFetchOutputs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AdminFetchOutputs.fulfilled, (state, action: PayloadAction<IOutput[]>) => {
        state.status = "succeeded";
        state.outputs = action.payload;
        state.error = null;
      })
      .addCase(AdminFetchOutputs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});
export const AdminFetchOutputs = createAsyncThunk("adminOutput/fetchOutputs", async () => {
  const response = await axiosInstance.get(
    process.env["REACT_APP_API_SERVER"] + "/api/admin/outputs",
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    }
  );
  return response.data;
});
export default adminOutputSlice.reducer;
