import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./adminSidebarNav.scss";

import { ChevronCircleIcon, RightChevron } from "src/lib/icons/index";

const AdminSidebarNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`adminSidebar ${isOpen ? "open" : "closed"}`}
      style={{
        height: "100%",
        backgroundColor: "#F6F6F6",
        display: "flex",
        flexDirection: "column"
      }}>
      {isOpen ? (
        <div className="arrow-icon" onClick={toggleSidebar}>
          <ChevronCircleIcon
            // className="rotate-chevron"
            height={"20px"}
            width={"20px"}
            color={"rgba(88, 89, 91, 1)"}
          />
        </div>
      ) : (
        <div className="arrow-icon closed" onClick={toggleSidebar}>
          <RightChevron height={"20px"} width={"20px"} color={"rgba(88, 89, 91, 1)"} />
        </div>
      )}

      {isOpen ? (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="flex flex-row p-3" style={{ alignItems: "center" }}>
              <FontAwesomeIcon icon={faBoxArchive} color="gray" size="lg" />
              <div className="pl-2 pr-2 font-semibold text-gray-600">Administration</div>
            </div>
            <div
              onClick={() => {
                navigate("/admin/modelEditor");
              }}
              className="flex flex-row p-1"
              style={{ alignItems: "center", cursor: "pointer" }}>
              Model Editor
            </div>
            <div
              onClick={() => {
                navigate("/admin/modelEditorFullView");
              }}
              className="flex flex-row p-1"
              style={{ alignItems: "center", cursor: "pointer" }}>
              Model Editor Full View
            </div>
            <div
              onClick={() => {
                navigate("/admin/inspectPrompt");
              }}
              className="flex flex-row p-1"
              style={{ alignItems: "center", cursor: "pointer" }}>
              Inspect Prompts
            </div>
            <div
              onClick={() => {
                navigate("/admin/userManagement");
              }}
              className="flex flex-row p-1"
              style={{ alignItems: "center", cursor: "pointer" }}>
              User Management
            </div>
            <div
              onClick={() => {
                navigate("/admin/clientManagement");
              }}
              className="flex flex-row p-1"
              style={{ alignItems: "center", cursor: "pointer" }}>
              Client Management
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row p-3 rotate-90" style={{ marginTop: "30px" }}>
            <FontAwesomeIcon icon={faBoxArchive} color="gray" size="lg" />
            <div className="pl-2 pr-2 font-semibold text-gray-600">Administration</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSidebarNav;
