// import { Provider } from "react-redux";
// import store from "./configureStore";
// import { RootRouter } from "./components/rootRouter";

// const App = () => (
//   <Provider store={store}>
//     <RootRouter />
//   </Provider>
// );

// export default App;

import { Provider } from "react-redux";
import store from "./configureStore";
import { RootRouter } from "./components/rootRouter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { ContextMenuProvider } from "./utils/context";
import Hotjar from "@hotjar/browser";

if (process.env["HOTJAR_SITE_ID"] !== undefined) {
  const siteId = parseInt(process.env["HOTJAR_SITE_ID"] || "3625547", 10);
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}
const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", Arial, sans-serif'
  }
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <ContextMenuProvider> */}
      <RootRouter />
      {/* </ContextMenuProvider> */}
    </Provider>
  </ThemeProvider>
);

export default App;
