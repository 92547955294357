import React, { useState, useEffect } from "react";

import { Dispatch } from "redux";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  GridOptions,
  CellValueChangedEvent,
  CellEditingStoppedEvent,
  ValueSetterParams,
  ColumnApi,
  GridApi
} from "ag-grid-community";
import {
  getAdminDataModels,
  updateWritingModel,
  updateWritingModelAgGrid
} from "src/reducers/admin/adminWritingModelReducer";
import { useAppDispatch } from "src/configureStore";
import { useSelector } from "react-redux";
import { RootState } from "src/configureStore";
import { IWritingModel } from "src/interfaces/IWriting";

const ModelEditorFullView: React.FC = () => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const writingModels = useSelector((state: RootState) => state.adminWritingModelReducer.models);
  const status = useSelector((state: RootState) => state.adminWritingModelReducer.status);
  const error = useSelector((state: RootState) => state.adminWritingModelReducer.error);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [saveData, setSaveData] = useState<IWritingModel[]>([]);

  const columnDefs = [
    {
      field: "modelName",
      headerName: "Name",
      sortable: true,
      filter: true,
      resizable: true,
      editable: true
    },
    {
      field: "description",
      headerName: "Description",
      sortable: true,
      filter: true,
      resizable: true,
      editable: true
    },
    { field: "pillSubtext", headerName: "Subtext", sortable: true, filter: true, editable: true },
    { field: "prompt", headerName: "Prompt", sortable: true, filter: true, editable: true },
    {
      field: "modelType",
      headerName: "Model Type",
      sortable: true,
      filter: true,
      editable: true
    },
    {
      field: "updatedAt",
      headerName: "Last Modified",
      sortable: true,
      resizable: true,
      width: 250,
      filter: true,
      editable: false
    },
    {
      field: "frequencyPenalty",
      headerName: "Frequency Penalty",
      sortable: true,
      filter: true,
      editable: true
    },
    {
      field: "temperature",
      headerName: "Temperature",
      sortable: true,
      filter: true,
      editable: true
    },
    { field: "maxTokens", headerName: "Max Tokens", sortable: true, filter: true, editable: true },
    { field: "topP", headerName: "topP", sortable: true, filter: true, editable: true },
    { field: "category", headerName: "Category", sortable: true, filter: true, editable: true },
    { field: "beta", headerName: "Beta", sortable: true, filter: true, editable: true }
  ];

  const gridOptions = {
    columnDefs,
    rowData: _.cloneDeep(writingModels),
    enableColumnsMenuItem: true,
    onCellEditingStopped: (event: CellEditingStoppedEvent) => {
      cellEditingStopped(event);
    },
    onCellValueChanged: (event: any) => {
      console.log("onCellValueChanged: ", event);
      cellEditingStopped(event);
    },
    enableRangeSelection: true,
    copyHeadersToClipboard: false,
    onGridReady: (params: any) => {
      setGridApi(params.api);
      setColumnApi(params.columnApi);
    }

    // onCellClicked
  };
  const parseClipboardData = (clipboardData: any) => {
    const rows = clipboardData.split("\n");
    const parsedData = [];

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].trim();
      if (row !== "") {
        const columns = row.split("\t");
        parsedData.push(columns);
      }
    }

    return parsedData;
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAdminDataModels());
    }
  }, [status, dispatch]);
  const cellEditingStopped = async (e: CellEditingStoppedEvent) => {
    if (e.newValue !== e.oldValue) {
      const colId = e.column.getColId();
      await dispatch(updateWritingModelAgGrid(e.data, e.newValue, colId));
    }
  };

  return (
    <div className="flex flex-col h-full w-full p-4">
      {status === "loading" && <p>Loading...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div className="flex-grow flex flex-col">
          <h1>Writing Models</h1>

          <div className="ag-theme-alpine" style={{ height: "90%", width: "100%" }}>
            <AgGridReact {...gridOptions} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelEditorFullView;
