import React, { useEffect, useState, MouseEvent, FocusEvent, useRef, KeyboardEvent } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { IWritingModel } from "../interfaces/IWriting";
import { Project } from "src/interfaces/IProject";
import EllipsisMenu from "./ellipsisMenu";
import { renameItem, deleteItem } from "src/reducers/projectReducer";
import "./projectTreeView.scss";
import { fetchDocumentAsset } from "src/reducers/documentAssetReducer";
import { useAppDispatch, RootState } from "src/configureStore";
import {
  setSelectedDocumentTypeById,
  getDocumentTypesSuccess
} from "src/reducers/documentTypeReducer";
import { setSelectedProjectById } from "src/reducers/projectReducer";
import { FolderIcon, FolderOpenIcon } from "src/lib/icons/index";
import { WritingModelSelector } from "src/components/writingModelSelector";
import { createDocumentAsset, setSelectedDocumentAsset } from "src/reducers/documentAssetReducer";
import { createDocumentType } from "src/reducers/documentTypeReducer";
import { fetchProjects, updateProjectIsPrivate } from "src/reducers/projectReducer";
Modal.setAppElement("#root");
export interface TreeItem {
  _id: string;
  name: string;
  objectType: string;
  children?: TreeItem[];
  order?: number;
  isPrivate: boolean;
}

export const ProjectTreeView: React.FC<{
  projects: Project[];
  updateProject: () => void;
  initialExpandedProjects?: string[];
  searchQuery?: string;
}> = ({ projects, updateProject, initialExpandedProjects = [], searchQuery = "" }) => {
  const [expandedProjects, setExpandedProjects] = useState<string[]>(initialExpandedProjects);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState("");
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [renamingItemId, setRenamingItemId] = useState<string | null>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [selectedProjectItemId, setSelectedProjectItemId] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [deleteType, setDeleteType] = useState<string | null>(null);
  const [deleteItemObj, setDeleteItemObj] = useState<any | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { documentId, projectId, documentAssetId } = useParams();
  // const [prevSelectedItem, setPrevSelectedItem] = useState<string | null>(null);
  const firstRender = useRef(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { allWritingModels } = useSelector((state: RootState) => state.writingSets);
  const [selectedModels, setSelectedModels] = useState<IWritingModel[]>(allWritingModels);
  const [newDocProjectId, setNewDocProjectId] = useState<string | null>(null);
  const [newAssetDocumentId, setNewAssetDocumentId] = useState<string | null>(null);
  const [folderIsPrivate, setFolderIsPrivate] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (projects.length > 0) {
      if (projects[projects.length - 1].isNew) {
        setIsRenaming(true);
        setRenamingItemId(projects[projects.length - 1]._id);
        setNewName(projects[projects.length - 1].name);
        setSelectedItemId(projects[projects.length - 1]._id);
        setSelectedProjectItemId(projects[projects.length - 1]._id);
      }
      // add this to set the first project as expanded by default
      if (firstRender.current) {
        const firstProjectId = projects[0]._id;
        setExpandedProjects((prevExpandedProjects) => {
          return [...prevExpandedProjects, firstProjectId];
        });
        firstRender.current = false;
      }
    }

    const newExpandedProjects = [...expandedProjects];
    const newExpandedItems = [...expandedItems];
    // Set expanded items based on the provided parameters

    if (projectId) {
      newExpandedProjects.push(projectId);
      setSelectedProjectById(projectId);
    }

    if (documentId) {
      newExpandedItems.push(documentId);
      setSelectedDocumentTypeById(documentId);
    }

    if (documentAssetId) {
      newExpandedItems.push(documentAssetId);
    }

    setExpandedProjects(Array.from(new Set(newExpandedProjects)));
    setExpandedItems(Array.from(new Set(newExpandedItems)));

    // setExpandedProjects(expandedProjects);
    // setExpandedItems(expandedItems);
  }, [projects, projectId, documentId, documentAssetId]);

  useEffect(() => {
    // const fProject = projects.find((item) => item._id === projectId);
    if (documentAssetId) {
      setSelectedItemId(documentAssetId);
    } else if (documentId) {
      setSelectedItemId(documentId);
    } else if (projectId) {
      setSelectedItemId(projectId);
      setSelectedProjectItemId(projectId);

      // if (fProject !== undefined) {
      //    setExpandedProjects((prevExpanded) => [...prevExpanded, fProject._id]);
      // }

      // dispatch(setSelectedProjectById(projectId));
    }
  }, [projectId, documentAssetId, documentId, projects]);

  useEffect(() => {
    setSelectedModels(allWritingModels);
  }, [allWritingModels]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setExpandedProjects(initialExpandedProjects);
      setExpandedItems([]);
    } else {
      const matchingProjectIds: string[] = [];
      const matchingItemIds: string[] = [];

      projects.forEach((project) => {
        let projectMatches = project.name.toLowerCase().includes(searchQuery.toLowerCase());

        project.children.forEach((child) => {
          let childMatches = child.name.toLowerCase().includes(searchQuery.toLowerCase());

          child.children.forEach((grandChild) => {
            if (grandChild.name.toLowerCase().includes(searchQuery.toLowerCase())) {
              matchingItemIds.push(grandChild._id);
              childMatches = true;
              projectMatches = true;
            }
          });

          if (childMatches) {
            matchingItemIds.push(child._id);
            projectMatches = true;
          }
        });

        if (projectMatches) {
          matchingProjectIds.push(project._id);
        }
      });

      setExpandedProjects([...expandedProjects, ...matchingProjectIds]);
      setExpandedItems([...expandedItems, ...matchingItemIds]);
    }
  }, [searchQuery]);

  const toggleProject = (id: string) => {
    setExpandedProjects((prevExpanded) => {
      const newExpandedSet = new Set(prevExpanded);
      if (newExpandedSet.has(id)) {
        newExpandedSet.delete(id);
      } else {
        newExpandedSet.add(id);
      }
      return Array.from(newExpandedSet);
    });
  };

  const toggleItem = (id: string) => {
    setExpandedItems((prevItemExpanded) => {
      const newExpandedSet = new Set(prevItemExpanded);
      if (newExpandedSet.has(id)) {
        newExpandedSet.delete(id);
      } else {
        newExpandedSet.add(id);
      }
      return Array.from(newExpandedSet);
    });
  };

  const getIdsFromNestedItems = (items: any[]): string[] => {
    let ids: string[] = [];

    items.forEach((item) => {
      ids.push(item._id);

      if (item.children && item.children.length > 0) {
        ids = ids.concat(getIdsFromNestedItems(item.children));
      }
    });

    return [...new Set(ids)]; // Return unique ids.
  };

  const doClick = async (level: number, item: any, event: MouseEvent) => {
    event.stopPropagation();

    if (selectedItemId !== item._id && !isRenaming) {
      // setSelectedItemId(item._id);

      if (level === 0) {
        if (selectedProjectItemId === item._id && !expandedProjects.includes(item._id)) {
          toggleProject(item._id);
        }
        setSelectedProjectItemId(item._id);
        await dispatch(setSelectedDocumentAsset({ wModel: undefined, type: "model" }));
        navigate(`/project/${item._id}`);
        dispatch(setSelectedProjectById(item._id));
      } else if (level === 1) {
        setSelectedProjectItemId(item.project); // Set the selected project ID
        toggleItem(item._id);
      } else if (level === 2) {
        const selectedProject = projects.find((project) => project._id === item.project);
        setSelectedProjectItemId(item.project);
        // setPrevSelectedItem(item._id);
        if (selectedProject) {
          await dispatch(getDocumentTypesSuccess(selectedProject.children));
          await dispatch(setSelectedDocumentTypeById(item.parentId));
        }

        await dispatch(fetchDocumentAsset(item._id));
        navigate(
          `/project/${item.project}/documentType/${item.parentId}/documentAsset/${item._id}`
        );
      }
    }
  };

  const openNewModal = (newDocProject: string | null, newAssetDocument: string) => {
    setSelectedModels(allWritingModels);
    setNewDocProjectId(newDocProject);
    setNewAssetDocumentId(newAssetDocument);
    setIsOpen(true);
  };

  const closeNewModal = () => {
    setIsOpen(false);
  };

  // const createNewDocument = async () => {
  //   //TODO: Create a new document
  //   // alert("TODO: Create a new document!");
  //   setIsOpen(false);
  //   // console.log("selectedModels: ", selectedModels);
  //   if (projectId && documentId && selectedModels !== undefined && selectedModels.length > 0) {
  //     const newDocAsset = await dispatch(
  //       createDocumentAsset(
  //         projectId,
  //         documentId,
  //         selectedModels[0].modelName || "Blank Document",
  //         selectedModels[0]._id
  //       )
  //     );
  //     const selectedProject = projects.find((project) => project._id === projectId);
  //     await dispatch(getDocumentTypesSuccess(selectedProject?.children || []));
  //     await dispatch(setSelectedDocumentTypeById(documentId));
  //     navigate(
  //       `/project/${projectId}/documentType/${documentId}/documentAsset/${newDocAsset.documentAsset._id}`
  //     );

  //     await dispatch(fetchProjects());
  //   }
  //   closeNewModal();
  // };
  const createNewDocument = async () => {
    setIsOpen(false);
    if (
      newDocProjectId &&
      newAssetDocumentId &&
      selectedModels !== undefined &&
      selectedModels.length > 0
    ) {
      const newDocAsset = await dispatch(
        createDocumentAsset(
          newDocProjectId,
          newAssetDocumentId,
          selectedModels[0].modelName || "Blank Document",
          selectedModels[0]._id
        )
      );
      const selectedProject = projects.find((project) => project._id === newDocProjectId);
      await dispatch(getDocumentTypesSuccess(selectedProject?.children || []));
      await dispatch(setSelectedDocumentTypeById(newAssetDocumentId));
      navigate(
        `/project/${newDocProjectId}/documentType/${newAssetDocumentId}/documentAsset/${newDocAsset.documentAsset._id}`
      );

      await dispatch(fetchProjects());
    }
    closeNewModal();
  };
  const handleDelete = async (event: MouseEvent, id: string, objectType: string, item: any) => {
    event.stopPropagation();
    setDeleteId(id);
    setDeleteType(objectType);
    setDeleteItemObj(item);
    setShowDeleteConfirm(true);
  };
  const doDelete = async () => {
    // console.log(`Delete clicked for ${deleteId}`);
    if (deleteId !== null && deleteType !== null && deleteItemObj !== null) {
      await dispatch(deleteItem(deleteId, deleteType));
      if (deleteType === "projects" && projectId === deleteId) {
        navigate(`/`);
        dispatch(setSelectedProjectById(""));
      } else if (deleteType === "documentTypes" && deleteItemObj._id === documentId) {
        navigate(`/project/${deleteItemObj?.project}`);
        dispatch(setSelectedProjectById(deleteItemObj?.project));
      } else if (deleteType === "documentAssets" && deleteItemObj?._id === documentAssetId) {
        const checkProject = projects.find((item) => {
          return item._id === deleteItemObj?.project;
        });
        const checkDocumentType = checkProject?.children.find((item) => {
          return item._id === deleteItemObj?.parentId;
        });
        if (checkDocumentType && checkDocumentType?.children.length > 1) {
          //find the first item that is not the one we are deleting on deleteItemObj._id
          const firstItem = checkDocumentType?.children.find((item) => {
            return item._id !== deleteItemObj?._id;
          });
          navigate(
            `/project/${deleteItemObj.project}/documentType/${deleteItemObj.parentId}/documentAsset/${firstItem?._id}`
          );
        } else {
          navigate(`/project/${deleteItemObj.project}`);
        }
      }
    }
    closeModal();
  };
  const closeModal = () => {
    setShowDeleteConfirm(false);
  };
  const renderItem = (
    item: TreeItem,
    level: number,
    isRenaming: boolean,
    setIsRenaming: (isRenaming: boolean) => void,
    newName: string,
    setNewName: (newName: string) => void,
    renamingItemId: string | null,
    nameInputRef: React.RefObject<HTMLInputElement>,
    parentId: string | null = null
  ) => {
    const isExpanded = expandedProjects.includes(item._id);
    const isItemExpanded = expandedItems.includes(item._id);
    // const levelM = `${level * 15}px`;
    const levelM = `${level === 2 ? level * 15 : 0}px`;

    // Check if the current item is the newly created project and trigger renaming

    const objectType = level === 0 ? "projects" : level === 1 ? "documentTypes" : "documentAssets";
    const handleRename = (id: string, event: React.MouseEvent | null | undefined) => {
      event?.stopPropagation?.();

      // if (Object.entries(event).length > 0) {
      //   event.stopPropagation();
      // }
      // console.log("Renaming item:", id);
      setIsRenaming(true);
      setRenamingItemId(id);
      setNewName(item.name);
    };
    const onTogglePrivacy = (event: MouseEvent) => {
      console.log("Toggle privacy clicked");
    };

    const handleRenameSubmit = async (
      event: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
      id: string
    ) => {
      event.stopPropagation();
      updateProject();
      if (event.type === "blur" || (event as KeyboardEvent<HTMLInputElement>).key === "Enter") {
        console.log(`Rename submitted for ${id} with new name: ${newName}`);
        // Call the API to rename the item, and update the state with the new name
        await dispatch(renameItem(id, newName, objectType));
        setIsRenaming(false);
      } else if ((event as KeyboardEvent<HTMLInputElement>).key === "Escape") {
        // Cancel renaming
        setIsRenaming(false);
        setNewName(item.name);
      }
    };

    // const handleDelete = async (event: MouseEvent, id: string, objectType: string, item: any) => {
    //   event.stopPropagation();
    //   setDeleteId(id);
    //   setDeleteType(objectType);
    //   setDeleteItemObj(item);
    //   setShowDeleteConfirm(true);
    //   // console.log(`Delete clicked for ${id}`);
    //   // await dispatch(deleteItem(id, objectType));
    //   // if (objectType === "projects") {
    //   //   navigate(`/`);
    //   //   dispatch(setSelectedProjectById(""));
    //   // } else if (objectType === "documentTypes") {
    //   //   navigate(`/project/${item.project}`);
    //   //   dispatch(setSelectedProjectById(item.project));
    //   // } else if (objectType === "documentAssets") {
    //   //   navigate(`/project/${item.project}`);
    //   // }
    // };
    // const doDelete = async () => {
    //   console.log(`Delete clicked for ${deleteId}`);
    //   if (deleteId !== null && deleteType !== null && deleteItemObj !== null) {
    //     await dispatch(deleteItem(deleteId, deleteType));
    //     if (deleteType === "projects" && projectId === deleteId) {
    //       navigate(`/`);
    //       dispatch(setSelectedProjectById(""));
    //     } else if (deleteType === "documentTypes" && deleteItemObj._id === documentId) {
    //       navigate(`/project/${deleteItemObj?.project}`);
    //       dispatch(setSelectedProjectById(deleteItemObj?.project));
    //     } else if (deleteType === "documentAssets" && deleteItemObj?._id === documentAssetId) {
    //       const checkProject = projects.find((item) => {
    //         return item._id === deleteItemObj?.project;
    //       });
    //       const checkDocumentType = checkProject?.children.find((item) => {
    //         return item._id === deleteItemObj?.parentId;
    //       });
    //       if (checkDocumentType && checkDocumentType?.children.length > 1) {
    //         //find the first item that is not the one we are deleting on deleteItemObj._id
    //         const firstItem = checkDocumentType?.children.find((item) => {
    //           return item._id !== deleteItemObj?._id;
    //         });
    //         navigate(
    //           `/project/${deleteItemObj.project}/documentType/${deleteItemObj.parentId}/documentAsset/${firstItem?._id}`
    //         );
    //       } else {
    //         navigate(`/project/${deleteItemObj.project}`);
    //       }
    //     }
    //   }
    //   closeModal();
    // };
    // const closeModal = () => {
    //   setShowDeleteConfirm(false);
    // };
    //every other item should hit this if statement
    if (item.isPrivate) {
      return (
        <>
          <div
            key={item._id}
            className={`thethingPrivate level ${level !== 0 ? "tree-item-space" : ""} ${
              level === 1 && isItemExpanded ? "expanded-item-border" : ""
            }`}
            style={{
              paddingLeft: levelM,
              paddingRight: level === 2 ? "4px" : "0px"
            }}>
            <div
              style={{ width: "100%" }}
              className={`flex items-center cursor-pointer text-sm ${
                level === 0
                  ? selectedProjectItemId === item._id
                    ? "project-item private-selected-project"
                    : "project-item private-project-item-unselected"
                  : level === 2
                  ? "tree-item level2-tree-item"
                  : "tree-item level1-tree-item"
              } ${
                selectedItemId === item._id && level !== 0 && level !== 1 ? "selected-item" : ""
              }`}
              onMouseEnter={() => setHoveredItemId(item._id)}
              onMouseLeave={() => setHoveredItemId(null)}
              // onClick={() => level === 0 && toggleProject(item._id)} // Only toggle for level 0 items
            >
              {level < 2 && (
                <>
                  {isExpanded || isItemExpanded ? (
                    <div
                      key={item._id}
                      style={{}}
                      onClick={(e) => {
                        e.stopPropagation();

                        doClick(level, item, e);
                      }}>
                      {level === 0 ? (
                        <div
                          className="project-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleProject(item._id);
                          }}>
                          <FolderOpenIcon height={"16px"} color={"white"} />
                        </div>
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </div>
                  ) : (
                    <div
                      key={item._id}
                      style={{}}
                      onClick={(e) => {
                        e.stopPropagation();
                        doClick(level, item, e);
                      }}>
                      {level === 0 ? (
                        <div
                          key={item._id}
                          className="project-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleProject(item._id);
                          }}>
                          <FolderIcon height={"16px"} color={"white"} />
                        </div>
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  )}
                </>
              )}
              <div
                key={item._id + level}
                style={{ width: "100%" }}
                onDoubleClick={(e) => handleRename(item._id, e)}
                onClick={(e) => {
                  e.stopPropagation();
                  doClick(level, item, e);
                }}>
                {isRenaming && renamingItemId === item._id ? (
                  <input
                    style={{ color: "black" }}
                    className="appearance-none block w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                    ref={nameInputRef}
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={(e) => handleRenameSubmit(e, item._id)}
                    onKeyDown={(e) => handleRenameSubmit(e, item._id)}
                    autoFocus
                  />
                ) : level === 0 ? (
                  <div className="font-semibold">{item.name}</div>
                ) : (
                  <div>{item.name}</div>
                )}
              </div>
              <div className="ml-auto mr-2" style={{ width: "20px", height: "20px" }}>
                {hoveredItemId === item._id && (
                  <EllipsisMenu
                    onRename={(event: MouseEvent) => handleRename(item._id, event)}
                    onDelete={(event: MouseEvent) =>
                      handleDelete(event, item._id, objectType, item)
                    }
                    color={level === 0 ? "white" : undefined}
                    includeNewDocument={level === 1 ? true : false}
                    includeNewProject={level === 0 ? true : false}
                    currentItem={item}
                    createNewProject={async (event, item) => {
                      // if(projects)
                      const checkProject = projects.find((tItem) => {
                        return tItem._id === item?._id;
                      });
                      //check the project for other documentTypes that have the name New Project
                      const checkDocumentType = checkProject?.children.filter((tItem) => {
                        return tItem.name === "New Project";
                      });
                      if (checkDocumentType && checkDocumentType.length > 0) {
                        await dispatch(
                          createDocumentType(
                            item._id,
                            "blank",
                            {
                              name:
                                "New Project(" + (checkDocumentType.length - 1).toString() + ")",
                              children: []
                            },
                            [],
                            [],
                            []
                          )
                        );
                      } else {
                        await dispatch(
                          createDocumentType(
                            item._id,
                            "blank",
                            { name: "New Project", children: [] },
                            [],
                            [],
                            []
                          )
                        );
                      }
                    }}
                    // openNewDocument={(event: MouseEvent) => openNewModal()}
                    openNewDocument={(event: MouseEvent) =>
                      level === 1 && openNewModal(parentId, item._id)
                    }
                    setIsFolderPrivate={(folder, isPrivate) => {
                      // console.log("FOLDER CLICKED");
                      // console.log(folder);
                      dispatch(updateProjectIsPrivate(folder._id, isPrivate));
                    }}
                    isCurrentUserAdmin={() => {
                      if (user) {
                        const foundClientUser = user.client?.users?.find((fUser: any) => {
                          return fUser.id === user._id;
                        });
                        // console.log("FOUnd user admin");
                        // console.log(foundClientUser);

                        if (foundClientUser) {
                          // console.log("IS CURRENT USER ADMIN PTV");
                          // console.log(foundClientUser.userType === "admin");
                          return foundClientUser.userType === "admin";
                        }
                        // console.log(user);
                        return false;
                        // return user.isAdmin;
                      }
                      return false;
                    }}
                    isCurrentUserOwner={(folder) => {
                      if (folder && user && user._id) {
                        console.log(folder);
                        return folder.createdBy === user._id;
                      }
                      return false;
                    }}
                  />
                )}
              </div>
            </div>
            {(isExpanded || isItemExpanded) &&
              (item.children
                ? [...item.children]
                    // .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((child, idx) => (
                      <div key={idx + item._id}>
                        {renderItem(
                          child,
                          level + 1,
                          isRenaming,
                          setIsRenaming,
                          newName,
                          setNewName,
                          renamingItemId,
                          nameInputRef,
                          level === 0 ? item._id : parentId
                        )}
                      </div>
                    ))
                : [])}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            key={item._id}
            className={`notPrivate level ${level !== 0 ? "tree-item-space" : ""} ${
              level === 1 && isItemExpanded ? "expanded-item-border" : ""
            }`}
            style={{
              paddingLeft: levelM,
              paddingRight: level === 2 ? "4px" : "0px"
            }}>
            <div
              style={{ width: "100%" }}
              className={`flex items-center cursor-pointer text-sm ${
                level === 0
                  ? selectedProjectItemId === item._id
                    ? "project-item selected-project"
                    : "project-item project-item-unselected"
                  : level === 2
                  ? "tree-item level2-tree-item"
                  : "tree-item level1-tree-item"
              } ${
                selectedItemId === item._id && level !== 0 && level !== 1 ? "selected-item" : ""
              }`}
              onMouseEnter={() => setHoveredItemId(item._id)}
              onMouseLeave={() => setHoveredItemId(null)}
              // onClick={() => level === 0 && toggleProject(item._id)} // Only toggle for level 0 items
            >
              {level < 2 && (
                <>
                  {isExpanded || isItemExpanded ? (
                    <div
                      key={item._id}
                      style={{}}
                      onClick={(e) => {
                        e.stopPropagation();

                        doClick(level, item, e);
                      }}>
                      {level === 0 ? (
                        <div
                          className="project-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleProject(item._id);
                          }}>
                          <FolderOpenIcon height={"16px"} color={"white"} />
                        </div>
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </div>
                  ) : (
                    <div
                      key={item._id}
                      style={{}}
                      onClick={(e) => {
                        e.stopPropagation();
                        doClick(level, item, e);
                      }}>
                      {level === 0 ? (
                        <div
                          key={item._id}
                          className="project-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleProject(item._id);
                          }}>
                          <FolderIcon height={"16px"} color={"white"} />
                        </div>
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  )}
                </>
              )}
              <div
                key={item._id + level}
                style={{ width: "100%" }}
                onDoubleClick={(e) => handleRename(item._id, e)}
                onClick={(e) => {
                  e.stopPropagation();
                  doClick(level, item, e);
                }}>
                {isRenaming && renamingItemId === item._id ? (
                  <input
                    style={{ color: "black" }}
                    className="appearance-none block w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                    ref={nameInputRef}
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={(e) => handleRenameSubmit(e, item._id)}
                    onKeyDown={(e) => handleRenameSubmit(e, item._id)}
                    autoFocus
                  />
                ) : level === 0 ? (
                  <div className="font-semibold">{item.name}</div>
                ) : (
                  <div>{item.name}</div>
                )}
              </div>
              <div className="ml-auto mr-2" style={{ width: "20px", height: "20px" }}>
                {hoveredItemId === item._id && (
                  <EllipsisMenu
                    onRename={(event: MouseEvent) => handleRename(item._id, event)}
                    onDelete={(event: MouseEvent) =>
                      handleDelete(event, item._id, objectType, item)
                    }
                    color={level === 0 ? "white" : undefined}
                    includeNewDocument={level === 1 ? true : false}
                    includeNewProject={level === 0 ? true : false}
                    currentItem={item}
                    setIsFolderPrivate={(folder, isPrivate) => {
                      // console.log("FOLDER CLICKED");
                      // console.log(folder);
                      dispatch(updateProjectIsPrivate(folder._id, isPrivate));
                    }}
                    createNewProject={async (event, item) => {
                      // if(projects)
                      const checkProject = projects.find((tItem) => {
                        return tItem._id === item?._id;
                      });
                      //check the project for other documentTypes that have the name New Project
                      const checkDocumentType = checkProject?.children.filter((tItem) => {
                        return tItem.name === "New Project";
                      });
                      if (checkDocumentType && checkDocumentType.length > 0) {
                        await dispatch(
                          createDocumentType(
                            item._id,
                            "blank",
                            {
                              name:
                                "New Project(" + (checkDocumentType.length - 1).toString() + ")",
                              children: []
                            },
                            [],
                            [],
                            []
                          )
                        );
                      } else {
                        await dispatch(
                          createDocumentType(
                            item._id,
                            "blank",
                            { name: "New Project", children: [] },
                            [],
                            [],
                            []
                          )
                        );
                      }
                    }}
                    // openNewDocument={(event: MouseEvent) => openNewModal()}
                    openNewDocument={(event: MouseEvent) =>
                      level === 1 && openNewModal(parentId, item._id)
                    }
                    isCurrentUserAdmin={() => {
                      if (user) {
                        const foundClientUser = user.client?.users?.find((fUser: any) => {
                          return fUser.id === user._id;
                        });

                        if (foundClientUser) {
                          // console.log("IS CURRENT USER ADMIN PTV");
                          // console.log(foundClientUser.userType === "admin");
                          return foundClientUser.userType === "admin";
                        }
                        // console.log(user.client);
                        return false;
                        // return user.isAdmin;
                      }
                      return false;
                    }}
                    isCurrentUserOwner={(folder) => {
                      if (folder && user && user._id) {
                        // console.log(folder);
                        return folder.createdBy === user._id;
                      }
                      return false;
                    }}
                  />
                )}
              </div>
            </div>
            {(isExpanded || isItemExpanded) &&
              (item.children
                ? [...item.children]
                    // .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((child, idx) => (
                      <div key={idx + item._id}>
                        {renderItem(
                          child,
                          level + 1,
                          isRenaming,
                          setIsRenaming,
                          newName,
                          setNewName,
                          renamingItemId,
                          nameInputRef,
                          level === 0 ? item._id : parentId
                        )}
                      </div>
                    ))
                : [])}
          </div>
        </>
      );
    }
  };

  return (
    <div>
      {/* {projects.map((project: any, idx: number) => ( */}
      {[...projects]
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .map((project: any, idx: number) => (
          <div key={idx + "hey"}>
            {renderItem(
              project,
              0,
              isRenaming,
              setIsRenaming,
              newName,
              setNewName,
              renamingItemId,
              nameInputRef,
              null
            )}
          </div>
        ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeNewModal}
        contentLabel="Add New Document Dialog"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            maxWidth: "1000px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            display: "flex",
            flexDirection: "column"
          }
        }}>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <WritingModelSelector
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
            writingSetId={"blank"}
            projectId={newDocProjectId}
          />
          <div className="flex mt-1" style={{ justifyContent: "center" }}>
            <button
              className="mt-4 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
              onClick={createNewDocument}>
              Add Document
            </button>
            <button
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ml-4"
              onClick={() => {
                setIsOpen(false);
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showDeleteConfirm}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 150
          },
          content: {
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none"
          }
        }}>
        <h2 style={{ marginBottom: "5px" }}>Are you sure you want to delete this item?</h2>
        <div className="flex mt-4" style={{ flexDirection: "row", justifyContent: "center" }}>
          <button
            onClick={() => {
              doDelete();
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4">
            Delete
          </button>
          <button
            onClick={() => {
              closeModal();
            }}
            className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-gray-500 hover:bg-gray-700 text-white ml-4">
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};
