// $$$AnimatedLogo
import React, { useEffect, useState, useRef } from "react";
import animatedWings from "src/assets/images/animatedWings.svg";

interface AnimatedLogoProps {
  width?: string;
  height?: string;
}

const AnimatedImage: React.FC<AnimatedLogoProps> = ({ width = "100px", height = "100px" }) => {
  const [svgContent, setSvgContent] = useState("");
  const elements = useRef<(HTMLElement | null)[]>([]);
  const currentIndex = useRef(0);

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(animatedWings);
      const text = await response.text();
      setSvgContent(text);
    };

    fetchSvg();
  }, []);

  useEffect(() => {
    elements.current = [
      document.getElementById("evenAL9zhoc6"),
      document.getElementById("evenAL9zhoc5"),
      document.getElementById("evenAL9zhoc4"),
      document.getElementById("evenAL9zhoc3")
    ];

    const intervalId = setInterval(() => {
      // Reset all elements to 0
      elements.current.forEach((element) => {
        if (element) {
          element.setAttribute("stroke-opacity", "0");
          element.setAttribute("fill-opacity", "0");
        }
      });

      // Set the stroke-opacity and fill-opacity of all previous elements and current element to 1
      for (let i = 0; i <= currentIndex.current; i++) {
        const element = elements.current[i];
        if (element) {
          element.setAttribute("stroke-opacity", "1");
          element.setAttribute("fill-opacity", "1");
        }
      }

      // Increment the currentIndex
      currentIndex.current += 1;

      // If the currentIndex exceeds the length of the elements array, reset it to 0
      if (currentIndex.current >= elements.current.length) {
        currentIndex.current = 0;
      }
    }, 500); // Interval of 0.5 seconds (500 milliseconds)

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [svgContent]);

  return (
    <div
      style={{
        width,
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <div
        style={{ width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    </div>
  );
};

export default AnimatedImage;
