import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "src/configureStore";
// import axios from "axios";
import axiosInstance from "src/lib/axiosConfig";
import { IWritingModel } from "src/interfaces/IWriting";

// Define the initial state
interface WritingModelsState {
  models: IWritingModel[];
  modelInputs: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}
const initialState: WritingModelsState = {
  models: [],
  modelInputs: [],
  status: "idle",
  error: null
};

const writingModelsSlice = createSlice({
  name: "writingModels",
  initialState,
  reducers: {
    setModelsLoading: (state) => {
      state.status = "loading";
    },
    setModelsSuccess: (state, action: PayloadAction<IWritingModel[]>) => {
      state.status = "succeeded";
      state.models = action.payload;
      state.error = null;
    },
    setModelInputsSuccess: (state, action: PayloadAction<any[]>) => {
      state.modelInputs = action.payload;
    },

    setModelsFailed: (state, action: PayloadAction<string>) => {
      state.status = "failed";
      state.error = action.payload;
    },
    saveModelSuccess: (state, action: PayloadAction<IWritingModel>) => {
      state.status = "succeeded";
      state.models.push(action.payload);
      state.error = null;
    },
    updateModelSuccess: (state, action: PayloadAction<IWritingModel>) => {
      state.status = "succeeded";
      const index = state.models.findIndex((model) => model._id === action.payload._id);
      if (index >= 0) {
        state.models[index] = action.payload;
      }
      state.error = null;
    }
  }
});
export const {
  setModelsLoading,
  setModelsSuccess,
  setModelsFailed,
  saveModelSuccess,
  updateModelSuccess,
  setModelInputsSuccess
} = writingModelsSlice.actions;
export const getAdminDataModels = () => async (dispatch: AppDispatch) => {
  dispatch(setModelsLoading());
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/admin/writingModels";
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    dispatch(setModelsSuccess(response.data));
  } catch (error: any) {
    dispatch(setModelsFailed(error.message));
  }
};
export const getInputsForModel = (modelId: string) => async (dispatch: AppDispatch) => {
  // dispatch(setModelsLoading());
  try {
    const url = process.env["REACT_APP_API_SERVER"] + `/api/admin/inputs/${modelId}`;
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    console.log(response.data);
    dispatch(setModelInputsSuccess(response.data));
  } catch (error: any) {
    console.log("FAILED TO GET MODEL INPUTS");
    // dispatch(setModelsFailed(error.message));
  }
};
// Add a new thunk for saving a model
export const saveWritingModel = (model: IWritingModel) => async (dispatch: AppDispatch) => {
  dispatch(setModelsLoading());
  try {
    const url = process.env["REACT_APP_API_SERVER"] + "/api/admin/writingModels";
    const response = await axiosInstance.post(url, model, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
    });
    dispatch(saveModelSuccess(response.data));
  } catch (error: any) {
    dispatch(setModelsFailed(error.message));
  }
};
// Add a new thunk for updating a model
export const updateWritingModel =
  (
    modelId: string,
    prompt: string,
    temp: number | undefined,
    freq: number | undefined,
    presence: number | undefined,
    maxTokens: number | undefined,
    description: string | undefined,
    modelType: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setModelsLoading());
    try {
      const url = process.env["REACT_APP_API_SERVER"] + `/api/admin/writingModels/${modelId}`;
      const response = await axiosInstance.patch(
        url,
        { prompt, temp, freq, presence, maxTokens, description, modelType },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(updateModelSuccess(response.data));
    } catch (error: any) {
      dispatch(setModelsFailed(error.message));
    }
  };
export const updateWritingModelInputs = (inputs: any) => async (dispatch: AppDispatch) => {
  console.log("STUFF");
  try {
    const url = process.env["REACT_APP_API_SERVER"] + `/api/admin/inputs`;

    const response = await axiosInstance.patch(
      url,
      { inputs },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );

    dispatch(updateModelSuccess(response.data));
  } catch (error: any) {
    // dispatch(setModelsFailed(error.message));
  }
};
export const deleteWritingModelInput = (input: any) => async (dispatch: AppDispatch) => {
  try {
    const url = process.env["REACT_APP_API_SERVER"] + `/api/admin/deleteInput`;

    const response = await axiosInstance.post(
      url,
      { inputId: input },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }
    );
    // dispatch(updateModelSuccess(response.data));
  } catch (error: any) {
    // dispatch(setModelsFailed(error.message));
  }
};
export const updateWritingModelAgGrid = (writingModel: any, newValue: any, column: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      const url =
        process.env["REACT_APP_API_SERVER"] + `/api/admin/writingModels/${writingModel._id}`;
      const saveModel = { ...writingModel };
      saveModel[column] = newValue;
      const response = await axiosInstance.patch(
        url,
        { writingModel: saveModel },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
      );
      dispatch(updateModelSuccess(response.data));
    } catch (error: any) {
      dispatch(setModelsFailed(error.message));
    }
  };
};

export default writingModelsSlice.reducer;
