import React, { useState, useEffect } from "react";
import { setPollingStatus } from "../reducers/documentAssetReducer";
import { deleteAssets } from "src/reducers/projectReducer";
import { scrapeUrl } from "../reducers/documentTypeReducer";
import { useAppDispatch } from "src/configureStore";
import { SearchIcon, DeleteIcon } from "src/lib/icons/index";
import validator from "validator";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { styled } from "@mui/material/styles";

interface UrlComponentProps {
  projectId: string | undefined;
  documentTypeId: string | undefined;
  projectURLs: any[];
  setProjectURLs: (projectURLs: any) => void;
  setInitialURLs?: (initialURLs: any) => void;
}

type UrlWithAttributes = {
  url: string;
  referenceType: string;
  isGlobal: boolean;
  _id: string;
};

const UrlComponent: React.FC<UrlComponentProps> = ({
  projectId,
  documentTypeId,
  projectURLs,
  setProjectURLs,
  setInitialURLs
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredURLs, setFilteredURLs] = useState<UrlWithAttributes[]>([]);
  const [newURLReferenceType, setNewURLReferenceType] = useState("");
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [URLToDelete, setURLToDelete] = useState<UrlWithAttributes | null>(null);
  const [showDeleteSingleURLDialog, setShowDeleteSingleURLDialog] = useState(false);

  const atLeastOneUrlSelected = (): boolean => {
    return projectURLs.some((url) => url.selected);
  };

  const StyledTableCell = styled(TableCell)({
    paddingTop: "4px !important",
    paddingBottom: "4px !important"
  });

  useEffect(() => {
    if (projectURLs !== undefined) {
      setFilteredURLs(
        projectURLs.filter(
          (url) => url.url && url.url.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  }, [projectURLs, searchInput]);

  const handleSearchInputChange = (e: any, type: string) => {
    if (type === "url") {
      setSearchInput(e.target.value);
    }
  };
  const handleToggleURL = (url: any) => {
    setProjectURLs(
      _.cloneDeep(projectURLs).map((sUrl: any, i: number) => {
        if (sUrl._id === url._id) {
          sUrl.selected = !sUrl.selected;
        }
        return sUrl;
      })
    );
  };

  const handleAddProjectURL = async (addUrl: UrlWithAttributes) => {
    if (projectId !== undefined) {
      dispatch(setPollingStatus("running"));
      const pURL = await dispatch(
        scrapeUrl(projectId, addUrl.url, documentTypeId, addUrl.referenceType, addUrl.isGlobal)
      );
      const newPURL = { ...pURL, selected: true, referenceType: newURLReferenceType };

      setProjectURLs((prevProjectURLs: any) => [...prevProjectURLs, newPURL]); // Add the new object to the project URLs
      if (setInitialURLs) {
        setInitialURLs((prevInitialURLs: any) => [...prevInitialURLs, newPURL]);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddButtonClick();
    }
  };
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  // Might need to use this one for updating project URLs
  const handleAttributeChange = (url: UrlWithAttributes, referenceType: string) => {
    const index = projectURLs.findIndex((u) => u === url);
    const updatedProjectUrls = projectURLs.map((currentUrl, i) =>
      i === index ? { ...currentUrl, referenceType } : currentUrl
    );
    setProjectURLs(updatedProjectUrls);
  };

  async function handleAddButtonClick() {
    if (validator.isURL(inputValue)) {
      // const sanitizedUrl = validator.escape(inputValue);
      // handleAddProjectURL({ _id: "", url: sanitizedUrl, referenceType: newURLReferenceType });
      handleAddProjectURL({
        _id: "",
        url: inputValue,
        referenceType: newURLReferenceType,
        isGlobal
      });
      setInputValue(""); // Clear the input field
      setModalIsOpen(false);
      setNewURLReferenceType("");
    } else {
      setInvalidUrl(true);
    }
  }

  const handleRemoveSelectedURLs = () => {
    // Get the list of removed URLs
    const removedURLs = projectURLs.filter((url: any) => url.selected === true);

    // Update the projectURLs to exclude removed URLs
    setProjectURLs((prevProjectURLs: any) =>
      prevProjectURLs.filter((url: any, i: any) => url.selected === false)
    );

    // Do something with removedURLs (e.g., log them or store them somewhere)
    if (projectId) dispatch(deleteAssets(projectId, removedURLs));
    setShowDeleteDialog(false);
  };

  //Deletes single file from the trashcan icon in the table
  const handleDeleteSingleURL = (URLToRemove: any) => {
    setShowDeleteSingleURLDialog(true);
    setURLToDelete(URLToRemove);
  };

  //Confirm single file delete from the trashcan icon in the table
  const handleDeleteConfirmed = () => {
    setProjectURLs((prevProjectURLs: any) =>
      prevProjectURLs.filter((url: any) => url !== URLToDelete)
    );
    if (projectId && URLToDelete) {
      dispatch(deleteAssets(projectId, [URLToDelete._id]));
    }
    setShowDeleteSingleURLDialog(false);
    setURLToDelete(null);
  };

  //Cancel single file delete from the trashcan icon in the table
  const handleDeleteCancelled = () => {
    setShowDeleteSingleURLDialog(false);
    setURLToDelete(null);
  };

  return (
    <div
      className="urlComponent mb-2"
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        // padding: "20px",
        // border: "1px solid #B3B3B3",
        // borderRadius: "4px",
        borderBottom: "1px solid #B3B3B3",
        borderTop: "1px solid #B3B3B3",
        // maxHeight: "40vh",
        // overflowY: "auto"
        overflowY: "hidden"
      }}>
      <div
        className=""
        style={{
          // padding: "20px",
          display: "flex",
          flex: "1 1 auto",
          // zIndex: 102,
          // background:"white",
          flexDirection: "column",
          overflowY: "hidden"
        }}>
        {/* <div className="font-semibold mb-2">Add Reference Reference</div> */}
        {modalIsOpen ? (
          <div style={{ flex: "0 1 auto", display: "flex", flexDirection: "column" }}>
            <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
            <div
              className="bg-white p-6 rounded shadow-md"
              style={{
                padding: "20px",
                display: "flex",
                flex: "1 1 auto",
                zIndex: 102,
                flexDirection: "column",
                // backgroundColor: "rgba(63,161,215,0.25)",
                backgroundColor: "white",
                overflowY: "hidden",
                marginLeft: "10vw",
                marginRight: "10vw"
                // overflowY: "auto"
              }}>
              <div className="font-semibold mb-2">
                Add a website address for retrieving reference information
              </div>
              <label
                htmlFor="website-address"
                className="block mt-4"
                style={{ color: "rgb(78, 78, 78)" }}>
                Website Address
              </label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  id="website-address"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  className="appearance-none block w-full px-1 py-0.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <select
                  disabled={!inputValue}
                  // value={url?.referenceType || ""}
                  value={newURLReferenceType || ""}
                  onChange={(e) => {
                    // if (inputValue) {
                    //   handleUrlAttributeChange(inputValue, e.target.value);
                    // }
                    setNewURLReferenceType(e.target.value);
                  }}
                  style={{ flex: "0 1 auto" }}
                  // className={`ml-2 appearance-none block w-full px-1 py-1 border ${
                  //   url?.referenceType ? "border-gray-300" : "border-red-500"
                  // } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
                  className={`ml-2 appearance-none block px-1 py-0.5 ${
                    !inputValue
                      ? "text-white rounded bg-gray-400 cursor-not-allowed"
                      : `border ${
                          // url?.referenceType ? "border-gray-300" : "border-red-500"
                          newURLReferenceType ? "border-gray-300" : "border-red-500"
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`
                  }`}>
                  <option value="" disabled hidden>
                    Select a Reference Type
                  </option>
                  <option value="exampleGrantProposals">Example Grant Proposals</option>
                  <option value="funderInfo">Funder Info</option>
                  <option value="grantGuidelines">Grant Guidelines</option>
                  <option value="organizationInfo">Organization Info</option>
                  <option value="storyElement">Story Element</option>
                </select>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={isGlobal}
                      onChange={(e) => setIsGlobal(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2">Make this URL global</span>
                  </label>
                </div>
              </div>
              <p style={{ fontSize: "14px", height: "20px", color: invalidUrl ? "red" : "fff" }}>
                {invalidUrl ? "That doesn't seem to be a valid URL" : ""}
              </p>
              <div className="flex mt-4" style={{ justifyContent: "center" }}>
                <button
                  disabled={!inputValue || !newURLReferenceType}
                  className={`mr-2 font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline ${
                    inputValue && newURLReferenceType
                      ? "bg-fw-med-blue hover:bg-fw-dark-blue text-white"
                      : "text-white font-semibold rounded bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={handleAddButtonClick}>
                  Add
                </button>
                <button
                  className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setModalIsOpen(false);
                    setInputValue("");
                    setNewURLReferenceType("");
                    setIsGlobal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflowY: "hidden"
            }}>
            <div className="font-semibold">Reference Websites</div>
            <div className="mb-2" style={{ color: "#323232", fontSize: "small" }}>
              To add a URL, click the &quot;Add URL&quot; button, paste the web link, and pick the
              reference type for the URL.
            </div>
            <div className="flex justify-between items-center mb-2">
              <span style={{ flex: "0 0 auto" }}>
                <SearchIcon
                  className="mr-2"
                  height={"15px"}
                  width={"15px"}
                  color={"rgba(88, 89, 91, 1)"}
                />
              </span>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => {
                  handleSearchInputChange(e, "url");
                }}
                className="w-full appearance-none block w-full placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                style={{
                  borderBottom: "1px solid rgba(88, 89, 91, 1)",
                  width: "250px",
                  marginRight: "auto"
                }}
                placeholder="Search..."
              />
            </div>
            <div
              style={{
                width: "100%",
                minHeight: "75px",
                flex: "1 1 auto",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
              className="p-1 overflow-y-auto mb-2 border border-gray-300 divide-y divide-gray-300 rounded-md">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-select-col table-header-cell">
                      Select
                    </TableCell>
                    <TableCell size="small" className="table-cell-first-col table-header-cell">
                      URL
                    </TableCell>
                    <TableCell
                      size="small"
                      className="table-cell-last-col table-header-cell"
                      style={{ width: "25%" }}>
                      Reference Type
                    </TableCell>
                    <TableCell style={{}}></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <TableContainer>
                <Table>
                  <TableBody>
                    {[...filteredURLs]
                      .sort((a, b) => a.url.toLowerCase().localeCompare(b.url.toLowerCase()))
                      .map((url: any, index) => (
                        // {filteredURLs.map((url: any, index) => (
                        <TableRow key={index}>
                          <StyledTableCell size="small" className="table-cell-select-col">
                            <input
                              type="checkbox"
                              checked={url.selected}
                              onChange={() => handleToggleURL(url)}
                              className="m-2 custom-checkbox"
                            />
                          </StyledTableCell>
                          <StyledTableCell size="small" className="table-cell-first-col">
                            {new DOMParser().parseFromString(url.url, "text/html").body.textContent}
                            {url.isGlobal && (
                              <span
                                className="ml-2 p-1 text-xs font-semibold text-white rounded-md"
                                style={{ display: "inline-block", backgroundColor: "#3fa1d7" }}>
                                Global
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className="table-cell-last-col"
                            style={{ width: "25%" }}>
                            <select
                              value={url.referenceType || ""}
                              onChange={(e) => {
                                handleAttributeChange(url, e.target.value);
                              }}
                              className={`appearance-none block w-full px-2 py-0.5 border ${
                                url.referenceType ? "border-gray-300" : "border-red-500"
                              } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
                              <option value="" disabled hidden>
                                Select a Reference Type
                              </option>
                              <option value="exampleGrantProposals">Example Grant Proposals</option>
                              <option value="funderInfo">Funder Info</option>
                              <option value="grantGuidelines">Grant Guidelines</option>
                              <option value="organizationInfo">Organization Info</option>
                              <option value="storyElement">Story Element</option>
                            </select>
                          </StyledTableCell>
                          <StyledTableCell size="small" className="table-cell" style={{}}>
                            <button onClick={() => handleDeleteSingleURL(url)}>
                              <DeleteIcon
                                className="mr-2"
                                height={"15px"}
                                width={"15px"}
                                color={"rgba(88, 89, 91, 1)"}
                              />
                            </button>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="flex items-center">
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded mr-4"
                onClick={() => setModalIsOpen(true)}>
                Add URL
              </button>
              <button
                disabled={!atLeastOneUrlSelected()}
                className={`ml-4 py-0.5 px-2 rounded focus:outline-none focus:shadow-outline font-semibold ${
                  atLeastOneUrlSelected()
                    ? "bg-gray-500 hover:bg-gray-700 text-white"
                    : "text-white font-semibold py-0.5 px-2 rounded bg-gray-400 cursor-not-allowed"
                }`}
                onClick={() => setShowDeleteDialog(true)}>
                Remove URL
              </button>
            </div>
            {showDeleteDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>Are you sure you want to remove the selected URLs?</p>
                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleRemoveSelectedURLs}>
                      Remove
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={() => setShowDeleteDialog(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showDeleteSingleURLDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 101 }}></div>
                <div className="bg-white p-6 rounded shadow-md" style={{ zIndex: 102 }}>
                  <p>
                    Are you sure you want to delete {URLToDelete ? URLToDelete.url : "this URL"}?
                  </p>

                  <div className="flex mt-4" style={{ justifyContent: "center" }}>
                    <button
                      className="font-semibold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline bg-fw-med-blue hover:bg-fw-dark-blue text-white mr-4"
                      onClick={handleDeleteConfirmed}>
                      Delete
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-0.5 px-2 rounded ml-4"
                      onClick={handleDeleteCancelled}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlComponent;
