import React, { useState, useEffect } from "react";
import axios from "axios";

interface VideoThumbnailProps {
  videoUrl: string;
  caption: string;
  platform: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ videoUrl, caption, platform }) => {
  const [showModal, setShowModal] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getYoutubeVideoId = (url: string): string | null => {
    if (!url || typeof url !== "string") {
      console.error(`Invalid URL provided: ${url}`);
      return null;
    }

    const regex = /(?:v=|\/)([0-9A-Za-z_-]{11}).*/;
    const matches = url.match(regex);
    return matches && matches.length > 1 ? matches[1] : null;
  };

  // const getVimeoVideoId = (url: string): string | null => {
  //   const regex = /(?:\/video\/)(\d+).*/;
  //   const matches = url.match(regex);
  //   return matches && matches.length > 1 ? matches[1] : null;
  // };

  const getVimeoVideoId = (url: string): string | null => {
    // Check if url is defined and is a string
    if (!url || typeof url !== "string") {
      console.error(`Invalid URL provided: ${url}`);
      return null;
    }

    const regex = /(?:\/video\/)(\d+).*/;
    const matches = url.match(regex);
    return matches && matches.length > 1 ? matches[1] : null;
  };

  const fetchVimeoThumbnailUrl = async (videoId: string): Promise<string> => {
    try {
      const response = await axios.get(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`
      );
      return response.data.thumbnail_url;
    } catch (error) {
      console.error("Error fetching Vimeo thumbnail:", error);
      return "";
    }
  };

  const videoId = platform === "youtube" ? getYoutubeVideoId(videoUrl) : getVimeoVideoId(videoUrl);

  const embedUrl =
    videoId && platform === "youtube"
      ? `https://www.youtube.com/embed/${videoId}`
      : videoId && platform === "vimeo"
      ? `https://player.vimeo.com/video/${videoId}`
      : "";

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (platform === "youtube" && videoId) {
        setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
      } else if (platform === "vimeo" && videoId) {
        const vimeoThumbnailUrl = await fetchVimeoThumbnailUrl(videoId);
        setThumbnailUrl(vimeoThumbnailUrl);
      }
    };

    fetchThumbnail();
  }, [videoId, platform]);

  // return (
  //   <div className="w-full">
  //     <div className="relative cursor-pointer" onClick={toggleModal}>
  //       <img className="w-full h-auto object-cover" src={thumbnailUrl} alt={caption} />
  //       <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 text-sm">
  //         {caption}
  //       </div>
  //     </div>
  //     {showModal && (
  //       <div
  //         className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75"
  //         style={{ zIndex: 1000 }}
  //         onClick={toggleModal}>
  //         <div className="w-10/12 relative" style={{ paddingBottom: "35%" }}>
  //           <iframe
  //             className="absolute top-0 left-0 w-full h-full"
  //             src={embedUrl}
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //             allowFullScreen
  //             title="Video"></iframe>
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
  return (
    <div className="w-full">
      <div className="relative cursor-pointer" onClick={toggleModal}>
        <img className="w-full h-auto object-cover" src={thumbnailUrl} alt={caption} />
        <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 text-sm">
          {caption}
        </div>
      </div>
      {showModal && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75"
          style={{ zIndex: 1000 }}
          onClick={toggleModal}>
          <div
            className="relative"
            style={{ paddingBottom: "542px", width: "50%" }}
            onClick={(e) => e.stopPropagation()}>
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src={embedUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video"></iframe>
            <div
              className="absolute top-0 left-0 w-full h-full"
              onMouseEnter={(e) => (e.currentTarget.style.pointerEvents = "none")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoThumbnail;
