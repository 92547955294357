import React, { useState, useEffect } from "react";

interface ModelEditorProps {
  model: any;
  onSave: (model: any) => void;
  onCancel: () => void;
}

const ModelEditor: React.FC<ModelEditorProps> = ({ model, onSave, onCancel }) => {
  const [localModel, setLocalModel] = useState(model);

  const handleSave = () => {
    onSave(localModel);
  };

  useEffect(() => {
    setLocalModel(model);
  }, [model]);

  const handleChange = (field: string, value: any) => {
    setLocalModel({ ...localModel, [field]: value });
  };

  // Render the form fields and UI here...

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow flex flex-col h-full">
        {localModel && (
          <div className="flex-grow flex flex-col h-full">
            <div className="flex flex-row">
              <div className="m-1">
                <div>Temperature</div>
                <input
                  style={{ width: "100px" }}
                  className="border border-gray-300"
                  value={localModel.temperature}
                  onChange={(e) => handleChange("temperature", e.target.value)}
                />
              </div>
              <div className="m-1">
                <div>Freq Penalty</div>
                <input
                  style={{ width: "100px" }}
                  className="border border-gray-300"
                  value={localModel.frequencyPenalty}
                  onChange={(e) => handleChange("frequencyPenalty", e.target.value)}
                />
              </div>
              <div className="m-1">
                <div>Presence Penalty</div>
                <input
                  style={{ width: "100px" }}
                  className="border border-gray-300"
                  value={localModel.presencePenalty}
                  onChange={(e) => handleChange("presencePenalty", e.target.value)}
                />
              </div>
              <div className="m-1">
                <div>Max Tokens</div>
                <input
                  style={{ width: "100px" }}
                  className="border border-gray-300"
                  value={localModel.maxTokens}
                  onChange={(e) => handleChange("maxTokens", e.target.value)}
                />
              </div>
              <div className="m-1">
                <div>Model Type</div>
                <input
                  style={{ width: "100px" }}
                  className="border border-gray-300"
                  value={localModel.modelType}
                  onChange={(e) => handleChange("modelType", e.target.value)}
                />
              </div>
            </div>
            <div>Description</div>
            <textarea
              id={"input-description"}
              value={localModel.description}
              className="text-xs w-full border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
              onChange={(e) => handleChange("description", e.target.value)}
            />
            <div>Prompt</div>
            <textarea
              id={"input-prompt"}
              value={localModel.prompt}
              className="text-xs w-full h-full border border-gray-300 p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
              onChange={(e) => handleChange("prompt", e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="mt-6">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0.5 px-2 rounded"
          onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ModelEditor;
