import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchWritingSets, getWritingSetById } from "src/reducers/writingSetReducer";
import { fetchDocumentAsset } from "src/reducers/documentAssetReducer";
import { fetchProjects } from "src/reducers/projectReducer";
import { useAppDispatch } from "src/configureStore";
import { setSelectedProjectById } from "src/reducers/projectReducer";
import { RootState } from "src/configureStore";
import { useParams, useNavigate } from "react-router-dom";
import { WMCard } from "src/components/wmCard";
import { DocumentCard } from "src/components/documentCard";
import AppealImage from "src/assets/images/appeal.svg";
import ProposalImage from "src/assets/images/proposal.svg";
import ThankYouImage from "src/assets/images/thankYou.svg";
import PersonalImage from "src/assets/images/personalEmail.svg";
import BlankDocImage from "src/assets/images/blankDoc.svg";
import OneClickImage from "src/assets/images/oneClickCampaign.svg";

import {
  setSelectedDocumentTypeById,
  getDocumentTypesSuccess,
  setSelectedDocumentType
} from "src/reducers/documentTypeReducer";
import { fetchUserRecents, fetchHelpLinks, IHelpLink } from "src/reducers/clientReducer";
import "./landingPage.scss";
import VideoThumbnail from "src/components/videoThumbnail";
import LinkPreview from "src/components/linkPreview";
import Footer from "src/components/footer";
import { getAllWritingModels } from "src/reducers/writingSetReducer";

const LandingPage = () => {
  const [sProject, setSProject] = useState<any>({});
  const [projectOptions, setProjectOptions] = useState([]);
  const dispatch: Dispatch<any> = useAppDispatch();
  const { projectId, documentAssetId } = useParams();

  const { selectedProject, projects, isLoaded } = useSelector(
    (state: RootState) => state.projectsReducer
  );
  const { userRecents } = useSelector((state: RootState) => state.clientReducer);

  const navigate = useNavigate();
  const { loadedModels } = useSelector((state: RootState) => state.writingSets);
  const user = useSelector((state: RootState) => state.auth.user);
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const checkLOU = setInterval(() => {
      if (window.LOU && window.LOU.startTour) {
        window.LOU.startTour("859910790656");
        clearInterval(checkLOU);
      }
    }, 100); // checks every 100ms

    return () => clearInterval(checkLOU); // clear interval on component unmount
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      dispatch(setSelectedProjectById(projects[0].id));
      setSProject({ label: projects[0].name, value: projects[0]._id });

      // setProjectOptions(
      //   projects.map((project) => {
      //     return { label: project.name, value: project._id };
      //   }) as any
      // );
      setProjectOptions(
        projects
          .map((project) => ({
            label: project.name,
            value: project._id
          }))
          .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) as any
      );
    }
  }, [projects]);
  useEffect(() => {
    if (authToken) {
      if (writingSets.length === 0) {
        dispatch(fetchWritingSets());
      }
      if (userRecents.length === 0) {
        dispatch(fetchUserRecents());
      }
      if (!isLoaded && projects.length === 0) {
        dispatch(fetchProjects());
      }
      if (!loadedModels) {
        if (documentAssetId) {
          dispatch(getAllWritingModels(documentAssetId));
        } else {
          dispatch(getAllWritingModels(""));
        }
      }
    }
  }, [dispatch, authToken]);
  useEffect(() => {
    if (selectedProject === undefined && projects.length > 0 && projectId !== undefined) {
      dispatch(setSelectedProjectById(projectId));
    }
  });

  useEffect(() => {
    dispatch(fetchHelpLinks());
  }, [dispatch]);

  const { writingSets, isLoading } = useSelector((state: RootState) => state.writingSets);
  const helpLinks = useSelector((state: RootState) => state.clientReducer.helpLinks); // Replace YourRootStateType with the type of your Redux root state
  const customSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: "200px" // Set the minimum width
    })
    // You can add more custom styles for other subcomponents here if needed
  };
  function selectImage(wModel: string) {
    switch (wModel) {
      case "Grants & Proposals":
        return ProposalImage;
      case "Thank You & Stewardship":
        return ThankYouImage;
      case "Personal Email Outreach":
        return PersonalImage;
      case "Fundraising Appeal":
        return AppealImage;
      case "One-Click Campaign":
        return OneClickImage;
      default:
        return BlankDocImage;
    }
  }

  return (
    <div className="pr-4 pl-4" style={{ width: "100%" }}>
      <div className="welcome-heading text-xl m-8 font-semibold">Welcome {user?.firstName}!</div>
      <hr style={{ width: "100%" }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
          {/* <div className="m-8"> */}
          {/* {userRecents && ( */}
          {userRecents && userRecents.length > 0 ? (
            <div className="m-8">
              {userRecents.length > 0 ? (
                <div className="section-title text-lg font-semibold mb-4">Recently viewed</div>
              ) : (
                ""
              )}
              <div className="gap-4 flex flex-wrap" style={{}}>
                {(userRecents ?? []).map((item: any, index: any) => {
                  return (
                    <DocumentCard
                      key={index}
                      title={item.name}
                      project={item.parentId.name}
                      image={selectImage(item.parentId.writingSet?.name)}
                      color={item.parentId.writingSet?.backgroundColor}
                      borderColor={item.parentId.writingSet?.borderColor}
                      textColor={item.parentId.writingSet?.textColor}
                      onClick={async () => {
                        const selectedProject = projects.find(
                          (project) => project._id === item.project
                        );
                        await dispatch(getDocumentTypesSuccess(selectedProject.children));
                        await dispatch(setSelectedDocumentTypeById(item.parentId._id));
                        await dispatch(fetchDocumentAsset(item._id));
                        navigate(
                          `/project/${item.project}/documentType/${item.parentId._id}/documentAsset/${item._id}`
                        );
                        // navigate(`/project/${projectId}/writingWizard/${item._id}`);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
          <div id="projectWizards" className="m-4 p-4">
            <div className="section-title text-lg font-semibold mb-4 ">Start a new project</div>
            <div className="mb-2">Select a folder</div>
            <Select
              className="text-xs mb-4 flex"
              styles={customSelectStyles}
              options={projectOptions}
              value={sProject}
              isSearchable={true}
              menuPosition="fixed"
              onChange={(selectedOption: any) => {
                setSProject(selectedOption);
                dispatch(setSelectedProjectById(selectedOption.id));
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="gap-4 flex flex-wrap">
                {writingSets.map((item: any, index: any) => (
                  <WMCard
                    key={index}
                    title={item.name}
                    image={selectImage(item.name)}
                    description={item.description}
                    color={item.backgroundColor}
                    textColor={item.textColor}
                    borderColor={item.borderColor}
                    onClick={async () => {
                      //if the writingSet is a blank document, then we need to create a new documentType that has a documentAsset as a key instead
                      //of adding it to the children array
                      // console.log(item);
                      // TBD: need to add project selector step before creating document
                      // set since no project is selected yet.
                      const newProjectId = projectId || sProject?.value;

                      if (item.name === "Blank Document") {
                        dispatch(setSelectedDocumentType(""));
                        navigate(`/project/${newProjectId}/writingWizard/blank`);
                        return;
                      } else {
                        dispatch(getWritingSetById(item._id));
                        dispatch(setSelectedDocumentType(""));
                        navigate(`/project/${newProjectId}/writingWizard/${item._id}`);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="shadow-lg m-8 p-6"
            style={{
              backgroundColor: "#FBFBFB",
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgb(112,112,112)",
              cursor: "pointer"
            }}>
            <div className="section-title text-lg font-semibold mb-1">News</div>
            <div className="section-subtitle text-sm mb-4">
              The latest updates with Fundwriter.ai
            </div>
            <div className="link-preview">
              <LinkPreview url="https://www.fundwriter.ai/blog/streamline-grant-writing-with-fundwriter-ai" />
            </div>
            <div className="link-preview">
              <LinkPreview url="https://www.fundwriter.ai/blog/fundwriter-raisers-edge-integration" />
            </div>
            {/* <div className="link-preview">
              <LinkPreview url="https://www.fundwriter.ai/blog/using-ai-to-improve-your-nonprofits-fundraising-documents" />
            </div> */}
            <div className="link-preview">
              <LinkPreview url="https://www.fundwriter.ai/blog/5-steps-for-writing-profitable-fundraising-letters" />
            </div>
          </div>
          <div
            className="shadow-lg m-8 p-6"
            style={{
              backgroundColor: "#FBFBFB",
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgb(112,112,112)",
              cursor: "pointer"
            }}>
            <div className="section-title text-lg font-semibold mb-1">Learn</div>
            <div className="section-subtitle text-sm mb-4">Learn how to use Fundwriter.ai</div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {[...helpLinks]
                .sort((a: IHelpLink, b: IHelpLink) => a.order - b.order)
                .filter((link: IHelpLink) => link.active)
                .map((link: IHelpLink) => (
                  <div className="video-thumb" key={link.videoUrl}>
                    <VideoThumbnail
                      videoUrl={link.videoUrl}
                      caption={link.caption}
                      platform={link.platform}
                    />
                  </div>
                ))}

              {/* <div className="video-thumb">
                <VideoThumbnail
                  videoUrl="https://vimeo.com/showcase/10325673/video/817088434"
                  caption="Quick start guide"
                  platform="vimeo"
                />
              </div>
              <div className="video-thumb">
                <VideoThumbnail
                  videoUrl="https://vimeo.com/showcase/10325673/video/817068500"
                  caption="Grants and Proposals"
                  platform="vimeo"
                />
              </div>
              <div className="video-thumb">
                <VideoThumbnail
                  videoUrl="https://vimeo.com/showcase/10325673/video/817067830"
                  caption="Pulling the pieces together"
                  platform="vimeo"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
